import React, { useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import { ChevronDown } from 'react-feather';
import { useSelector } from 'react-redux';
import JsonSchemaEditor from 'components/JsonSchemaEditor';
import { resourceSelectors } from 'store/slices/resourcesSlice';

import RequestParams from './Request/RequestParams';
import RouteHeaders from './RouteHeaders/RouteHeaders';
import { createShallowCopy } from 'utils/helpers';

const additionalParams = [
    { name: 'Headers', state_name: 'headers' },
    { name: 'Path parameters', state_name: 'path_parameters' },
    { name: 'Query parameters', state_name: 'query_parameters' },
];

const RouteFormRequest = ({
    onStateUpdate,
    onUpdateSchema,
    data,
    headers,
    errors,
    globalHeaders,
}) => {
    const [openedParams, setOpenedParams] = useState([]);

    const resources = useSelector(resourceSelectors.getResources);
    const headersRef = useRef(null);
    const pathRef = useRef(null);
    const queryRef = useRef(null);

    const resourcesOptions = resources.map((item) => ({
        value: item.name.toLowerCase(),
        label: item.name,
        id: item.id,
        target: 'resource',
    }));

    const addParameter = (e, item, recent = undefined) => {
        e.stopPropagation();
        const newData = JSON.parse(JSON.stringify(data));

        if (recent) {
            newData[item.state_name].push(recent);
        } else if (item.state_name === 'headers') {
            newData['headers'].push({
                name: '',
                value: '',
                description: '',
            });
        } else {
            newData[item.state_name].push({
                name: '',
                type: 'string',
                is_required: '0',
                description: '',
                resource_id: '',
            });
        }

        if (openedParams.indexOf(item.name) === -1) {
            openedParams.push(item.name);
        }

        setOpenedParams(openedParams);
        onStateUpdate({ data: newData });
    };

    const deleteParameterRow = (index, item) => {
        const newData = JSON.parse(JSON.stringify(data));
        newData[item.state_name].splice(index, 1);

        const openedParamsIndex = openedParams.indexOf(item.name);
        if (openedParamsIndex !== -1 && newData[item.state_name].length === 0) {
            const newOpenedParams = openedParams.slice();
            newOpenedParams.splice(openedParamsIndex, 1);
            setOpenedParams(newOpenedParams);
        }

        onStateUpdate({ data: newData });
    };

    const changeParameter = (e, index, item) => {
        const { name, value } = e.target;
        const newData = JSON.parse(JSON.stringify(data));

        newData[item.state_name][index][name] = value;

        onStateUpdate({ data: newData });
    };

    const onSelectChange = (option, index, item, param) => {
        const newData = JSON.parse(JSON.stringify(data));
        let parameters = [...newData[param.state_name]];
        let newParam = parameters[index];

        if (option?.target === 'resource') {
            newParam['resource_id'] = option?.id;
            newParam.description = null;
        } else {
            newParam[option?.target] = option?.value;
            newParam['resource_id'] = '';
        }

        parameters[index] = { ...newParam };

        const updatedData = { ...data, [param.state_name]: parameters };

        onStateUpdate({ data: updatedData });
    };

    const toggleOpenedParams = (e, param) => {
        const newData = JSON.parse(JSON.stringify(data));
        const findIndex = openedParams.indexOf(param.name);
        const params = newData[param.state_name];

        if (params.length === 0) {
            addParameter(e, param);
        } else {
            findIndex === -1
                ? openedParams.push(param.name)
                : openedParams.splice(findIndex, 1);
            setOpenedParams([...openedParams]);
        }
    };

    const handleRecentHeaders = (e, item) => {
        const newData = JSON.parse(JSON.stringify(data));
        const findIndex = newData['headers'].findIndex((h) => h.id === item.id);

        findIndex === -1
            ? addParameter(e, additionalParams[0], item)
            : deleteParameterRow(findIndex, additionalParams[0]);
    };

    const getSchema = (schema) => {
        onUpdateSchema(schema);
    };

    const scrollToSection = (stateName) => {
        if (stateName === 'headers') {
            scrollTo(headersRef);
        }
        if (stateName === 'path_parameters') {
            scrollTo(pathRef);
        }
        if (stateName === 'query_parameters') {
            scrollTo(queryRef);
        }
    };

    const scrollTo = (ref) => {
        setTimeout(() => {
            window.scrollTo({
                behavior: 'smooth',
                top: ref?.current?.offsetTop,
            });
        }, 100);
    };

    const models = resources?.map((el) => JSON.parse(el.schema));
    const methodsWithBody = ['POST', 'PUT', 'PATCH'];
    const isHeadersOpened = openedParams.includes(additionalParams[0].name);
    const isPathParametersOpened = openedParams.includes(
        additionalParams[1].name,
    );
    const isQueryParametersOpened = openedParams.includes(
        additionalParams[2].name,
    );
    const apiGlobalHeaders = [
        ...createShallowCopy(
            globalHeaders?.filter((el) => el.apply_to_request) || [],
        ),
    ];

    return (
        <div>
            <div className="d-flex mb-4 w-100">
                <button
                    className="btn btn-light"
                    onClick={(e) => {
                        e.preventDefault();
                        addParameter(e, additionalParams[0]);
                        scrollToSection(additionalParams[0].state_name);
                    }}
                    style={{ fontWeight: '500' }}
                >
                    <span style={{ marginRight: '8px' }}>+</span>Header
                </button>
                <button
                    className="btn btn-light mx-3"
                    onClick={(e) => {
                        e.preventDefault();
                        addParameter(e, additionalParams[1]);
                        scrollToSection(additionalParams[1].state_name);
                    }}
                    style={{ fontWeight: '500' }}
                >
                    <span style={{ marginRight: '8px' }}>+</span>Path parameter
                </button>
                <button
                    className="btn btn-light"
                    onClick={(e) => {
                        e.preventDefault();
                        addParameter(e, additionalParams[2]);
                        scrollToSection(additionalParams[2].state_name);
                    }}
                    style={{ fontWeight: '500' }}
                >
                    <span style={{ marginRight: '8px' }}>+</span>Query Parameter
                </button>
            </div>
            {!!data?.headers?.length && (
                <div ref={headersRef}>
                    <span className="anchor" id="headers" />
                    <div
                        onClick={(e) =>
                            toggleOpenedParams(e, additionalParams[0])
                        }
                        className="d-flex justify-content-between align-items-center ps-3 pe-2 mb-2 cursor-pointer"
                        style={{
                            borderBottom: '2px solid #edeff3',
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <h6>{`${additionalParams[0].name} (${
                                data[additionalParams[0].state_name]?.length
                            })`}</h6>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-light mb-1 mt-1 text-start"
                                onClick={(e) =>
                                    addParameter(e, additionalParams[0])
                                }
                            >
                                +
                            </button>
                            <ChevronDown
                                size={16}
                                color="#001A37"
                                className="ms-3"
                                style={{
                                    transform:
                                        isHeadersOpened && 'rotate(180deg)',
                                }}
                            />
                        </div>
                    </div>
                    <Collapse isOpened={isHeadersOpened}>
                        <RouteHeaders
                            items={data?.headers}
                            headers={headers}
                            globalHeaders={apiGlobalHeaders}
                            errors={errors}
                            changeParam={(e, index) =>
                                changeParameter(e, index, additionalParams[0])
                            }
                            deleteRow={(index) =>
                                deleteParameterRow(index, additionalParams[0])
                            }
                            handleRecentHeaders={handleRecentHeaders}
                        />
                    </Collapse>
                </div>
            )}
            {!!data?.path_parameters?.length && (
                <div ref={pathRef}>
                    <span className="anchor" id="path" />
                    <div
                        onClick={(e) =>
                            toggleOpenedParams(e, additionalParams[1])
                        }
                        className="d-flex justify-content-between align-items-center ps-3 pe-2 mb-2 cursor-pointer"
                        style={{
                            borderBottom: '2px solid #edeff3',
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <h6>{`${additionalParams[1].name} (${
                                data[additionalParams[1].state_name]?.length
                            })`}</h6>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-light mb-1 mt-1 text-start"
                                onClick={(e) =>
                                    addParameter(e, additionalParams[1])
                                }
                            >
                                +
                            </button>
                            <ChevronDown
                                size={16}
                                color="#001A37"
                                className="ms-3"
                                style={{
                                    transform:
                                        isPathParametersOpened &&
                                        'rotate(180deg)',
                                }}
                            />
                        </div>
                    </div>
                    <Collapse isOpened={isPathParametersOpened}>
                        <RequestParams
                            paramName={additionalParams[1].name}
                            resourcesOptions={resourcesOptions}
                            items={data[additionalParams[1].state_name]}
                            changeParam={(e, index) =>
                                changeParameter(e, index, additionalParams[1])
                            }
                            deleteRow={(index) =>
                                deleteParameterRow(index, additionalParams[1])
                            }
                            onSelectChange={(e, option, index) =>
                                onSelectChange(
                                    e,
                                    option,
                                    index,
                                    additionalParams[1],
                                )
                            }
                            errors={errors}
                        />
                    </Collapse>
                </div>
            )}
            {!!data?.query_parameters?.length && (
                <div ref={queryRef}>
                    <span className="anchor" id="query" />
                    <div
                        onClick={(e) =>
                            toggleOpenedParams(e, additionalParams[2])
                        }
                        className="d-flex justify-content-between align-items-center ps-3 pe-2 mb-2 cursor-pointer"
                        style={{
                            borderBottom: '2px solid #edeff3',
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <h6>{`${additionalParams[2].name} (${
                                data[additionalParams[2].state_name]?.length
                            })`}</h6>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-light mb-1 mt-1 text-start"
                                onClick={(e) =>
                                    addParameter(e, additionalParams[2])
                                }
                            >
                                +
                            </button>
                            <ChevronDown
                                size={16}
                                color="#001A37"
                                className="ms-3"
                                style={{
                                    transform:
                                        isQueryParametersOpened &&
                                        'rotate(180deg)',
                                }}
                            />
                        </div>
                    </div>
                    <Collapse isOpened={isQueryParametersOpened}>
                        <RequestParams
                            paramName={additionalParams[2].name}
                            resourcesOptions={resourcesOptions}
                            items={data[additionalParams[2].state_name]}
                            changeParam={(e, index) =>
                                changeParameter(e, index, additionalParams[2])
                            }
                            deleteRow={(index) =>
                                deleteParameterRow(index, additionalParams[2])
                            }
                            onSelectChange={(e, option, index) =>
                                onSelectChange(
                                    e,
                                    option,
                                    index,
                                    additionalParams[2],
                                )
                            }
                            errors={errors}
                        />
                    </Collapse>
                </div>
            )}
            {methodsWithBody.includes(data.method) && (
                <div className="mb-4">
                    <JsonSchemaEditor
                        schema={data?.schema}
                        models={models}
                        getSchema={getSchema}
                        schemaType="request"
                    />
                </div>
            )}
        </div>
    );
};

export default RouteFormRequest;
