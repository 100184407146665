import { useLayoutEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import ContactEmail from './ContactEmail';
import CurrentPlan from './CurrentPlan';
import { useDispatch, useSelector } from 'react-redux';
import { getCards } from 'store/slices/billingSlice';
import { options } from './utils/consts';
import PaymentMethod from './PaymentMethod';
import { organizationSelectors } from 'store/slices/organizationSlice';

loadStripe.setLoadParameters({ advancedFraudSignals: false });
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ManageBilling = () => {
    const dispatch = useDispatch();
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    useLayoutEffect(() => {
        if (currentOrg) dispatch(getCards());
    }, [dispatch, currentOrg]);

    return (
        <>
            <div className="d-flex mx-5 justify-content-center">
                {currentOrg && <ContactEmail />}
                <Elements stripe={stripePromise} options={options}>
                    <CurrentPlan />
                    {currentOrg && <PaymentMethod />}
                </Elements>
            </div>
        </>
    );
};

export default ManageBilling;
