import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'utils/http';
import { hideGlobalLoader, showGlobalLoader } from './appSlice';
import { deleteCookie, setCookie } from 'utils/cookie';

export const updateOrganization = createAsyncThunk(
    'organization/updateOrganization',

    async ({ body }, { rejectWithValue }) => {
        try {
            const response = await http.put(`/api/v1/organization`, body);

            return response;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const updateOrganizationInvoiceEmail = createAsyncThunk(
    'organization/updateOrganizationInvoiceEmail',

    async ({ data, onSuccess, onError }, { rejectWithValue }) => {
        try {
            const response = await http.patch(`/api/v1/organization`, data);
            onSuccess(response);
        } catch (error) {
            onError(error);
            return rejectWithValue(error);
        }
    },
);

export const transferOrgansization = createAsyncThunk(
    'organization/transferOrgansization',

    async ({ body }, { dispatch, rejectWithValue }) => {
        dispatch(showGlobalLoader());
        try {
            await http.post(`/api/v1/organization/transfer`, body);
            dispatch(hideGlobalLoader());
        } catch (error) {
            dispatch(hideGlobalLoader());
            return rejectWithValue(error.response.data);
        }
    },
);

export const getOrganizationMembers = createAsyncThunk(
    'organization/getOrganizationMembers',
    async () => {
        try {
            const response = await http.get(`/api/v1/organization/members`);

            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    },
);

export const inviteTeamMember = createAsyncThunk(
    'organization/inviteTeamMember',

    async ({ data, onSuccess, onError }, { dispatch }) => {
        dispatch(showGlobalLoader());
        try {
            const response = await http.post(
                `/api/v1/organization/members`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        }
    },
);

export const deleteTeamMember = createAsyncThunk(
    'organization/deleteTeamMember',

    async ({ id, onSuccess }, { dispatch }) => {
        dispatch(showGlobalLoader());
        try {
            await http.delete(`/api/v1/organization/members/${id}`);
            dispatch(hideGlobalLoader());
            onSuccess();
        } catch (error) {
            console.log(error);

            dispatch(hideGlobalLoader());
            // onError(error);
        }
    },
);

export const updateTeamMemberRole = createAsyncThunk(
    'organization/updateTeamMemberRole',

    async ({ id, data, onSuccess, onError }, { dispatch }) => {
        dispatch(showGlobalLoader());
        try {
            await http.patch(`/api/v1/organization/members/${id}`, data);
            dispatch(hideGlobalLoader());
            onSuccess();
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        }
    },
);

export const getInvitations = createAsyncThunk(
    'organization/getInvitations',
    async () => {
        try {
            const response = await http.get(
                `/api/v1/organization/members/invites`,
            );

            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    },
);

export const resendInvite = createAsyncThunk(
    'organization/resendInvite',
    async ({ inviteId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.post(
                `/api/v1/organization/members/invites/${inviteId}/resend`,
            );
            dispatch(hideGlobalLoader());
            onSuccess();
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const deleteInvite = createAsyncThunk(
    'organization/deleteInvite',
    async ({ inviteId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.delete(
                `/api/v1/organization/members/invites/${inviteId}`,
            );
            dispatch(hideGlobalLoader());
            onSuccess();
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

const initialState = {
    currentOrganization: null,
    invoiceEmailUpdatingStatus: 'idle',
    organizationMembers: [],
    organizationMembersLoadingStatus: 'idle',
    pendingInvites: [],
    pendingInvitesLoadingStatus: 'idle',
};

export const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        setCurrentOrganization(state, action) {
            state.currentOrganization = action.payload;

            const cookieOptions =
                process.env.NODE_ENV === 'development'
                    ? {}
                    : { domain: process.env.REACT_APP_DOMAIN };
            if (action.payload) {
                setCookie('current_org', action.payload.domain, cookieOptions);
            } else {
                deleteCookie('current_org');
            }
        },
    },
    extraReducers: {
        [updateOrganizationInvoiceEmail.pending]: (state) => {
            state.invoiceEmailUpdatingStatus = 'loading';
        },
        [updateOrganizationInvoiceEmail.fulfilled]: (state) => {
            state.invoiceEmailUpdatingStatus = 'succeeded';
        },
        [updateOrganizationInvoiceEmail.rejected]: (state) => {
            state.invoiceEmailUpdatingStatus = 'failed';
        },
        [getOrganizationMembers.pending]: (state) => {
            state.organizationMembersLoadingStatus = 'loading';
        },
        [getOrganizationMembers.fulfilled]: (state, action) => {
            state.organizationMembers = action.payload;
            state.organizationMembersLoadingStatus = 'succeeded';
        },
        [getOrganizationMembers.rejected]: (state) => {
            state.organizationMembersLoadingStatus = 'failed';
        },
        [getInvitations.pending]: (state) => {
            state.pendingInvitesLoadingStatus = 'loading';
        },
        [getInvitations.fulfilled]: (state, action) => {
            state.pendingInvites = action.payload;
            state.pendingInvitesLoadingStatus = 'succeeded';
        },
        [getInvitations.rejected]: (state) => {
            state.pendingInvitesLoadingStatus = 'failed';
        },
    },
});

// Actions

export const { setCurrentOrganization } = organizationSlice.actions;

// Selectors

export const organizationSelectors = {
    getCurrentOrganization: (state) => state.organization.currentOrganization,
    getInvoiceEmailUpdatingStatus: (state) =>
        state.organization.invoiceEmailUpdatingStatus,
    getOrganizationMembersList: (state) =>
        state.organization.organizationMembers,
    getOrganizationMembersLoadingStatus: (state) =>
        state.organization.organizationMembersLoadingStatus,
    getPendingInvites: (state) => state.organization.pendingInvites,
    getPendingInvitesLoadingStatus: (state) =>
        state.organization.pendingInvitesLoadingStatus,
};

export default organizationSlice.reducer;
