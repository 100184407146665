export default function LeftSideBarHeader({ className, children }) {
    return (
        <div
            className={`sidebar-header d-flex ml-3 ${
                className ? className : ''
            }`}
        >
            {children}
        </div>
    );
}
