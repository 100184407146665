import React from 'react';
import RequiredMark from 'ui/RequiredMark/RequiredMark';
import UsersItem from './UsersItem';

const Users = ({ items, changeParam, deleteRow, errors }) => {
    if (items.length > 0) {
        return (
            <div className="pb-3">
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th className="title-table">
                                Username
                                <RequiredMark />
                            </th>
                            <th className="title-table">
                                Password
                                <RequiredMark />
                            </th>
                            <th className="title-table">Description</th>
                            <th className="title-table">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <UsersItem
                                key={index}
                                username={item.username}
                                password={item.password}
                                description={item.description}
                                errors={errors}
                                handleChange={(e) => changeParam(e, index)}
                                deleteRow={(e) => {
                                    deleteRow(e, index);
                                }}
                                index={index}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        );
    } else {
        return <div></div>;
    }
};

export default Users;
