import React, { useEffect, useState } from 'react';
import { getQueryParam } from 'utils/helpers';
import { useDispatch } from 'react-redux';
import { verifyEmail } from 'store/slices/authSlice';

const VerificationPage = () => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const token = getQueryParam('token');
        const data = { token };

        dispatch(verifyEmail({ data, onSuccess, onError }));
    }, [dispatch]);

    const onSuccess = (response) => {
        if (response.status === 200) {
            window.location.href = '/projects';
        }
    };

    const onError = (error) => {
        setErrors(error.response.data);
    };

    return (
        <div className="position-absolute top-50 start-50 translate-middle">
            {errors && (
                <p className="fs-2 text-secondary mb-4">{errors.message}</p>
            )}
        </div>
    );
};

export default VerificationPage;
