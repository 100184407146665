import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchApi } from './apiSlice';
import { fetchMe } from './authSlice';
import { fetchProject } from './projectsSlice';
import { setCurrentOrganization } from './organizationSlice';
import { deleteCookie, getCookie } from 'utils/cookie';

export const init = createAsyncThunk(
    'app/init',
    async ({ projectUid, apiUid, apiCode }, { dispatch }) => {
        dispatch(showGlobalLoader());
        let chain = [dispatch(fetchMe())];
        if (projectUid && projectUid !== undefined) {
            chain.push(dispatch(fetchProject({ pid: projectUid })));
        }

        if (apiUid) {
            chain.push(dispatch(fetchApi({ projectUid, apiUid, apiCode })));
        }

        await Promise.all(chain).then((response) => {
            const account = response[0]?.payload;

            const currentDomain =
                process.env.REACT_APP_DOMAIN === window.location.hostname ||
                window.location.hostname === 'localhost'
                    ? null
                    : window.location.hostname.split('.')[0];
            const currentOrg = account?.organizations.find(
                (el) =>
                    el.domain === currentDomain ||
                    el.domain === getCookie('current_org'),
            );

            if (currentDomain && !currentOrg) {
                deleteCookie('current_org');
                window.location.href =
                    window.location.protocol +
                    '//' +
                    window.location.host.split('.').slice(1).join('.');
            } else if (currentDomain && currentOrg) {
                dispatch(setCurrentOrganization(currentOrg ?? null));
            } else if (!currentDomain && currentOrg) {
                window.location.href = `${window.location.protocol}//${getCookie('current_org')}.${window.location.host}`;
            } else {
                dispatch(setCurrentOrganization(null));
            }
        });

        // await Promise.all(chain);
        dispatch(hideGlobalLoader());
        dispatch(loaded(true));
    },
);

const initialState = {
    showGlobalLoader: false,
    isLoaded: false,
    orgDomain: null,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        showGlobalLoader(state) {
            state.showGlobalLoader = true;
        },
        hideGlobalLoader(state) {
            state.showGlobalLoader = false;
        },
        loaded(state, action) {
            state.isLoaded = action.payload;
        },
        setOrgDomain(state) {
            if (process.env.NODE_ENV === 'development') {
                const subDomain = window.location.hostname.split('.')[0];
                if (subDomain !== 'localhost') {
                    state.orgDomain = subDomain;
                } else {
                    state.orgDomain = null;
                }
            } else {
                const currentDomain =
                    process.env.REACT_APP_DOMAIN === window.location.hostname
                        ? null
                        : window.location.hostname.split('.')[0];
                state.orgDomain = currentDomain;
            }
        },
    },
    extraReducers: {},
});

//Actions

export const { showGlobalLoader, hideGlobalLoader, loaded, setOrgDomain } =
    appSlice.actions;

// Selectors

export const appSelectors = {
    getIsLoaded: (state) => state.app.isLoaded,
    getShowGlobalLoader: (state) => state.app.showGlobalLoader,
    getOrgDomain: (state) => state.app.orgDomain,
};

export default appSlice.reducer;
