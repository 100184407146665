import React, { Fragment, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, useParams } from 'react-router-dom';
import {
    Folder,
    Trash2,
    ChevronLeft,
    Download,
    Upload,
    Edit3,
} from 'react-feather';
import { toast } from 'react-toastify';
import Markdown from 'markdown-to-jsx';

import RoutesList from './RoutesList/RoutesList';
import RoutesTrash from './RoutesTrash';
import PagesTrash from './PagesTrash';
import RouteForm from './RouteForm';
import RouteView from './RouteView';
import GroupFormModal from './GroupFormModal';
import ApiContent from 'components/ApiContent/ApiContent';
import MainContent from 'ui/MainContent';
import { LeftSideBar, LeftSideBarHeader } from 'components/LeftSideBar';
import { Input, InputSearch } from 'ui/Inputs';
import Modal from 'ui/Modal';
import TextEditor from 'components/TextEditor/TextEditor';
import DocumentationContainer from 'pages/Documentation/DocumentationContainer';
import PageForm from './PageForm/PageForm';
import PagesList from './PagesList/PagesList';
import PageView from './PageView/PageView';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors, fetchApi, patchApi } from 'store/slices/apiSlice';
import {
    emptyRoutesTrash,
    fetchRoutesTrash,
    routeSelectors,
} from 'store/slices/routesSlice';
import {
    emptyPagesTrash,
    getTrashedPages,
    pageSelectors,
} from 'store/slices/pagesSlice';
import ListSettingsSelector from './ListSettingsSelector';
import arrowBack from 'assets/img/arrows/arrow-back.svg';
import routesIcon from 'assets/img/routes.svg';
import pageIcon from 'assets/img/page-light.svg';
import { combineClasses as cc } from 'utils/helpers';
import RecentRoutes from './RecentRoutes';
import OpenApiPage from 'pages/OpenApiPage';
import { organizationSelectors } from 'store/slices/organizationSlice';
import { authSelectors } from 'store/slices/authSlice';

export const RoutesContainer = () => {
    const dispatch = useDispatch();
    const { apiCode } = useParams();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const route = useSelector(routeSelectors.getCurrentRoute);
    const routes = useSelector(routeSelectors.getRoutes);
    const routesTrash = useSelector(routeSelectors.getRoutesTrash);
    const pages = useSelector(pageSelectors.getPages);
    const pagesTrash = useSelector(pageSelectors.getPagesTrash);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const [inputSearch, setInputSearch] = useState('');
    const [modalShown, setModalShown] = useState({
        isGroupFormModalShown: false,
        isEmptyTrashModalShown: false,
        isSetApiDescriptionModalShown: false,
    });
    const [descriptionProperties, setDescriptionProperties] = useState({
        description_title: '',
        description_text: '',
    });
    const [sidebarView, setSidebarView] = useState('all');
    const [errors, setErrors] = useState({});
    const [selectedDropdown, setSelectedDropdown] = useState(null);

    let routesList = !!routes?.length
        ? routes.filter((route) => {
              const name = route.name || '';
              const url = route.url || '';

              return (
                  name.toLowerCase().indexOf(inputSearch.toLowerCase()) !==
                      -1 ||
                  url.toLowerCase().indexOf(inputSearch.toLowerCase()) !== -1
              );
          })
        : [];
    let routesTrashList = !!routesTrash?.length
        ? routesTrash.filter((route) => {
              const name = route.name || '';
              const url = route.url;

              return (
                  name.toLowerCase().indexOf(inputSearch.toLowerCase()) !==
                      -1 ||
                  url.toLowerCase().indexOf(inputSearch.toLowerCase()) !== -1
              );
          })
        : [];
    let pagesList = !!pages?.length
        ? pages.filter((page) => {
              const name = page.title || '';

              return (
                  name.toLowerCase().indexOf(inputSearch.toLowerCase()) !== -1
              );
          })
        : [];
    let pagesTrashList = !!pagesTrash?.length
        ? pagesTrash.filter((page) => {
              const name = page.title || '';

              return (
                  name.toLowerCase().indexOf(inputSearch.toLowerCase()) !== -1
              );
          })
        : [];
    const hasOwnerRights =
        (project?.owner?.id === myAccount.id &&
            currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights =
        project?.user_role === 'MAINTAINER' ||
        hasOwnerRights ||
        project?.user_role === 'WRITE' ||
        currentOrg?.role === 'ADMIN';

    const apiRoutesPath = apiCode
        ? '/project/:projectCode/api/:apiCode/routes'
        : '/project/api/routes';
    const { description_title, description_text } = descriptionProperties;
    const {
        isGroupFormModalShown,
        isEmptyTrashModalShown,
        isSetApiDescriptionModalShown,
    } = modalShown;

    useEffect(() => {
        setDescriptionProperties({
            description_title: api?.description_title || '',
            description_text: api?.description_text || '',
        });
    }, [api?.description_text, api?.description_title]);

    useEffect(() => {
        if (route?.deleted_at) {
            setSidebarView('trash');
        }
    }, [route?.deleted_at]);

    const handleChange = (e) => {
        setInputSearch(e.target.value);
    };

    const handleApiDescriptionChange = (text, name) => {
        setDescriptionProperties({
            ...descriptionProperties,
            [name]: text,
        });
    };

    const toggleCreateGroupModal = () => {
        setModalShown({
            ...modalShown,
            isGroupFormModalShown: !isGroupFormModalShown,
        });
    };

    const emptyTrash = () => {
        dispatch(
            emptyRoutesTrash({
                pUid: project?.uid,
                apiId: api.id,
                handleResponse: onSuccessEmptyTrash,
            }),
        );
        dispatch(emptyPagesTrash({ pUid: project?.uid, apiId: api?.id }));
    };

    const onSuccessEmptyTrash = () => {
        dispatch(fetchRoutesTrash({ project: project?.uid, apiId: api?.id }));
        dispatch(getTrashedPages({ projectUid: project?.uid, apiId: api?.id }));
        toast.success('This trash has been emptied');
        setModalShown({ ...modalShown, isEmptyTrashModalShown: false });
    };

    const updateApiDescription = async () => {
        const data = {
            description_title,
            description_text,
        };
        setErrors({});

        await dispatch(
            patchApi({
                projectUid: project?.uid,
                apiUid: api?.uid,
                data,
                onError: onPatchError,
            }),
        ).unwrap();
        toast.success('API description has been saved successfully');
        await dispatch(
            fetchApi({
                projectUid: project?.uid,
                apiUid: api?.uid,
            }),
        ).unwrap();
        toggleSetApiDescriptionModal();
    };

    const onPatchError = (error) => {
        if (error) {
            setErrors(error.response.data.errors);
        }
    };

    const toggleDropdown = (e, id) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setSelectedDropdown(id);
    };

    const toggleSetApiDescriptionModal = () => {
        setModalShown({
            ...modalShown,
            isSetApiDescriptionModalShown: !isSetApiDescriptionModalShown,
        });
        setDescriptionProperties({
            description_title: api?.description_title || '',
            description_text: api?.description_text || '',
        });
    };

    return (
        <ApiContent>
            <LeftSideBar initialWidth={380} minWidth={240}>
                <nav className="h-100 d-flex flex-column">
                    <LeftSideBarHeader className="flex-column">
                        <Fragment>
                            <div className="d-flex align-items-baseline justify-content-between mb-3">
                                <a
                                    href={`/project?project=${project?.uid}`}
                                    className="d-flex align-items-center"
                                >
                                    <img
                                        src={arrowBack}
                                        alt="back"
                                        className="block"
                                    />
                                    <span className="page-header__back">
                                        Back to API's
                                    </span>
                                </a>
                                <>
                                    {sidebarView === 'all' ? (
                                        <div className="d-flex">
                                            <ListSettingsSelector />
                                            {hasRights && (
                                                <button
                                                    className="btn-action hover-default ms-2"
                                                    onClick={
                                                        toggleCreateGroupModal
                                                    }
                                                >
                                                    <Folder
                                                        size={13}
                                                        color="#007BFF"
                                                    />
                                                </button>
                                            )}
                                        </div>
                                    ) : (
                                        hasRights && (
                                            <button
                                                style={{ marginLeft: 'auto' }}
                                                className="btn-action hover-default"
                                                onClick={() =>
                                                    setModalShown({
                                                        ...modalShown,
                                                        isEmptyTrashModalShown:
                                                            !isEmptyTrashModalShown,
                                                    })
                                                }
                                            >
                                                <Trash2
                                                    size={13}
                                                    color="#007BFF"
                                                />
                                            </button>
                                        )
                                    )}
                                </>
                            </div>
                            <InputSearch
                                id="input-search"
                                type="text"
                                placeHolder="Search..."
                                inputStyle={{
                                    width: '100%',
                                    padding: '0.5rem 0.8rem 0.5rem 2rem',
                                    borderRadius: '4px',
                                    maxHeight: '40px',
                                }}
                                labelStyle={{ width: '100%' }}
                                className="form-control"
                                value={inputSearch}
                                onChange={handleChange}
                            />
                        </Fragment>
                    </LeftSideBarHeader>
                    <div className="routes-nav-panel">
                        {sidebarView === 'all' ? (
                            <>
                                <span className="routes-nav-title">
                                    all routes
                                </span>
                                <span
                                    className="routes-nav-trash"
                                    onClick={() => setSidebarView('trash')}
                                >
                                    <Trash2 size={12} color="#001A37" />
                                    Trash (
                                    {Number(
                                        routesTrash?.length +
                                            pagesTrash?.length,
                                    )}
                                    )
                                </span>
                            </>
                        ) : (
                            <>
                                <span className="routes-nav-title">trash</span>
                                <span
                                    className="routes-nav-trash"
                                    onClick={() => setSidebarView('all')}
                                >
                                    <ChevronLeft size={12} color="#001A37" />
                                    Back to all routes
                                </span>
                            </>
                        )}
                    </div>
                    {sidebarView === 'all' ? (
                        <div style={{ paddingBottom: '50px' }}>
                            <PagesList
                                pages={pagesList}
                                toggleDropdown={toggleDropdown}
                                selectedDropdown={selectedDropdown}
                            />
                            <RoutesList
                                routes={routesList}
                                inputSearch={inputSearch}
                                pages={pagesList}
                                toggleDropdown={toggleDropdown}
                                selectedDropdown={selectedDropdown}
                            />
                        </div>
                    ) : (
                        <div style={{ paddingBottom: '50px' }}>
                            {!!routesTrashList?.length ||
                            !!pagesTrashList?.length ? (
                                <>
                                    <PagesTrash
                                        pagesTrash={pagesTrashList}
                                        toggleDropdown={toggleDropdown}
                                        selectedDropdown={selectedDropdown}
                                    />
                                    <RoutesTrash
                                        routesTrash={routesTrashList}
                                        toggleDropdown={toggleDropdown}
                                        selectedDropdown={selectedDropdown}
                                        setSelectedDropdown={
                                            setSelectedDropdown
                                        }
                                    />
                                </>
                            ) : (
                                <div className="create-route-panel not-found mt-1">
                                    <p className="create-route-panel__desc">
                                        No routes or pages found
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                </nav>
            </LeftSideBar>
            <MainContent>
                <div className="main-container m-5">
                    <Route exact path={apiRoutesPath}>
                        <div className="d-flex align-items-center mb-3">
                            <h3 className="title-h3 word-break">
                                {api?.description_title ||
                                    ' Please add some title here...'}
                            </h3>
                            {hasRights ? (
                                <Edit3
                                    onClick={toggleSetApiDescriptionModal}
                                    className={`cursor-pointer`}
                                    color="#007BFF"
                                    size={20}
                                    style={{
                                        minWidth: '20px',
                                        marginLeft: '8px',
                                    }}
                                />
                            ) : null}
                        </div>

                        <div className="ck">
                            {api?.description_text ? (
                                <Markdown className="ck ck-line-height word-break">
                                    {api.description_text}
                                </Markdown>
                            ) : (
                                <p>Please add some description here...</p>
                            )}
                        </div>
                        <div className="mt-4">
                            <h6>Create</h6>
                            <div className="d-flex mt-3">
                                <Link
                                    to={`/project/api/routes/create?project=${project?.uid}&api=${api?.uid}`}
                                    className={cc(
                                        `d-flex align-items-center btn btn-light`,
                                        { disabled: !hasRights },
                                    )}
                                >
                                    <img
                                        width={18}
                                        height={18}
                                        src={routesIcon}
                                        alt="routes icon"
                                    />
                                    <span className="d-block ms-2">Route</span>
                                </Link>
                                <Link
                                    to={`/project/api/pages/create?project=${project?.uid}&api=${api?.uid}`}
                                    className={cc(
                                        `d-flex align-items-center btn btn-light ms-3`,
                                        { disabled: !hasRights },
                                    )}
                                >
                                    <img
                                        width={18}
                                        height={18}
                                        src={pageIcon}
                                        alt="pages icon"
                                    />
                                    <span className="d-block ms-2">Page</span>
                                </Link>
                            </div>
                        </div>
                        <div className="mt-4">
                            <h6>Actions</h6>
                            <div className="d-flex mt-3">
                                <Link
                                    to={`/project/api/settings/import?project=${project?.uid}&api=${api?.uid}`}
                                    className={cc(
                                        `d-flex align-items-center btn btn-light`,
                                        { disabled: !hasRights },
                                    )}
                                >
                                    <Upload
                                        size={20}
                                        strokeWidth={1.5}
                                        color="#007BFF"
                                    />
                                    <span className="d-block ms-2">Import</span>
                                </Link>
                                <Link
                                    to={`/project/api/settings/export?project=${project?.uid}&api=${api?.uid}`}
                                    className={cc(
                                        `d-flex align-items-center btn btn-light ms-3`,
                                        { disabled: !hasRights },
                                    )}
                                >
                                    <Download
                                        size={20}
                                        strokeWidth={1.5}
                                        color="#007BFF"
                                    />
                                    <span className="d-block ms-2">Export</span>
                                </Link>
                            </div>
                        </div>
                        <RecentRoutes
                            routes={routes}
                            routesTrash={routesTrash}
                            pages={pages}
                            pagesTrash={pagesTrash}
                            project={project}
                            api={api}
                        />
                    </Route>
                    <Route path="/project/api/routes/create">
                        <RouteForm />
                    </Route>
                    <Route path="/project/api/routes/:id/edit">
                        <RouteForm />
                    </Route>
                    <Route path="/project/api/routes/browse/:id">
                        <RouteView />
                    </Route>
                    <Route path="/r/:projectCode/:apiCode/:id">
                        <RouteView />
                    </Route>
                    <Route path="/project/api/documentation">
                        <DocumentationContainer />
                    </Route>
                    <Route path="/project/api/pages/create">
                        <PageForm />
                    </Route>
                    <Route path="/project/api/pages/:pageId/edit">
                        <PageForm />
                    </Route>
                    <Route path="/project/api/pages/browse/:pageId">
                        <PageView />
                    </Route>
                    <Route path="/project/api/openapi">
                        <OpenApiPage />
                    </Route>
                </div>
            </MainContent>
            {isGroupFormModalShown && (
                <Modal
                    show={isGroupFormModalShown}
                    title="Add new group"
                    body={
                        <GroupFormModal
                            onClose={toggleCreateGroupModal}
                            onSuccess={toggleCreateGroupModal}
                        />
                    }
                />
            )}
            <Modal
                show={isEmptyTrashModalShown}
                title="Delete"
                body="This trash will be emptied, are you sure?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={emptyTrash}
                        >
                            Empty trash
                        </button>
                        <button
                            type="button"
                            onClick={() =>
                                setModalShown({
                                    ...modalShown,
                                    isEmptyTrashModalShown: false,
                                })
                            }
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
            <Modal
                customClasses={{ modalDialog: 'min-width-600' }}
                show={isSetApiDescriptionModalShown}
                title="Write title and description of the API"
                body={
                    <Fragment>
                        <div className="form-group mt-3">
                            <Input
                                type="text"
                                name="name"
                                className="form-control mt-2"
                                value={description_title}
                                onChange={(e) =>
                                    handleApiDescriptionChange(
                                        e.target.value,
                                        'description_title',
                                    )
                                }
                                labelText="Title"
                                labelClassName="label-main fw-bold"
                                placeHolder="Enter API title"
                                errorText={errors?.description_title}
                            />
                        </div>
                        <div className="form-group mt-3">
                            <label className="label-main fw-bold mb-2">
                                Description
                            </label>
                            <TextEditor
                                description={api?.description_text || ''}
                                handleEditorChange={(editorData) =>
                                    handleApiDescriptionChange(
                                        editorData,
                                        'description_text',
                                    )
                                }
                                editorClassName={'ck-line-height'}
                            />
                        </div>
                    </Fragment>
                }
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={updateApiDescription}
                        >
                            Save
                        </button>

                        <button
                            type="button"
                            onClick={toggleSetApiDescriptionModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
        </ApiContent>
    );
};

export default RoutesContainer;
