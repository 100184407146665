import React from 'react';
import AppLogo from 'components/AppLogo';

const WelcomeFooter = () => {
    return (
        <div className="welcome-footer bg-white">
            <footer className="welcome-footer__container">
                <div className="welcome-footer__content d-flex justify-content-between align-items-start ">
                    <div className="welcome-footer__logo">
                        <AppLogo />
                    </div>
                    <div className="">
                        <h5 className="welcome-footer__title">Start here</h5>
                        <ul className="list-unstyled">
                            <li className="welcome-footer__item">
                                <a
                                    href="/login"
                                    className="welcome-footer__link"
                                >
                                    Log in
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/signup"
                                    className="welcome-footer__link"
                                >
                                    Sign up
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="">
                        <h5 className="welcome-footer__title">Features</h5>
                        <ul className="list-unstyled">
                            <li>
                                <a
                                    href="/#pricing-section"
                                    className="welcome-footer__link"
                                >
                                    Pricing
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="">
                        <h5 className="welcome-footer__title">Product</h5>
                        <ul className="list-unstyled">
                            <li className="welcome-footer__item">
                                <a href="/" className="welcome-footer__link">
                                    About
                                </a>
                            </li>
                            {/* <li className="welcome-footer__item">
                <NavLink to="/partners" className="welcome-footer__link">
                    Partners
                </NavLink>
              </li> */}
                            <li className="welcome-footer__item welcome-footer__item--padding">
                                <a
                                    href="/#contact-section"
                                    className="welcome-footer__link"
                                >
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="welcome-footer__copyright">
                    <p>2022 CDProjects Studio. All Rights Reserved</p>
                </div>
            </footer>
        </div>
    );
};

export default WelcomeFooter;
