import React from 'react';

const DivStyle = {
    position: 'fixed',
    zIndex: '20000000',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 35, 73, .2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export default function GlobalLoader({ isLoading }) {
    return (
        <>
            {isLoading ? (
                <div style={DivStyle}>
                    <div className="d-flex justify-content-center">
                        <div
                            className="spinner-border spinner-border-md text-primary"
                            role="status"
                        ></div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}
