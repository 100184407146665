import { useDrag } from 'react-dnd';

const useDragMethod = (item, type) => {
    const [, drag] = useDrag({
        type,
        item,
    });

    return { drag };
};

export default useDragMethod;
