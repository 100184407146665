import React, { useState } from 'react';
import { ChevronDown } from 'react-feather';
import { Collapse } from 'react-collapse';
import { replaceEnvVariable } from 'utils/helpers';

const RequestHeadersView = (props) => {
    const [isOpened, setIsOpened] = useState(true);

    if (props.items && !!props.items.length) {
        return (
            <div>
                <span className="anchor" id="headers" />
                <div
                    onClick={() => setIsOpened(!isOpened)}
                    className="d-flex justify-content-between align-items-center ps-3 pe-2 cursor-pointer"
                    style={{
                        height: '47px',
                        borderBottom: '2px solid #edeff3',
                    }}
                >
                    <h6 className="text">
                        Headers {`(${props.items.length})`}
                    </h6>
                    <ChevronDown
                        size={16}
                        color="#001A37"
                        style={{
                            transform: isOpened && 'rotate(180deg)',
                        }}
                    />
                </div>
                <Collapse isOpened={isOpened}>
                    <table className="route-view-table w-100">
                        <thead>
                            <tr>
                                <th className="route-view-table-header">
                                    Name
                                </th>
                                <th className="route-view-table-header">
                                    Value
                                </th>
                                <th className="route-view-table-header">
                                    Description
                                </th>
                            </tr>
                        </thead>
                        {props.items.map((header, index) => (
                            <tbody key={index} className="w-100">
                                <tr>
                                    <td className="col-4 text-break">
                                        {header.name}
                                    </td>
                                    <td className="text-break">
                                        {replaceEnvVariable(
                                            header.value,
                                            props.variables,
                                        )}
                                    </td>
                                    <td className="text-break">
                                        {header.description}
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </Collapse>
            </div>
        );
    }

    return <></>;
};

export default RequestHeadersView;
