import React from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store/slices/authSlice';
import { deleteCookie } from 'utils/cookie';

const OrganizationsList = ({ organizations }) => {
    const { protocol } = window.location;
    const myAccount = useSelector(authSelectors.getMyAccount);

    const removeCurrentOrg = () => {
        deleteCookie('current_org');
    };

    return (
        <>
            <div className="px-2">
                <span className="projects-favorite sub-menu p-2 mt-1">
                    Your organizations
                </span>
            </div>
            <div>
                <hr className="dropdown-divider header-divider" />
            </div>
            {!!organizations?.length ? (
                <ul className="starred-projects-container overflow-auto">
                    {organizations?.map((item, index) => (
                        <li key={index}>
                            <a
                                className="dropdown-item link-dropdown py-2 word-break"
                                href={`${protocol}//${item?.domain}.${process.env.REACT_APP_DOMAIN}/projects`}
                                // onClick={() => setCurrentOrg(item?.domain)}
                            >
                                {item?.name || ''}
                            </a>
                        </li>
                    ))}
                    <div>
                        <hr className="dropdown-divider header-divider" />
                    </div>
                    <li key="default">
                        <a
                            className="dropdown-item link-dropdown py-2 word-break"
                            href={`${protocol}//${process.env.REACT_APP_DOMAIN}/projects`}
                            onClick={removeCurrentOrg}
                        >
                            {myAccount?.first_name} {myAccount?.last_name}
                        </a>
                    </li>
                </ul>
            ) : (
                <span className="p-2">You don't have any organization</span>
            )}
        </>
    );
};

export default OrganizationsList;
