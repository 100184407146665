import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import JSONPretty from 'react-json-pretty';
import Tooltip from 'ui/Tooltip';
import useCopyToClipboard from './hooks/useCopyToClipboard';
import { toast } from 'react-toastify';
import { Copy } from 'react-feather';
import { SchemaTypeContext } from './JsonSchemaViewer';
import { useContext } from 'react';
import { isCombiner, literalsMap } from './helpers';

export default function ViewerJsonExample({ data }) {
    const [setCopiedText] = useCopyToClipboard();
    const schemaType = useContext(SchemaTypeContext);

    const filterByBehaviour = useCallback(
        (data) => {
            const typesMap = {
                request: () => data.filter((el) => el.behaviour !== 'read'),
                response: () => data.filter((el) => el.behaviour !== 'write'),
            };
            return typesMap?.[schemaType]?.() || data;
        },
        [schemaType],
    );

    const dataToJson = useCallback((data) => {
        const grouped = _.groupBy(data, (item) =>
            item?.parent ? item?.parent?.id : item.parent,
        );

        const childrenOf = (parent) => {
            return (grouped[parent] || []).reduce((acc, val) => {
                const type = Array.isArray(val.type) ? val.type[0] : val.type;
                const subType = Array.isArray(val.subType)
                    ? val.subType[0]
                    : val.subType;
                const isDetachedRootNode = val.detached && val.name === 'root';
                const isCombinerType = isCombiner(type);

                if (isDetachedRootNode && type === 'object') {
                    return childrenOf(val.id);
                }
                if (
                    isDetachedRootNode &&
                    type === 'array' &&
                    ['object', '$ref'].includes(subType)
                ) {
                    return [childrenOf(val.id)];
                }

                const field =
                    type === 'array' && subType === 'object'
                        ? [childrenOf(val.id)]
                        : type === 'object' || type === '$ref'
                          ? childrenOf(val.id)
                          : isCombinerType
                            ? [childrenOf(val.id)].map(Object.values).flat()
                            : type === 'array' && subType === '$ref'
                              ? [childrenOf(val.id)]
                              : type === 'array'
                                ? subType
                                    ? [literalsMap[subType]]
                                    : []
                                : val?.extraProps?.example || literalsMap[type];

                return {
                    ...acc,
                    ...(val.isCombinerChild
                        ? { [val.id]: field }
                        : { [val.name]: field }),
                };
            }, {});
        };

        return JSON.stringify(Object.values(childrenOf(null))[0], null, 6);
    }, []);

    const onCopyToClipboard = (code) => {
        setCopiedText(code);
        toast.success('Copied to Clipboard');
    };

    const filteredData = filterByBehaviour(data);
    const jsonFromData = useMemo(
        () => dataToJson(filteredData),
        [filteredData, dataToJson],
    );

    return (
        <div className="JsonSchemaViewer__json-example-wrapper">
            <div className="JsonSchemaViewer__json-example-copy-button-wrapper">
                <Tooltip id="copyjson" content="Copy JSON">
                    <button
                        onClick={() => onCopyToClipboard(jsonFromData)}
                        className="JsonSchemaViewer__schema-static-button"
                        type="button"
                    >
                        <Copy size={16} color="#001a37b3" />
                    </button>
                </Tooltip>
            </div>
            <div className="JsonSchemaViewer__examples-code-editor-wrapper">
                <JSONPretty data={jsonFromData} />
            </div>
        </div>
    );
}
