import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'utils/http';
import { hideGlobalLoader, showGlobalLoader } from './appSlice';

export const fetchProject = createAsyncThunk(
    'projects/fetchProject',
    async ({ pid }, { dispatch }) => {
        try {
            dispatch(isProjectFetching(true));
            const response = await http.get(`/api/v1/project/${pid}`);
            dispatch(isProjectFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isProjectFetching(false));
        } finally {
            dispatch(isProjectFetching(false));
        }
    },
);

export const getProjects = createAsyncThunk(
    'projects/getProjects',
    async ({ filters = [] }, { dispatch }) => {
        try {
            dispatch(isProjectsFetching(true));
            let searchParams = new URLSearchParams();
            if (filters.find((el) => el === 'trashed')) {
                searchParams.append('filter[]', 'trashed');
            }
            if (filters.find((el) => el === 'my-projects')) {
                searchParams.append('filter[]', 'my-projects');
            }
            const query = searchParams.toString()
                ? `?${searchParams.toString()}`
                : '';
            const response = await http.get(`/api/v1/projects${query}`);
            dispatch(isProjectsFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isProjectsFetching(false));
            console.log(error);
        } finally {
            dispatch(isProjectsFetching(false));
        }
    },
);

export const getFavorites = createAsyncThunk(
    'projects/getFavorites',
    async (_, { dispatch }) => {
        try {
            dispatch(isStarredProjectsFetching(true));
            const response = await http.get(`/api/v1/projects/favorites`);
            dispatch(isStarredProjectsFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isStarredProjectsFetching(false));
            console.log(error);
        } finally {
            dispatch(isStarredProjectsFetching(false));
        }
    },
);

export const fetchRecentProjects = createAsyncThunk(
    'projects/fetchRecentProjects',
    async (_, { dispatch }) => {
        try {
            dispatch(isRecentProjectsFetching(true));
            const response = await http.get('/api/v1/user/projects/recent');
            dispatch(isRecentProjectsFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isRecentProjectsFetching(false));
            console.log(error);
        } finally {
            dispatch(isRecentProjectsFetching(false));
        }
    },
);

export const toggleFavoriteProject = createAsyncThunk(
    'projects/toggleFavoriteProject',
    async ({ projectUid }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const data = {
                uid: projectUid,
            };
            await http.post(`/api/v1/project/${projectUid}/favorite`, data);
            dispatch(getFavorites());
            dispatch(hideGlobalLoader());
        } catch (error) {
            console.log(error);
            dispatch(hideGlobalLoader());
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const createProject = createAsyncThunk(
    'projects/createProject',
    async ({ data, onSuccess, onResponse, onError, headers }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post('/api/v1/project', data, {
                headers,
            });
            dispatch(hideGlobalLoader());
            onSuccess(response);
            onResponse(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const trashProject = createAsyncThunk(
    'projects/trashProject',
    async ({ uid, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(`/api/v1/project/${uid}/trash`);
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const restoreProject = createAsyncThunk(
    'projects/restoreProject',
    async ({ uid, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(`/api/v1/project/${uid}/restore`);
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const deleteProject = createAsyncThunk(
    'projects/deleteProject',
    async ({ uid, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.delete(`/api/v1/project/${uid}`);
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const updateProject = createAsyncThunk(
    'projects/updateProject',
    async ({ pid, data, onSuccess, onError, headers }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(`/api/v1/project/${pid}`, data, {
                headers,
            });
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const inviteMember = createAsyncThunk(
    'projects/inviteMember',
    async ({ pid, data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/project/${pid}/members/invite`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (errors) {
            onError(errors);
            dispatch(hideGlobalLoader());
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const resendInvite = createAsyncThunk(
    'projects/resendInvite',
    async ({ puid, inviteId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.post(
                `/api/v1/project/${puid}/members/invite/${inviteId}/resend`,
            );
            dispatch(hideGlobalLoader());
            onSuccess();
        } catch (errors) {
            onError(errors);
            dispatch(hideGlobalLoader());
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const getPendingInvites = createAsyncThunk(
    'projects/getPendingInvites',
    async ({ project }, { dispatch }) => {
        try {
            dispatch(isPendingInvitesFetching(true));
            const response = await http.get(
                `/api/v1/project/${project}/members/invites`,
            );
            dispatch(isPendingInvitesFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isPendingInvitesFetching(false));
            console.log(error);
        } finally {
            dispatch(isPendingInvitesFetching(false));
        }
    },
);

export const deleteInvite = createAsyncThunk(
    'projects/deleteInvite',
    async ({ project, inviteId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.delete(
                `/api/v1/project/${project}/members/invite/${inviteId}`,
            );
            onSuccess(response);
            dispatch(hideGlobalLoader());
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const getProjectMembers = createAsyncThunk(
    'project/getProjectMembers',
    async ({ project }, { dispatch }) => {
        try {
            dispatch(isProjectMembersFetching(true));
            const response = await http.get(
                `/api/v1/project/${project}/members`,
            );
            dispatch(isProjectMembersFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isProjectMembersFetching(false));
            console.log(error);
        } finally {
            dispatch(isProjectMembersFetching(false));
        }
    },
);

export const changeRole = createAsyncThunk(
    'projects/changeRole',
    async ({ pid, mid, data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.put(
                `/api/v1/project/${pid}/members/${mid}/role`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const deleteMember = createAsyncThunk(
    'projects/deleteMember',
    async ({ projectUid, memberId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.delete(
                `/api/v1/project/${projectUid}/members/${memberId}`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const getTransfers = createAsyncThunk(
    'projects/getTransfers',
    async (_, { dispatch }) => {
        try {
            dispatch(isTransfersFetching(true));
            const response = await http.get('/api/v1/user/transfers');
            dispatch(isTransfersFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isTransfersFetching(false));
            console.log(error);
        } finally {
            dispatch(isTransfersFetching(false));
        }
    },
);

export const transferProject = createAsyncThunk(
    'projects/transferProject',
    async ({ uid, data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/project/${uid}/transfer`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const cancelTransferProject = createAsyncThunk(
    'projects/cancelTransferProject',
    async ({ uid, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/project/${uid}/transfer/cancel`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (errors) {
            dispatch(hideGlobalLoader());
            onError(errors);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const acceptTransferProject = createAsyncThunk(
    'projects/acceptTransferProject',
    async ({ uid, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/project/${uid}/transfer/accept`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (errors) {
            dispatch(hideGlobalLoader());
            onError(errors);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const declineTransferProject = createAsyncThunk(
    'projects/declineTransferProject',
    async ({ uid, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/project/${uid}/transfer/reject`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (errors) {
            dispatch(hideGlobalLoader());
            onError(errors);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const deleteProjectLogo = createAsyncThunk(
    'projects/deleteProjectLogo',
    async ({ pid, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.delete(`api/v1/project/${pid}/logo`);
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            onError(error);
            dispatch(hideGlobalLoader());
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

const initialState = {
    project: {},
    isProjectFetching: false,

    projects: [],
    isProjectsFetching: false,

    recentProjects: [],
    isRecentProjectsFetching: false,

    starredProjects: [],
    isStarredProjectsFetching: false,

    pendingInvites: [],
    isPendingInvitesFetching: false,

    projectMembers: [],
    isProjectMembersFetching: false,

    isCreateProjectModalShown: false,

    transfers: [],
    isTransfersFetching: false,
};

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        isProjectFetching(state, action) {
            state.isProjectFetching = action.payload;
        },
        isProjectsFetching(state, action) {
            state.isProjectsFetching = action.payload;
        },
        isStarredProjectsFetching(state, action) {
            state.isStarredProjectsFetching = action.payload;
        },
        isRecentProjectsFetching(state, action) {
            state.isRecentProjectsFetching = action.payload;
        },
        isPendingInvitesFetching(state, action) {
            state.isPendingInvitesFetching = action.payload;
        },
        isProjectMembersFetching(state, action) {
            state.isProjectMembersFetching = action.payload;
        },
        isCreateProjectModalShown(state, action) {
            state.isCreateProjectModalShown = action.payload;
        },
        isTransfersFetching(state, action) {
            state.isTransfersFetching = action.payload;
        },
    },
    extraReducers: {
        [fetchProject.fulfilled]: (state, action) => {
            state.project = action.payload;
        },
        [getProjects.fulfilled]: (state, action) => {
            state.projects = action.payload;
        },
        [getFavorites.fulfilled]: (state, action) => {
            state.starredProjects = action.payload;
        },
        [fetchRecentProjects.fulfilled]: (state, action) => {
            state.recentProjects = action.payload;
        },
        [getPendingInvites.fulfilled]: (state, action) => {
            state.pendingInvites = action.payload;
        },
        [getProjectMembers.fulfilled]: (state, action) => {
            state.projectMembers = action.payload;
        },
        [getTransfers.fulfilled]: (state, action) => {
            state.transfers = action.payload;
        },
    },
});

// Actions

export const {
    isProjectFetching,
    isProjectsFetching,
    isStarredProjectsFetching,
    isRecentProjectsFetching,
    isPendingInvitesFetching,
    isProjectMembersFetching,
    isCreateProjectModalShown,
    isTransfersFetching,
} = projectsSlice.actions;

// Selectors

export const projectSelectors = {
    getProjects: (state) => state.projects.projects,
    getCurrentProject: (state) => state.projects.project,
    getIsProjectFetching: (state) => state.projects.isProjectFetching,
    getIsProjectsFetching: (state) => state.projects.isProjectsFetching,
    getIsCreateProjectModalShown: (state) =>
        state.projects.isCreateProjectModalShown,
    getTransfers: (state) => state.projects.transfers,
    getIsTransfersFetching: (state) => state.projects.isTransfersFetching,
    getProjectMembers: (state) => state.projects.projectMembers,
    getIsProjectMembersFetching: (state) =>
        state.projects.isProjectMembersFetching,
    getPendingInvites: (state) => state.projects.pendingInvites,
    getIsPendingInvitesFetching: (state) =>
        state.projects.isPendingInvitesFetching,
    getIsStarredProjectsFetching: (state) =>
        state.projects.isStarredProjectsFetching,
    getIsRecentProjectsFetching: (state) =>
        state.projects.isRecentProjectsFetching,
    getRecentProjects: (state) => state.projects.recentProjects,
    getStarredProjects: (state) => state.projects.starredProjects,
};

export default projectsSlice.reducer;
