import React from 'react';
import CustomSelect from 'ui/CustomSelect';

const options = [
    { value: '200', label: '200 Ok' },
    { value: '201', label: '201 Created' },
    { value: '202', label: '202 Accepted' },
    { value: '204', label: '204 No Content' },
    { value: '206', label: '206 Partial Content' },
    { value: '301', label: '301 Moved Permanently' },
    { value: '400', label: '400 Bad Request' },
    { value: '401', label: '401 Unauthorized' },
    { value: '403', label: '403 Forbidden' },
    { value: '404', label: '404 Not Found' },
    { value: '405', label: '405 Method Not Allowed' },
    { value: '406', label: '406 Not Acceptable' },
    { value: '407', label: '407 Proxy Authentication Required' },
    { value: '409', label: '409 Conflict' },
    { value: '410', label: '410 Gone' },
    { value: '411', label: '411 Length Required' },
    { value: '413', label: '413 Payload Too Larg' },
    { value: '422', label: '422 Unprocessable Entity' },
    { value: '429', label: '429 Too Many Requests' },
];

const selectStyles = {
    menu: (provider, state) => ({
        ...provider,
        right: '0',
        minWidth: '300px',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        width: `${state?.selectProps?.value[0]?.label?.length * 8 + 70}px`,
    }),
};

const ResponseExampleSelect = ({
    value,
    selectResponseStatus,
    deleteResponseExample,
    index,
    onClick,
    items,
}) => {
    const handleClick = (value) => onClick(value);
    const existingValues = new Set(items.map((item) => item.http_status_code));
    const disabledOptions = options.map((option) => {
        return {
            ...option,
            isDisabled: existingValues.has(option.value),
        };
    });
    return (
        <div className="d-flex align-items-center">
            <div className="ms-3 w-100">
                <CustomSelect
                    className="select-main-container"
                    name="http_status_code"
                    options={disabledOptions}
                    onChange={(e) => {
                        handleClick(e.value);
                        selectResponseStatus(e, index);
                    }}
                    value={value}
                    customStyles={selectStyles}
                />
            </div>
            {items.length > 1 && (
                <div className="text-end ms-3">
                    <button
                        className="btn btn-light"
                        onClick={(e) => {
                            deleteResponseExample(e, index);
                        }}
                    >
                        -
                    </button>
                </div>
            )}
        </div>
    );
};

export default ResponseExampleSelect;
