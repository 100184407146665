const HeaderLogo = ({ src }) => {
    return (
        <div>
            <img
                className="border-radius-8"
                src={src}
                alt="logo"
                width="40"
                height="40"
            />
        </div>
    );
};

export default HeaderLogo;
