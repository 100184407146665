import React, { useState, useRef, useEffect, useCallback } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import ErrorText from 'ui/ErrorText';

const AutoSuggestInput = ({
    handleChange,
    value,
    options,
    placeholder,
    name,
    id,
    errorText,
    className,
}) => {
    const [suggest, setSuggest] = useState(options);
    const [cursor, setCursor] = useState(0);
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const [inputClassName, setInputClassName] = useState(className);
    const [error, setError] = useState(errorText);
    const dropdownRef = useRef(null);
    const dropdownRefs = suggest.reduce((acc, current, index) => {
        acc[index] = React.createRef();
        return acc;
    }, {});

    useOutsideClick(dropdownRef, () => setIsShowDropdown(!isShowDropdown));

    const handleInputChange = (e) => {
        const searchVal = e.target.value;
        let suggestion = [];
        setCursor(0);

        if (searchVal.length > 0) {
            suggestion = options
                .sort()
                .filter((e) =>
                    e
                        .toLocaleLowerCase()
                        .includes(searchVal.toLocaleLowerCase()),
                );
            setIsShowDropdown(suggestion.length !== 0 ? true : false);
        }

        setSuggest(suggestion);
        handleChange(e);
    };

    const suggestedText = (value) => {
        setSuggest([]);
        handleChange({ target: { name, value } });
    };

    const resetError = useCallback(() => {
        setError([]);
        setInputClassName(className);
    }, [className]);

    const onScroll = (item) => {
        const dropdownHeight = dropdownRef.current.offsetHeight;
        const itemHeight = dropdownRefs[item].current.offsetHeight;
        const itemTop = dropdownRefs[item].current.offsetTop;

        dropdownRef.current.scrollTo({
            behavior: 'smooth',
            top: itemTop - dropdownHeight / 2 + itemHeight / 2,
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp' && cursor > 0) {
            setCursor((prev) => {
                onScroll(prev - 1);
                return prev - 1;
            });
        } else if (e.key === 'ArrowDown' && cursor < suggest.length - 1) {
            setCursor((prev) => {
                onScroll(prev + 1);
                return prev + 1;
            });
        } else if (e.key === 'Enter') {
            e.preventDefault();
            suggestedText(suggest[cursor]);
        }
    };

    useEffect(() => {
        if (errorText) {
            setError(errorText);
            setInputClassName(`${className} is-invalid`);
        }
        if (!errorText) resetError();
    }, [errorText, className, resetError]);

    return (
        <div className="suggest-input-container">
            <input
                type="text"
                name={name}
                id={id}
                value={value}
                onChange={handleInputChange}
                onClick={() => setIsShowDropdown(!isShowDropdown)}
                onFocus={resetError}
                placeholder={placeholder}
                className={inputClassName}
                autoComplete="off"
                onKeyDown={handleKeyDown}
            />
            {error && <ErrorText text={error[0]} />}
            {isShowDropdown && suggest.length > 0 && (
                <div ref={dropdownRef} className="suggest-list">
                    <ul>
                        {suggest.map((item, i) => (
                            <li
                                ref={dropdownRefs[i]}
                                key={i}
                                onClick={() => suggestedText(item)}
                                className={cursor === i ? 'active' : null}
                            >
                                <span>{item}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default AutoSuggestInput;
