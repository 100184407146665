export const headers = [
    'Accept',
    'Accept-Charset',
    'Accept-Encoding',
    'Accept-Language',
    'Access-Control-Request-Headers',
    'Access-Control-Request-Method',
    'Authorization',
    'Cache-Control',
    'Connection',
    'Content-MD5',
    'Content-Length',
    'Content-Transfer-Encoding',
    'Content-Type',
    'Cookie',
    'Date',
    'Expect',
    'From',
    'Host',
    'If-Match',
    'If-Modified-Since',
    'If-None-Match',
    'If-Range',
    'If-Unmodified-Since',
    'Keep-Alive',
    'Max-Forwards',
    'Origin',
    'Pragma',
    'Proxy-Authorization',
    'Range',
    'Referer',
    'TE',
    'Trailer',
    'Transfer-Encoding',
    'Upgrade',
    'User-Agent',
    'Via',
    'Warning',
    'X-Requested-With',
    'X-Do-Not-Track',
    'x-api-key',
];
