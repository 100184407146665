import React, { useState } from 'react';
import { MoreHorizontal, Star } from 'react-feather';

import PropTypes from 'prop-types';
import ProjectAvatar from 'components/Avatar/ProjectAvatar';
import { formatDate } from 'utils/helpers';
import { useSelector } from 'react-redux';
import { organizationSelectors } from 'store/slices/organizationSlice';

const ProjectItem = ({
    project,
    toggleFavorite,
    handleTrashModal,
    handleDeleteModal,
    handleCancelTransferModal,
    handleRestoreProject,
    isTrashed,
    myAccount,
}) => {
    const [isStarred, setIsStarred] = useState(project.is_favorite);

    const toggleStarred = (project) => {
        toggleFavorite(project);
        setIsStarred(!isStarred);
    };

    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const hasOwnerRights =
        (project?.owner?.id === myAccount?.id &&
            currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights = hasOwnerRights || currentOrg?.role === 'ADMIN';

    return (
        <tr className="table-main-row projects-table-item">
            {!isTrashed && (
                <td className="text-center">
                    <button
                        className={`btn-star btn-star-icon ${
                            isStarred && 'btn-star-selected'
                        }`}
                        onClick={() => toggleStarred(project)}
                        name={project?.uid}
                        disabled={project?.deleted_at}
                    >
                        {/* <Star className="btn-star-img"/> */}
                    </button>
                </td>
            )}
            <td style={{ position: 'relative' }}>
                <ProjectAvatar project={project} />{' '}
                {project?.deleted_at ? (
                    <>
                        {project?.name}{' '}
                        <span className="badge bg-danger mx-2">deleted</span>
                    </>
                ) : (
                    <>
                        <a
                            className={`table-main-link`}
                            href={`/project?project=${project?.uid}`}
                            name={project?.uid}
                        >
                            {project.name}
                        </a>
                        {project?.is_transfer_pending &&
                            !project?.deleted_at && (
                                <span className="badge bg-success mx-2">
                                    in transfer
                                </span>
                            )}
                    </>
                )}
            </td>
            <td
                className={`text-link-normal word-break ${
                    !!project?.deleted_at ? 'project-disabled' : ''
                }`}
                style={{ maxWidth: '600px' }}
            >
                {project?.description || 'No description'}
            </td>
            <td>{project?.apis_count}</td>
            <td>{formatDate(project?.created_at)}</td>
            <td>
                {hasRights && !project?.deleted_at && (
                    <div className="btn-group dropstart">
                        <button
                            type="button"
                            className="projects-table-dots"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <MoreHorizontal className="project-more-img" />
                        </button>
                        <ul className="dropdown-menu header-dropdown-list">
                            <li>
                                <a
                                    href={`/project/settings/summary?project=${project?.uid}`}
                                    className="dropdown-item link-dropdown project-dropdown-link"
                                >
                                    {' '}
                                    Project settings{' '}
                                </a>
                            </li>
                            {hasRights && !project?.is_transfer_pending && (
                                <li>
                                    <button
                                        className="dropdown-item btn-dropdown project-dropdown-link"
                                        name="trash"
                                        onClick={(e) =>
                                            handleTrashModal(e, project)
                                        }
                                    >
                                        Move to trash
                                    </button>
                                </li>
                            )}
                            {project?.is_transfer_pending && hasRights && (
                                <li>
                                    <button
                                        className="dropdown-item btn-dropdown project-dropdown-link"
                                        name="cancel"
                                        onClick={(e) =>
                                            handleCancelTransferModal(
                                                e,
                                                project,
                                            )
                                        }
                                    >
                                        Cancel transfer
                                    </button>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
                {project?.deleted_at && hasRights && (
                    <div className="btn-group dropstart">
                        <button
                            type="button"
                            className="projects-table-dots"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <MoreHorizontal className="project-more-img" />
                        </button>
                        <ul className="dropdown-menu header-dropdown-list">
                            <li>
                                <button
                                    className="dropdown-item btn-dropdown project-dropdown-link"
                                    name="restore"
                                    onClick={(e) =>
                                        handleRestoreProject(e, project)
                                    }
                                >
                                    Restore project
                                </button>
                            </li>
                            <li>
                                <button
                                    className="dropdown-item btn-dropdown project-dropdown-link"
                                    name="delete"
                                    onClick={(e) =>
                                        handleDeleteModal(e, project)
                                    }
                                >
                                    Delete project
                                </button>
                            </li>
                        </ul>
                    </div>
                )}
            </td>
        </tr>
    );
};

const Table = ({
    projects,
    toggleFavorite,
    isTrashed,
    myAccount,
    handleTrashModal,
    handleDeleteModal,
    handleCancelTransferModal,
    handleRestoreProject,
}) => {
    return (
        <div className="project-list mx-5">
            <table className="table table-hover table-main projects-table">
                <thead className="table-main-head">
                    <tr className="projects-table-row">
                        {!isTrashed && (
                            <th className="projects-table-title">
                                <Star
                                    size={14}
                                    className="projects-table-star"
                                />
                            </th>
                        )}
                        <th className="projects-table-title">Name</th>
                        <th className="projects-table-title">Description</th>
                        <th className="projects-table-title">API</th>
                        <th className="projects-table-title">Created at</th>
                        <th className="projects-table-title"></th>
                    </tr>
                </thead>
                <tbody className="table-main-body">
                    {projects.length > 0 &&
                        projects.map((project) => (
                            <ProjectItem
                                project={project}
                                key={project.id}
                                toggleFavorite={toggleFavorite}
                                handleTrashModal={handleTrashModal}
                                handleDeleteModal={handleDeleteModal}
                                handleCancelTransferModal={
                                    handleCancelTransferModal
                                }
                                handleRestoreProject={handleRestoreProject}
                                isTrashed={isTrashed}
                                myAccount={myAccount}
                            />
                        ))}
                    {projects.length === 0 && (
                        <tr>
                            <td colSpan="6" className="text-center">
                                No projects found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Table;

ProjectItem.propTypes = {
    project: PropTypes.object,
};
