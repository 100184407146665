import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Plus } from 'react-feather';

import CustomSelect from 'ui/CustomSelect';
import Modal from 'ui/Modal';
import CreateEnvModal from './CreateEnvModal';
import EnvironmentModal from './EnvironmentModal';
import { setCookie, getCookie } from 'utils/cookie';

import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import { authSelectors } from 'store/slices/authSlice';
import {
    deleteEnvironment,
    environmentSelectors,
    getEnvironments,
    setSelectedEnvironments,
    createEnvironment,
} from 'store/slices/environmentsSlice';
import { organizationSelectors } from 'store/slices/organizationSlice';

const selectStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        color: '#fff',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '400',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? '#f6f7f9'
            : state.isSelected
              ? '#f6f7f9'
              : 'white',
        ':active': {},
        color: '#001a37',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '400',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    }),
    control: (provided, state) => ({
        ...provided,
        width: '100%',
        display: 'flex',
        borderColor: 'none',
        boxShadow: 'none',
        color: '#000',
        borderRadius: '4px',
        border: 'none',
        height: '34px',
        minHeight: '34px',
        backgroundColor: '#f6f7f9',
        cursor: 'pointer',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#000',
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        transition: 'transform 100ms linear',
        '& svg': {
            width: '18px',
            height: '18px',
        },
    }),
    menuList: (provided, state) => ({
        ...provided,
        '& :first-of-type': {
            marginBottom: '4px',
            borderBottom: '1px solid #f6f7f9',
        },
    }),
};

const EnvironmentEditor = () => {
    const dispatch = useDispatch();

    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const environments = useSelector(environmentSelectors.getEnvironments);
    const selectedEnvironments = useSelector(
        environmentSelectors.getSelectedEnvironments,
    );
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const [modalShown, setModalShown] = useState({
        isCreateEnvModalShown: false,
        isEnvModalShown: false,
        isDeleteModalShown: false,
    });

    const environmentsOptions = [
        {
            value: 'no-env',
            label: 'No environment',
            item: { api_id: api?.id, variables: [] },
        },
        ...(environments
            ? environments?.map((item) => ({
                  value: item.name.toLowerCase(),
                  label: item.name,
                  item,
              }))
            : []),
    ];
    const selectedEnvironment = selectedEnvironments?.find(
        (el) => el.item.api_id === api?.id,
    );
    const hasOwnerRights =
        (project.owner.id === myAccount.id && currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project?.user_role === 'MAINTAINER';
    useEffect(() => {
        const selectedEnvironments = JSON.parse(
            getCookie('selected_environments'),
        );
        const environmentsData =
            selectedEnvironments === null
                ? null
                : selectedEnvironments.filter(
                      (el) => el.item.api_id === api?.id,
                  );
        const defaultEnvironment = [
            {
                value: 'no-env',
                label: 'No environment',
                item: { api_id: api?.id, variables: [] },
            },
        ];

        dispatch(
            getEnvironments({ projectUid: project?.uid, apiId: api?.uid }),
        );

        if (!environmentsData) {
            setCookie(
                'selected_environments',
                JSON.stringify(defaultEnvironment),
                {},
            );
            dispatch(setSelectedEnvironments(defaultEnvironment));
        } else if (!environmentsData?.length) {
            const newSelectedEnvironmentsArray = [...selectedEnvironments];

            newSelectedEnvironmentsArray.push(defaultEnvironment[0]);
            dispatch(setSelectedEnvironments(newSelectedEnvironmentsArray));
            setCookie(
                'selected_environments',
                JSON.stringify(newSelectedEnvironmentsArray),
                {},
            );
        } else {
            dispatch(setSelectedEnvironments(selectedEnvironments));
        }
    }, [api?.id, api?.uid, dispatch, project?.uid]);

    const toggleCreateEnvModal = () => {
        setModalShown({
            ...modalShown,
            isCreateEnvModalShown: !modalShown.isCreateEnvModalShown,
        });
    };

    const toggleEnvModal = () => {
        setModalShown({
            ...modalShown,
            isEnvModalShown: !modalShown.isEnvModalShown,
        });
    };

    const toggleDeleteModal = () => {
        setModalShown({
            ...modalShown,
            isDeleteModalShown: !modalShown.isDeleteModalShown,
        });
    };

    const handleSelectChange = (option) => {
        const selectedEnvironments = JSON.parse(
            getCookie('selected_environments'),
        );
        const filteredEnvironments = selectedEnvironments.filter(
            (el) => el.item.api_id !== api?.id,
        );

        filteredEnvironments.push(option);
        dispatch(setSelectedEnvironments(filteredEnvironments));
        setCookie(
            'selected_environments',
            JSON.stringify(filteredEnvironments),
            {},
        );
    };

    const onSuccessCreateEnvironment = (response) => {
        const selectedEnvironments = JSON.parse(
            getCookie('selected_environments'),
        );
        const filteredEnvironments = selectedEnvironments.filter(
            (el) => el.item.api_id !== api?.id,
        );
        const newEnvironment = {
            value: response.data.data.name.toLowerCase(),
            label: response.data.data.name,
            item: response.data.data,
        };

        filteredEnvironments.push(newEnvironment);
        dispatch(setSelectedEnvironments(filteredEnvironments));
        toggleCreateEnvModal();
        toast.success('The environment has been created');
        setCookie(
            'selected_environments',
            JSON.stringify(filteredEnvironments),
            {},
        );
        dispatch(
            getEnvironments({ projectUid: project?.uid, apiId: api?.uid }),
        );
    };

    const onDeleteEnvironment = (envId) => {
        toggleDeleteModal();
        toggleEnvModal();
        dispatch(
            deleteEnvironment({ envId, onSuccess: onSuccessDeleteEnvironment }),
        );
    };

    const onSuccessDeleteEnvironment = () => {
        const selectedEnvironments = JSON.parse(
            getCookie('selected_environments'),
        );
        const filteredEnvironments = selectedEnvironments.filter(
            (el) => el.item.api_id !== api?.id,
        );
        const defaultEnvironment = {
            value: 'no-env',
            label: 'No environment',
            item: { api_id: api?.id, variables: [] },
        };

        filteredEnvironments.push(defaultEnvironment);
        dispatch(setSelectedEnvironments(filteredEnvironments));
        setCookie(
            'selected_environments',
            JSON.stringify(filteredEnvironments),
            {},
        );
        toast.success('The environment has been deleted');
        dispatch(
            getEnvironments({ projectUid: project?.uid, apiId: api?.uid }),
        );
        setModalShown({
            ...modalShown,
            isDeleteModalShown: false,
            isEnvModalShown: false,
        });
    };

    const createEnvironmentHandle = (
        projectUid,
        apiUid,
        data,
        onCreated,
        onError,
    ) => {
        dispatch(
            createEnvironment({
                projectUid,
                apiId: apiUid,
                data,
                onSuccess: onCreated,
                onError,
            }),
        );
    };

    return (
        <>
            <div className="env-editor-wrapper">
                <div className="d-flex align-items-center">
                    <div className="env-editor-select">
                        <CustomSelect
                            name="env"
                            options={environmentsOptions}
                            onChange={handleSelectChange}
                            value={selectedEnvironment?.value}
                            menuPortalTarget={'null'}
                            customStyles={selectStyles}
                        />
                    </div>
                    {hasRights && (
                        <div className="d-flex">
                            <button
                                className="env-editor-button"
                                onClick={toggleCreateEnvModal}
                            >
                                <Plus color="#0d6efd" size={18} />
                            </button>
                            {selectedEnvironment?.value !== 'no-env' && (
                                <button
                                    className="env-editor-button"
                                    onClick={toggleEnvModal}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="1"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Modal
                show={modalShown.isCreateEnvModalShown}
                title="Create a new environment"
                body={
                    <CreateEnvModal
                        onClose={toggleCreateEnvModal}
                        onSubmit={createEnvironmentHandle}
                        project={project}
                        api={api}
                        onCreated={onSuccessCreateEnvironment}
                    />
                }
            />
            <Modal
                show={modalShown.isEnvModalShown}
                body={
                    <EnvironmentModal
                        onClose={toggleEnvModal}
                        selectedEnvironment={selectedEnvironment}
                        onDelete={toggleDeleteModal}
                    />
                }
                customClasses={{
                    modalDialog: 'modal-dialog--wide modal-dialog--heightened',
                }}
            />
            <Modal
                show={modalShown.isDeleteModalShown}
                title="Delete permanently?"
                body="This environment will be deleted permanently and you will not be able to restore it."
                footer={
                    <>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() =>
                                onDeleteEnvironment(
                                    selectedEnvironment?.item?.id,
                                )
                            }
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={toggleDeleteModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </>
                }
            />
        </>
    );
};

export default EnvironmentEditor;
