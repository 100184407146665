import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import WelcomeHeader from 'layouts/welcome/WelcomeHeader';
import { Input } from 'ui/Inputs';
import { resetPassword } from 'store/slices/authSlice';

const ResetPasswordPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState({
        token: '',
        email: '',
        password: '',
        password_confirmation: '',
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        if (!token) {
            history.push('/forgot-password');
        }
        setData({ ...data, token: token });
    }, [data, history, location.search]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(resetPassword({ data, onSuccess, onError }));
    };

    const onError = (errorResponse) => {
        setErrors(errorResponse.response.data.errors);
    };

    const onSuccess = (successResponse) => {
        toast.dark('You password has been successfully changed');

        history.push('/login');
    };

    return (
        <section className="recover-section bg-gray">
            <WelcomeHeader />
            <div className="container form-bg-image">
                <div className="row"></div>
                <div className="row mt-5">
                    <div className="col-10 col-md-6 mt-md-5 pt-4 pb-3 singup-registration">
                        <h1 className="mb-3 h3">Reset your password</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-4">
                                <Input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    value={data.email}
                                    onChange={handleChange}
                                    labelClassName="form-label"
                                    labelText="Email"
                                    placeHolder="Enter your email address"
                                    errorText={errors.email}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <Input
                                    type="password"
                                    name="password"
                                    className="form-control"
                                    value={data.password}
                                    onChange={handleChange}
                                    labelClassName="form-label"
                                    labelText="Password"
                                    placeHolder="Enter your new password"
                                    errorText={errors.password}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <Input
                                    type="password"
                                    name="password_confirmation"
                                    className="form-control"
                                    value={data.password_confirmation}
                                    onChange={handleChange}
                                    labelClassName="form-label"
                                    labelText="Password confirmation"
                                    placeHolder="Confirm your new password"
                                    errorText={errors.password_confirmation}
                                />
                            </div>
                            <button
                                className="btn btn-primary d-block my-5"
                                type="submit"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                    <div className="col-1 col-lg-2"></div>
                    <div className="col-10 col-md-4 pt-5 px-4 px-md-0">
                        <p className="mb-2 mt-md-5 pt-md-5 form-label">
                            Remember your password?
                        </p>
                        <a href="/login" className="title-h4">
                            Log in
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ResetPasswordPage;
