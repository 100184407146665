import React from 'react';

export default function PreviewTab({ data }) {
    return (
        <iframe
            srcDoc={
                data?.contentType === 'text/html'
                    ? data.result
                    : JSON.stringify(data.result, null, 2)
            }
            title="err"
            style={{ width: '100%', height: '303px' }}
        ></iframe>
    );
}
