import React, { useEffect } from 'react';
import CustomSelect from 'ui/CustomSelect';
import { Input } from 'ui/Inputs';

const customStyles = (fieldError) => ({
    valueContainer: (provided, state) => ({
        ...provided,
        color: '#001a37',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: '400',
        backgroundColor: 'white',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? '#f6f7f9'
            : state.isSelected
            ? '#f6f7f9;'
            : 'white',
        ':active': {
            // backgroundColor: state.isSelected ? 'yellow' : 'pink'
        },
        color: '#001a37',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: '400',
    }),
    control: (provided, state) => ({
        ...provided,
        width: '100%',
        minWidth: '70px',
        display: 'flex',
        // border: '1px solid #ced4da',
        borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
        boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(13 110 253 / 25%)' : '',
        color: '#001a37',
        borderRadius: '4px',

        ...(!!fieldError && {
            border: '1px solid #dc3545',
            borderColor: '#dc3545 !important',
        }),
        '&:hover': {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
        },
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
});

const types = [
    { value: 'string', label: 'string', target: 'type' },
    { value: 'number', label: 'number', target: 'type' },
    { value: 'boolean', label: 'boolean', target: 'type' },
    { value: 'array', label: 'array', target: 'type' },
    { value: 'object', label: 'object', target: 'type' },
];

const is_required = [
    { value: '0', label: 'optional', target: 'is_required' },
    { value: '1', label: 'required', target: 'is_required' },
];

const RequestParamsItem = ({
    name,
    type,
    paramName,
    resourcesOptions,
    onSelectChange,
    handleChange,
    isRequired,
    resource,
    errors,
    index,
    description,
    deleteRow,
}) => {
    useEffect(() => {
        if (resource) return;

        const findResourceOption = (paramName, options) => {
            if (paramName === 'Body parameters') {
                return options?.find((el) => el.value === name.toLowerCase());
            }
        };

        if (name && paramName === 'Body parameters' && type === 'resource') {
            const resourceOption = findResourceOption(
                paramName,
                resourcesOptions,
            );
            if (!resourceOption) return;
            onSelectChange(resourceOption);
        }
    }, [name, paramName, type, resourcesOptions, onSelectChange, resource]);

    const errorSwitcher = (paramName, index, errors, inputName) => {
        const errorsObject = {
            name: {
                'Query parameters': errors[`queryParameters.${index}.name`],
                'Path parameters': errors[`pathParameters.${index}.name`],
            },
            description: {
                'Query parameters':
                    errors[`queryParameters.${index}.description`],
                'Path parameters':
                    errors[`pathParameters.${index}.description`],
            },
        };
        return errorsObject[inputName][paramName];
    };

    return (
        <tr>
            <td>
                <Input
                    type="text"
                    name="name"
                    className="form-control"
                    value={name}
                    onChange={handleChange}
                    placeholder="Parameter name"
                    errorText={errorSwitcher(paramName, index, errors, 'name')}
                />
            </td>
            <td className="w-20">
                <CustomSelect
                    className="select-main-container"
                    customStyles={customStyles}
                    options={
                        paramName === 'Body parameters'
                            ? types
                            : types.slice(0, 5)
                    }
                    onChange={onSelectChange}
                    defaultOption={type}
                    value={type}
                />
            </td>
            <td className="w-20">
                <CustomSelect
                    className="select-main-container"
                    customStyles={customStyles}
                    name="is_required"
                    options={is_required}
                    onChange={onSelectChange}
                    value={isRequired.toString()}
                />
            </td>
            <td>
                <Input
                    type="text"
                    name="description"
                    className="form-control"
                    value={description || ''}
                    onChange={handleChange}
                    placeholder="Describe the field"
                    errorText={errorSwitcher(
                        paramName,
                        index,
                        errors,
                        'description',
                    )}
                />
            </td>
            <td className="text-end align-middle">
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={deleteRow}
                >
                    -
                </button>
            </td>
        </tr>
    );
};

export default RequestParamsItem;
