import React, { Fragment, useEffect, useState } from 'react';
import { Trash } from 'react-feather';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import LocalLoader from 'ui/LocalLoader';
import { formatDate } from 'utils/helpers';
import CustomSelect from 'ui/CustomSelect';
import Modal from 'ui/Modal';
import { authSelectors } from 'store/slices/authSlice';
import {
    deleteTeamMember,
    getOrganizationMembers,
    organizationSelectors,
    updateTeamMemberRole,
} from 'store/slices/organizationSlice';
import ProjectAccessModal from 'pages/Members/ProjectAccessModal';
import {
    fetchMemberProjects,
    memberSelectors,
} from 'store/slices/membersSlice';

const role = [
    { value: 'MEMBER', label: 'Member' },
    { value: 'MANAGER', label: 'Manager' },
    { value: 'ADMIN', label: 'Admin' },
];

const MembersList = () => {
    const dispatch = useDispatch();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const teamMembers = useSelector(
        organizationSelectors.getOrganizationMembersList,
    );
    const teamMembersStatus = useSelector(
        organizationSelectors.getOrganizationMembersLoadingStatus,
    );
    const isMemberProjectsFetching = useSelector(
        memberSelectors.getIsMemberProjectsFetching,
    );
    const memberProjects = useSelector(memberSelectors.getMemberProjects);

    const [memberToDelete, setMemberToDelete] = useState(null);
    const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
    const [isShowAccessModal, setIsShowAccessModal] = useState(false);

    useEffect(() => {
        dispatch(getOrganizationMembers());
    }, [dispatch]);

    const onDeleteModalShown = (flag, member) => {
        setIsDeleteModalShown(flag);
        setMemberToDelete(member);
    };

    const onDelete = () => {
        setIsDeleteModalShown(false);

        dispatch(
            deleteTeamMember({
                id: memberToDelete.id,
                onSuccess: onSuccessDelete,
            }),
        );
    };

    const onSuccessDelete = (response) => {
        toast.success('Member has been deleted');
        dispatch(getOrganizationMembers());
    };

    const handleChangeRole = (selectedOption, member) => {
        dispatch(
            updateTeamMemberRole({
                id: member.id,
                data: { role: selectedOption.value },
                onSuccess: onSuccessChangeRole,
                onError: onErrorChangeRole,
            }),
        );
    };

    const onSuccessChangeRole = (response) => {
        toast.success("Member's role has been changed");
        dispatch(getOrganizationMembers());
    };

    const onErrorChangeRole = (error) => {
        if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
            return;
        }
        toast.error('Something went wrong. Please try again later');
    };

    const toggleAccessModal = () => {
        setIsShowAccessModal((prev) => !prev);
    };

    const onShowAccessModal = (e) => {
        if (e.target.id) {
            dispatch(fetchMemberProjects({ uuid: e.target.id }));
        }
        toggleAccessModal();
    };

    return (
        <div className="mt-5">
            <h4 className="text-big mb-4">Organization members</h4>
            <div className="mt-3 table-scroll">
                <LocalLoader loading={teamMembersStatus === 'loading'}>
                    <table className="table table-main">
                        <thead className="table-main-head">
                            <tr>
                                <th className="pending-table-title">Name</th>
                                <th className="pending-table-title">Email</th>
                                <th className="pending-table-title">Access</th>
                                <th className="pending-table-title">
                                    Joined at
                                </th>
                                <th className="pending-table-title">
                                    Permissions
                                </th>
                                <td className="pending-table-title">&nbsp;</td>
                            </tr>
                        </thead>
                        <tbody>
                            {!!teamMembers?.length ? (
                                teamMembers?.map((member) => (
                                    <tr
                                        className="table-main-row"
                                        key={member.id}
                                    >
                                        <td className="pending-table-title">
                                            {`${member.first_name} ${member.last_name}`}
                                            {member.role === 'OWNER' && (
                                                <span className="badge bg-primary mx-2">
                                                    Owner
                                                </span>
                                            )}
                                        </td>
                                        <td>{member.email}</td>
                                        <td>
                                            {(member.role === 'MEMBER' ||
                                                member.role === 'MANAGER') && (
                                                <button
                                                    type="button"
                                                    id={member.uuid}
                                                    className="btn-link-main text-link-normal"
                                                    onClick={(id) =>
                                                        onShowAccessModal(id)
                                                    }
                                                >
                                                    View access
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            {member.joined_at &&
                                                formatDate(member.joined_at)}
                                        </td>
                                        <td>
                                            {member.role !== 'OWNER' &&
                                            myAccount?.id !== member?.id ? (
                                                <CustomSelect
                                                    name="role"
                                                    options={role}
                                                    onChange={(e) =>
                                                        handleChangeRole(
                                                            e,
                                                            member,
                                                        )
                                                    }
                                                    value={member.role}
                                                />
                                            ) : (
                                                member.role
                                            )}
                                        </td>
                                        <td className="text-end">
                                            {myAccount?.id !== member?.id &&
                                                member.role !== 'OWNER' && (
                                                    <button
                                                        className="btn btn-outline-danger btn-sm"
                                                        value={member.id}
                                                        onClick={() =>
                                                            onDeleteModalShown(
                                                                true,
                                                                member,
                                                            )
                                                        }
                                                    >
                                                        <Trash size={15} />
                                                    </button>
                                                )}

                                            {/* ) : (
                                            ''
                                        )} */}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={6} className="text-center">
                                        No members
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </LocalLoader>
            </div>

            <Modal
                show={isDeleteModalShown}
                title="Delete memeber"
                body="Are you sure you want to delete member?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onDelete}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={() => setIsDeleteModalShown(false)}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
            <Modal
                show={isShowAccessModal}
                title="Projects access"
                body={
                    <ProjectAccessModal
                        isLoading={isMemberProjectsFetching}
                        projects={memberProjects}
                    />
                }
                footer={
                    <button
                        type="submit"
                        className={`btn btn-link`}
                        onClick={toggleAccessModal}
                    >
                        Close
                    </button>
                }
            />
        </div>
    );
};

export default MembersList;
