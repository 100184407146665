import React from 'react';
import ViewerListItem from './ViewerListItem';

export default function ViewerList({
    list,
    isCollapsed,
    setCollapsed,
    models,
}) {
    return (
        <>
            {list.map((listItem, i) => (
                <ViewerListItem
                    key={listItem.id}
                    listItem={listItem}
                    models={models}
                    isCollapsed={isCollapsed}
                    setCollapsed={setCollapsed}
                    idx={i}
                    listLength={list.length}
                />
            ))}
        </>
    );
}
