import React, { useState } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import './assets/styles/codeEditorStyles.scss';
import useCopyToClipboard from './hooks/useCopyToClipboard';
import { toast } from 'react-toastify';

export default function GenerateFromJson({
    getJson,
    setActiveTab,
    generateJson,
    isEditedJsonValid,
}) {
    const [code, setCode] = useState('{\n}');
    const [isValidJson, setIsValidJson] = useState(true);
    const [jsonErrorMessage, setJsonErrorMessage] = useState('');
    const [setCopiedText] = useCopyToClipboard();

    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            setJsonErrorMessage(e.message);
            return false;
        }
        return true;
    };

    const onEditJson = (code) => {
        setCode(code);

        if (isJsonString(code)) {
            setIsValidJson(true);
            getJson(code, true);
        } else {
            setIsValidJson(false);
            getJson(null, false);
        }
    };

    const onCopyToClipboard = () => {
        setCopiedText(code);
        toast.success('Copied to Clipboard');
    };

    return (
        <>
            <div className="d-flex align-items-center mb-2 justify-content-between">
                <span className="JsonSchemaEditor__generator-top-pannel-text">
                    Paste or write a JSON example below, then click
                    <span className="JsonSchemaEditor__generator-top-pannel-text--bold">
                        &nbsp;Generate&nbsp;
                    </span>
                    below to built a schema.
                </span>
                <div className="d-flex justify-content-end">
                    <button
                        className="JsonSchemaEditor__generate-button"
                        type="button"
                        onClick={() => {
                            if (isEditedJsonValid)
                                setCode(
                                    JSON.stringify(JSON.parse(code), null, 2),
                                );
                        }}
                    >
                        Format
                    </button>
                    <button
                        onClick={onCopyToClipboard}
                        className="JsonSchemaEditor__generate-button"
                        type="button"
                    >
                        Copy
                    </button>
                </div>
            </div>
            <div className="JsonSchemaEditor__generator-wrapper">
                <div className="JsonSchemaEditor__examples-code-editor-wrapper">
                    <Editor
                        value={code}
                        onValueChange={onEditJson}
                        highlight={(code) => highlight(code, languages.js)}
                        style={{
                            fontFamily:
                                'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
                            fontSize: 12,
                            fontWeight: 400,
                        }}
                    />
                </div>
                {!isValidJson && (
                    <span className="JsonSchemaEditor__examples-json-error-message">
                        {jsonErrorMessage}
                    </span>
                )}
            </div>
            <div className="mb-4 mt-3">
                <button
                    className="JsonSchemaEditor__generate-tab-button me-2"
                    onClick={generateJson}
                    disabled={!isEditedJsonValid}
                >
                    Generate
                </button>
                <button
                    className="JsonSchemaEditor__cancel-tab-button"
                    onClick={setActiveTab}
                >
                    Cancel
                </button>
            </div>
        </>
    );
}
