import React from 'react';
import { formatDate } from 'utils/helpers';

const MemberProjects = ({ memberProjects }) => {
    return (
        <table className="table table-main">
            <thead className="table-main-head">
                <tr>
                    <th className="item-table-title">Project name</th>
                    <th className="item-table-title">Role</th>
                    <td className="item-table-title">Created at</td>
                </tr>
            </thead>
            <tbody>
                {memberProjects?.map((project) => (
                    <tr className="table-main-row" key={project.id}>
                        <td>{project.name}</td>
                        <td>{project.role}</td>
                        <td>
                            {project.created_at &&
                                formatDate(project.created_at)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default MemberProjects;
