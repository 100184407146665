import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import UserAvatar from 'components/Avatar/UserAvatar';
import { authSelectors } from 'store/slices/authSlice';
import { loaded } from 'store/slices/appSlice';
import { deleteTokens } from 'utils/auth';
import { organizationSelectors } from 'store/slices/organizationSlice';
import { CreditCard, LogOut, RefreshCw, Settings, Users } from 'react-feather';
import { OfficeBuilding } from 'assets/img/Svg';

const ProfileSelector = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const myAccount = useSelector(authSelectors.getMyAccount);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const userName = myAccount.first_name + ' ' + myAccount.last_name;

    const onLogout = () => {
        dispatch(loaded(false));
        window.localStorage.setItem('remember_me', false);
        deleteTokens();
        history.replace('/');
    };

    return (
        <div className="btn-group">
            <button
                type="button"
                className="header-round-btn header-avatar-btn"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <UserAvatar
                    iconUrl={myAccount.avatar_url}
                    first_name={myAccount.first_name}
                    last_name={myAccount.last_name}
                    className="header-avatar-img"
                />
            </button>
            <ul className="dropdown-menu">
                <li className="px-2">
                    <span className="projects-favorite sub-menu p-2 mt-1 d-block word-break">
                        {userName}
                    </span>
                </li>
                <li>
                    <hr className="dropdown-divider header-divider" />
                </li>
                <li>
                    <a
                        className="dropdown-item link-dropdown header-link text-link-normal"
                        href="/settings/profile"
                    >
                        <Settings
                            className="sidebar-link-icon"
                            size={20}
                            strokeWidth={1.5}
                        />
                        Settings
                    </a>
                </li>
                {(!currentOrg || currentOrg?.role === 'OWNER') && (
                    <li>
                        <a
                            className="dropdown-item link-dropdown header-link text-link-normal"
                            href="/billing"
                        >
                            <CreditCard
                                className="sidebar-link-icon"
                                size={20}
                                strokeWidth={1.5}
                            />
                            Billing
                        </a>
                    </li>
                )}
                {(currentOrg?.role === 'OWNER' ||
                    currentOrg?.role === 'ADMIN') && (
                    <li>
                        <a
                            className="dropdown-item link-dropdown header-link text-link-normal"
                            href="/transfers"
                        >
                            <RefreshCw
                                className="sidebar-link-icon"
                                size={20}
                                strokeWidth={1.5}
                            />
                            Transfers
                        </a>
                    </li>
                )}
                {currentOrg?.role === 'OWNER' && (
                    <li>
                        <a
                            className="dropdown-item link-dropdown header-link text-link-normal"
                            href="/settings/organization"
                        >
                            <OfficeBuilding className="sidebar-link-icon" />
                            Organization
                        </a>
                    </li>
                )}
                {(currentOrg?.role === 'OWNER' ||
                    currentOrg?.role === 'ADMIN') && (
                    <li>
                        <a
                            className="dropdown-item link-dropdown header-link text-link-normal"
                            href="/settings/team"
                        >
                            <Users
                                size={20}
                                className="sidebar-link-icon"
                                strokeWidth={1.5}
                            />
                            Team
                        </a>
                    </li>
                )}
                <li>
                    <hr className="dropdown-divider header-divider" />
                </li>
                <li>
                    <span
                        onClick={onLogout}
                        className="dropdown-item link-dropdown header-link cursor-pointer"
                    >
                        <LogOut
                            className="sidebar-link-icon"
                            size={20}
                            strokeWidth={1.5}
                        />
                        Logout
                    </span>
                </li>
            </ul>
        </div>
    );
};

export default ProfileSelector;
