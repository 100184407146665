import React from 'react';
import ViewerList from './ViewerList';
import ViewerJsonExample from './ViewerJsonExample';
import ViewerExamples from './ViewerExamples';

export default function ViewerTabsFacade({
    tree,
    schemaName,
    isCollapsed,
    models,
    setCollapsed,
    data,
    examples,
    activeTab,
}) {
    const tabsMap = {
        schema: (
            <div className="JsonSchemaViewer__schema-wrapper">
                <ViewerList
                    list={tree}
                    schemaName={schemaName}
                    isCollapsed={isCollapsed}
                    models={models}
                    setCollapsed={setCollapsed}
                />
            </div>
        ),
        json: <ViewerJsonExample data={data} />,
        examples: <ViewerExamples examples={examples} />,
    };

    return <>{tabsMap[activeTab]}</>;
}
