import React, { useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { MoreHorizontal } from 'react-feather';
import { uniqueId } from 'utils/helpers';
import useOutsideClick from 'hooks/useOutsideClick';
import useDropdownPosition from 'hooks/useDropdownPosition';
import iconPage from 'assets/img/page-dark.svg';
import { organizationSelectors } from 'store/slices/organizationSlice';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store/slices/authSlice';

const PagesListItem = ({
    toggleDropdown,
    selectedDropdown,
    handleClickAction,
    project,
    api,
    page,
}) => {
    const myAccount = useSelector(authSelectors.getMyAccount);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const location = useLocation();
    const [dropdownId, setDropdownId] = useState(undefined);
    const dropdownRef = useRef(null);
    const isActiveLink =
        location.pathname === `/project/api/pages/browse/${page.id}` ||
        location.pathname === `/project/api/pages/${page.id}/edit`;
    const hasOwnerRights =
        (project.owner.id === myAccount.id && currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project.user_role === 'MAINTAINER' ||
        project.user_role === 'WRITE';
    const isShowDropdown = selectedDropdown === dropdownId;

    useOutsideClick(dropdownRef, () => toggleDropdown(undefined, null));
    useDropdownPosition(dropdownRef, isShowDropdown);

    return (
        <li className="nav-item sidebar-link-item mx-2">
            <NavLink
                className={
                    (isActiveLink && 'sidebar-link-active') +
                    ' nav-link sidebar-link sidebar-link-route word-break'
                }
                to={`/project/api/pages/browse/${page.id}?project=${project.uid}&api=${api.uid}`}
                activeClassName="sidebar-link-active nav-link sidebar-link sidebar-link-route word-break"
            >
                <img
                    src={iconPage}
                    alt="page"
                    style={{ width: '20px', height: '14px' }}
                />
                <span className="mx-1">{page.title}</span>
                <div className="action-container">
                    {hasRights && (
                        <>
                            <button
                                type="button"
                                className="btn-action edit hover-default"
                                onClick={(e) => {
                                    const id = uniqueId();
                                    setDropdownId(id);
                                    toggleDropdown(e, id);
                                }}
                            >
                                <MoreHorizontal size={16} color="#000000" />
                            </button>
                            {isShowDropdown && (
                                <ul
                                    ref={dropdownRef}
                                    className="dropdown-list"
                                    style={{ minWidth: '110px' }}
                                >
                                    <li>
                                        <button
                                            className="dropdown-link"
                                            name="edit"
                                            onClick={(e) => {
                                                handleClickAction(
                                                    e,
                                                    page,
                                                    'edit-page',
                                                );
                                                toggleDropdown(e, null);
                                            }}
                                        >
                                            Edit
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className="dropdown-link text-danger"
                                            name="delete"
                                            onClick={(e) =>
                                                handleClickAction(
                                                    e,
                                                    page,
                                                    'delete-page',
                                                )
                                            }
                                        >
                                            Delete
                                        </button>
                                    </li>
                                </ul>
                            )}
                        </>
                    )}
                </div>
            </NavLink>
        </li>
    );
};

export default PagesListItem;
