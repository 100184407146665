import LocalLoader from 'ui/LocalLoader';
import RoutesGroup from './RoutesGroup';
import RoutesItem from './RoutesItem';

const RoutesList = ({
    routes,
    pages,
    isRoutesFetching,
    groups,
    inputSearch,
}) => {
    const withoutGroup = routes?.filter((el) => el.group_id === null);

    if (!pages.length && !routes?.length && inputSearch)
        return (
            <LocalLoader loading={isRoutesFetching} className="mt-5">
                <div className="create-route-panel not-found">
                    <p className="create-route-panel__desc">
                        No routes or pages found
                    </p>
                </div>
            </LocalLoader>
        );

    if (!pages.length && !routes?.length && !groups?.length)
        return (
            <LocalLoader loading={isRoutesFetching} className="mt-5">
                <div className="create-route-panel not-found mt-3">
                    <p className="create-route-panel__desc">
                        This API currently doesn't have any routes or pages
                    </p>
                </div>
            </LocalLoader>
        );

    return (
        <>
            {!!groups?.length &&
                groups.map((group) => {
                    const children = routes?.filter(
                        (el) => el.group_id === group.id,
                    );

                    return !inputSearch ||
                        (inputSearch && !!children.length) ? (
                        <RoutesGroup
                            key={group.id}
                            group={group}
                            children={children}
                        />
                    ) : null;
                })}
            <ul className="nav flex-column flex-grow-1 pb-2">
                {withoutGroup?.map((route) => {
                    return <RoutesItem key={route.id} route={route} />;
                })}
            </ul>
        </>
    );
};

export default RoutesList;
