import { useState } from 'react';

import { Input } from 'ui/Inputs';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    organizationSelectors,
    updateOrganizationInvoiceEmail,
} from 'store/slices/organizationSlice';
import { fetchMe } from 'store/slices/authSlice';

const ContactEmail = () => {
    const dispatch = useDispatch();
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const invoiceEmailUpdatingStatus = useSelector(
        organizationSelectors.getInvoiceEmailUpdatingStatus,
    );

    const [email, setEmail] = useState(currentOrg?.invoice_email || '');
    const [errors, setErrors] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const data = { invoice_email: email };
        dispatch(updateOrganizationInvoiceEmail({ data, onSuccess, onError }));
    };

    const onSuccess = () => {
        toast.success('Your data has been saved.');
        dispatch(fetchMe());
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    return (
        <div className="p-4 billing--card-block">
            <h3 className="title-h5">Contact email</h3>
            <p className="billing--paragraph pt-1">
                Where should invoices be sent?
            </p>

            <div className="w-100">
                <form className="d-flex mt-3">
                    <Input
                        type="email"
                        name="email"
                        className="form-control"
                        labelClassName="form-label"
                        placeHolder="Enter alternative email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        errorText={errors?.invoice_email}
                    />
                    <button
                        className="btn btn-primary ms-3 d-flex align-items-center"
                        onClick={handleSubmit}
                        disabled={
                            email === currentOrg?.invoice_email ||
                            invoiceEmailUpdatingStatus === 'loading'
                        }
                    >
                        Save
                        {invoiceEmailUpdatingStatus === 'loading' && (
                            <span
                                className="spinner-border spinner-border-sm ms-2"
                                aria-hidden="true"
                            ></span>
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ContactEmail;
