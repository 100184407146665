export const validateSchema = (schema) => {
    if (!schema) return '';

    return JSON.parse(schema);
};

export const createShallowCopy = (data) => {
    return JSON.parse(JSON.stringify(data));
};

export const generateId = () => {
    const createRandomString = () => Math.random().toString(16).slice(2);
    return `${createRandomString()}-${createRandomString()}`;
};

export const capitalize = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

export function resolveType(type) {
    return Array.isArray(type) ? type[0] : type;
}

export const findRef = (models, refName) => {
    return models.find((model) => Object.keys(model)[0] === refName) || null;
};

export const defineSchema = (schema, currentModelName) => {
    if (!schema)
        return {
            [currentModelName || 'schema']: {
                type: 'object',
                examples: [],
                properties: {},
            },
        };

    return JSON.parse(schema);
};

export const isCombiner = (type) => ['allOf', 'anyOf', 'oneOf'].includes(type);

export const literalsMap = {
    string: 'string',
    number: '1.0',
    integer: 1,
    boolean: true,
    object: {},
    array: [],
    null: null,
};
