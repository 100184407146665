import { Helmet } from 'react-helmet';

import MainContent from 'ui/MainContent';
import ManageBilling from './ManageBilling/ManageBilling';
// import InvoiceMain from './TableBilling/InvoiceMain';
import { useSelector } from 'react-redux';
import { organizationSelectors } from 'store/slices/organizationSlice';
import { useHistory } from 'react-router-dom';
import { useLayoutEffect } from 'react';

const BillingPage = () => {
    const history = useHistory();
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    useLayoutEffect(() => {
        if (currentOrg && currentOrg?.role !== 'OWNER') history.push('/403');
    }, [currentOrg, currentOrg?.role, history]);

    return (
        <MainContent>
            <div className="main-container">
                <Helmet>
                    <title>Billing - CDP</title>
                </Helmet>

                <div className="page-header d-flex flex-column mx-sm-5 mx-3 mb-5">
                    <h1 className="title-h3">Billing</h1>
                    <p className="pt-2 billing--paragraph">
                        Manage your billing and payment details
                    </p>
                </div>
                <ManageBilling />
                {/* {currentOrg && <InvoiceMain />}  */}
            </div>
        </MainContent>
    );
};

export default BillingPage;
