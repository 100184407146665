import moment from 'moment/moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { createShallowCopy } from 'utils/helpers';

export default function RecentComponents({
    resources,
    responses,
    resourcesTrash,
    responsesTrash,
    project,
    api,
}) {
    const setTag = (array, tag) => {
        return array.map((el) => {
            const objCopy = createShallowCopy(el);
            objCopy.tag = tag;
            return objCopy;
        });
    };

    const taggedModels = setTag(resources, 'models');
    const taggedTrashedModels = setTag(resourcesTrash, 'models');
    const taggedResponses = setTag(responses, 'responses');
    const taggedTrashedResponses = setTag(responsesTrash, 'responses');

    const sortAndCobineData = (...args) => {
        const sortCallback = (a, b) =>
            new Date(b.updated_at) - new Date(a.updated_at);

        const result = args.reduce((acc, el) => {
            const sortedArray = el?.toSorted(sortCallback);
            const lastUpdatetdElements = sortedArray.slice(0, 5);

            return [...acc, ...lastUpdatetdElements];
        }, []);
        return result.toSorted(sortCallback).slice(0, 5);
    };

    const recentData = sortAndCobineData(
        taggedModels,
        taggedResponses,
        taggedTrashedModels,
        taggedTrashedResponses,
    );

    return (
        <div className="mt-4">
            <h6>Recent changes</h6>
            <ul className="mt-3">
                {!!recentData?.length ? (
                    recentData?.map((el) => (
                        <li key={el.name}>
                            <Link
                                to={`/project/api/components/${el?.tag}/browse/${el?.id}?project=${project?.uid}&api=${api?.uid}`}
                                className="me-2"
                            >
                                <span>{el?.name}</span>
                            </Link>
                            -
                            <span>
                                <span className="mx-2 recent-data__path">{`components/${el?.tag}/${el?.name}`}</span>
                                <span className="recent-data__path">|</span>
                                <span className="ms-2 recent-data__date">
                                    {el?.deleted_at === el?.updated_at && (
                                        <>
                                            <span className="me-1 recent-data__deleted">
                                                deleted
                                            </span>
                                            {moment(el?.deleted_at).format(
                                                'DD/MM/YYYY hh:mm',
                                            )}
                                        </>
                                    )}
                                    {el?.created_at === el?.updated_at && (
                                        <>
                                            <span className="me-1 recent-data__created">
                                                created
                                            </span>
                                            {moment(el?.updated_at).format(
                                                'DD/MM/YYYY hh:mm',
                                            )}
                                        </>
                                    )}
                                    {el?.created_at !== el?.updated_at &&
                                        !el?.deleted_at && (
                                            <>
                                                <span className="me-1 recent-data__updated">
                                                    updated
                                                </span>
                                                {moment(el?.updated_at).format(
                                                    'DD/MM/YYYY hh:mm',
                                                )}
                                            </>
                                        )}
                                </span>
                            </span>
                        </li>
                    ))
                ) : (
                    <p>No recent activities</p>
                )}
            </ul>
        </div>
    );
}
