import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';

const LeftSideBar = ({ initialWidth, minWidth, children }) => {
    const [isResizing, setIsResizing] = useState(false);
    const [leftSidebarWidth, setLeftSidebarWidth] = useState(
        initialWidth || 263,
    );

    const handleMousemove = useCallback(
        (e) => {
            if (!isResizing) {
                return;
            }

            let minimalWidth = minWidth || 160;
            let maxWidth = 400;
            const newWidth = e.clientX - document.body.offsetLeft;

            if (newWidth > minimalWidth && newWidth < maxWidth) {
                setLeftSidebarWidth(newWidth);
            }
        },
        [isResizing, minWidth],
    );

    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMousemove);

        return () => {
            document.removeEventListener('mousemove', handleMousemove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleMousemove]);

    const handleMousedown = (e) => {
        setIsResizing(true);
    };

    const handleMouseUp = (e) => {
        setIsResizing(false);
    };

    const rootStyle = `:root{--leftSidebarWidth:${leftSidebarWidth}px;}`;

    return (
        <div className="sidebar-container">
            <style>{rootStyle}</style>
            <div className="left-sidebar">
                <div className="sidebar">
                    <div className="sidebar-content">{children}</div>
                </div>
                <div
                    id="dragger"
                    className="sidebar-dragger"
                    onMouseDown={handleMousedown}
                />
            </div>
        </div>
    );
};

export default LeftSideBar;
