import React, { useState } from 'react';
import JSONPretty from 'react-json-pretty';
import { statusCodes } from 'shared/data/statusCodes';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { toast } from 'react-toastify';
import { Copy } from 'react-feather';
import Tooltip from 'ui/Tooltip';
import PrettyTextTab from './Tabs/PrettyTextTab';
import PreviewTab from './Tabs/PreviewTab';
import RawTextTab from './Tabs/RawTextTab';

export default function ResponseView({
    status,
    data,
    responseStatus,
    clearResponse,
}) {
    const [currentTab, setCurrentTab] = useState('pretty');
    const [setCopiedText] = useCopyToClipboard();

    const selectTab = (e) => {
        setCurrentTab(e.target.dataset.tab);
    };

    const onCopyToClipboard = () => {
        setCopiedText(
            data.contentType === 'text/html'
                ? data.result
                : JSON.stringify(data.result),
        );
        toast.success('Copied to Clipboard');
    };

    const setStatusColor = (code) => {
        if (code >= 100 && code <= 199) return 'secondary';
        if (code >= 200 && code <= 299) return 'success';
        if (code >= 300 && code <= 399) return 'secondary';
        if (code >= 400 && code <= 599) return 'danger';
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {status === 'error' ? (
                        <span className="badge text-bg-danger">Error</span>
                    ) : (
                        <span
                            className={`badge text-bg-${setStatusColor(responseStatus)}`}
                        >
                            {responseStatus} {statusCodes?.[responseStatus]}
                        </span>
                    )}
                    <Tooltip
                        id="copyexample"
                        content="Copy response"
                        shiftX={-6}
                    >
                        <button
                            type="button"
                            onClick={onCopyToClipboard}
                            className="btn"
                        >
                            <Copy size={16} color="#001a37b3" />
                        </button>
                    </Tooltip>
                </div>
                <button
                    type="button"
                    className="btn btn-link"
                    onClick={clearResponse}
                >
                    Clear
                </button>
            </div>
            {status === 'ok' && (
                <ul className="route-tabs-list mb-2">
                    <li
                        data-tab="pretty"
                        className={
                            currentTab === 'pretty'
                                ? 'route-tabs-list-item--selected'
                                : 'route-tabs-list-item'
                        }
                        onClick={selectTab}
                    >
                        Pretty
                    </li>
                    <li
                        data-tab="raw"
                        className={
                            currentTab === 'raw'
                                ? 'route-tabs-list-item--selected'
                                : 'route-tabs-list-item'
                        }
                        onClick={selectTab}
                    >
                        Raw
                    </li>
                    <li
                        data-tab="preview"
                        className={
                            currentTab === 'preview'
                                ? 'route-tabs-list-item--selected'
                                : 'route-tabs-list-item'
                        }
                        onClick={selectTab}
                    >
                        Preview
                    </li>
                </ul>
            )}

            {status === 'ok' && currentTab === 'pretty' && (
                <PrettyTextTab data={data} />
            )}
            {status === 'ok' && currentTab === 'raw' && (
                <RawTextTab data={data} />
            )}
            {status === 'ok' && currentTab === 'preview' && (
                <PreviewTab data={data} />
            )}
            {status === 'error' && (
                <div
                    className="p-1"
                    style={{
                        backgroundColor: '#f4f5f7',
                        borderRadius: '8px',
                    }}
                >
                    <JSONPretty data={data?.result} />
                </div>
            )}
        </div>
    );
}
