import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MoreHorizontal, Plus } from 'react-feather';
import folderOpen from 'assets/img/folder-open.svg';
import folderClose from 'assets/img/folder-close.svg';

import RoutesGroupChildren from './RoutesGroupChildren';
import useDropMethod from 'hooks/useDropMethod';
import useOutsideClick from 'hooks/useOutsideClick';
import { uniqueId } from 'utils/helpers';
import { setLastOpenGroupId } from 'store/slices/routesSlice';
import { useHistory } from 'react-router-dom';
import { apiSelectors } from 'store/slices/apiSlice';
import { authSelectors } from 'store/slices/authSlice';
import { organizationSelectors } from 'store/slices/organizationSlice';

export default function RoutesGroup(props) {
    const dispatch = useDispatch();

    const [isShow, setIsShow] = useState(false);
    const [dropdownId, setDropdownId] = useState(undefined);

    const api = useSelector(apiSelectors.getCurrentApi);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const itemRef = useRef(null);
    const dropdownRef = useRef(null);
    const history = useHistory();
    const { drop, isOver } = useDropMethod(props.moveRoute, props.group.id);
    const dragHoverBg = isOver && '#0d6efd14';
    const hasOwnerRights =
        (props.project.owner.id === myAccount.id &&
            currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        props.project.user_role === 'MAINTAINER' ||
        props.project.user_role === 'WRITE';
    const isShowDropdown = props.selectedDropdown === dropdownId;
    const currentRouteId = props.route.id;
    const currentSelectedRoute = props.children.find(
        (route) => route.id === currentRouteId,
    );
    const selectedRoutesById = props.children.map((route) => route.id);

    useOutsideClick(dropdownRef, () => props.toggleDropdown(undefined, null));
    drop(itemRef);

    useEffect(() => {
        if (currentSelectedRoute) {
            setIsShow(true);
            dispatch(setLastOpenGroupId(props.group.id));
        }
    }, [currentSelectedRoute, currentRouteId, props.group.id, dispatch]);

    const handleToggleGroup = () => {
        setIsShow((prev) => !prev);

        if (!isShow) {
            dispatch(setLastOpenGroupId(props.group.id));
        } else {
            dispatch(setLastOpenGroupId(null));
        }
    };
    const location = {
        pathname: `/project/api/routes/create`,
        search: `?project=${props.project.uid}&api=${api.uid}`,
        state: { groupId: props.group.id },
    };

    return (
        <ul
            className={`nav flex-column`}
            style={{ backgroundColor: dragHoverBg }}
            ref={itemRef}
        >
            <li className="nav-item sidebar-link-item mx-2">
                <div
                    onClick={handleToggleGroup}
                    className={
                        'nav-link sidebar-link sidebar-link-route wrod-break'
                    }
                >
                    {isShow ? (
                        <img
                            src={folderOpen}
                            alt="folder-open"
                            style={{ marginRight: '4px' }}
                        />
                    ) : (
                        <img
                            src={folderClose}
                            alt="folder-close"
                            style={{ marginRight: '4px' }}
                        />
                    )}
                    <span className="me-1">{props.group.name}</span>

                    <div className="action-container">
                        {hasRights && (
                            <>
                                <div className="d-flex">
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            history.push(location);
                                        }}
                                        className="btn-action edit hover-default me-2"
                                    >
                                        <Plus size={14} color="#000000" />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={(e) => {
                                            const id = uniqueId();
                                            setDropdownId(id);
                                            props.toggleDropdown(e, id);
                                        }}
                                        className="btn-action edit hover-default"
                                    >
                                        <MoreHorizontal
                                            size={16}
                                            color="#000000"
                                        />
                                    </button>
                                </div>
                                {isShowDropdown && (
                                    <ul
                                        ref={dropdownRef}
                                        className="dropdown-list"
                                    >
                                        <li>
                                            <button
                                                className="dropdown-link"
                                                name="edit"
                                                onClick={(e) =>
                                                    props.handleClickAction(
                                                        e,
                                                        {
                                                            ...props.group,
                                                            routes: selectedRoutesById,
                                                        },
                                                        'edit-group',
                                                    )
                                                }
                                            >
                                                Edit
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                className="dropdown-link text-danger"
                                                name="delete"
                                                onClick={(e) =>
                                                    props.handleClickAction(
                                                        e,
                                                        props.group,
                                                        'delete-group',
                                                    )
                                                }
                                            >
                                                Delete
                                            </button>
                                        </li>
                                    </ul>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </li>
            {!!props.children.length && (
                <ul className="nav flex-column nav-children">
                    {isShow &&
                        props.children.map((route) => {
                            return (
                                <RoutesGroupChildren
                                    key={route.id}
                                    {...props}
                                    route={route}
                                    routesSettings={props.routesSettings}
                                />
                            );
                        })}
                </ul>
            )}
        </ul>
    );
}
