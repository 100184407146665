import React from 'react';
import { Route } from 'react-router';
import ModelsForm from './ModelsForm';
import ModelsView from './ModelsView';

export default function ModelsRoutes() {
    return (
        <>
            <Route path="/project/api/components/models/create">
                <ModelsForm />
            </Route>
            <Route path="/project/api/components/models/browse/:id">
                <ModelsView />
            </Route>
            <Route path="/project/api/components/models/:id/edit">
                <ModelsForm />
            </Route>
        </>
    );
}
