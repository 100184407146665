import RouteResponseTabs from '../../RouteResponseTabs/RouteTabs';
import ResponseExampleItem from './ResponseExampleItem';

const ResponseExample = (props) => {
    if (props.items?.length > 0) {
        return (
            <div className="mb-4">
                <RouteResponseTabs
                    items={props.items}
                    activeTab={props.activeTab}
                    onClick={props.handleClick}
                    selectResponseStatus={props.selectResponseStatus}
                    selectSharedResponse={props.selectSharedResponse}
                    deleteResponseExample={props.deleteResponseExample}
                    addResponseExample={props.addResponseExample}
                />
                <div>
                    {props.items.map((item, index) => {
                        const httpStatusCode = item.http_status_code;
                        if (httpStatusCode === props.activeTab) {
                            return (
                                <ResponseExampleItem
                                    key={index}
                                    item={item}
                                    index={index}
                                    editorChange={props.editorChange}
                                    changeParam={props.changeParam}
                                    errors={props.errors}
                                    // handleRecentHeaders={
                                    //     props.handleRecentHeaders
                                    // }
                                    // addResponseHeader={props.addResponseHeader}
                                    // deleteResponseHeader={
                                    //     props.deleteResponseHeader
                                    // }
                                    // headers={props.headers}
                                    // changeHeaderParameter={
                                    //     props.changeHeaderParameter
                                    // }
                                />
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
        );
    } else {
        return <div />;
    }
};

export default ResponseExample;
