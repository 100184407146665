import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ErrorText from 'ui/ErrorText';

export default function TextEditor({
    description,
    handleEditorChange,
    errorText,
    editorClassName,
    showEditorRef,
    textEditorForComment,
}) {
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromBlockArray(htmlToDraft(description)),
        ),
    );
    const [error, setError] = useState('');
    const editorRef = useRef();

    useEffect(() => {
        if (errorText) setError(errorText);
    }, [errorText, showEditorRef]);

    useEffect(() => {
        if (showEditorRef) {
            editorRef?.current.focusEditor();
        }
    }, [showEditorRef]);
    const onEditorChange = (editorState) => {
        setEditorState(editorState);

        if (
            convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
            convertToRaw(editorState.getCurrentContent()).blocks[0].text === ''
        ) {
            handleEditorChange('');
        } else {
            handleEditorChange(
                draftToHtml(convertToRaw(editorState.getCurrentContent())),
            );
        }
    };

    const removeErrorText = () => {
        setError('');
    };

    const toolbarOptions = textEditorForComment
        ? ['inline', 'list', 'link', 'remove', 'history']
        : [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'remove',
              'history',
          ];

    const errorBorderStyle = {
        border: '2px solid red',
        borderRadius: '8px',
        overflow: 'hidden',
    };

    return (
        <div>
            <div
                onFocus={removeErrorText}
                style={error ? errorBorderStyle : null}
            >
                <Editor
                    editorState={editorState}
                    onEditorStateChange={onEditorChange}
                    toolbar={{
                        options: toolbarOptions,
                        inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                            ],
                        },
                        blockType: {
                            inDropdown: true,
                            options: [
                                'Normal',
                                'H1',
                                'H2',
                                'H3',
                                'H4',
                                'H5',
                                'H6',
                                'Blockquote',
                                'Code',
                            ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                        },
                        list: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: textEditorForComment
                                ? ['unordered', 'ordered']
                                : ['unordered', 'ordered', 'indent', 'outdent'],
                        },
                    }}
                    editorClassName={editorClassName}
                    ref={editorRef}
                />
            </div>
            {error && <ErrorText text={errorText[0]} />}
        </div>
    );
}
