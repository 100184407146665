export const projectSelectors = {
    getProjects: (state) => state.projects.projects,
    getCurrentProject: (state) => state.projects.project,
    getIsProjectFetching: (state) => state.projects.isProjectFetching,
    getIsProjectsFetching: (state) => state.projects.isProjectsFetching,
    getIsCreateProjectModalShown: (state) =>
        state.projects.isCreateProjectModalShown,
    getTransfers: (state) => state.projects.transfers,
    getIsTransfersFetching: (state) => state.projects.isTransfersFetching,
    getProjectMembers: (state) => state.projects.projectMembers,
    getIsProjectMembersFetching: (state) =>
        state.projects.isProjectMembersFetching,
    getPendingInvites: (state) => state.projects.pendingInvites,
    getIsPendingInvitesFetching: (state) =>
        state.projects.isPendingInvitesFetching,
    getIsStarredProjectsFetching: (state) =>
        state.projects.isStarredProjectsFetching,
    getIsRecentProjectsFetching: (state) =>
        state.projects.isRecentProjectsFetching,
    getRecentProjects: (state) => state.projects.recentProjects,
    getStarredProjects: (state) => state.projects.starredProjects,
};

export const appSelectors = {
    getIsLoaded: (state) => state.app.isLoaded,
    getShowGlobalLoader: (state) => state.app.showGlobalLoader,
    getOrgDomain: (state) => state.app.orgDomain,
};

export const apiSelectors = {
    getCurrentApi: (state) => state.api.api,
    getApis: (state) => state.api.apis,
    getIsApisFetching: (state) => state.api.isApisFetching,
    getIsCreateApiModalShown: (state) => state.api.isCreateApiModalShown,
};

export const authSelectors = {
    getMyAccount: (state) => state.auth.myAccount,
};

export const environmentSelectors = {
    getEnvironments: (state) => state.environments.environments,
    getSelectedEnvironments: (state) => state.environments.selectedEnvironments,
};

export const documentationSelectors = {
    getIsDocumentationUsersFetching: (state) =>
        state.documentation.isDocumentationUsersFetching,
};

export const historySelectors = {
    getHistory: (state) => state.history.history,
    getCurrentHistory: (state) => state.history.historyById,
    getIsHistoryFetching: (state) => state.history.isHistoryFetching,
    getIsHistoryByIdFetching: (state) => state.history.isHistoryByIdFetching,
};

export const memberSelectors = {
    getCurrentMember: (state) => state.members.member,
    getMember: (state) => state.members.member,
    getMembers: (state) => state.members.members,
    getMemberProjects: (state) => state.members.memberProjects,
    getIsMembersFetching: (state) => state.members.isMembersFetching,
    getIsMemberProjectsFetching: (state) =>
        state.members.isMemberProjectsFetching,
};

export const resourceSelectors = {
    getCurrentResource: (state) => state.resources.resource,
    getResources: (state) => state.resources.resources,
    getResourcesTrash: (state) => state.resources.resourcesTrash,
    getIsResourcesTrashFetching: (state) =>
        state.resources.isResourcesTrashFetching,
    getIsResourceFetching: (state) => state.resources.isResourceFetching,
    getIsResourcesFetching: (state) => state.resources.isResourcesFetching,
};

export const routeSelectors = {
    getCurrentRoute: (state) => state.routes.route,
    getRoutes: (state) => state.routes.routes,
    getRoutesTrash: (state) => state.routes.routesTrash,
    getIsRoutesFetching: (state) => state.routes.isRoutesFetching,
    getIsRouteFetching: (state) => state.routes.isRouteFetching,
    getGroups: (state) => state.routes.groups,
    getHeaders: (state) => state.routes.headers,
};

export const pageSelectors = {
    getCurrentPage: (state) => state.pages.page,
    getPages: (state) => state.pages.pages,
    getPagesTrash: (state) => state.pages.pagesTrash,
    getIsPagesTrashFetching: (state) => state.pages.isPagesTrashFetching,
    getIsPagesFetching: (state) => state.pages.isPagesFetching,
    getIsPageFetching: (state) => state.pages.isPageFetching,
};
