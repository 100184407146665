import React, { useLayoutEffect, useState } from 'react';
import { getCookie, setCookie } from 'utils/cookie';

export default function CookieConsentBanner() {
    const [displayConsent, setDisplayConsent] = useState(true);

    useLayoutEffect(() => {
        const isCookiesAccpeted = getCookie('cookies_accpeted');
        setDisplayConsent(!isCookiesAccpeted);
    }, []);

    const onAcceptCookies = () => {
        setCookie('cookies_accpeted', 1);
        setDisplayConsent(false);
    };

    return (
        displayConsent && (
            <div className="cookie-banner__wrapper">
                <div className="cookie-banner__container">
                    <p className="cookie-banner__text">
                        This website uses cookies to ensure you get the best
                        experience on our website
                    </p>
                    <button
                        className="btn btn-outline-light"
                        onClick={onAcceptCookies}
                    >
                        Got it!
                    </button>
                </div>
            </div>
        )
    );
}
