import React from 'react';
import { Input, PasswordField } from 'ui/Inputs';

const UsersItem = ({
    username,
    password,
    description,
    handleChange,
    deleteRow,
    errors,
    index,
}) => {
    return (
        <tr>
            <td>
                <Input
                    type="text"
                    name="username"
                    className="form-control"
                    value={username || ''}
                    onChange={handleChange}
                    placeHolder="User name"
                    errorText={errors[`users.${index}.username`]}
                />
            </td>
            <td>
                <PasswordField
                    name="password"
                    className="form-control"
                    placeHolder="User password"
                    value={password || ''}
                    onChange={handleChange}
                    margin="mb-0"
                    errorText={errors[`users.${index}.password`]}
                />
            </td>
            <td>
                <Input
                    type="text"
                    name="description"
                    className="form-control"
                    value={description || ''}
                    onChange={handleChange}
                    placeHolder="User description"
                    errorText={errors[`users.${index}.description`]}
                />
            </td>
            <td className="text-end">
                <button
                    className={`btn btn-light ${index === 0 && 'btn-hidden'}`}
                    onClick={deleteRow}
                >
                    -
                </button>
            </td>
        </tr>
    );
};

export default UsersItem;
