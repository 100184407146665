import React from 'react';
import { Input } from 'ui/Inputs';

const EnvProperties = ({ items, changeParam, deleteRow, errors }) => {
    if (items?.length > 0) {
        return (
            <div className="pb-3 overflow-auto" style={{ maxHeight: '400px' }}>
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th className="title-table">&nbsp;</th>
                            <th className="title-table">Variable</th>
                            <th className="title-table">Value</th>
                            <th className="title-table">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td className="align-middle">
                                    <input
                                        type="checkbox"
                                        name="is_active"
                                        className="form-check-input align-baseline"
                                        onChange={(e) => changeParam(e, index)}
                                        defaultChecked={item.is_active}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        value={item.name}
                                        onChange={(e) => changeParam(e, index)}
                                        placeHolder="Variable name"
                                        errorText={
                                            errors[`variables.${index}.name`]
                                        }
                                    />
                                </td>
                                <td>
                                    <Input
                                        type="text"
                                        name="value"
                                        className="form-control"
                                        value={item.value}
                                        onChange={(e) => changeParam(e, index)}
                                        placeHolder="Variable value"
                                        errorText={
                                            errors[`variables.${index}.value`]
                                        }
                                    />
                                </td>
                                <td className="btn btn-light">
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        onClick={(e) => deleteRow(e, index)}
                                    >
                                        -
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    } else {
        return <></>;
    }
};

export default EnvProperties;
