import { useEffect, useState } from 'react';
import { Copy, Plus, Trash2 } from 'react-feather';
import Editor from 'react-simple-code-editor';
import { generateId } from './helpers';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import './assets/styles/codeEditorStyles.scss';
import usePrevious from './hooks/usePrevious';
import Tooltip from 'ui/Tooltip';
import useCopyToClipboard from './hooks/useCopyToClipboard';
import { toast } from 'react-toastify';

export default function Examples({
    examples,
    addExample,
    editExample,
    editExampleName,
    deleteExample,
}) {
    const [code, setCode] = useState('');
    const [isValidJson, setIsValidJson] = useState(true);
    const [jsonErrorMessage, setJsonErrorMessage] = useState('');
    const [currentExample, setCurrentExample] = useState(
        Object.keys(examples)[0] || null,
    );
    const prevExamples = usePrevious(examples);

    const [setCopiedText] = useCopyToClipboard();

    useEffect(() => {
        setCode(JSON.stringify(examples[currentExample], null, 2) || '');
    }, [examples, currentExample]);

    useEffect(() => {
        const prevExamplesLength =
            prevExamples && Object.keys(prevExamples)?.length;
        const examplesLength = Object.keys(examples)?.length;

        if (prevExamples && prevExamplesLength < examplesLength) {
            setCurrentExample(Object.keys(examples)[examplesLength - 1]);
        }
    }, [examples, currentExample, prevExamples]);

    const selectExample = (el) => {
        setCurrentExample(el);
        setJsonErrorMessage('');
        setIsValidJson(true);
    };

    const isJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            setJsonErrorMessage(e.message);
            return false;
        }
        return true;
    };

    const onAddExample = () => {
        addExample();
    };

    const onDeleteExample = (exampleName) => {
        const examplesKeys = Object.keys(examples);

        deleteExample(exampleName);
        setCurrentExample(
            examplesKeys[examplesKeys.indexOf(currentExample) + 1] ||
                examplesKeys[examplesKeys.indexOf(currentExample) - 1],
        );
    };

    const onEditExample = (code) => {
        setCode(code);
        if (isJsonString(code)) {
            setIsValidJson(true);
        } else {
            setIsValidJson(false);
        }
    };

    const onEditExampleName = (e) => {
        const { value, dataset } = e.target;

        editExampleName(dataset.exampleName, value);
        setCurrentExample(value);
    };

    const onCopyToClipboard = () => {
        setCopiedText(code);
        toast.success('Copied to Clipboard');
    };

    const onFormatCode = () => {
        if (isValidJson)
            editExample(
                JSON.parse(JSON.stringify(code, null, 2)),
                currentExample,
            );
    };

    return (
        <div
            className="JsonSchemaEditor__examples-wrapper"
            style={{
                maxHeight: !Object.keys(examples).length ? '100px' : '100%',
            }}
        >
            <div className="JsonSchemaEditor__examples-buttons-wrapper">
                <div>
                    <div className="JsonSchemaEditor__examples-button-wrapper">
                        <button
                            className="JsonSchemaEditor__examples-add-button"
                            onClick={onAddExample}
                            data-key="generator"
                            type="button"
                        >
                            <Plus size={16} />
                            <span className="ms-2">Example</span>
                        </button>
                    </div>
                </div>
                <div style={{ borderBottom: '1px solid #edeff3' }}></div>
                <div className="JsonSchemaEditor__examples-buttons-list">
                    {Object.keys(examples)?.map((el) => (
                        <div
                            className="JsonSchemaEditor__examples-button-wrapper"
                            key={generateId()}
                        >
                            <button
                                onClick={() => selectExample(el)}
                                className={
                                    currentExample === el
                                        ? 'JsonSchemaEditor__examples-tab-button JsonSchemaEditor__tab-button--active'
                                        : 'JsonSchemaEditor__examples-tab-button'
                                }
                                type="button"
                            >
                                <span>{el}</span>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <div className="JsonSchemaEditor__examples-editor-wrapper">
                {!!Object.keys(examples)?.length ? (
                    <>
                        <div className="JsonSchemaEditor__examples-info-pannel-wrapper">
                            <div className="JsonSchemaEditor__examples-info-pannel">
                                <input
                                    onChange={onEditExampleName}
                                    value={currentExample || ''}
                                    data-example-name={currentExample}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className="ms-3">
                                <button
                                    className="JsonSchemaEditor__generate-button"
                                    type="button"
                                    onClick={onFormatCode}
                                >
                                    Format
                                </button>
                                <Tooltip
                                    id="copyexample"
                                    content="Copy example"
                                >
                                    <button
                                        onClick={onCopyToClipboard}
                                        className="JsonSchemaEditor__examples-copy-button"
                                        type="button"
                                    >
                                        <Copy size={16} color="#001a37b3" />
                                    </button>
                                </Tooltip>
                                <Tooltip
                                    id="removeexample"
                                    content="Remove example"
                                >
                                    <button
                                        className="JsonSchemaEditor__delete-button"
                                        onClick={() =>
                                            onDeleteExample(currentExample)
                                        }
                                        data-key="generator"
                                        type="button"
                                    >
                                        <Trash2 size={16} />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="JsonSchemaEditor__examples-code-editor-wrapper">
                            <Editor
                                value={code}
                                onValueChange={(code) =>
                                    onEditExample(code, currentExample)
                                }
                                highlight={(code) =>
                                    highlight(code, languages.js)
                                }
                                style={{
                                    fontFamily:
                                        'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
                                    fontSize: 12,
                                    fontWeight: 400,
                                }}
                                onBlur={() => {
                                    if (isValidJson)
                                        editExample(code, currentExample);
                                }}
                            />
                        </div>
                        {!isValidJson && (
                            <span className="JsonSchemaEditor__examples-json-error-message">
                                {jsonErrorMessage}
                            </span>
                        )}
                    </>
                ) : (
                    <div style={{ marginTop: '35px', textAlign: 'center' }}>
                        <p>No Example. Click '+ Example' to get started.</p>
                    </div>
                )}
            </div>
        </div>
    );
}
