import React from 'react';

const getColor = (colors) => {
    let color = colors[Math.floor(Math.random() * colors.length)];
    return color;
};

const logoColor = getColor(['#00D3FF', '#B700FF', '#00FFA8', '#007bff']);

const logoStyle = {
    color: logoColor,
    backgroundColor: logoColor + '26',
    marginRight: '16px',
};

const UserAvatar = ({ iconUrl, first_name, last_name, className }) => {
    const initials =
        !!first_name && !!last_name
            ? `${first_name.charAt(0).toUpperCase()}${last_name
                  .charAt(0)
                  .toUpperCase()}`
            : '';

    return (
        <>
            {iconUrl ? (
                <img
                    src={`${iconUrl}?${performance.now()}`}
                    alt="user logo"
                    className={
                        className
                            ? className
                            : 'project-member-logo inline-logo'
                    }
                    style={{ marginRight: '16px' }}
                />
            ) : (
                <span
                    className={
                        className
                            ? className
                            : 'project-member-logo inline-logo'
                    }
                    style={logoStyle}
                >
                    {initials}
                </span>
            )}
        </>
    );
};

export default UserAvatar;
