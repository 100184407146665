import React, { useState } from 'react';
import { ProjectAvatar } from 'components/Avatar';
import { InputSearch } from 'ui/Inputs';

export default function AllProjects({ projects }) {
    const [searchValue, setSearchValue] = useState('');

    const filtered = projects?.filter((el) =>
        el.name.toLowerCase().includes(searchValue.toLowerCase()),
    );

    const onInputChange = (e) => setSearchValue(e.target.value);

    return (
        <>
            <div className="px-2">
                <InputSearch
                    id="input-search"
                    name="inputSearch"
                    type="text"
                    inputStyle={{
                        padding: '0.5rem 0.8rem 0.5rem 2rem',
                    }}
                    labelStyle={{ width: '100%' }}
                    className="form-control"
                    value={searchValue}
                    onChange={onInputChange}
                />
            </div>
            <div>
                <hr className="dropdown-divider header-divider" />
            </div>
            <ul className="starred-projects-container overflow-auto">
                {filtered?.map((project) => (
                    <li key={project.id} className="d-flex">
                        <a
                            className="dropdown-item link-dropdown py-2 word-break"
                            href={`/project?project=${project.uid}`}
                            name={project.name}
                            id={project.uid}
                        >
                            <ProjectAvatar project={project} />
                            {project.name}
                        </a>
                    </li>
                ))}
            </ul>
        </>
    );
}
