import { findRef } from './findRef';

export function resolveRefChain(schema, models) {
    let resolvedRef;
    const visited = new Set();

    function resolver(schema) {
        const refName = Object.keys(schema)[0];

        if (visited.has(refName)) return;

        visited.add(refName);

        if (refName && schema[refName]?.$ref) {
            const nextRefName = schema[refName]?.$ref.split('/').slice(-1)[0];

            visited.add(nextRefName);

            const nextRef = findRef(models, nextRefName);
            if (!nextRef) {
                resolvedRef = schema;
                return;
            }
            resolvedRef = nextRef;
            if (!nextRef[Object.keys(nextRef)[0]]?.$ref) return;

            resolver(nextRef);
        } else {
            resolvedRef = schema;
            return;
        }
    }
    resolver(schema);

    return resolvedRef;
}
