import React, { useRef, Fragment } from 'react';

import RoutesNoGroup from './RoutesNoGroup';
import RoutesGroup from './RoutesGroup';
import useDropMethod from 'hooks/useDropMethod';

export default function RoutesListDndContainer({
    props,
    routes,
    route,
    groups,
    withoutGroup,
    inputSearch,
    routesSettings,
}) {
    const itemRef = useRef(null);
    const { drop, isOver } = useDropMethod(props.moveRoute);
    const dragHoverBg = isOver && '#0d6efd14';

    drop(itemRef);

    return (
        <Fragment>
            {!!groups.length &&
                groups.map((group) => {
                    const children = routes.filter(
                        (el) => el.group_id === group.id,
                    );

                    return !inputSearch ||
                        (inputSearch && !!children.length) ? (
                        <RoutesGroup
                            key={group.id}
                            {...props}
                            group={group}
                            children={children}
                            route={route}
                            routesSettings={routesSettings}
                        />
                    ) : null;
                })}
            <ul
                className="nav flex-column flex-grow-1 pb-2"
                ref={itemRef}
                style={{ backgroundColor: dragHoverBg }}
            >
                {withoutGroup.map((route) => {
                    return (
                        <RoutesNoGroup
                            key={route.id}
                            {...props}
                            withoutGroup={withoutGroup}
                            route={route}
                            routesSettings={routesSettings}
                        />
                    );
                })}
            </ul>
        </Fragment>
    );
}
