import React from 'react';
import Picker, { registerLocale } from 'react-datepicker';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import enGb from 'date-fns/locale/en-GB';

registerLocale('en-gb', enGb);

const CustomTimeInput = ({ date, value, onChange }) => {
    return (
        <MaskedInput
            value={value}
            onChange={(e) => onChange(e.target.value)}
            style={{
                border: 'solid 1px #edeff3',
                borderRadius: '4px',
                padding: '4px 6px',
                maxWidth: '46px',
            }}
            mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
            type="text"
            placeholderChar="0"
            showMask={true}
        />
    );
};

export default function DatePicker({
    value,
    onChange,
    showTimeInput,
    dateFormat,
    minDate,
    maxDate,
}) {
    return (
        <Picker
            selected={value ? moment(value).toDate() : new Date()}
            onChange={onChange}
            showTimeInput={showTimeInput || false}
            dateFormat={dateFormat || 'dd/MM/yyyy'}
            locale="en-gb"
            showPopperArrow={false}
            minDate={
                minDate ? moment(minDate).toDate() : new Date('01-01-2021')
            }
            maxDate={maxDate ? moment(maxDate).toDate() : null}
            customTimeInput={<CustomTimeInput />}
            onChangeRaw={(e) => e.preventDefault()}
        />
    );
}
