import React, { useState } from 'react';
import { Input } from 'ui/Inputs';
import { useEffect } from 'react';

const PasswordField = ({
    errorText,
    label,
    className,
    placeHolder,
    value,
    onChange,
    name,
    isRequired,
    margin,
}) => {
    const [isShownPassword, setIsShownPassword] = useState(false);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        if (errorText) {
            setError(errorText || undefined);
        }
    }, [errorText, setError]);

    const togglePassword = (e) => {
        e.preventDefault();

        setIsShownPassword(!isShownPassword);
    };

    return (
        <div
            className={`form-group form-group-main ${margin ? margin : 'mb-4'}`}
        >
            <Input
                type={isShownPassword ? 'text' : 'password'}
                name={name}
                className={className}
                value={value}
                onChange={(e) => onChange(e)}
                labelText={label}
                labelClassName="label-main mb-2"
                placeHolder={placeHolder}
                errorText={error}
                isRequired={isRequired}
                resetPassIcon={() => setError(undefined)}
            />
            {!error && (
                <button
                    className={`user-password-icon-btn user-password-icon ${
                        !label && 'top-32'
                    }
                            ${
                                !!isShownPassword
                                    ? 'user-password-icon-show'
                                    : ''
                            }`}
                    onClick={togglePassword}
                ></button>
            )}
        </div>
    );
};

export default PasswordField;
