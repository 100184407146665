import React, { useEffect, useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import './MembersSelector.scss';
import MembersList from './MembersList';
import LocalLoader from 'ui/LocalLoader';
import useOutsideClick from 'hooks/useOutsideClick';
import {
    getOrganizationMembers,
    organizationSelectors,
} from 'store/slices/organizationSlice';
import { combineClasses as cc } from 'utils/helpers';

const MembersSelector = () => {
    const dispatch = useDispatch();

    const teamMembers = useSelector(
        organizationSelectors.getOrganizationMembersList,
    );
    const orgMembersStatus = useSelector(
        organizationSelectors.getOrganizationMembersLoadingStatus,
    );

    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const [showList, setShowList] = useState(false);

    const selectMemberDropdown = useRef();

    useEffect(() => {
        if (currentOrg) dispatch(getOrganizationMembers());
    }, [dispatch, currentOrg]);

    const handleOutsideClick = () => {
        setShowList(false);
    };

    useOutsideClick(selectMemberDropdown, handleOutsideClick);

    const toggleList = (e) => {
        setShowList(!showList);
    };

    return (
        <div className="btn-group" ref={selectMemberDropdown}>
            <button
                type="button"
                className={cc('custom-dropdown-btn link-dropdown-btn', {
                    show: showList,
                })}
                onClick={toggleList}
            >
                People
                <ChevronDown
                    className="custom-dropdown-btn-arrow"
                    size={12}
                    strokeWidth={2}
                    aria-expanded={showList ? 'true' : 'false'}
                />
            </button>
            {showList && (
                <div
                    className={cc(
                        'dropdown-menu link-dropdown-list dropdown-people',
                        { show: showList },
                    )}
                >
                    <div>
                        <LocalLoader loading={orgMembersStatus === 'loading'}>
                            <MembersList members={teamMembers} />
                        </LocalLoader>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MembersSelector;
