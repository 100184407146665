import React, { useState, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';

import HeaderLogo from './HeaderLogo';
import ProjectsSelector from './ProjectsSelector';
import MembersSelector from './MembersSelector';
import NotificationsSelector from './NotificationsSelector';
import ProfileSelector from './ProfileSelector';
import Modal from 'ui/Modal';
import routesIcon from 'assets/img/routes.svg';
import pageIcon from 'assets/img/page-light.svg';
import resourceIcon from 'assets/img/resource.svg';
import responsesIcon from 'assets/img/share.svg';
import ApisSelector from './ApisSelector/ApisSelector';
import OrganizationsSelector from './OrganizationsSelector/OrganizationsSelector';
import { useSelector } from 'react-redux';
import { organizationSelectors } from 'store/slices/organizationSlice';

const listItem = [
    {
        title: 'Route',
        description: 'Create a new route.',
        link: '/project/api/routes/create?project=',
        image: routesIcon,
    },
    {
        title: 'Page',
        description: 'Create a new page.',
        link: '/project/api/pages/create?project=',
        image: pageIcon,
    },
    {
        title: 'Model',
        description: 'Create a new model.',
        link: '/project/api/components/models/create?project=',
        image: resourceIcon,
    },
    {
        title: 'Shared response',
        description: 'Create a new shared response.',
        link: '/project/api/components/responses/create?project=',
        image: responsesIcon,
    },
];

const Header = ({
    project,
    api,
    myAccount,
    onClickCreateProject,
    onClickCreateApi,
}) => {
    const { pathname } = useLocation();

    const [isOpenModalShow, setIsOpenModalShow] = useState();
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const hasOwnerRights =
        (project?.owner?.id === myAccount.id &&
            currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRouteRights =
        project?.user_role === 'MAINTAINER' ||
        hasOwnerRights ||
        project?.user_role === 'WRITE' ||
        currentOrg?.role === 'ADMIN' ||
        (currentOrg?.role === 'MANAGER' && project?.user_role !== 'READ');
    const hasProjectRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project?.user_role === 'MAINTAINER';
    const noShadowStyle = pathname.includes('/project/api')
        ? {
              boxShadow: 'none',
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
          }
        : {};

    const isShowCreateApiBtn =
        pathname === '/project' ||
        pathname.toLowerCase() === `/project/${project?.code?.toLowerCase()}`;
    const isShowCreateRouteBtn =
        pathname.includes('/project/api/routes') ||
        pathname.toLowerCase() ===
            `/project/${project?.code?.toLowerCase()}/api/${api?.code?.toLowerCase()}/routes`;
    const isShowCreateResourceBtn =
        pathname.includes('/project/api/components') ||
        pathname.toLowerCase() ===
            `/project/${project?.code?.toLowerCase()}/api/${api?.code?.toLowerCase()}/components`;
    const isShowCreatePageBtn =
        pathname.includes('/project/api/pages') ||
        pathname.toLowerCase() ===
            `/project/${project?.code?.toLowerCase()}/api/${api?.code?.toLowerCase()}/pages`;
    const isShowApiPage =
        pathname.includes('/project/api/') ||
        pathname.toLowerCase() ===
            `/project/${project?.code?.toLowerCase()}/api/${api?.code?.toLowerCase()}`;
    const handleClick = (e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        setIsOpenModalShow(!isOpenModalShow);
    };

    return (
        <div className="header-wrapper" style={noShadowStyle}>
            <nav className="main-container d-flex justify-content-between align-items-center px-sm-5 px-3 py-2 mb-0">
                <div className="d-flex align-items-center">
                    <HeaderLogo />
                    <ProjectsSelector />
                    {isShowApiPage && (
                        <ApisSelector project={project} api={api} />
                    )}
                    <MembersSelector />
                    {currentOrg?.role !== 'MEMBER' &&
                        pathname === '/projects' && (
                            <button
                                className="btn btn-primary"
                                onClick={onClickCreateProject}
                            >
                                Create
                            </button>
                        )}

                    {isShowCreateApiBtn && hasProjectRights && (
                        <button
                            className="btn btn-primary"
                            onClick={onClickCreateApi}
                        >
                            Create
                        </button>
                    )}
                    {((isShowCreateRouteBtn && hasRouteRights) ||
                        (isShowCreateResourceBtn && hasRouteRights) ||
                        (isShowCreatePageBtn && hasRouteRights)) && (
                        <button
                            className="btn btn-primary"
                            onClick={handleClick}
                        >
                            Create
                        </button>
                    )}
                </div>

                <div className="d-flex">
                    {!!myAccount?.organizations?.length && (
                        <OrganizationsSelector />
                    )}
                    <NotificationsSelector />
                    <ProfileSelector />
                </div>
            </nav>
            <Modal
                show={isOpenModalShow}
                customClasses={{
                    modalDialog: 'min-width-600',
                }}
                title="Select what you want to create"
                body={
                    <div>
                        <ul className="py-1 w-100 list-group modal-dialog-body-grid">
                            {listItem.map((item, i) => (
                                <li
                                    className="list-group-item list-group-item-action cursor-pointer"
                                    style={{ border: 'none', color: '#000' }}
                                    key={i}
                                >
                                    <Link
                                        style={{ color: '#000' }}
                                        to={`${item.link}${project?.uid}&api=${api?.uid}`}
                                        className="d-flex align-items-start"
                                        onClick={() =>
                                            setIsOpenModalShow(false)
                                        }
                                    >
                                        <img
                                            src={item.image}
                                            alt=""
                                            style={{
                                                width: '26px',
                                                height: '26px',
                                            }}
                                        />
                                        <div className="px-3">
                                            <p
                                                className="fw-bold pb-1"
                                                style={{ fontSize: '18px' }}
                                            >
                                                {item.title}
                                            </p>
                                            <p>{item.description}</p>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                }
                footer={
                    <Fragment>
                        <button
                            type="button"
                            onClick={() => setIsOpenModalShow(false)}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </Fragment>
                }
            />
        </div>
    );
};

export default Header;
