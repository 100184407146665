import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx';
import PreBlock from 'ui/PreBlock';
import RouteResponseTabs from '../RouteResponseTabs/RouteTabs';
import RequestHeadersView from './RequestHeadersView';
import { useSelector } from 'react-redux';
import { resourceSelectors } from 'store/slices/resourcesSlice';
import JsonSchemaViewer from 'components/JsonSchemaViewer/JsonSchemaViewer';
import { routeSelectors } from 'store/slices/routesSlice';
import { ChevronDown } from 'react-feather';
import { Collapse } from 'react-collapse';

const ResponseExampleView = (props) => {
    const [isOpened, setIsOpened] = useState(true);
    const [activeTab, setActiveTab] = useState(
        props.items && props.items[0]?.http_status_code,
    );
    const responses = useSelector(resourceSelectors.getResponses);
    const route = useSelector(routeSelectors.getCurrentRoute);
    const handleClick = (value) => setActiveTab(value);

    const resources = useSelector(resourceSelectors.getResources);

    const models = resources?.map((el) => JSON.parse(el.schema));

    const applyToResponseHeaders = route?.global_headers.filter(
        (el) => el.apply_to_response,
    );

    const allHeaders = [
        ...applyToResponseHeaders,
        ...(route?.response_headers || []),
    ];

    if (props.items && !!props.items.length) {
        return (
            <div>
                <span className="anchor" id="response" />
                <RequestHeadersView items={allHeaders} />
                <div
                    onClick={() => setIsOpened(!isOpened)}
                    className="d-flex justify-content-between align-items-center ps-3 pe-2 cursor-pointer mt-3"
                    style={{
                        height: '47px',
                        borderBottom: '2px solid #edeff3',
                    }}
                >
                    <h6 className="text">
                        Responses {`(${props.items.length})`}
                    </h6>
                    <ChevronDown
                        size={16}
                        color="#001A37"
                        style={{ transform: isOpened && 'rotate(180deg)' }}
                    />
                </div>
                <Collapse isOpened={isOpened}>
                    <div className="pt-4">
                        <RouteResponseTabs
                            items={props.items}
                            activeTab={activeTab}
                            onClick={handleClick}
                            isShowSelect={false}
                            isShowSharedResponseSelect={false}
                        />
                        {props.items.map((item, index) => {
                            const httpStatusCode = item.http_status_code;
                            const foundSharedResponse =
                                responses.find(
                                    (el) => el.id === item.shared_response,
                                ) || null;
                            if (httpStatusCode !== activeTab) {
                                return null;
                            }

                            return foundSharedResponse ? (
                                <div key={index} className="response-example">
                                    {foundSharedResponse?.description && (
                                        <div className="response-example-desc word-break">
                                            <Markdown
                                                options={{
                                                    overrides: {
                                                        pre: PreBlock,
                                                    },
                                                }}
                                            >
                                                {
                                                    foundSharedResponse.description
                                                }
                                            </Markdown>
                                        </div>
                                    )}
                                    <div className="mt-4">
                                        <JsonSchemaViewer
                                            models={models}
                                            schema={foundSharedResponse?.schema}
                                            schemaType="response"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div key={index} className="response-example">
                                    {item.description && (
                                        <div className="response-example-desc word-break">
                                            <Markdown
                                                options={{
                                                    overrides: {
                                                        pre: PreBlock,
                                                    },
                                                }}
                                            >
                                                {item.description}
                                            </Markdown>
                                        </div>
                                    )}
                                    {item?.schema && (
                                        <div className="mt-4">
                                            <JsonSchemaViewer
                                                models={models}
                                                schema={item?.schema}
                                                schemaType="response"
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </Collapse>
            </div>
        );
    } else {
        return <div className="mt-4">No responses added</div>;
    }
};

export default ResponseExampleView;
