import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Collapse } from 'react-collapse';

import { Input } from 'ui/Inputs';
import CustomSelect from 'ui/CustomSelect';
import { UserAvatar } from 'components/Avatar';
import {
    getPendingInvites,
    getProjectMembers,
    inviteMember,
    projectSelectors,
} from 'store/slices/projectsSlice';
import { memberSelectors } from 'store/slices/membersSlice';
import useOutsideClick from 'hooks/useOutsideClick';

const role = [
    { value: 'READ', label: 'Read' },
    { value: 'WRITE', label: 'Write' },
    { value: 'MAINTAINER', label: 'Maintainer' },
];

const InviteForm = () => {
    const dispatch = useDispatch();

    // const myAccount = useSelector(authSelectors.getMyAccount);
    const members = useSelector(memberSelectors.getMembers);
    const project = useSelector(projectSelectors.getCurrentProject);
    const [data, setData] = useState({
        email: '',
        role: 'READ',
    });
    const [errors, setErrors] = useState({});
    const [showDropdownMembers, setShowDropdownMembers] = useState(false);
    const dropdownRef = useRef();
    const filteredSearchMembers =
        !!members &&
        members.filter((member) => {
            const fullName = member.first_name + ' ' + member.last_name;
            const email = member.email || '';

            return (
                fullName.toLowerCase().includes(data.email.toLowerCase()) ||
                email.toLowerCase().includes(data.email.toLowerCase())
            );
        });

    useOutsideClick(dropdownRef, () => setShowDropdownMembers(false));
    const handleChange = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleChangeSelect = (option) => {
        setData({ ...data, role: option.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(inviteMember({ pid: project.uid, data, onSuccess, onError }));
        setData({ ...data, email: '' });
    };

    const onSelectMember = (member) => {
        if (member) {
            setData({ ...data, email: member.email });
        }
    };

    const onSuccess = (response) => {
        toast.success('The invitation has been sent');
        dispatch(getPendingInvites({ project: project.uid }));
        dispatch(getProjectMembers({ project: project.uid }));
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    return (
        <div>
            <form style={{ maxWidth: '500px' }} onSubmit={handleSubmit}>
                <div
                    className="form-group mb-4 position-relative"
                    onClick={() => setShowDropdownMembers(true)}
                >
                    <Input
                        type="text"
                        name="email"
                        className="form-control"
                        value={data?.email}
                        onChange={handleChange}
                        labelText="Email address"
                        labelClassName="invite-label mb-2"
                        placeHolder="Search for members or invite"
                        errorText={errors?.email}
                        isDisabled={project?.is_transfer_pending}
                        isRequired={true}
                        autoComplete={'off'}
                        inputRef={dropdownRef}
                    />
                    {/* {!isAccessToInviteUser && (
                        <p className="mt-2">
                            To invite more users please upgrade your plan
                        </p>
                    )} */}
                    {showDropdownMembers &&
                        data?.email &&
                        filteredSearchMembers?.length > 0 && (
                            <Collapse isOpened={true}>
                                <ul
                                    className="dropdown-list w-100 mt-1"
                                    style={{
                                        overflowY: 'scroll',
                                        maxHeight: '160px',
                                    }}
                                >
                                    {filteredSearchMembers?.map((member) => (
                                        <li
                                            key={member.uuid}
                                            className="d-flex"
                                            onClick={() =>
                                                setShowDropdownMembers(false)
                                            }
                                            style={{ height: '50px' }}
                                        >
                                            <button
                                                className="dropdown-link"
                                                onClick={() =>
                                                    onSelectMember(member)
                                                }
                                            >
                                                <UserAvatar
                                                    iconUrl={member.avatar_url}
                                                    first_name={
                                                        member.first_name
                                                    }
                                                    last_name={member.last_name}
                                                />
                                                {`${member.first_name} ${member.last_name}`}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </Collapse>
                        )}
                </div>

                <div className="form-group mb-4">
                    <label htmlFor="role" className="invite-label mb-2">
                        Role
                    </label>
                    <CustomSelect
                        name="role"
                        options={role}
                        onChange={handleChangeSelect}
                        defaultOption={data?.role}
                        value={data?.role}
                        isDisabled={project?.is_transfer_pending}
                    />
                </div>
                <button
                    type="submit"
                    className="btn btn-primary mt-3"
                    disabled={project?.is_transfer_pending}
                >
                    Invite
                </button>
            </form>
        </div>
    );
};

export default InviteForm;
