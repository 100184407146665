import { useEffect } from 'react';

const useDropdownPosition = (dropdownRef, isShowDropdown) => {
    useEffect(() => {
        if (dropdownRef?.current) {
            const positionDropdown =
                dropdownRef?.current?.getBoundingClientRect();
            const innerHeight = window.innerHeight;

            if (innerHeight - positionDropdown.bottom <= 0) {
                dropdownRef.current.className = 'dropdown-list-up';
            }
        }
    }, [dropdownRef, isShowDropdown]);
};

export default useDropdownPosition;
