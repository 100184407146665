export const headersValues = [
    'application/atom+xml',
    'application/ecmascript',
    'application/json',
    'application/vnd.api+json',
    'application/javascript',
    'application/octet-stream',
    'application/ogg',
    'application/pdf',
    'application/postscript',
    'application/rdf+xml',
    'application/rss+xml',
    'application/soap+xml',
    'application/font-woff',
    'application/x-yaml',
    'application/xhtml+xml',
    'application/xml',
    'application/xml-dtd',
    'application/xop+xml',
    'application/zip',
    'application/gzip',
    'application/graphql',
    'application/x-www-form-urlencoded',
    'audio/basic',
    'audio/L24',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/vorbis',
    'audio/vnd.rn-realaudio',
    'audio/vnd.wave',
    'audio/webm',
    'image/gif',
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'message/http',
    'message/imdn+xml',
    'message/partial',
    'message/rfc822',
    'multipart/mixed',
    'multipart/alternative',
    'multipart/related',
    'multipart/form-data',
    'multipart/signed',
    'multipart/encrypted',
    'text/plain',
    'text/vcard',
    'text/cmd',
    'text/css',
    'text/csv',
    'text/html',
    'text/xml',
];
