import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Markdown from 'markdown-to-jsx';
import { Edit3, Trash2, RotateCcw, AlertCircle, Play } from 'react-feather';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import LocalLoader from 'ui/LocalLoader';
import Modal from 'ui/Modal';
import {
    countRouteMethod,
    createShallowCopy,
    replaceEnvVariable,
} from 'utils/helpers';
import PreBlock from 'ui/PreBlock';
import shortLink from 'assets/img/short-link.svg';
import Comments from 'components/Comments';
import Tooltip from 'ui/Tooltip';
import {
    deleteRoute,
    fetchRoute,
    fetchRoutes,
    fetchRoutesTrash,
    permanentDeleteRoute,
    restoreRoute,
    routeSelectors,
} from 'store/slices/routesSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import { environmentSelectors } from 'store/slices/environmentsSlice';
import { getComments } from 'store/slices/commentsSlice';
import { getModels, resourceSelectors } from 'store/slices/resourcesSlice';
import RequestTab from './RequestTab';
import ResponsesTab from './ResponsesTab';
import { organizationSelectors } from 'store/slices/organizationSlice';
import { authSelectors } from 'store/slices/authSlice';
import EndpointRunner from '../EndpointRunner';

const RouteView = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const route = useSelector(routeSelectors.getCurrentRoute);
    const routeLoadingStatus = useSelector(
        routeSelectors.getRouteLoadingStatus,
    );
    const modelsLoading = useSelector(resourceSelectors.getIsResourcesFetching);
    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const selectedEnvironments = useSelector(
        environmentSelectors.getSelectedEnvironments,
    );
    const resources = useSelector(resourceSelectors.getResources);
    const routes = useSelector(routeSelectors.getRoutes);

    const [isDeleteRouteModalShown, setIsDeleteRouteModalShown] =
        useState(false);
    const [isRunModalShown, setIsRunModalShown] = useState(false);
    const [currentTab, setCurrentTab] = useState('request');

    const isAccessToCreateComment = !!currentOrg?.plan?.is_comments_enabled;
    const hasOwnerRights =
        (project.owner.id === myAccount.id && currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project.user_role === 'MAINTAINER' ||
        project.user_role === 'WRITE';
    const selectedEnvironment = selectedEnvironments.find(
        (el) => el.item.api_id === api.id,
    );

    const getRoute = useCallback(async () => {
        await dispatch(getModels({ apiId: api.id })).unwrap();
        const response = await dispatch(fetchRoute({ rid: id })).unwrap();
        if (!response.deleted_at && isAccessToCreateComment) {
            dispatch(
                getComments({
                    type: 'route',
                    typeId: id,
                    start: 0,
                }),
            );
        }
    }, [api.id, dispatch, id, isAccessToCreateComment]);

    useEffect(() => {
        if (id) {
            getRoute();
        }
        setCurrentTab('request');
    }, [getRoute, id]);

    const toggleDeleteRouteModal = () => {
        setIsDeleteRouteModalShown(!isDeleteRouteModalShown);
    };
    const toggleRunModal = () => {
        setIsRunModalShown((prev) => !prev);
    };

    const fetchRoutesAndTrashedRoutes = useCallback(() => {
        dispatch(
            fetchRoutes({
                project: project.uid,
                apiId: api.id,
            }),
        );
        dispatch(
            fetchRoutesTrash({
                project: project.uid,
                apiId: api.id,
            }),
        );
    }, [api.id, dispatch, project.uid]);

    const handleSuccessDeleteRoute = useCallback(() => {
        fetchRoutesAndTrashedRoutes();
        setIsDeleteRouteModalShown(false);
        history.push(
            `/project/api/routes?project=${project.uid}&api=${api.uid}`,
        );
    }, [api.uid, fetchRoutesAndTrashedRoutes, history, project.uid]);

    const handleDelete = useCallback(async () => {
        if (route.deleted_at) {
            await dispatch(
                permanentDeleteRoute({ routeId: route.id }),
            ).unwrap();
            toast.success('Route permanently deleted');
            handleSuccessDeleteRoute();
        } else {
            await dispatch(deleteRoute({ id: route.id })).unwrap();
            toast.success('Successfully deleted');
            handleSuccessDeleteRoute();
        }
    }, [dispatch, route.deleted_at, route.id, handleSuccessDeleteRoute]);

    const onSuccessCopyToClipboard = () => {
        toast.success('The short link of your route has been copied');
    };

    const handleRestoreRoute = useCallback(async () => {
        const foundDuplicatedRoute = routes.find(
            (el) => el.name === route.name && el.url === route.url,
        );

        if (foundDuplicatedRoute) {
            toast.error('You already have route with the same name and url', {
                autoClose: 5000,
            });
            return;
        }
        await dispatch(
            restoreRoute({
                routeId: route.id,
            }),
        ).unwrap();
        toast.success('Route successfully restored');
        fetchRoutesAndTrashedRoutes();
        history.push(
            `/project/api/routes?project=${project.uid}&api=${api.uid}`,
        );
    }, [
        api.uid,
        dispatch,
        fetchRoutesAndTrashedRoutes,
        history,
        project.uid,
        route.id,
        route.name,
        route.url,
        routes,
    ]);

    const selectTab = (e) => {
        setCurrentTab(e.target.dataset.tab);
    };

    const models = resources?.map((el) => JSON.parse(el.schema));
    const routeHeaders = [
        ...createShallowCopy(
            route?.global_headers?.filter((el) => el.apply_to_request) || [],
        ),
        ...createShallowCopy(route?.headers || []),
    ];

    const pageIsLoading = modelsLoading || routeLoadingStatus === 'loading';

    return (
        <>
            <Helmet>
                <title>{`${route.name || route.url} - ${api.name} - ${
                    project.name
                } - CDProjects`}</title>
            </Helmet>
            <LocalLoader loading={pageIsLoading} height="50vh">
                <div className="row">
                    <div className="col-10">
                        <div className="row">
                            <div className="col col-lg-11">
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex align-items-center">
                                        <h3 className="title-h4 word-break fw-bold">
                                            <div>
                                                {route.name ||
                                                    replaceEnvVariable(
                                                        route.url,
                                                        selectedEnvironment
                                                            ?.item?.variables,
                                                    )}
                                            </div>
                                        </h3>
                                        <CopyToClipboard
                                            text={`${window.location.origin}/r/${project?.code}/${api?.code}/${route.id}`}
                                            className="px-2 cursor-pointer block"
                                            onCopy={onSuccessCopyToClipboard}
                                        >
                                            <img
                                                src={shortLink}
                                                alt="copy link"
                                            />
                                        </CopyToClipboard>
                                    </div>

                                    <div className="d-flex align-items-center">
                                        <div className="d-flex align-items-center me-3">
                                            <Tooltip
                                                id="internalAlert"
                                                content="When a page is set as internal, it is no longer visible in public documentation,
                                                     but can still be accessed and seen by members within the workspace."
                                            >
                                                <AlertCircle
                                                    color="gray"
                                                    size={15}
                                                />
                                            </Tooltip>
                                            <p
                                                style={{
                                                    margin: '0 8px 0 4px',
                                                }}
                                            >
                                                {!!route.is_internal
                                                    ? 'Internal'
                                                    : 'Public'}
                                            </p>
                                        </div>
                                        {hasRights && !route.deleted_at && (
                                            <>
                                                <button
                                                    onClick={() =>
                                                        history.push(
                                                            `/project/api/routes/${route.id}/edit?project=${project.uid}&api=${api.uid}`,
                                                        )
                                                    }
                                                    className="btn-action me-2 edit  hover-default"
                                                >
                                                    <Edit3
                                                        size={13}
                                                        color="#001A37"
                                                    />
                                                </button>
                                                <button
                                                    onClick={
                                                        toggleDeleteRouteModal
                                                    }
                                                    className="btn-action remove hover-remove"
                                                >
                                                    <Trash2
                                                        size={13}
                                                        color="#FC4D70"
                                                    />
                                                </button>
                                            </>
                                        )}
                                        {hasRights && route.deleted_at && (
                                            <>
                                                <button
                                                    onClick={handleRestoreRoute}
                                                    className="btn-action me-2 edit  hover-default"
                                                >
                                                    <RotateCcw
                                                        size={13}
                                                        color="#001A37"
                                                    />
                                                </button>
                                                <button
                                                    onClick={
                                                        toggleDeleteRouteModal
                                                    }
                                                    className="btn-action remove hover-remove"
                                                >
                                                    <Trash2
                                                        size={13}
                                                        color="#FC4D70"
                                                    />
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button
                                        onClick={toggleRunModal}
                                        className="btn btn-light d-flex align-items-center"
                                    >
                                        <Play
                                            size={16}
                                            className="me-2"
                                            color="green"
                                        />
                                        Run
                                    </button>
                                </div>
                                {route.deleted_at && (
                                    <p className="text-semi-small text-danger mb-3">
                                        {`This route was deleted on ${moment(
                                            route.deleted_at,
                                        ).format('DD/MM/YYYY')} at ${moment(
                                            route.deleted_at,
                                        ).format('HH:mm')}`}
                                    </p>
                                )}

                                {route.description && (
                                    <div className="mb-3 mb-4">
                                        <div>
                                            <Markdown
                                                options={{
                                                    overrides: {
                                                        pre: PreBlock,
                                                    },
                                                }}
                                                className="word-break"
                                            >
                                                {route.description}
                                            </Markdown>
                                        </div>
                                    </div>
                                )}

                                <div className="mb-3">
                                    <span className="anchor" id="request" />
                                    <h5 className="mb-2 title-h5">
                                        HTTP Request
                                    </h5>
                                    <div className="badge-route word-break">
                                        {countRouteMethod(route.method, {
                                            fontSize: 14,
                                            lineHeight: '16px',
                                            padding: '4px 8px',
                                            backgroundColor: '#F6F7F9',
                                            borderRadius: 4,
                                            marginRight: 10,
                                            height: 'auto',
                                            minWidth: 'auto',
                                        })}
                                        {replaceEnvVariable(
                                            route.url,
                                            selectedEnvironment?.item
                                                ?.variables,
                                        )}
                                    </div>
                                </div>
                                <ul className="route-tabs-list">
                                    <li
                                        data-tab="request"
                                        className={
                                            currentTab === 'request'
                                                ? 'route-tabs-list-item--selected'
                                                : 'route-tabs-list-item'
                                        }
                                        onClick={selectTab}
                                    >
                                        Request
                                    </li>
                                    <li
                                        data-tab="response"
                                        className={
                                            currentTab === 'response'
                                                ? 'route-tabs-list-item--selected'
                                                : 'route-tabs-list-item'
                                        }
                                        onClick={selectTab}
                                    >
                                        Responses
                                    </li>
                                </ul>
                                {currentTab === 'request' && (
                                    <RequestTab
                                        route={route}
                                        models={models}
                                        routeHeaders={routeHeaders}
                                        variables={
                                            selectedEnvironment?.item?.variables
                                        }
                                    />
                                )}
                                {currentTab === 'response' && (
                                    <ResponsesTab route={route} />
                                )}
                                {!route.deleted_at && <Comments type="route" />}
                            </div>
                        </div>
                    </div>

                    <Modal
                        show={isDeleteRouteModalShown}
                        title="Delete"
                        body={`This route will be ${
                            route.deleted_at ? 'permanently ' : ''
                        }deleted, are you sure?`}
                        footer={
                            <Fragment>
                                <button
                                    type="submit"
                                    className="btn btn-danger"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </button>
                                <button
                                    type="button"
                                    onClick={toggleDeleteRouteModal}
                                    className="btn btn-link"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                            </Fragment>
                        }
                    />
                    <Modal
                        show={isRunModalShown}
                        customClasses={{
                            modalDialog:
                                'modal-xl modal-dialog-scrollable modal-dialog--max-height-550',
                        }}
                        title="Send request to your server"
                        body={<EndpointRunner onClose={toggleRunModal} />}
                    />
                </div>
            </LocalLoader>
        </>
    );
};

export default RouteView;
