import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocalLoader from 'ui/LocalLoader';
import { apiSelectors } from 'store/slices/apiSlice';
import {
    getTrashedModels,
    getTrashedResponses,
    resourceSelectors,
} from 'store/slices/resourcesSlice';
import ModelsTrash from './Models/ModelsTrash';
import ResponsesTrash from './Responses/ResponsesTrash';
import { FolderClose, FolderOpen } from 'assets/img/Svg';

const ResourcesTrashList = ({
    inputSearch,
    resourcesTrashList,
    responsesTrashList,
}) => {
    const dispatch = useDispatch();

    const api = useSelector(apiSelectors.getCurrentApi);
    const isResourcesTrashFetching = useSelector(
        resourceSelectors.getIsResourcesTrashFetching,
    );

    const isResponsesTrashFetching = useSelector(
        resourceSelectors.getIsResponsesTrashFetching,
    );

    const [isShowModels, setIsShowModels] = useState(false);
    const [isShowResponses, setIsShowResponses] = useState(false);

    useEffect(() => {
        dispatch(getTrashedModels({ apiId: api.id }));
        dispatch(getTrashedResponses({ apiId: api.id }));
    }, [dispatch, api]);

    useEffect(() => {
        if (resourcesTrashList?.length) setIsShowModels(true);
        if (responsesTrashList?.length) setIsShowResponses(true);
    }, [resourcesTrashList?.length, responsesTrashList?.length]);

    const toggleModelsFolder = () => {
        setIsShowModels((prev) => !prev);
    };

    const toggleResponsesFolder = () => {
        setIsShowResponses((prev) => !prev);
    };
    const isDataFetching = isResourcesTrashFetching || isResponsesTrashFetching;

    return (
        <LocalLoader loading={isDataFetching} className="mt-5">
            <ul className="nav flex-column">
                <li className="nav-item sidebar-link-item mx-2">
                    <div
                        onClick={toggleModelsFolder}
                        className={
                            'nav-link sidebar-link sidebar-link-route wrod-break'
                        }
                    >
                        <div style={{ marginRight: '4px' }}>
                            {isShowModels ? (
                                <FolderOpen style={{ stroke: '#6e2fff' }} />
                            ) : (
                                <FolderClose style={{ stroke: '#6e2fff' }} />
                            )}
                        </div>
                        <span className="me-1">Models</span>
                    </div>
                </li>
                <ModelsTrash
                    resourcesTrashList={resourcesTrashList}
                    isShowModels={isShowModels}
                    inputSearch={inputSearch}
                />
            </ul>
            <ul className="nav flex-column">
                <li className="nav-item sidebar-link-item mx-2">
                    <div
                        onClick={toggleResponsesFolder}
                        className={
                            'nav-link sidebar-link sidebar-link-route wrod-break'
                        }
                    >
                        <div style={{ marginRight: '4px' }}>
                            {isShowResponses ? (
                                <FolderOpen style={{ stroke: '#00c26e' }} />
                            ) : (
                                <FolderClose style={{ stroke: '#00c26e' }} />
                            )}
                        </div>
                        <span className="me-1">Responses</span>
                    </div>
                </li>
                <ResponsesTrash
                    responsesTrashList={responsesTrashList}
                    isShowModels={isShowResponses}
                    inputSearch={inputSearch}
                />
            </ul>
        </LocalLoader>
    );
};

export default ResourcesTrashList;
