import React from 'react';

import RouteHeadersItem from './RouteHeadersItem';
import { uniqueId } from 'utils/helpers';
import RouteGlobalHeadersItem from './RouteGlobalHeadersItem';

const RouteHeaders = (props) => {
    if (props.items?.length > 0) {
        return (
            <div className="pb-3">
                <div className="pb-1">
                    {props.headers.map((item, index) => (
                        <div key={index} className="px-2 d-flex">
                            <label className="pt-1 nowrap cursor-pointer">
                                <input
                                    className="me-1 align-middle cursor-pointer"
                                    type="checkbox"
                                    id={`${uniqueId()}`}
                                    onChange={(e) =>
                                        props.handleRecentHeaders(
                                            e,
                                            item,
                                            props.index,
                                        )
                                    }
                                    checked={props.items.some(
                                        (h) => h.id === item.id,
                                    )}
                                />
                                <span className="align-middle">
                                    {item.name}: {item.value}
                                </span>
                            </label>
                        </div>
                    ))}
                </div>
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th className="title-table">Name</th>
                            <th className="title-table">Value</th>
                            <th className="title-table">Description</th>
                            <th className="title-table">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props?.globalHeaders.map((item, index) => (
                            <RouteGlobalHeadersItem key={index} item={item} />
                        ))}
                        {props.items.map((item, index) => (
                            <RouteHeadersItem
                                key={index}
                                name={item.name}
                                value={item.value}
                                description={item.description}
                                index={index}
                                errors={props.errors}
                                handleChange={(e) =>
                                    props.changeParam(e, index, item)
                                }
                                deleteRow={() => props.deleteRow(index, item)}
                                isResponsesHeaders={props?.isResponsesHeaders}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        );
    } else {
        return <div></div>;
    }
};
export default RouteHeaders;
