import React from 'react';
import { AlertTriangle } from 'react-feather';
import { Helmet } from 'react-helmet';

export default function AppErrorFallback() {
    const onRefreshPage = () => {
        window.location.reload();
    };

    const onReturnToLastPage = () => {
        window.history.back();

        setTimeout(() => {
            window.location.reload();
        }, 200);
    };

    return (
        <>
            <Helmet>
                <title>Error</title>
            </Helmet>
            <div className="AppErrorFallback__container">
                <AlertTriangle color="orange" size={50} />
                <h1 className="AppErrorFallback__title">
                    Something went wrong.
                </h1>
                <p className="AppErrorFallback__text">
                    While we check things on our end, try to&nbsp;
                    <span
                        className="AppErrorFallback__refresh-link"
                        onClick={onRefreshPage}
                    >
                        refresh the page.
                    </span>
                </p>
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={onReturnToLastPage}
                >
                    Return to last page
                </button>
            </div>
        </>
    );
}
