import React from 'react';

const ErrorText = ({ text }) => {
    if (text) {
        return (
            <div className="invalid-feedback" style={{ display: 'block' }}>
                {text}
            </div>
        );
    }
    return null;
};

export default ErrorText;
