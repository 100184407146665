import { combineReducers } from 'redux';
import apiSlice from './apiSlice';
import appSlice from './appSlice';
import authSlice from './authSlice';
import commentsSlice from './commentsSlice';
import documentationSlice from './documentationSlice';
import environmentsSlice from './environmentsSlice';
import historySlice from './historySlice';
import membersSlice from './membersSlice';
import pagesSLice from './pagesSlice';
import projectsSlice from './projectsSlice';
import resourcesSlice from './resourcesSlice';
import routesSlice from './routesSlice';
import billingSlice from './billingSlice';
import organizationSlice from './organizationSlice';

export const rootReducer = combineReducers({
    api: apiSlice,
    app: appSlice,
    auth: authSlice,
    comments: commentsSlice,
    documentation: documentationSlice,
    environments: environmentsSlice,
    history: historySlice,
    members: membersSlice,
    pages: pagesSLice,
    projects: projectsSlice,
    resources: resourcesSlice,
    routes: routesSlice,
    billing: billingSlice,
    organization: organizationSlice,
});
