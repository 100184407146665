import React, { useEffect, useState, Fragment } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { RotateCw, Trash } from 'react-feather';
import LocalLoader from 'ui/LocalLoader';
import { formatDate } from 'utils/helpers';
import Modal from 'ui/Modal';
import {
    getInvitations,
    organizationSelectors,
    deleteInvite,
    resendInvite,
} from 'store/slices/organizationSlice';

const PendingInvites = () => {
    const dispatch = useDispatch();

    const pendingInvitesStatus = useSelector(
        organizationSelectors.getPendingInvitesLoadingStatus,
    );
    const pendingInvites = useSelector(organizationSelectors.getPendingInvites);

    const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
    const [selectedInvite, setSelectedInvite] = useState(null);

    useEffect(() => {
        dispatch(getInvitations());
    }, [dispatch]);

    const onDeleteModalShown = (flag, invite) => {
        setIsDeleteModalShown(flag);
        setSelectedInvite(invite);
    };

    const onDelete = () => {
        onDeleteModalShown(false);
        dispatch(
            deleteInvite({
                inviteId: selectedInvite.id,
                onSuccess: onSuccessDelete,
                onError: onErrorDelete,
            }),
        );
    };

    const onSuccessDelete = () => {
        toast.success('The invitation has been deleted');
        dispatch(getInvitations());
    };

    const onErrorDelete = (error) => {
        toast.error(error.message);
    };

    const resendMemberInvite = (inviteId) => {
        dispatch(
            resendInvite({
                inviteId,
                onSuccess: onSuccessResend,
                onError: onErrorResend,
            }),
        );
    };

    const onSuccessResend = () => {
        toast.success('The invitation has been sent');
    };

    const onErrorResend = (error) => {
        toast.error(error.message);
    };

    return (
        <div className="mt-5">
            <h4 className="text-big mb-4">Pending invites</h4>
            <LocalLoader loading={pendingInvitesStatus === 'loading'}>
                <table className="table item-table mt-3">
                    <thead className="item-table-head">
                        <tr>
                            <th className="item-table-title">Email</th>
                            <th className="item-table-title">Project</th>
                            <th className="item-table-title">Project role</th>
                            <th className="item-table-title">
                                Organization role
                            </th>
                            <th className="item-table-title">Sent at</th>
                            <th className="item-table-title">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pendingInvites?.map((invite) => (
                            <tr key={invite.id} className="table-main-row">
                                <td>{invite.email}</td>
                                <td>{invite.project}</td>
                                <td>{invite.project_role}</td>
                                <td>{invite.organization_role}</td>
                                <td>{formatDate(invite.created_at)}</td>
                                <td className="text-end">
                                    {}
                                    <>
                                        <button
                                            className="btn btn-outline-dark btn-sm me-2"
                                            value={invite.id}
                                            onClick={() =>
                                                resendMemberInvite(invite.id)
                                            }
                                        >
                                            <RotateCw size={15} />
                                        </button>
                                        <button
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={() =>
                                                onDeleteModalShown(true, invite)
                                            }
                                        >
                                            <Trash size={15} />
                                        </button>
                                    </>
                                </td>
                            </tr>
                        ))}
                        {!pendingInvites?.length && (
                            <tr>
                                <td colSpan="5" className="text-center">
                                    No invites sent
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </LocalLoader>

            <Modal
                show={isDeleteModalShown}
                title="Delete invitation"
                body="Are you sure you want to delete the invitation?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onDelete}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={() => setIsDeleteModalShown(false)}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
        </div>
    );
};

export default PendingInvites;
