import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { Input } from 'ui/Inputs';
import LocalLoader from 'ui/LocalLoader';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import {
    createModel,
    getModel,
    getModels,
    resourceSelectors,
    updateModel,
} from 'store/slices/resourcesSlice';
import JsonSchemaEditor from 'components/JsonSchemaEditor';
import ExpandableTextEditor from 'components/ExpandableTextEditor';

const ModelsForm = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const resources = useSelector(resourceSelectors.getResources);
    const resourcesLoadingStatus = useSelector(
        resourceSelectors.getResourcesLoadingStatus,
    );
    const isResourcesFetching = useSelector(
        resourceSelectors.getIsResourcesFetching,
    );
    const isResourceFetching = useSelector(
        resourceSelectors.getIsResourceFetching,
    );
    const [data, setData] = useState({
        name: '',
        description: '',
        schema: '',
    });
    const [action, setAction] = useState('create');
    const [errors, setErrors] = useState({});

    useLayoutEffect(() => {
        const duplicateModelAction = location.state?.action === 'duplicate';

        if (id && resourcesLoadingStatus === 'succeeded') {
            setAction('update');
            dispatch(
                getModel({
                    resourceId: id,
                    onSuccess: onResourceFetchSuccess,
                }),
            );
        }
        if (duplicateModelAction) {
            setData({
                name: `${location.state.name} Copy` || '',
                description: location.state.description || '',
                schema: location.state.schema || '',
            });
        }
    }, [dispatch, id, location.state, resourcesLoadingStatus]);

    const onResourceFetchSuccess = (response) => {
        setData({
            name: response.data.data.name || '',
            description: response.data.data.description || '',
            schema: response.data.data.schema || '',
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const request = { ...data };

        if (action === 'create') {
            dispatch(
                createModel({
                    body: request,
                    apiId: api.id,
                    onSuccess: onSuccessCreate,
                    onError: onErrorCreate,
                }),
            );
        } else {
            dispatch(
                updateModel({
                    body: request,
                    resourceId: id,
                    onSuccess: onSuccessResourceUpdate,
                    onError: onErrorResourceUpdate,
                }),
            );
        }
    };

    const handleEditorChange = (editorData) => {
        const newData = { ...data };
        newData.description = editorData;

        setData({ ...newData });
    };

    const onSuccessCreate = (response) => {
        toast.success('The model has been created');
        dispatch(getModels({ projectUid: project.uid, apiId: api.id }));
        history.push(
            `/project/api/components/models/browse/${response.data.data.id}?project=${project.uid}&api=${api.uid}`,
        );
    };

    const onErrorCreate = (error) => {
        setErrors(error.response.data.errors);
    };

    const onSuccessResourceUpdate = (response) => {
        toast.success('The model has been updated');
        dispatch(getModels({ projectUid: project.uid, apiId: api.id }));
        history.push(
            `/project/api/components/models/browse/${response.data.data.id}?project=${project.uid}&api=${api.uid}`,
        );
    };

    const onErrorResourceUpdate = (error) => {
        setErrors(error.response.data.errors);
    };

    const getSchema = (schema) => {
        setData({ ...data, schema });
    };

    const models = resources?.map((el) => JSON.parse(el.schema));
    const isDataFetching = isResourceFetching || isResourcesFetching;

    return (
        <div className="row">
            <div className="col col-md-10 col-lg-10">
                <LocalLoader loading={isDataFetching} height="50vh">
                    <h4 className="title-h4 mb-4" id="routeHead">
                        {action === 'create'
                            ? 'Create a new model'
                            : 'Edit model'}
                    </h4>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="form-group mb-4">
                                <Input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    value={data?.name}
                                    onChange={handleChange}
                                    labelText="Model name"
                                    labelClassName="form-label"
                                    placeHolder="Enter model name"
                                    errorText={errors?.name}
                                    isRequired={true}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <ExpandableTextEditor
                                    label="Description"
                                    description={data?.description}
                                    handleEditorChange={handleEditorChange}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <JsonSchemaEditor
                                    schema={data?.schema}
                                    models={models}
                                    modelName={data?.name}
                                    getSchema={getSchema}
                                    schemaType="model"
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                {action === 'create' ? 'Create' : 'Update'}
                            </button>

                            <Link
                                to={
                                    action === 'create'
                                        ? `/project/api/components?project=${project.uid}&api=${api.uid}`
                                        : `/project/api/components/models/browse/${params?.id}?project=${project.uid}&api=${api.uid}`
                                }
                                type="button"
                                className="btn btn-link-main text-link-normal m-1"
                            >
                                Cancel
                            </Link>
                        </div>
                    </form>
                </LocalLoader>
            </div>
        </div>
    );
};

export default ModelsForm;
