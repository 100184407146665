import React from 'react';
import ResponseExampleView from './ResponseExampleView';

export default function ResponsesTab({ route }) {
    return (
        <div className="mt-4">
            <ResponseExampleView items={route.responses} />
        </div>
    );
}
