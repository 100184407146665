import React, { useEffect, Fragment, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AlertTriangle, Trash2, Upload, Edit3, XCircle } from 'react-feather';
import { toast } from 'react-toastify';

import PageHeader from 'ui/PageHeader';
import { Input } from 'ui/Inputs';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'ui/Modal';
import { ProjectAvatar } from 'components/Avatar';
import ImageCrop from 'components/ImageCrop';
import { authSelectors } from 'store/slices/authSlice';
import {
    cancelTransferProject,
    deleteProjectLogo,
    fetchProject,
    projectSelectors,
    transferProject,
    trashProject,
    updateProject,
} from 'store/slices/projectsSlice';
import { organizationSelectors } from 'store/slices/organizationSlice';

const REGEXP_ACCEPT_IMAGE = /(image\/gif|image\/jpe?g|image\/png)/i;

const SummaryPage = () => {
    const dispatch = useDispatch();
    const myAccount = useSelector(authSelectors.getMyAccount);
    const project = useSelector(projectSelectors.getCurrentProject);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const [data, setData] = useState({
        name: '',
        code: '',
        description: '',
    });
    const [domain, setDomain] = useState('');
    const [imageProperties, setImageProperties] = useState({
        sourceImage: '',
        logoUrl: '',
        avatarUrl: '',
        loadedImage: '',
        croppedImage: {},
    });

    const [errors, setErrors] = useState({});
    const [modalShown, setModalShown] = useState({
        isTransferModalShown: false,
        isTrashModalShown: false,
        isCancelTransferModalShown: false,
        isDeleteLogoModalShown: false,
    });
    const [file, setFile] = useState({
        isFileLoading: false,
        isWrongFileType: false,
    });
    const uploadLogoButtonRef = useRef();

    const hasOwnerRights =
        (project?.owner?.id === myAccount?.id &&
            currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights = hasOwnerRights || currentOrg?.role === 'ADMIN';

    useEffect(() => {
        setData({
            name: project?.name,
            code: project?.code,
            description: project?.description ?? '',
        });
        setImageProperties((prevState) => ({
            ...prevState,
            logoUrl: project?.logo_thumb_url,
            avatarUrl: project?.avatar_url,
        }));
    }, [
        project?.avatar_url,
        project?.code,
        project?.description,
        project?.logo_thumb_url,
        project?.name,
    ]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const dataForm = formData();
        const headers = imageProperties.loadedImage
            ? { 'Content-Type': 'multipart/form-data' }
            : { 'Content-Type': 'aplication/json' };
        const newData = imageProperties.loadedImage ? dataForm : data;

        dispatch(
            updateProject({
                pid: project?.uid,
                data: newData,
                onSuccess,
                onError,
                headers,
            }),
        );
    };

    const onSuccess = (response) => {
        const queryParams = new URLSearchParams(window.location.search);
        const projectUid = queryParams.get('project');
        const data = response.data.data;
        const logo = response.data.data.logo_thumb_url;

        toast.success('Your data has been saved.');

        if (projectUid !== data?.uid || project?.logo_thumb_url !== logo) {
            setTimeout(
                () =>
                    window.location.replace(
                        `/project/settings/summary?project=${data?.uid}`,
                    ),
                700,
            );
        }
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    const toggleTransferModal = () => {
        setModalShown({
            ...modalShown,
            isTransferModalShown: !modalShown.isTransferModalShown,
        });
        setDomain('');
    };

    const onTransfer = () => {
        dispatch(
            transferProject({
                uid: project?.uid,
                data: { domain },
                onSuccess: onSuccessTransfer,
                onError,
            }),
        );
    };

    const onSuccessTransfer = (response) => {
        toast.success('The transfer process has been initiated');
        toggleTransferModal();
        setTimeout(() => {
            window.location.href = '/projects';
        }, 700);
    };

    const toggleCancelTransferModal = () => {
        setModalShown({
            ...modalShown,
            isCancelTransferModalShown: !modalShown.isCancelTransferModalShown,
        });
    };

    const onCancelTransfer = () => {
        dispatch(
            cancelTransferProject({
                uid: project?.uid,
                onSuccess: onSuccessCancelTransfer,
                onError: onErrorNotification,
            }),
        );
    };

    const onSuccessCancelTransfer = () => {
        toggleCancelTransferModal();
        toast.success('The project’s transfer has been canceled');
        setTimeout(() => {
            window.location.href = `/project/settings/summary?project=${project?.uid}`;
        }, 700);
    };

    const onErrorNotification = () => {
        toast.error('Something went wrong, please try again later.');
    };

    const toggleTrashModal = () => {
        setModalShown({
            ...modalShown,
            isTrashModalShown: !modalShown.isTrashModalShown,
        });
    };

    const onTrash = () => {
        toggleTrashModal();
        dispatch(
            trashProject({
                uid: project?.uid,
                onSuccess: onSuccessTrash,
                onError: onErrorNotification,
            }),
        );
    };

    const onSuccessTrash = (response) => {
        toast.success('The project has been moved to trash');
        window.location.href = '/projects';
    };

    const onFileSelect = (e) => {
        if (e.target.files && e.target.files[0]) {
            if (!REGEXP_ACCEPT_IMAGE.test(e.target.files[0].type)) {
                setFile({
                    ...file,
                    isWrongFileType: true,
                });
                return;
            }

            const targetFile = e.target.files[0];
            const reader = new FileReader();

            reader.onloadstart = () =>
                setFile({
                    isFileLoading: true,
                    isWrongFileType: false,
                });
            reader.onloadend = () => setFile({ ...file, isFileLoading: false });
            reader.onload = (e) => {
                setImageProperties({
                    ...imageProperties,
                    sourceImage: e.target.result,
                    loadedImage: targetFile,
                });

                setFile({
                    ...file,
                    isWrongFileType: false,
                });
            };

            reader.readAsDataURL(e.target.files[0]);
        }
        uploadLogoButtonRef.current.value = '';
    };

    const onSaveAvatar = (croppedImage) => {
        const imageUrl = window.URL.createObjectURL(croppedImage);

        setImageProperties({
            ...imageProperties,
            sourceImage: '',
            logoUrl: imageUrl,
            croppedImage,
        });
    };

    const onCancelAvatarSelection = () => {
        setImageProperties({
            ...imageProperties,
            sourceImage: '',
            loadedImage: '',
        });
    };

    const formData = () => {
        const fd = new FormData();

        fd.append('logo_thumb', imageProperties.croppedImage);
        // fd.append('logo', imageProperties.loadedImage);
        fd.append('name', data.name);
        fd.append('code', data.code);
        fd.append('description', data.description);

        return fd;
    };

    const toggleDeleteLogoModal = () => {
        setModalShown({
            ...modalShown,
            isDeleteLogoModalShown: !modalShown.isDeleteLogoModalShown,
        });
    };

    const onDeleteLogo = () => {
        dispatch(
            deleteProjectLogo({
                pid: project?.id,
                onSuccess: onSuccessDeleteLogo,
                onError: onErrorDeleteLogo,
            }),
        );
    };

    const onSuccessDeleteLogo = () => {
        toast.success('Logo has been deleted.');
        toggleDeleteLogoModal();
        setImageProperties({ ...imageProperties, logoUrl: '' });
        dispatch(fetchProject({ pid: project?.uid }));
    };

    const onErrorDeleteLogo = (error) => {
        toast.error('Something went wrong, please try again later.');
    };

    return (
        <div className="container-fluid p-5">
            <Helmet>
                <title>
                    Summary - CDProjects - Create beautiful REST API
                    Documentations
                </title>
            </Helmet>
            <div className="row">
                <div className="col">
                    <div className="d-flex justify-content-between mb-4">
                        <PageHeader margin="mb-0">Summary</PageHeader>
                        {hasRights && (
                            <div className="btn-group">
                                <button
                                    type="button"
                                    className="btn btn-primary dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Manage project
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <button
                                            className="dropdown-item text-wrap"
                                            onClick={() =>
                                                project?.is_transfer_pending
                                                    ? toggleCancelTransferModal()
                                                    : toggleTransferModal()
                                            }
                                        >
                                            {project?.is_transfer_pending
                                                ? 'Cancel transfer'
                                                : 'Transfer project'}
                                        </button>
                                    </li>
                                    {!project?.is_transfer_pending && (
                                        <li>
                                            <button
                                                className="dropdown-item text-wrap"
                                                onClick={toggleTrashModal}
                                            >
                                                Delete project
                                            </button>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                    <p className="pb-4">
                        Here you can change the project's related data.
                    </p>
                    <form style={{ maxWidth: '500px' }}>
                        {hasRights && (
                            <div className="form-group mb-4">
                                <span className="d-block mb-2">Logo</span>
                                <div className="d-flex align-items-center">
                                    <img
                                        src={
                                            imageProperties.logoUrl ||
                                            `${
                                                imageProperties.avatarUrl
                                            }?${performance.now()}`
                                        }
                                        alt="logo"
                                        className="border-radius-8"
                                        width="80px"
                                        height="80px"
                                    />

                                    <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        ref={uploadLogoButtonRef}
                                        className="input-file"
                                        accept="image/gif, image/jpeg, image/png"
                                        onChange={onFileSelect}
                                    />
                                    <div className="ms-4">
                                        {!imageProperties.logoUrl ? (
                                            <label
                                                className="d-flex align-items-center cursor-pointer"
                                                htmlFor="file"
                                            >
                                                <Upload
                                                    color="#0d6efd"
                                                    size={18}
                                                />
                                                <span className="fw-bold ms-2 text-primary">
                                                    Upload logo
                                                </span>
                                            </label>
                                        ) : (
                                            <>
                                                <label
                                                    className="d-flex align-items-center me-3 mb-4 cursor-pointer"
                                                    htmlFor="file"
                                                >
                                                    <Edit3
                                                        color="#0d6efd"
                                                        size={18}
                                                    />
                                                    <span className="fw-bold ms-2 text-primary">
                                                        Change logo
                                                    </span>
                                                </label>
                                                <button
                                                    type="button"
                                                    className="text-danger fw-bold d-flex align-items-center bg-white"
                                                    onClick={
                                                        toggleDeleteLogoModal
                                                    }
                                                >
                                                    <Trash2
                                                        color="#dc3545"
                                                        size={18}
                                                    />
                                                    <span className="d-inline-block ms-2">
                                                        Delete
                                                    </span>
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {file?.isWrongFileType && (
                                    <span className="text-danger d-block mt-3">
                                        Wrong file format. Supported formats:
                                        jpeg, jpg, png, gif.
                                    </span>
                                )}
                                {errors?.logo_thumb && (
                                    <span className="text-danger d-block mt-3">
                                        {errors?.logo_thumb[0]}
                                    </span>
                                )}
                            </div>
                        )}
                        <div className="form-group mb-4">
                            <Input
                                type="text"
                                name="name"
                                className="form-control"
                                value={data?.name}
                                id="name"
                                onChange={handleChange}
                                labelText="Name"
                                labelClassName="form-label"
                                placeHolder="Enter project name"
                                errorText={errors?.name}
                                isDisabled={
                                    !hasRights || project?.is_transfer_pending
                                }
                                isRequired={true}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <Input
                                type="text"
                                name="code"
                                className="form-control"
                                value={data?.code}
                                id="code"
                                onChange={handleChange}
                                labelText="Code"
                                labelClassName="form-label"
                                placeHolder="Enter project code"
                                errorText={errors?.code}
                                isDisabled={
                                    !hasRights || project?.is_transfer_pending
                                }
                                isRequired={true}
                            />
                        </div>
                        <div className="form-group mb-4">
                            <Input
                                type="textarea"
                                value={data?.description}
                                onChange={handleChange}
                                name="description"
                                className="form-control"
                                labelText="Description"
                                labelClassName="form-label"
                                id="description"
                                placeHolder="Enter project description"
                                rows="7"
                                isDisabled={
                                    !hasRights || project?.is_transfer_pending
                                }
                                errorText={errors?.description}
                            />
                        </div>
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            className="btn btn-primary"
                            disabled={
                                !hasRights || project?.is_transfer_pending
                            }
                        >
                            Save
                        </button>
                    </form>
                </div>
            </div>
            <Modal
                show={modalShown.isTransferModalShown}
                title={
                    <div className="d-flex align-items-center">
                        <AlertTriangle
                            size={20}
                            className="me-2 text-warning"
                            strokeWidth={2}
                        />
                        <span>Transfer project</span>
                    </div>
                }
                body={
                    <div>
                        <p className="fw-bold text-black-50 mb-2">
                            Project to transfer
                        </p>
                        <div className="d-flex align-items-center">
                            <ProjectAvatar project={project} />
                            <strong>{project ? project?.name : ''}</strong>
                        </div>
                        <Input
                            type="text"
                            name="email"
                            className="form-control"
                            value={domain}
                            onChange={(e) => setDomain(e.target.value)}
                            labelText="New organization (domain)"
                            labelClassName="form-label fw-bold text-black-50 mt-4"
                            errorText={errors?.domain}
                        />
                        <p className="fw-bold mt-5">
                            Once accepted, new owners can administer project
                            access and content.
                        </p>
                    </div>
                }
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            disabled={!domain}
                            onClick={onTransfer}
                        >
                            Transfer
                        </button>
                        <button
                            type="button"
                            onClick={toggleTransferModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
            <Modal
                show={modalShown.isTrashModalShown}
                title="Move to trash"
                body="This Project will be moved to trash, are you sure ?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onTrash}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={toggleTrashModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
            <Modal
                show={modalShown.isCancelTransferModalShown}
                title="Cancel transfer"
                body="This Project's transfer will be canceled, are you sure ?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onCancelTransfer}
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={toggleCancelTransferModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
            <Modal
                show={!!imageProperties.sourceImage}
                body={
                    <div className="p-3">
                        <ImageCrop
                            sourceImage={imageProperties.sourceImage}
                            saveAvatar={onSaveAvatar}
                            cancelAvatarSelection={onCancelAvatarSelection}
                        />
                    </div>
                }
            />
            <Modal
                show={modalShown.isDeleteLogoModalShown}
                title={
                    <div className="d-flex align-items-center">
                        <XCircle color="#dc3545" />
                        <span className="ms-3">Delete</span>
                    </div>
                }
                body="Are you sure you want to delete this logo ?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onDeleteLogo}
                            disabled={project?.is_transfer_pending}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={toggleDeleteLogoModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
        </div>
    );
};

export default SummaryPage;
