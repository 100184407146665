import React from 'react';
import { Edit3, Trash2 } from 'react-feather';

export default function GlobalHeadersItem({
    header,
    openEditForm,
    openDeleteHeaderModal,
}) {
    const headerTags = {
        request: (
            <span
                className="me-2"
                style={{
                    backgroundColor: '#F2F8FF',
                    fontSize: '12px',
                    borderRadius: '4px',
                    padding: '5px 6px',
                }}
            >
                REQUEST
            </span>
        ),
        response: (
            <span
                className=""
                style={{
                    backgroundColor: '#EDEFF3',
                    fontSize: '12px',
                    borderRadius: '4px',
                    padding: '5px 6px',
                }}
            >
                RESPONSE
            </span>
        ),
    };

    return (
        <li className="p-1 d-flex align-items-center justify-content-between">
            <div className="d-flex" style={{ fontSize: '14px' }}>
                <div>
                    <span>{header?.name}</span>
                    <span className="px-1">:</span>
                    <span>{header?.value}</span>
                </div>
                <div className="ms-3">
                    {!!header.apply_to_request && headerTags.request}
                    {!!header.apply_to_response && headerTags.response}
                </div>
            </div>

            <div className="d-flex">
                <button
                    type="button"
                    onClick={() => openEditForm('edit', header)}
                    className="btn-action me-3 edit  hover-default"
                >
                    <Edit3 size={13} color="#001A37" />
                </button>
                <button
                    type="button"
                    onClick={() => openDeleteHeaderModal(header)}
                    className="btn-action remove hover-remove"
                >
                    <Trash2 size={13} color="#FC4D70" />
                </button>
            </div>
        </li>
    );
}
