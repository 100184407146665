import React from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store/slices/authSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import LocalLoader from 'ui/LocalLoader';
import { resourceSelectors } from 'store/slices/resourcesSlice';
import { Link, useLocation } from 'react-router-dom';
import { Plus } from 'react-feather';
import ResponsesListItem from './ResponsesListItem';

export default function ResponsesListConfigurator({
    responses,
    selectedDropdown,
    toggleDropdown,
    handleClickAction,
    isShowResponses,
    hasRights,
    inputSearch,
}) {
    const { pathname } = useLocation();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const isResponsesFetching = useSelector(
        resourceSelectors.getIsResponsesFetching,
    );

    if (!responses?.length && inputSearch)
        return (
            <LocalLoader loading={isResponsesFetching} className="mt-5">
                <div
                    className={`nav flex-column nav-children ${
                        !isShowResponses && 'd-none'
                    }`}
                >
                    <div className="create-route-panel not-found">
                        <p className="create-route-panel__desc">
                            No responses found
                        </p>
                    </div>
                </div>
            </LocalLoader>
        );

    if (!responses?.length && !hasRights)
        return (
            <LocalLoader loading={isResponsesFetching} className="mt-5">
                <div
                    className={`nav flex-column nav-children ${
                        !isShowResponses && 'd-none'
                    }`}
                >
                    <div className="create-route-panel not-found">
                        <p className="create-route-panel__desc">
                            There are no responses at the moment.
                        </p>
                    </div>
                </div>
            </LocalLoader>
        );

    if (!responses?.length && hasRights)
        return (
            <LocalLoader loading={isResponsesFetching} className="mt-5">
                <div
                    className={`nav flex-column nav-children ${
                        !isShowResponses && 'd-none'
                    }`}
                >
                    <Link
                        to={`/project/api/components/responses/create?project=${project?.uid}&api=${api?.uid}`}
                        className={`create-route-panel ${
                            pathname.includes(
                                '/project/api/components/responses/create',
                            ) && 'disabled'
                        }`}
                    >
                        <h6 className="create-route-panel__title">
                            <Plus size={16} />
                            <span>Create your first response</span>
                        </h6>
                        <p className="create-route-panel__desc">
                            You can edit and share with your team
                        </p>
                    </Link>
                </div>
            </LocalLoader>
        );

    return (
        <>
            {!!responses?.length && (
                <ul
                    className={`nav flex-column nav-children ${
                        !isShowResponses && 'd-none'
                    }`}
                >
                    {responses?.map((response) => {
                        return (
                            <ResponsesListItem
                                key={response.id}
                                response={response}
                                selectedDropdown={selectedDropdown}
                                toggleDropdown={toggleDropdown}
                                handleClickAction={handleClickAction}
                                project={project}
                                api={api}
                                myAccount={myAccount}
                            />
                        );
                    })}
                </ul>
            )}
        </>
    );
}
