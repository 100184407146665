import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import ApiNavigationPanel from 'components/ApiNavigationPanel';
import EnvironmentEditor from 'components/EnvironmentEditor';

import { apiSelectors } from 'store/slices/apiSlice';
import { projectSelectors } from 'store/slices/projectsSlice';

const ApiContent = ({ children }) => {
    const dispatch = useDispatch();

    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const apis = useSelector(apiSelectors.getApis);

    const [navHeight, setNavHeight] = useState(0);
    const navPanel = useRef();
    const rootStyle = `:root{--contentTop:${navHeight + 60}px;}`;

    const updateDimensions = () => {
        setNavHeight(navPanel.current.clientHeight);
    };

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, [dispatch, project?.uid]);

    return (
        <div className="main-container">
            <style>{rootStyle}</style>
            <Helmet>
                <title>
                    {api?.name || 'Api'} - {project?.name || 'Name'} -
                    CDProjects
                </title>
            </Helmet>
            <div
                ref={navPanel}
                className="api-nav-panell d-flex align-items-center justify-content-between"
            >
                <ApiNavigationPanel project={project} api={api} apis={apis} />
                <EnvironmentEditor />
            </div>
            <div className="d-flex justify-content-start main-layout">
                {children}
            </div>
        </div>
    );
};

export default ApiContent;
