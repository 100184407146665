import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import arrowUp from '../../../assets/img/arrows/arrow-up-right.svg';
import { authSelectors } from '../../../store/selectors';
import { FREE_PLAN_PRICE, freePlan } from './utils/consts';
import { defineCurrentDomain } from 'utils/helpers';
import { organizationSelectors } from 'store/slices/organizationSlice';
import Modal from 'ui/Modal';
import { cancelSubscription } from 'store/slices/billingSlice';
import { toast } from 'react-toastify';

const CurrentPlan = () => {
    const dispatch = useDispatch();
    const myAccount = useSelector(authSelectors.getMyAccount);
    const history = useHistory();
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [isShowCancelSubscriptionModal, setIsShowCancelSubscriptionModal] =
        useState(false);

    useEffect(() => {
        const domain = defineCurrentDomain();

        if (domain && myAccount?.organizations?.length) {
            const currentOrg = myAccount?.organizations?.find(
                (el) => el.domain === domain,
            );
            setSelectedOrganization(currentOrg);
        }
    }, [myAccount?.organizations]);

    const toggleIsShowCancelSubscriptionModal = () => {
        setIsShowCancelSubscriptionModal((prev) => !prev);
    };

    const handleCancelSubscription = () => {
        dispatch(
            cancelSubscription({
                onSuccess: onSuccessCancelSubscription,
                onError: onErrorancelSubscription,
            }),
        );
    };

    const onSuccessCancelSubscription = () => {
        toast.success('Subscription canceled successfully');
        window.location.href = `${window.location.protocol}//${process.env.REACT_APP_DOMAIN}/billing`;
    };
    const onErrorancelSubscription = () => {
        toast.error('Something went wrong, please try again later.');
    };

    const plan = selectedOrganization ? selectedOrganization?.plan : freePlan;

    const numbersOfUsers =
        plan.max_users_count === null
            ? 'Unlimited'
            : `${currentOrg ? selectedOrganization?.users_count : myAccount?.users_count} out of ${plan.max_users_count}`;
    const numbersOfProjects =
        plan.max_projects_count === null
            ? 'Unlimited'
            : `${currentOrg ? selectedOrganization?.projects_count : myAccount?.projects_count} out of ${plan.max_projects_count}`;
    const numbersOfApis =
        plan.max_apis_count === null
            ? 'Unlimited'
            : `${currentOrg ? selectedOrganization?.apis_count : myAccount?.api_count} out of ${plan.max_apis_count}`;
    const price = plan.cost ? `€${plan.cost}` : FREE_PLAN_PRICE;

    return (
        <div
            className="p-4 billing--card-block"
            style={{ margin: currentOrg ? '0 32px' : 'unset' }}
        >
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <h3 className="title-h5">Current plan</h3>
                    <span className="billing--status">{plan?.name}</span>
                </div>
                <div
                    className="d-flex cursor-pointer"
                    onClick={() => history.push('/billing/plans')}
                >
                    <button className="px-2 bg-white">
                        <img src={arrowUp} alt="arrow" className="" />
                    </button>
                    <p className="billing--title billing--paragraph">
                        {currentOrg ? 'Change plan' : 'Create Organization'}
                    </p>
                </div>
            </div>
            <p className="billing--paragraph pt-1">For teams (Unlimited API)</p>
            <div className="pt-4">
                <div className="d-flex align-items-center">
                    <p className="billing--plan-price">{price}</p>
                    {price !== FREE_PLAN_PRICE && (
                        <span className="billing--paragraph__small px-2 pt-3">
                            per month
                        </span>
                    )}
                </div>
            </div>

            <div className="pt-4">
                <div className="d-flex justify-content-between align-items-center">
                    <p className="billing--paragraph billing--paragraph-lineheight">
                        Numbers of APIs
                    </p>
                    <p className="billing--paragraph billing--paragraph-lineheight opacity-100 fw-semibold">
                        {numbersOfApis}
                    </p>
                </div>
                <div className="d-flex justify-content-between align-items-center pt-2">
                    <p className="billing--paragraph billing--paragraph-lineheight">
                        Numbers of projects
                    </p>
                    <p className="billing--paragraph  billing--paragraph-lineheight opacity-100 fw-semibold">
                        {numbersOfProjects}
                    </p>
                </div>
                <div className="d-flex justify-content-between align-items-center pt-2">
                    <p className="billing--paragraph billing--paragraph-lineheight">
                        Numbers of users
                    </p>
                    <p className="billing--paragraph  billing--paragraph-lineheight opacity-100 fw-semibold">
                        {numbersOfUsers}
                    </p>
                </div>
            </div>
            {currentOrg && (
                <p
                    className="billing--decline-link text-end mt-4"
                    onClick={toggleIsShowCancelSubscriptionModal}
                >
                    Cancel subscription
                </p>
            )}
            <Modal
                show={isShowCancelSubscriptionModal}
                title="Cancel subscription"
                body="Current description will be canceled and your organization will be deactivated"
                footer={
                    <>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={handleCancelSubscription}
                        >
                            Cancel subscription
                        </button>
                        <button
                            type="button"
                            onClick={toggleIsShowCancelSubscriptionModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                    </>
                }
            />
        </div>
    );
};

export default CurrentPlan;
