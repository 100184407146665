import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'utils/http';
import { hideGlobalLoader, showGlobalLoader } from './appSlice';

export const getModels = createAsyncThunk(
    'resources/getModels',
    async ({ apiId }, { dispatch }) => {
        try {
            dispatch(isResourcesFetching(true));
            const response = await http.get(
                `/api/v1/api/${apiId}/resources/models`,
            );
            dispatch(isResourcesFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isResourcesFetching(false));
            console.log(error);
        } finally {
            dispatch(isResourcesFetching(false));
        }
    },
);

export const getTrashedModels = createAsyncThunk(
    'resources/getTrashedModels',
    async ({ apiId }, { dispatch }) => {
        try {
            dispatch(isTrashedResourcesFetching(true));
            const response = await http.get(
                `/api/v1/api/${apiId}/resources/models/trash`,
            );
            dispatch(isTrashedResourcesFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isTrashedResourcesFetching(false));
            console.log(error);
        } finally {
            dispatch(isTrashedResourcesFetching(false));
        }
    },
);

export const createModel = createAsyncThunk(
    'resources/createModel',
    async ({ body, apiId, onSuccess, onError }) => {
        try {
            const response = await http.post(
                `/api/v1/api/${apiId}/resources/models`,
                body,
            );
            onSuccess(response);
        } catch (error) {
            onError(error);
        }
    },
);

export const getModel = createAsyncThunk(
    'resources/getModel',
    async ({ resourceId, onSuccess }, { dispatch }) => {
        try {
            dispatch(isResourceFetching(true));
            const response = await http.get(
                `/api/v1/resources/models/${resourceId}`,
            );
            onSuccess(response);
            dispatch(isResourceFetching(false));
            return response.data.data;
        } catch (error) {
            dispatch(isResourceFetching(false));
            console.log(error);
        } finally {
            dispatch(isResourceFetching(false));
        }
    },
);

export const updateModel = createAsyncThunk(
    'resources/updateModel',
    async ({ body, resourceId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.put(
                `api/v1/resources/models/${resourceId}`,
                body,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const deleteModel = createAsyncThunk(
    'resources/deleteModel',
    async ({ resourceId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.delete(`/api/v1/resources/models/${resourceId}`);
            dispatch(hideGlobalLoader());
            onSuccess();
        } catch (error) {
            onError(error);
            dispatch(hideGlobalLoader());
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const deleteModelPermanent = createAsyncThunk(
    'resources/deleteModelPermanent',
    async ({ resourceId, onSuccess }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.post(`/api/v1/resources/models/${resourceId}/destroy`);
            dispatch(hideGlobalLoader());
            onSuccess();
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const restoreModel = createAsyncThunk(
    'resources/restoreModel',
    async ({ resourceId, onSuccess }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/resources/models/${resourceId}/restore`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const emptyResourcesTrash = createAsyncThunk(
    'resources/emptyResourcesTrash',
    async ({ apiId, onSuccess }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.delete(
                `api/v1/api/${apiId}/resources/trash`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const copyModelToApi = createAsyncThunk(
    'resources/copyModelToApi',
    async (
        { resourceId, data, onSuccess, onError, isCopyModalShown },
        { dispatch },
    ) => {
        try {
            dispatch(showGlobalLoader());
            await http.post(
                `/api/v1/resources/models/${resourceId}/copy`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess();
            isCopyModalShown(false);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);
export const getResponses = createAsyncThunk(
    'resources/getResponses',
    async ({ apiId }, { dispatch }) => {
        try {
            dispatch(isResponsesFetching(true));
            const response = await http.get(
                `/api/v1/api/${apiId}/resources/responses`,
            );
            dispatch(isResponsesFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isResponsesFetching(false));
            console.log(error);
        } finally {
            dispatch(isResponsesFetching(false));
        }
    },
);

export const getTrashedResponses = createAsyncThunk(
    'resources/getTrashedResponses',
    async ({ apiId }, { dispatch }) => {
        try {
            dispatch(isTrashedResponsesFetching(true));
            const response = await http.get(
                `/api/v1/api/${apiId}/resources/responses/trash`,
            );
            dispatch(isTrashedResponsesFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isTrashedResponsesFetching(false));
            console.log(error);
        } finally {
            dispatch(isTrashedResponsesFetching(false));
        }
    },
);

export const createResponse = createAsyncThunk(
    'resources/createResponse',
    async ({ body, apiId, onSuccess, onError }) => {
        try {
            const response = await http.post(
                `/api/v1/api/${apiId}/resources/responses`,
                body,
            );
            onSuccess(response);
        } catch (error) {
            onError(error);
        }
    },
);

export const getResponse = createAsyncThunk(
    'resources/getResponse',
    async ({ responseId, onSuccess }, { dispatch }) => {
        try {
            dispatch(isResponseFetching(true));
            const response = await http.get(
                `/api/v1/resources/responses/${responseId}`,
            );
            onSuccess(response);
            dispatch(isResponseFetching(false));
            return response.data.data;
        } catch (error) {
            dispatch(isResponseFetching(false));
            console.log(error);
        } finally {
            dispatch(isResourceFetching(false));
        }
    },
);

export const updateResponse = createAsyncThunk(
    'resources/updateResponse',
    async ({ body, responseId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.put(
                `api/v1/resources/responses/${responseId}`,
                body,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const deleteResponse = createAsyncThunk(
    'resources/deleteResponse',
    async ({ responseId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.delete(`/api/v1/resources/responses/${responseId}`);
            dispatch(hideGlobalLoader());
            onSuccess();
        } catch (error) {
            onError(error);
            dispatch(hideGlobalLoader());
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const deleteResponsePermanent = createAsyncThunk(
    'resources/deleteResponsePermanent',
    async ({ responseId, onSuccess }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.post(
                `/api/v1/resources/responses/${responseId}/destroy`,
            );
            dispatch(hideGlobalLoader());
            onSuccess();
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const restoreResponse = createAsyncThunk(
    'resources/restoreResponse',
    async ({ responseId, onSuccess }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/resources/responses/${responseId}/restore`,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const copyResponseToApi = createAsyncThunk(
    'resources/copyResponseToApi',
    async (
        { responseId, data, onSuccess, onError, isCopyModalShown },
        { dispatch },
    ) => {
        try {
            dispatch(showGlobalLoader());
            await http.post(
                `/api/v1/resources/responses/${responseId}/copy`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess();
            isCopyModalShown(false);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const getGlobalHeaders = createAsyncThunk(
    'resources/getGlobalHeaders',
    async ({ apiId }, { dispatch }) => {
        try {
            dispatch(isGlobalHeadersFetching(true));
            const response = await http.get(
                `api/v1/project/api/${apiId}/headers`,
            );
            dispatch(isGlobalHeadersFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isGlobalHeadersFetching(false));
            console.log(error);
        } finally {
            dispatch(isGlobalHeadersFetching(false));
        }
    },
);

export const createGlobalHeader = createAsyncThunk(
    'resources/createGlobalHeader',
    async ({ body, apiId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `api/v1/project/api/${apiId}/header`,
                body,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            onError(error);
            dispatch(hideGlobalLoader());
        }
    },
);

export const getGlobalHeader = createAsyncThunk(
    'resources/getGlobalHeader',
    async ({ headerId, onSuccess }, { dispatch }) => {
        try {
            dispatch(isGlobalHeaderFetching(true));
            const response = await http.get(
                `api/v1/project/api/header/${headerId}`,
            );
            onSuccess(response);
            dispatch(isGlobalHeaderFetching(false));
            return response.data.data;
        } catch (error) {
            dispatch(isGlobalHeaderFetching(false));
            console.log(error);
        } finally {
            dispatch(isGlobalHeaderFetching(false));
        }
    },
);

export const updateGlobalHeader = createAsyncThunk(
    'resources/updateGlobalHeader',
    async ({ body, headerId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.put(
                `api/v1/project/api/header/${headerId}`,
                body,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const deleteGlobalHeader = createAsyncThunk(
    'resources/deleteGlobalHeader',
    async ({ headerId, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.delete(`api/v1/project/api/header/${headerId}`);
            dispatch(hideGlobalLoader());
            onSuccess();
        } catch (error) {
            onError(error);
            dispatch(hideGlobalLoader());
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const getPublicDocsResources = createAsyncThunk(
    'pages/getPublicDocsResources',
    async ({ apiId, token, onError }, { rejectWithValue }) => {
        try {
            const response = await http.get(
                `/api/v1/cddocs/api/${apiId}/resources/models`,
                {
                    headers: { 'Doc-Auth': token },
                },
            );
            return response.data.data;
        } catch (error) {
            onError(error);
            return rejectWithValue(error);
        }
    },
);

export const getPublicDocsResponses = createAsyncThunk(
    'pages/getResponses',
    async ({ apiId, token, onError }, { rejectWithValue }) => {
        try {
            const response = await http.get(
                `/api/v1/cddocs/api/${apiId}/resources/responses`,
                {
                    headers: { 'Doc-Auth': token },
                },
            );
            return response.data.data;
        } catch (error) {
            onError(error);
            return rejectWithValue(error);
        }
    },
);

const initialState = {
    resources: [],
    resource: {},
    resourcesTrash: [],
    isResourceFetching: false,
    isResourcesFetching: false,
    isResourcesTrashFetching: false,
    resourcesLoadingStatus: 'idle',
    responses: [],
    response: {},
    responsesTrash: [],
    isResponseFetching: false,
    isResponsesFetching: false,
    isResponsesTrashFetching: false,
    responsesLoadingStatus: 'idle',
    globalHeaders: [],
    globalHeader: {},
    isGlobalHeaderFetching: false,
    isGlobalHeadersFetching: false,
    globalHeadersLoadingStatus: 'idle',

    docsResources: [],
    isDocsResourcesFetching: false,
    docsResourcesLoadingStatus: 'idle',
    docsResponses: [],
    isDocsResponsesFetching: false,
    docsResponsesLoadingStatus: 'idle',
};

export const resourcesSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        isResourceFetching(state, action) {
            state.isResourceFetching = action.payload;
        },
        isResourcesFetching(state, action) {
            state.isResourcesFetching = action.payload;
        },
        isTrashedResourcesFetching(state, action) {
            state.isResourcesTrashFetching = action.payload;
        },
        isResponseFetching(state, action) {
            state.isResponseFetching = action.payload;
        },
        isResponsesFetching(state, action) {
            state.isResponsesFetching = action.payload;
        },
        isTrashedResponsesFetching(state, action) {
            state.isResponsesTrashFetching = action.payload;
        },
        isGlobalHeaderFetching(state, action) {
            state.isGlobalHeaderFetching = action.payload;
        },
        isGlobalHeadersFetching(state, action) {
            state.isGlobalHeadersFetching = action.payload;
        },
    },
    extraReducers: {
        [getModels.fulfilled]: (state, action) => {
            state.resources = action.payload || [];
            state.resourcesLoadingStatus = 'succeeded';
        },
        [getTrashedModels.fulfilled]: (state, action) => {
            state.resourcesTrash = action.payload || [];
        },
        [getModel.fulfilled]: (state, action) => {
            state.resource = action.payload || {};
        },
        [getResponses.fulfilled]: (state, action) => {
            state.responses = action.payload || [];
            state.responsesLoadingStatus = 'succeeded';
        },
        [getTrashedResponses.fulfilled]: (state, action) => {
            state.responsesTrash = action.payload || [];
        },
        [getResponse.fulfilled]: (state, action) => {
            state.response = action.payload || {};
        },
        [getGlobalHeaders.fulfilled]: (state, action) => {
            state.globalHeaders = action.payload || [];
            state.globalHeadersLoadingStatus = 'succeeded';
        },
        [getGlobalHeader.fulfilled]: (state, action) => {
            state.globalHeader = action.payload || {};
        },
        [getPublicDocsResources.pending]: (state) => {
            state.isDocsResourcesFetching = true;
        },
        [getPublicDocsResources.fulfilled]: (state, action) => {
            state.docsResources = action.payload;
            state.isDocsResourcesFetching = false;
            state.docsResourcesLoadingStatus = 'succeeded';
        },
        [getPublicDocsResources.rejected]: (state) => {
            state.isDocsResourcesFetching = false;
        },
        [getPublicDocsResponses.pending]: (state) => {
            state.isDocsResponsesFetching = true;
        },
        [getPublicDocsResponses.fulfilled]: (state, action) => {
            state.docsResponses = action.payload;
            state.isDocsResponsesFetching = false;
            state.docsResponsesLoadingStatus = 'succeeded';
        },
        [getPublicDocsResponses.rejected]: (state) => {
            state.isDocsResponsesFetching = false;
        },
    },
});

// Actions

export const {
    isResourceFetching,
    isResourcesFetching,
    isTrashedResourcesFetching,
    isResponseFetching,
    isResponsesFetching,
    isTrashedResponsesFetching,
    isGlobalHeaderFetching,
    isGlobalHeadersFetching,
} = resourcesSlice.actions;

// Selectors

export const resourceSelectors = {
    getCurrentResource: (state) => state.resources.resource,
    getResources: (state) => state.resources.resources,
    getResourcesTrash: (state) => state.resources.resourcesTrash,
    getIsResourcesTrashFetching: (state) =>
        state.resources.isResourcesTrashFetching,
    getIsResourceFetching: (state) => state.resources.isResourceFetching,
    getIsResourcesFetching: (state) => state.resources.isResourcesFetching,
    getResourcesLoadingStatus: (state) =>
        state.resources.resourcesLoadingStatus,
    getCurrentResponse: (state) => state.resources.response,
    getResponses: (state) => state.resources.responses,
    getResponsesTrash: (state) => state.resources.responsesTrash,
    getIsResponsesTrashFetching: (state) =>
        state.resources.isResponsesTrashFetching,
    getIsResponseFetching: (state) => state.resources.isResponseFetching,
    getIsResponsesFetching: (state) => state.resources.isResponsesFetching,
    getResponsesLoadingStatus: (state) =>
        state.resources.responsesLoadingStatus,
    getCurrentGlobalHeader: (state) => state.resources.globalHeader,
    getGlobalHeaders: (state) => state.resources.globalHeaders,
    getIsGlobalHeaderFetching: (state) =>
        state.resources.isGlobalHeaderFetching,
    getIsGlobalHeadersFetching: (state) =>
        state.resources.isGlobalHeadersFetching,
    getGlobalHeadersLoadingStatus: (state) =>
        state.resources.globalHeadersLoadingStatus,
    getDocsResources: (state) => state.resources.docsResources,
    getIsDocsResourceFetching: (state) =>
        state.resources.isDocsResourceFetching,
    getDocsResponses: (state) => state.resources.docsResponses,
    getIsDocsResponsesFetching: (state) =>
        state.resources.isDocsResponsesFetching,
    getDocsResourcesLoadingStatus: (state) =>
        state.resources.docsResourcesLoadingStatus,
    getDocsResponsesLoadingStatus: (state) =>
        state.resources.docsResponsesLoadingStatus,
};

export default resourcesSlice.reducer;
