import { BaseNode } from './BaseNode';

export class RegularNode extends BaseNode {
    constructor(fragment, parent = null, options) {
        super(fragment);
        this.parent = parent;
        this.name = Object.keys(fragment)[0];
        this.fragment = fragment[this.name];
        this.id = super.generateId();
        this.type = super.defineType();
        this.subType = super.defineSubType();
        this.description = this.fragment.description || '';
        this.children = super.setupChildren(this.fragment);
        this.isRequired = options.isRequired || null;
        this.behaviour = super.defineBehaviour();
        this.isNullable = super.defineIsNullable();
        this.enumValue = super.defineEnum();
        this.detached = options?.detached;
        this.depth = options.depth;
        this.extraProps = super.defineExtraProps();
        this.isCombinerChild = options?.isCombinerChild;
    }
}
