import React from 'react';
import { Input } from 'ui/Inputs';

export default function ArrayExtraProps({
    extraProps,
    onChangeExtraProperties,
}) {
    return (
        <div>
            <div className="JsonSchemaEditor__schema-types-modal-extra-props">
                <div className="d-flex align-items-center mb-3 ps-1">
                    <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        defaultChecked={extraProps?.uniqueItems}
                        onChange={onChangeExtraProperties}
                        id="uniqueItems"
                    />
                    <label className="ms-2" htmlFor="uniqueItems">
                        Unique items
                    </label>
                </div>
            </div>
            <div className="mb-3 ps-1 d-flex justify-content-between">
                <div>
                    <Input
                        type="number"
                        id="minItems"
                        className="form-control"
                        value={extraProps?.minItems || ''}
                        onChange={onChangeExtraProperties}
                        labelClassName="JsonSchemaEditor__schema-types-modal-title"
                        labelText="MIN_ITEMS"
                        placeHolder=">=0"
                    />
                </div>
                <div>
                    <Input
                        type="number"
                        id="maxItems"
                        className="form-control"
                        value={extraProps?.maxItems || ''}
                        onChange={onChangeExtraProperties}
                        labelClassName="JsonSchemaEditor__schema-types-modal-title"
                        labelText="MAX_ITEMS"
                        placeHolder=">=0"
                    />
                </div>
            </div>
        </div>
    );
}
