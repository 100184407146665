import React, { useState, useEffect } from 'react';

const Tooltip = ({
    id,
    content,
    hidden,
    children,
    alignment = 'right',
    childrenClassNames,
    shiftX = 0,
}) => {
    const [active, setActive] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    useEffect(() => {
        if (active) {
            const tooltipWrapper = document.getElementById(id);
            const topPosition = tooltipWrapper.getBoundingClientRect().top;
            const rightPosition =
                tooltipWrapper.getBoundingClientRect()[alignment];

            setPosition({
                top: topPosition,
                left: rightPosition + 2 + shiftX,
            });
        } else {
            setPosition({ top: 0, left: 0 });
        }
    }, [active, alignment, id, shiftX]);

    const showTip = () => {
        setActive(true);
    };

    const hideTip = () => {
        setActive(false);
    };

    const tooltipWrapperClassNames = childrenClassNames
        ? `${childrenClassNames} tooltip-wrapper`
        : 'tooltip-wrapper';
    return (
        <div
            id={id}
            className={tooltipWrapperClassNames}
            onMouseOver={showTip}
            onMouseOut={hideTip}
            onClick={hideTip}
        >
            {children}
            {active && (
                <div
                    className={`tooltip-tip  ${hidden ? 'hidden' : ''}`}
                    style={position}
                >
                    {content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
