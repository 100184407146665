import RouteResponseTabsItem from './RouteTabsItem';
import ResponseExampleSelect from '../RouteForm/Response/ResponseExampleSelect';
import CustomSelect from 'ui/CustomSelect';
import { useSelector } from 'react-redux';
import { resourceSelectors } from 'store/slices/resourcesSlice';

const RouteResponseTabs = ({
    items,
    activeTab,
    onClick,
    selectResponseStatus,
    selectSharedResponse,
    deleteResponseExample,
    isShowSelect = true,
    isShowSharedResponseSelect = true,
    addResponseExample,
}) => {
    const responses = useSelector(resourceSelectors.getResponses);
    const sortedItems =
        items.length > 0 &&
        [...items].sort(
            (prev, next) => prev.http_status_code - next.http_status_code,
        );

    const options = responses.reduce((acc, el) => {
        acc.push({
            value: el.name.toLowerCase(),
            label: el.name,
            id: el.id,
        });
        return acc;
    }, []);

    const selectStyles = {
        control: (provided, state) => ({
            ...provided,
            maxWidth: '280px',
            minWidth: '100px',
            height: '44px',
            display: 'flex',
            border: '2px solid #edeff3',
            borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
            boxShadow: state.isFocused
                ? '0 0 0 0.25rem rgb(13 110 253 / 25%)'
                : '',
            color: '#001a37',
            borderRadius: '6px',
            '&:hover': {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? '#86b7fe' : '#edeff3',
            },
        }),
        menu: (provider, state) => {
            const optionsLength = state?.options?.map((el) => el.value.length);
            const maxOptionLength = Math.max(...optionsLength);
            return {
                ...provider,
                right: 0,
                width: maxOptionLength * 8 + 70 || 90,
            };
        },
        valueContainer: (provided, state) => ({
            ...provided,
            width: state?.selectProps?.value[0]?.label?.length * 8 + 70 || 270,
        }),
    };
    return (
        <div className="d-flex justify-content-between align-items-baseline w-100 mb-3">
            <div className="response-example-tabs">
                <ul className="response-example-tabs-list">
                    {sortedItems.map((item, index) => (
                        <RouteResponseTabsItem
                            key={index}
                            label={item.http_status_code}
                            activeTab={activeTab}
                            onClick={onClick}
                            index={index}
                        />
                    ))}
                </ul>
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex">
                    {isShowSharedResponseSelect &&
                        items.map((item, index) => {
                            const httpStatusCode = item.http_status_code;
                            const currentSharedResponse = options.find(
                                (el) => el.id === item.shared_response,
                            );
                            if (httpStatusCode !== activeTab) return null;
                            return (
                                <CustomSelect
                                    key={index}
                                    className="select-main-container"
                                    name="shared_responses"
                                    placeholder="No shared response selected"
                                    options={options}
                                    onChange={(e) =>
                                        selectSharedResponse(e, index)
                                    }
                                    value={currentSharedResponse?.value ?? null}
                                    customStyles={selectStyles}
                                />
                            );
                        })}

                    {isShowSelect &&
                        items.map((item, index) => {
                            const httpStatusCode = item.http_status_code;
                            if (httpStatusCode !== activeTab) return null;
                            return (
                                <ResponseExampleSelect
                                    deleteResponseExample={
                                        deleteResponseExample
                                    }
                                    selectResponseStatus={selectResponseStatus}
                                    index={index}
                                    value={httpStatusCode.toString()}
                                    key={index}
                                    onClick={onClick}
                                    items={items}
                                />
                            );
                        })}
                </div>
                {isShowSelect && (
                    <button
                        className="btn btn-light mt-3 align-self-end"
                        onClick={addResponseExample}
                    >
                        + Add response
                    </button>
                )}
            </div>
        </div>
    );
};

export default RouteResponseTabs;
