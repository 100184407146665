import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Input } from 'ui/Inputs';
import { cardOptions } from 'pages/Billing/ManageBilling/utils/consts';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import { authSelectors } from 'store/selectors';
import {
    billingSelectors,
    createSubscription,
} from 'store/slices/billingSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import Modal from 'ui/Modal';
import ContactUsModal from 'components/ContacUsModal/ContactUsModal';

export default function Purchase() {
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();

    const cards = useSelector(billingSelectors.getAllCards);
    const recentProjects = useSelector(projectSelectors.getRecentProjects);
    const [data, setData] = useState({
        name: '',
        domain: '',
        email: '',
        city: '',
        zipcode: '',
        street: '',
        transfer_project: false,
    });
    const [showContactUsModal, setShowContactUsModal] = useState(false);
    const location = useLocation();
    const selectedPlan = location?.state?.plan;

    const [cardName, setCardName] = useState('');
    const [errors, setErrors] = useState(null);

    const currentPaymentCard = cards && cards[0];
    const currentPaymentCardId = currentPaymentCard?.id;
    const planId = selectedPlan?.id;

    const onInputChange = (e) => {
        const { name, value, checked } = e.target;

        setData((prev) => ({
            ...prev,
            [name]: name === 'transfer_project' ? checked : value,
        }));
    };

    const setUpPaymentMethod = async () => {
        const paymentMethod = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                name: cardName,
                email: data?.email,
            },
        });

        if (paymentMethod.error) {
            return;
        }
        return paymentMethod;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const paymentMethod = await setUpPaymentMethod();

        try {
            const body = {
                ...data,
                domain: data.domain.toLowerCase(),
                paymentMethod:
                    currentPaymentCardId || paymentMethod?.paymentMethod?.id,
                planId,
            };

            dispatch(
                createSubscription({
                    body,
                    onSuccess: onSuccessCreateSubscription,
                    onError: handleError,
                }),
            );
        } catch (e) {
            console.log(e);
            // setIsLoading(false);
        }
    };

    const handleChangeCardName = (e) => {
        e.preventDefault();
        setCardName(e.target.value);
    };

    const handleCardElementChange = (event) => {
        if (!event.error) {
            setErrors(null);
            return;
        }

        setErrors(event?.error?.message);
    };

    const onSuccessCreateSubscription = () => {
        window.location.href = `${window.location.protocol}//${data?.domain}.${process.env.REACT_APP_DOMAIN}`;
    };

    const handleError = (error) => {
        setErrors(error?.response?.data?.errors);
    };

    const toggleContactUsModal = (e) => {
        if (e) e.preventDefault();

        setShowContactUsModal((prev) => !prev);
    };

    return (
        <div>
            <h1 className="title-h3">Upgrade plan</h1>
            <h3 className="title-h4 mb-2 mt-4">{selectedPlan?.name}</h3>
            <div className="mb-4">
                <div className="d-flex align-items-center">
                    <p className="billing--paragraph billing--paragraph-lineheight me-2">
                        Numbers of APIs
                    </p>
                    <p className="billing--paragraph billing--paragraph-lineheight opacity-100 fw-semibold">
                        {selectedPlan?.max_apis_count || 'Unlimited'}
                    </p>
                </div>
                <div className="d-flex align-items-center mt-1">
                    <p className="billing--paragraph billing--paragraph-lineheight me-2">
                        Numbers of projects
                    </p>
                    <p className="billing--paragraph  billing--paragraph-lineheight opacity-100 fw-semibold">
                        {selectedPlan?.max_projects_count || 'Unlimited'}
                    </p>
                </div>
                <div className="d-flex align-items-center mt-1">
                    <p className="billing--paragraph billing--paragraph-lineheight me-2">
                        Numbers of users
                    </p>
                    <p className="billing--paragraph  billing--paragraph-lineheight opacity-100 fw-semibold">
                        {selectedPlan?.max_users_count || 'Unlimited'}
                    </p>
                </div>
            </div>

            <div className="mb-3">
                <h3 className="title-h4 mb-2 mt-4">Billing information</h3>
                <p className="billing--paragraph billing--paragraph-lineheight">
                    This will be displayed on all your invoices and billing
                    communication.
                </p>
            </div>
            <p className="billing--paragraph billing--paragraph-lineheight mb-3">
                If you have issues with upgrading plan, please{' '}
                <a
                    href="/"
                    className="link-opacity-100"
                    onClick={toggleContactUsModal}
                >
                    contact us
                </a>{' '}
                and we will help you to solve the problem.
            </p>
            <div className="row">
                <div
                    className="mb-3 col-6"
                    style={{ borderRight: '2px solid #edeff3' }}
                >
                    <div className="col-8">
                        <div className="form-group mb-2">
                            <Input
                                type="text"
                                name="name"
                                className="form-control"
                                labelText="Organization name"
                                labelClassName="label-main mb-2"
                                onChange={onInputChange}
                                value={data.name}
                                errorText={errors?.name}
                                isRequired
                            />
                        </div>
                        <div className="form-group mb-2">
                            <Input
                                type="text"
                                name="domain"
                                className="form-control"
                                labelText="Domain"
                                labelClassName="label-main mb-2"
                                onChange={onInputChange}
                                value={data?.domain}
                                errorText={errors?.domain}
                                isRequired
                            />
                        </div>
                        <div className="form-group mb-2">
                            <Input
                                type="text"
                                name="email"
                                className="form-control"
                                labelText="Email"
                                labelClassName="label-main mb-2"
                                onChange={onInputChange}
                                value={data?.email}
                                errorText={errors?.email}
                                isRequired
                            />
                        </div>

                        <div className="d-flex">
                            <div className="form-group mb-2 w-100 me-4">
                                <Input
                                    type="text"
                                    name="city"
                                    className="form-control"
                                    labelText="City"
                                    labelClassName="label-main mb-2"
                                    onChange={onInputChange}
                                    value={data?.city}
                                />
                            </div>
                            <div className="form-group mb-2 w-100">
                                <Input
                                    type="text"
                                    name="zipcode"
                                    className="form-control"
                                    labelText="Zipcode"
                                    labelClassName="label-main mb-2"
                                    onChange={onInputChange}
                                    value={data?.zipcode}
                                />
                            </div>
                        </div>
                        <div className="form-group mb-2">
                            <Input
                                type="text"
                                name="street"
                                className="form-control"
                                labelText="Street"
                                labelClassName="label-main mb-2"
                                onChange={onInputChange}
                                value={data?.street}
                            />
                        </div>
                        {!!recentProjects?.length && (
                            <div className="form-group mb-2">
                                <div className="d-flex align-items-center">
                                    <input
                                        className="form-check-input mt-0"
                                        type="checkbox"
                                        checked={data.transfer_project}
                                        onChange={onInputChange}
                                        name="transfer_project"
                                        id="transfer"
                                    />
                                    <label
                                        className="form-check-label ms-2"
                                        htmlFor="transfer"
                                    >
                                        Transfer project{' '}
                                        <span className="fw-bold">
                                            {recentProjects?.[0]?.name}
                                        </span>{' '}
                                        to the new organization
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-4" style={{ margin: '0 auto' }}>
                    <div id="payment-form">
                        <h5 className="title-h5">Card information</h5>
                        <div className="form-group my-3">
                            <Input
                                type="text"
                                name="cardName"
                                className="form-control"
                                value={cardName}
                                labelText="Card name"
                                labelClassName="form-label opacity-75"
                                placeHolder="Enter card name"
                                onChange={handleChangeCardName}
                            />
                        </div>
                        <label className="d-block w-100 form-label opacity-75">
                            <span className="form-label is-invalid d-block mb-2">
                                Card number
                            </span>
                            <CardElement
                                id="card-element"
                                options={cardOptions}
                                onChange={handleCardElementChange}
                            />
                        </label>
                        {/* {error && (
                        <p className="invalid-feedback d-block">{error}</p>
                    )} */}
                    </div>
                </div>
            </div>
            <button
                className="btn btn-primary"
                type="button"
                onClick={handleSubmit}
            >
                Subscribe
            </button>
            <Modal
                show={showContactUsModal}
                body={<ContactUsModal onClose={toggleContactUsModal} />}
            />
        </div>
    );
}
