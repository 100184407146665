import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Collapse } from 'react-collapse';
import { useDispatch } from 'react-redux';

import LocalLoader from 'ui/LocalLoader';
import EnvProperties from './EnvProperties';
import { Input } from 'ui/Inputs';
import { setCookie, getCookie } from 'utils/cookie';
import {
    getEnvironments,
    setSelectedEnvironments,
} from 'store/slices/environmentsSlice';

const AddEnvVariablesForm = ({
    envId,
    items,
    title,
    setView,
    onSubmit,
    project,
    api,
}) => {
    const dispatch = useDispatch();

    const [data, setData] = useState({ envName: title, variables: items });
    const [errors, setErrors] = useState({});

    const addParameter = useCallback(() => {
        const newData = JSON.parse(JSON.stringify(data));
        const newVariablesArray = [...newData.variables];

        newVariablesArray.push({
            is_active: true,
            name: '',
            value: '',
        });

        if (newData.envName === '') {
            setData({ envName: title, variables: newVariablesArray });
        } else {
            setData({ ...newData, variables: newVariablesArray });
        }
    }, [data, title]);

    useEffect(() => {
        if (data.variables?.length === 0) {
            addParameter();
        }
    }, [addParameter, data.variables?.length]);

    const deleteParameterRow = (e, index) => {
        e.preventDefault();
        const newData = JSON.parse(JSON.stringify(data));

        const filteredUsersArray = newData.variables.filter(
            (el, i) => i !== index,
        );

        setData({ ...newData, variables: filteredUsersArray });
    };

    const changeParameter = (e, index) => {
        const { name, value } = e.target;
        const newData = JSON.parse(JSON.stringify(data));
        let variablesDataItem = newData.variables[index];

        if (e.target.name === 'is_active') {
            variablesDataItem['is_active'] = !variablesDataItem['is_active'];
        } else {
            variablesDataItem[name] = value;
        }

        newData.variables[index] = { ...variablesDataItem };

        setData({ ...newData });
    };

    const changeEnvName = (e) => {
        setData({ ...data, envName: e.target.value });
    };

    const handleSubmit = () => {
        const newData = { name: data.envName, variables: data.variables };

        onSubmit(newData, envId, onSuccess, onError);
    };

    const onSuccess = (response) => {
        const selectedEnvironments = JSON.parse(
            getCookie('selected_environments'),
        );
        const filteredEnvironments = selectedEnvironments.filter(
            (el) => el.item.api_id !== api.id,
        );
        const newEnvironment = {
            value: response.data.data.name.toLowerCase(),
            label: response.data.data.name,
            item: response.data.data,
        };

        setView('view');
        toast.success(`The environment has been updated`);

        filteredEnvironments.push(newEnvironment);
        dispatch(setSelectedEnvironments(filteredEnvironments));
        setCookie(
            'selected_environments',
            JSON.stringify(filteredEnvironments),
            {},
        );
        dispatch(getEnvironments({ projectUid: project.uid, apiId: api.uid }));
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    return (
        <>
            <div className="form-group mb-4 mt-3">
                <div className="mb-4" style={{ width: '40%' }}>
                    <Input
                        type="text"
                        name="envName"
                        className="form-control mt-2"
                        value={data.envName}
                        onChange={changeEnvName}
                        labelText="Environment name"
                        labelClassName="label-main"
                        placeHolder="Environment name"
                        errorText={errors.name}
                    />
                </div>
                <div
                    className="d-flex justify-content-between align-items-center ps-3 pe-2 mb-2"
                    style={{ borderBottom: '2px solid #edeff3' }}
                >
                    <div className="d-flex align-items-center">
                        <h6>Variables</h6>
                    </div>
                    <button
                        type="button"
                        className="btn btn-light mb-1 mt-1 text-start"
                        onClick={addParameter}
                    >
                        +
                    </button>
                </div>
                <LocalLoader>
                    <Collapse isOpened={true}>
                        <EnvProperties
                            changeParam={changeParameter}
                            deleteRow={deleteParameterRow}
                            items={data.variables}
                            errors={errors}
                        />
                    </Collapse>
                </LocalLoader>
            </div>
            <div className="modal-footer border-top-0">
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                >
                    Save
                </button>
                <button
                    type="button"
                    onClick={() => setView('view')}
                    className="btn btn-link"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
            </div>
        </>
    );
};
export default AddEnvVariablesForm;
