import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'utils/http';
import { hideGlobalLoader, showGlobalLoader } from './appSlice';

export const fetchMembers = createAsyncThunk(
    'members/fetchMembers',
    async (_, { dispatch }) => {
        try {
            dispatch(isMembersFetching(true));
            const response = await http.get('/api/v1/members');
            dispatch(isMembersFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isMembersFetching(false));
            console.log(error);
        } finally {
            dispatch(isMembersFetching(false));
        }
    },
);

export const fetchMemberProjects = createAsyncThunk(
    'members/fetchMemberProjects',
    async ({ uuid }, { dispatch }) => {
        try {
            dispatch(isMemberProjectsFetching(true));
            const response = await http.get(`/api/v1/user/${uuid}/projects`);
            dispatch(isMemberProjectsFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isMemberProjectsFetching(false));
            console.log(error);
        } finally {
            dispatch(isMemberProjectsFetching(false));
        }
    },
);

export const fetchMemberProfile = createAsyncThunk(
    'members/fetchMemberProfile',
    async ({ uuid }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            dispatch(isMemberFetching(true));
            const response = await http.get(`/api/v1/user/${uuid}`);
            dispatch(isMemberFetching(false));
            dispatch(hideGlobalLoader());

            return response.data.data;
        } catch (error) {
            dispatch(isMemberFetching(false));
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(isMemberFetching(false));
            dispatch(hideGlobalLoader());
        }
    },
);

const initialState = {
    members: [],
    isMembersFetching: false,

    member: {},
    isMemberFetching: false,

    memberProjects: [],
    isMemberProjectsFetching: false,
};

export const membersSlice = createSlice({
    name: 'members',
    initialState,
    reducers: {
        isMemberFetching(state, action) {
            state.isMemberFetching = action.payload;
        },
        isMembersFetching(state, action) {
            state.isMembersFetching = action.payload;
        },
        isMemberProjectsFetching(state, action) {
            state.isMemberProjectsFetching = action.payload;
        },
    },
    extraReducers: {
        [fetchMembers.fulfilled]: (state, action) => {
            state.members = action.payload;
        },
        [fetchMemberProfile.fulfilled]: (state, action) => {
            state.member = action.payload;
        },
        [fetchMemberProjects.fulfilled]: (state, action) => {
            state.memberProjects = action.payload;
        },
    },
});

// Actions

export const { isMemberFetching, isMembersFetching, isMemberProjectsFetching } =
    membersSlice.actions;

// Selectors

export const memberSelectors = {
    getCurrentMember: (state) => state.members.member,
    getMember: (state) => state.members.member,
    getMembers: (state) => state.members.members,
    getMemberProjects: (state) => state.members.memberProjects,
    getIsMembersFetching: (state) => state.members.isMembersFetching,
    getIsMemberProjectsFetching: (state) =>
        state.members.isMemberProjectsFetching,
};

export default membersSlice.reducer;
