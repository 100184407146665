import React, { useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';
import LocalLoader from 'ui/LocalLoader';
import useOutsideClick from 'hooks/useOutsideClick';
import { apiSelectors } from 'store/slices/apiSlice';
import OrganizationsList from './OrganizationsList';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store/slices/authSlice';
import { appSelectors } from 'store/slices/appSlice';

const OrganizationsSelector = () => {
    const isApisFetching = useSelector(apiSelectors.getIsApisFetching);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const orgDomain = useSelector(appSelectors.getOrgDomain);

    const [showList, setShowList] = useState(false);
    const selectOrganizationDropdown = useRef();

    const handleOutsideClick = () => {
        setShowList(false);
    };

    useOutsideClick(selectOrganizationDropdown, handleOutsideClick);

    const toggleList = (e) => {
        setShowList(!showList);
    };

    const selectedOrganization = myAccount?.organizations.find(
        (el) => el.domain === orgDomain,
    );

    return (
        <div className="btn-group" ref={selectOrganizationDropdown}>
            <button
                type="button"
                className="custom-dropdown-btn link-dropdown-btn m-0"
                onClick={toggleList}
            >
                {selectedOrganization?.name ||
                    `${myAccount?.first_name} ${myAccount?.last_name}`}
                <ChevronDown
                    className="custom-dropdown-btn-arrow"
                    size={12}
                    strokeWidth={2}
                    aria-expanded={showList ? 'true' : 'false'}
                />
            </button>
            {showList && (
                <div
                    className={`dropdown-menu ${showList ? 'show' : ''}`}
                    style={{ minWidth: '13rem' }}
                >
                    <div>
                        <LocalLoader loading={isApisFetching}>
                            <OrganizationsList
                                organizations={myAccount?.organizations}
                            />
                        </LocalLoader>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OrganizationsSelector;
