import visaIcon from 'assets/img/visa.svg';
import masterCardIcon from 'assets/img/mastercard.svg';
import americanExpressIcon from 'assets/img/american-express.svg';
import discoverIcon from 'assets/img/discover.svg';
import dinersIcon from 'assets/img/diners.svg';
import jcbIcon from 'assets/img/jcb.svg';
import unionpayIcon from 'assets/img/unionpay.svg';
import cartesBancairesIcon from 'assets/img/cartes_bancaires.svg';
import defaultCard from 'assets/img/default-payment-card.svg';

export const getCardIcon = (brand) => {
    switch (brand.toLowerCase()) {
        case 'visa':
            return visaIcon;
        case 'mastercard':
            return masterCardIcon;
        case 'amex':
            return americanExpressIcon;
        case 'discover':
            return discoverIcon;
        case 'diners':
            return dinersIcon;
        case 'jcb':
            return jcbIcon;
        case 'unionpay':
            return unionpayIcon;
        case 'cartes_bancaires':
            return cartesBancairesIcon;
        default:
            return defaultCard;
    }
};
