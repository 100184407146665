const History = ({ isAccessToActivateHistory }) => {
    return (
        <>
            {!isAccessToActivateHistory ? (
                <p className="text-center w-100 pt-2">
                    History is not enabled for your plan. Please upgrade your
                    plan to activate history.
                </p>
            ) : (
                <p className="text-center w-100 pt-2">No history found</p>
            )}
        </>
    );
};

export default History;
