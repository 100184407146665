import { findRef } from '../utils/findRef';
import { BaseNode } from './BaseNode';

export class ReferenceNode extends BaseNode {
    constructor(fragment, parent = null, options) {
        super(fragment);
        this.parent = parent;
        this.name = Object.keys(fragment)[0];
        this.fragment = fragment[this.name];
        this.id = super.generateId();
        this.type = super.defineType();
        this.subType = super.defineSubType();
        this.description = this.fragment.description || '';
        this.behaviour = super.defineBehaviour();
        this.refPath =
            this.fragment?.$ref || this.fragment?.items?.$ref || null;
        this.refName = this.refPath?.split('/')?.slice(-1)[0] || null;
        this.children =
            this.fragment.properties ||
            this.fragment?.items?.properties ||
            null;
        this.isRequired = options.isRequired || null;
        this.detached = options?.detached;
        this.refSchema = findRef(options.models, this.refName);
        this.depth = options.depth;
        this.extraProps = super.defineExtraProps();
        this.isCombinerChild = options?.isCombinerChild;
    }
}
