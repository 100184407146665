import { useSelector } from 'react-redux';
import { organizationSelectors } from 'store/slices/organizationSlice';
import { Input } from 'ui/Inputs';

const ServersItem = ({
    server,
    changeParameterServer,
    index,
    onDeleteRowServer,
    project,
    errors,
    myAccount,
}) => {
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const hasOwnerRights =
        project.owner.id === myAccount.id || currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project.user_role === 'MAINTAINER' ||
        project.user_role === 'WRITE';
    return (
        <li className="d-flex justify-content-between pe-2 mb-2 ">
            <div className="d-flex w-100">
                <div style={{ width: '63%', marginRight: '8px' }}>
                    <Input
                        type="text"
                        name="url"
                        className="form-control"
                        onChange={(e) => changeParameterServer(e, index)}
                        placeHolder="Enter your URL"
                        value={server.url}
                        errorText={errors[`api_servers.${index}.url`]}
                        isDisabled={!hasRights}
                    />
                </div>
                <div style={{ width: '37%' }}>
                    <Input
                        type="text"
                        name="name"
                        className="form-control"
                        onChange={(e) => changeParameterServer(e, index)}
                        placeHolder="Name (optional)"
                        value={server.name || ''}
                        errorText={errors[`api_servers.${index}.name`]}
                        isDisabled={!hasRights}
                    />
                </div>
                <>
                    {hasRights && (
                        <button
                            type="button"
                            className="btn-action hover-default btn-section-server"
                            onClick={() => onDeleteRowServer(index)}
                        >
                            -
                        </button>
                    )}
                </>
            </div>
        </li>
    );
};

export default ServersItem;
