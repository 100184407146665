import React from 'react';
import background from 'assets/img/landing/bg/bg-new.svg';
import left from 'assets/img/landing/pattern-left.svg';
import right from 'assets/img/landing/pattern-right.svg';

const IntroSection = () => {
    return (
        <>
            <div className="landing-page__patterns-relative">
                <div className="landing-page__patterns-absolute">
                    <img
                        className="landing-page__patterns-left"
                        src={left}
                        alt="pattern-left"
                    />
                    <img
                        className="landing-page__patterns-right"
                        src={right}
                        alt="pattern-right"
                    />
                </div>
            </div>
            <section className="section-intro" id="into-section">
                <div className="container section-intro__container">
                    <h1 className="section-intro__title">
                        Create beautiful{' '}
                        <span className="section-intro__title section-intro__title--color">
                            REST API
                        </span>
                        <br />
                        Documentations
                    </h1>
                    <ul className="d-flex section-intro__list">
                        <li className="section-intro__item">Create.</li>
                        <li className="section-intro__item">Share.</li>
                        <li className="section-intro__item">Built.</li>
                        <li className="section-intro__item">Test.</li>
                    </ul>
                    <div className="section-intro__info-content">
                        <ul className="section-intro__info">
                            <li className="section-intro__info-item">
                                <h2 className="section-intro__info-title">
                                    2.3k{' '}
                                    <span className="section-intro__sign">
                                        +
                                    </span>
                                </h2>
                                <p className="section-intro__info-text">
                                    Projects
                                </p>
                            </li>
                            <li className="section-intro__info-item">
                                <h2 className="section-intro__info-title">
                                    12k{' '}
                                    <span className="section-intro__sign">
                                        +
                                    </span>
                                </h2>
                                <p className="section-intro__info-text">APIs</p>
                            </li>
                            <li className="section-intro__info-item">
                                <h2 className="section-intro__info-title">
                                    49k{' '}
                                    <span className="section-intro__sign">
                                        +
                                    </span>
                                </h2>
                                <p className="section-intro__info-text">
                                    Routes
                                </p>
                            </li>
                            <li className="section-intro__info-item">
                                <h2 className="section-intro__info-title">
                                    20k{' '}
                                    <span className="section-intro__sign">
                                        +
                                    </span>
                                </h2>
                                <p className="section-intro__info-text">
                                    Users
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <a
                            href="/signup"
                            className="btn btn-primary section-intro__button"
                        >
                            Register
                        </a>
                        <a
                            href="/#pricing-feature"
                            className="section-intro__learn-more"
                        >
                            Learn more
                        </a>
                    </div>
                </div>
                <div className="section-intro__bg">
                    <img
                        src={background}
                        alt="landing"
                        className="section-intro__image"
                    />
                </div>
            </section>
        </>
    );
};

export default IntroSection;
