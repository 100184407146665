import React, { useEffect } from 'react';

const Environments = ({
    environments,
    environmentsIds,
    publicEnvIds,
    setEnvironments,
}) => {
    useEffect(() => {
        const environmentsIdArray = publicEnvIds === null ? [] : publicEnvIds;

        setEnvironments(environmentsIdArray);
    }, [setEnvironments, publicEnvIds]);

    const onChangeActiveEnvironments = (e, id) => {
        const newIdArray = [...environmentsIds];

        if (e.target.checked && newIdArray.includes(id)) return;

        if (e.target.checked && !newIdArray.includes(id)) {
            newIdArray.push(id);
            setEnvironments(newIdArray);
        }

        if (!e.target.checked && newIdArray.includes(id)) {
            const filteredIdArray = newIdArray.filter((el) => el !== id);
            setEnvironments(filteredIdArray);
        }
    };

    const activeEnvironments = environments?.map((el) => ({
        isActive: environmentsIds?.includes(el.id),
        name: el.name,
        id: el.id,
    }));

    return (
        <div className="form-group mb-4">
            <p className="mb-2 fw-bold">Environments</p>
            <ul>
                {activeEnvironments?.map((el) => (
                    <li key={el.id}>
                        <div className="d-flex">
                            <input
                                type="checkbox"
                                name="is_active"
                                className="form-check-input align-baseline me-3"
                                onChange={(e) =>
                                    onChangeActiveEnvironments(e, el.id)
                                }
                                checked={el.isActive}
                            />
                            <span className="d-block">{el.name}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Environments;
