import { UserAvatar } from 'components/Avatar';
import useOutsideClick from 'hooks/useOutsideClick';
import React, { useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import { AlertTriangle } from 'react-feather';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
    organizationSelectors,
    transferOrgansization,
    updateOrganization,
} from 'store/slices/organizationSlice';
import { Input } from 'ui/Inputs';
import Modal from 'ui/Modal';
import PageHeader from 'ui/PageHeader';

export default function Organization() {
    const dispatch = useDispatch();
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const teamMembers = useSelector(
        organizationSelectors.getOrganizationMembersList,
    );

    const [data, setData] = useState({
        name: '',
        email: '',
        city: '',
        zipcode: '',
        street: '',
    });
    const [error, setError] = useState(null);
    const [transferEmail, setTransferEmail] = useState('');
    const [transferError, setTransferError] = useState(null);
    const [modalShown, setModalShown] = useState({
        isTransferModalShown: false,
    });
    const [showDropdownMembers, setShowDropdownMembers] = useState(false);
    const dropdownRef = useRef();
    const filteredSearchMembers =
        !!teamMembers &&
        teamMembers.filter((member) => {
            const fullName = member.first_name + ' ' + member.last_name;
            const email = member.email || '';

            return (
                fullName.toLowerCase().includes(transferEmail.toLowerCase()) ||
                email.toLowerCase().includes(transferEmail.toLowerCase())
            );
        });

    useOutsideClick(dropdownRef, () => setShowDropdownMembers(false));

    useEffect(() => {
        setData({
            name: currentOrg?.name || '',
            email: currentOrg?.email || '',
            city: currentOrg?.city || '',
            zipcode: currentOrg?.zipcode || '',
            street: currentOrg?.street || '',
        });
    }, [currentOrg]);

    const onInputChange = (e) => {
        const { name, value } = e.target;

        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await dispatch(
                updateOrganization({
                    body: data,
                }),
            ).unwrap();
            toast.success('Changes saved successfully');
        } catch (e) {
            setError(e.response.data.errors);
        }
    };

    const toggleTransferModal = () => {
        setModalShown({
            ...modalShown,
            isTransferModalShown: !modalShown.isTransferModalShown,
        });
        setTransferEmail('');
    };

    const onTransfer = async () => {
        const body = { email: transferEmail };
        try {
            await dispatch(
                transferOrgansization({
                    body,
                }),
            ).unwrap();
            onSuccessTransfer();
        } catch (error) {
            onTransferError(error);
        }
    };

    const onSuccessTransfer = () => {
        toast.success('Project has been transfered successfully');
        toggleTransferModal();
        setTimeout(() => {
            window.location.href = '/projects';
        }, 700);
    };

    const onTransferError = (error) => {
        setTransferError({ email: [error.message] });
    };

    const isOwner = currentOrg?.role === 'OWNER';

    return (
        <div className="container-fluid p-5">
            <Helmet>
                <title>
                    Organization - CDProjects - Create beautiful REST API
                    Documentations
                </title>
            </Helmet>
            <div className="mb-3 row">
                <div className="d-flex justify-content-between mb-4">
                    <PageHeader margin="mb-0">Organization</PageHeader>
                    {isOwner && (
                        <div className="btn-group">
                            <button
                                type="button"
                                className="btn btn-primary dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Manage organization
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <button
                                        className="dropdown-item text-wrap"
                                        onClick={toggleTransferModal}
                                    >
                                        Change Owner
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
                <div className="col-5">
                    <div className="form-group mb-2">
                        <Input
                            type="text"
                            name="name"
                            className="form-control"
                            labelText="Organization name"
                            labelClassName="label-main mb-2"
                            onChange={onInputChange}
                            value={data.name}
                            errorText={error?.name}
                            isRequired
                            isDisabled={!isOwner}
                        />
                    </div>
                    <div className="form-group mb-2">
                        <Input
                            type="text"
                            name="email"
                            className="form-control"
                            labelText="Email"
                            labelClassName="label-main mb-2"
                            onChange={onInputChange}
                            value={data?.email}
                            errorText={error?.email}
                            isRequired
                            isDisabled={!isOwner}
                        />
                    </div>

                    <div className="d-flex">
                        <div className="form-group mb-2 w-100 me-4">
                            <Input
                                type="text"
                                name="city"
                                className="form-control"
                                labelText="City"
                                labelClassName="label-main mb-2"
                                onChange={onInputChange}
                                value={data?.city}
                                errorText={error?.city}
                                isDisabled={!isOwner}
                            />
                        </div>
                        <div className="form-group mb-2 w-100">
                            <Input
                                type="text"
                                name="zipcode"
                                className="form-control"
                                labelText="Zipcode"
                                labelClassName="label-main mb-2"
                                onChange={onInputChange}
                                value={data?.zipcode}
                                errorText={error?.zipcode}
                                isDisabled={!isOwner}
                            />
                        </div>
                    </div>
                    <div className="form-group mb-4">
                        <Input
                            type="text"
                            name="street"
                            className="form-control"
                            labelText="Street"
                            labelClassName="label-main mb-2"
                            onChange={onInputChange}
                            value={data?.street}
                            errorText={error?.street}
                            isDisabled={!isOwner}
                        />
                    </div>
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleSubmit}
                        disabled={!isOwner}
                    >
                        Submit
                    </button>
                </div>
            </div>
            <Modal
                show={modalShown.isTransferModalShown}
                title={
                    <div className="d-flex align-items-center">
                        <AlertTriangle
                            size={20}
                            className="me-2 text-warning"
                            strokeWidth={2}
                        />
                        <span>Transfer organization</span>
                    </div>
                }
                body={
                    <div>
                        <p className="fw-bold text-black-50 mb-2">
                            Organization to transfer
                        </p>
                        <div className="d-flex align-items-center">
                            <strong>{currentOrg?.name}</strong>
                        </div>
                        <div
                            className="form-group mb-4 position-relative"
                            onClick={() => setShowDropdownMembers(true)}
                        >
                            <Input
                                type="text"
                                name="email"
                                className="form-control"
                                value={transferEmail}
                                onChange={(e) =>
                                    setTransferEmail(e.target.value)
                                }
                                labelText="New owner (email)"
                                labelClassName="form-label fw-bold text-black-50 mt-4"
                                errorText={transferError?.email}
                                autoComplete={'off'}
                                inputRef={dropdownRef}
                            />

                            {showDropdownMembers &&
                                transferEmail &&
                                filteredSearchMembers.length > 0 && (
                                    <Collapse isOpened={true}>
                                        <ul
                                            className="dropdown-list w-100 mt-1"
                                            style={{
                                                overflowY: 'scroll',
                                                maxHeight: '160px',
                                            }}
                                        >
                                            {filteredSearchMembers?.map(
                                                (member) => (
                                                    <li
                                                        key={member.uuid}
                                                        className="d-flex"
                                                        onClick={() =>
                                                            setShowDropdownMembers(
                                                                false,
                                                            )
                                                        }
                                                        style={{
                                                            height: '50px',
                                                        }}
                                                    >
                                                        <button
                                                            className="dropdown-link"
                                                            onClick={() =>
                                                                setTransferEmail(
                                                                    member?.email,
                                                                )
                                                            }
                                                        >
                                                            <UserAvatar
                                                                iconUrl={
                                                                    member.avatar_url
                                                                }
                                                                first_name={
                                                                    member.first_name
                                                                }
                                                                last_name={
                                                                    member.last_name
                                                                }
                                                            />
                                                            {`${member.first_name} ${member.last_name}`}
                                                        </button>
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                    </Collapse>
                                )}
                        </div>
                        <p className="fw-bold mt-5">
                            Once accepted, new owners can administer
                            organization access, content and payments.
                        </p>
                    </div>
                }
                footer={
                    <>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            disabled={transferEmail === '' ? true : false}
                            onClick={onTransfer}
                        >
                            Transfer
                        </button>
                        <button
                            type="button"
                            onClick={toggleTransferModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </>
                }
            />
        </div>
    );
}
