import React, { Fragment, useEffect, useState } from 'react';
import { Trash } from 'react-feather';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import LocalLoader from 'ui/LocalLoader';
import { formatDate } from 'utils/helpers';
import CustomSelect from 'ui/CustomSelect';
import Modal from 'ui/Modal';
import { authSelectors } from 'store/slices/authSlice';
import {
    changeRole,
    deleteMember,
    getProjectMembers,
    projectSelectors,
} from 'store/slices/projectsSlice';
import { organizationSelectors } from 'store/slices/organizationSlice';

const role = [
    { value: 'READ', label: 'Read' },
    { value: 'WRITE', label: 'Write' },
    { value: 'MAINTAINER', label: 'Maintainer' },
];

const MembersList = ({ project }) => {
    const dispatch = useDispatch();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const projectMembers = useSelector(projectSelectors.getProjectMembers);
    const isProjectMembersFetching = useSelector(
        projectSelectors.getIsProjectMembersFetching,
    );
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const [memberToDelete, setMemberToDelete] = useState(null);
    const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);

    const hasOwnerRights =
        (project?.owner?.id === myAccount?.id &&
            currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights =
        project?.user_role === 'MAINTAINER' ||
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN';

    useEffect(() => {
        dispatch(getProjectMembers({ project: project?.uid }));
    }, [dispatch, project?.uid]);

    const onDeleteModalShown = (flag, member) => {
        setIsDeleteModalShown(flag);
        setMemberToDelete(member);
    };

    const onDelete = () => {
        setIsDeleteModalShown(false);

        dispatch(
            deleteMember({
                projectUid: project?.uid,
                memberId: memberToDelete.id,
                onSuccess: onSuccessDelete,
                onError: onErrorDelete,
            }),
        );
    };

    const onSuccessDelete = (response) => {
        toast.success('Member has been deleted');
        dispatch(getProjectMembers({ project: project?.uid }));
    };

    const onErrorDelete = (error) => {
        toast.error('Something went wrong. Please try again later');
    };

    const handleChangeRole = (selectedOption, member) => {
        dispatch(
            changeRole({
                pid: project?.uid,
                mid: member.id,
                data: { role: selectedOption.value },
                onSuccess: onSuccessChangeRole,
                onError: onErrorChangeRole,
            }),
        );
    };

    const onSuccessChangeRole = (response) => {
        toast.success("Member's role has been changed");
        dispatch(getProjectMembers({ project: project?.uid }));
    };

    const onErrorChangeRole = (error) => {
        toast.error('Something went wrong. Please try again later');
    };

    return (
        <div className="mt-5">
            <h4 className="text-big mb-4">Project members</h4>
            <div className="mt-3 table-scroll">
                <LocalLoader loading={isProjectMembersFetching}>
                    <table className="table table-main">
                        <thead className="table-main-head">
                            <tr>
                                <th className="pending-table-title">Name</th>
                                <th className="pending-table-title">Email</th>
                                <th className="pending-table-title">
                                    Joined at
                                </th>
                                <th className="pending-table-title">
                                    Permissions
                                </th>
                                <td className="pending-table-title">&nbsp;</td>
                            </tr>
                        </thead>
                        <tbody>
                            {!!projectMembers?.length ? (
                                projectMembers?.map((member) => (
                                    <tr
                                        className="table-main-row"
                                        key={member.id}
                                    >
                                        <td className="pending-table-title">
                                            {`${member?.first_name} ${member?.last_name}`}
                                        </td>
                                        <td>{member?.email}</td>
                                        <td>
                                            {member?.joined_at &&
                                                formatDate(member?.joined_at)}
                                        </td>
                                        <td>
                                            {(member?.id !== myAccount?.id &&
                                                hasRights &&
                                                !project?.is_transfer_pending) ||
                                            (hasRights &&
                                                member?.role !== 'MAINTAINER' &&
                                                member?.id !== myAccount?.id &&
                                                !project?.is_transfer_pending) ? (
                                                <CustomSelect
                                                    name="role"
                                                    options={role}
                                                    onChange={(e) =>
                                                        handleChangeRole(
                                                            e,
                                                            member,
                                                        )
                                                    }
                                                    value={member?.role}
                                                />
                                            ) : (
                                                member?.role
                                            )}
                                        </td>
                                        <td className="text-end">
                                            {(member?.id !== myAccount?.id &&
                                                hasRights &&
                                                !project?.is_transfer_pending) ||
                                            (hasRights &&
                                                member?.role !== 'MAINTAINER' &&
                                                member?.id !== myAccount?.id &&
                                                !project?.is_transfer_pending) ? (
                                                <button
                                                    className="btn btn-outline-danger btn-sm"
                                                    value={member.id}
                                                    onClick={() =>
                                                        onDeleteModalShown(
                                                            true,
                                                            member,
                                                        )
                                                    }
                                                >
                                                    <Trash size={15} />
                                                </button>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5} className="text-center">
                                        No members
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </LocalLoader>
            </div>

            <Modal
                show={isDeleteModalShown}
                title="Delete memeber"
                body="Are you sure you want to delete member?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onDelete}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={() => isDeleteModalShown(false)}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
        </div>
    );
};

export default MembersList;
