import React, { useEffect, useState } from 'react';
import { ChevronDown, X } from 'react-feather';
import { useHistory, useLocation } from 'react-router';

const Multiselect = ({ options, onChange }) => {
    const location = useLocation();
    const history = useHistory();
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        const p = new URLSearchParams(location.search).getAll('filter[]');
        const selected = options.filter((x) => p.includes(x.id));
        setSelected(selected);
    }, [location.search, options]);

    const handleChange = (event) => {
        const target = event.target;
        const value = target.checked;
        const id = target.id;
        let searchParams = new URLSearchParams(location.search);

        if (value === true) {
            const item = options.find((x) => x.id === id);
            let chosen = selected;
            chosen.push(item);
            setSelected(chosen);
            onChange(selected);

            searchParams.append('filter[]', item.id);
            history.push({ search: searchParams.toString() });
        } else {
            removeItem(id);
        }

        const dropdownMenu = document.getElementById('dropdown-menu');
        const dropdownButton = document.getElementById('multiselectDropDown');

        if (dropdownMenu && dropdownButton) {
            dropdownMenu.classList.toggle('show');
            dropdownButton.classList.toggle('show');
            dropdownButton.setAttribute('aria-expanded', 'false');
        }
    };

    const isChecked = (id) => {
        const index = selected?.findIndex((x) => x.id === id);
        return index > -1;
    };

    const removeItem = (id) => {
        const index = selected?.findIndex((x) => x.id === id);
        let chosen = selected;
        let searchParams = new URLSearchParams(location.search);
        const removed = chosen.splice(index, 1);
        setSelected(chosen);
        onChange(selected);

        // Remove multiple params
        const notRemovedFilters = searchParams
            .getAll('filter[]')
            .filter((tag) => tag !== removed[0].id);
        searchParams.delete('filter[]');
        for (const filter of notRemovedFilters)
            searchParams.append('filter[]', filter);
        history.push({ search: searchParams.toString() });
    };

    return (
        <div className="mx-4 mb-4 dropdown d-flex">
            <button
                className="custom-dropdown-btn multiselect-btn text-start"
                type="button"
                id="multiselectDropDown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                Filters
                <ChevronDown
                    className="custom-dropdown-btn-arrow custom-dropdown-btn-arrow-big"
                    size={10}
                    strokeWidth={3}
                />
            </button>
            <ul
                className="dropdown-menu header-dropdown-list multiselect-filter-list"
                aria-labelledby="multiselectDropDown"
                id="dropdown-menu"
            >
                {options.map((option) => (
                    <li key={option.id}>
                        <label
                            className="dropdown-item form-check multiselect-filter-item"
                            htmlFor={option.id}
                        >
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name={option.id}
                                id={option.id}
                                onChange={handleChange}
                                checked={isChecked(option.id)}
                            />
                            {option.name}
                        </label>
                    </li>
                ))}
            </ul>
            {selected?.map((item) => (
                <button
                    key={item.id}
                    className="btn multiselect-filter-tags"
                    name="owner"
                    onClick={() => removeItem(item.id)}
                >
                    {item.name}
                    <X
                        className="custom-dropdown-btn-arrow custom-dropdown-btn-arrow-big"
                        size={10}
                        strokeWidth={3}
                    />
                </button>
            ))}
        </div>
    );
};

export default Multiselect;
