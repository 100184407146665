import { Collapse } from 'react-collapse';

import ServersItem from './ServersItem';
import LocalLoader from 'ui/LocalLoader';
import { useSelector } from 'react-redux';
import { organizationSelectors } from 'store/slices/organizationSlice';

const Servers = ({
    project,
    myAccount,
    deleteParameterRow,
    addParameter,
    changeParameterServer,
    errors,
    servers,
}) => {
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const hasOwnerRights =
        (project.owner.id === myAccount.id && currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project.user_role === 'MAINTAINER' ||
        project.user_role === 'WRITE';
    return (
        <section>
            <div
                className="d-flex justify-content-between align-items-center pe-2 ps-3 mb-3"
                style={
                    !hasRights
                        ? { height: '44px' }
                        : { borderBottom: '2px solid #edeff3' }
                }
            >
                <h6 style={{ fontWeight: '400' }}>Servers</h6>
                {hasRights && (
                    <button
                        type="button"
                        className="btn btn-light mb-1 mt-1 text-start"
                        onClick={addParameter}
                    >
                        +
                    </button>
                )}
            </div>
            <ul className="mb-4">
                <LocalLoader>
                    {servers?.length > 0 && (
                        <Collapse isOpened={true}>
                            {servers?.map((server, index) => (
                                <ServersItem
                                    server={server}
                                    key={index}
                                    errors={errors}
                                    index={index}
                                    onDeleteRowServer={deleteParameterRow}
                                    project={project}
                                    myAccount={myAccount}
                                    changeParameterServer={
                                        changeParameterServer
                                    }
                                />
                            ))}
                        </Collapse>
                    )}
                    {!servers?.length && !hasRights && (
                        <div
                            className="create-route-panel not-found"
                            style={{ margin: 0, height: '44px' }}
                        >
                            <p className="create-route-panel__desc">
                                There are no servers at the moment.
                            </p>
                        </div>
                    )}
                    {!servers?.length && hasRights && (
                        <div
                            className="create-route-panel not-found"
                            style={{ margin: 0, height: '44px' }}
                        >
                            <p className="create-route-panel__desc">
                                No servers found. Please create your first
                                server.
                            </p>
                        </div>
                    )}
                </LocalLoader>
            </ul>
        </section>
    );
};

export default Servers;
