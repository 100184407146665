import React from 'react';
import { Helmet } from 'react-helmet';

import PageHeader from 'ui/PageHeader';
import InviteForm from './InviteForm';
import PendingInvites from './PendingInvites';
import MembersList from './MembersList';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store/slices/authSlice';
import { organizationSelectors } from 'store/slices/organizationSlice';

const MembersPage = ({ project }) => {
    const myAccount = useSelector(authSelectors.getMyAccount);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const hasOwnerRights =
        (project?.owner?.id === myAccount?.id &&
            currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project?.user_role === 'MAINTAINER';

    return (
        <div className="main-container m-5">
            <Helmet>
                <title>
                    Members - {project?.name ?? ''} - Create beautiful REST API
                    Documentations
                </title>
            </Helmet>

            <PageHeader>Members</PageHeader>

            <div>
                {hasRights && (
                    <>
                        <InviteForm project={project} />
                        <PendingInvites project={project} />
                    </>
                )}
                <MembersList project={project} />
            </div>
        </div>
    );
};

export default MembersPage;
