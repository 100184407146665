import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ApiContent from 'components/ApiContent/ApiContent';
import MainContent from 'ui/MainContent';
import Table from './Table';
import View from './View';

export default function HistoryContainer() {
    return (
        <ApiContent>
            <MainContent>
                <div className="main-container">
                    <Switch>
                        <Route exact path="/project/api/history">
                            <div className="page-header d-flex flex-row justify-content-between mx-sm-5 mx-3">
                                <h1 className="title-h3">History of editing</h1>
                            </div>

                            <Table />
                        </Route>
                        <Route path="/project/api/history/browse/:id">
                            <View />
                        </Route>
                    </Switch>
                </div>
            </MainContent>
        </ApiContent>
    );
}
