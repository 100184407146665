import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import iconCheck from 'assets/img/landing/Icon-checkmark.svg';
import { useSelector } from 'react-redux';
import { organizationSelectors } from 'store/slices/organizationSlice';

const pricingCardItemContent = [
    { content: 'Unlimited Projects', free: '1 Project', medium: '10 Projects' },
    { content: 'Unlimited APIs', free: '1 API', medium: '10 APIs' },
    { content: 'Unlimited Users', free: '1 User', medium: '10 Users' },
    { content: 'Multiple environments' },
    { content: 'Import/Export your collections (Postman, Swagger)' },
    {
        content: 'Support Premium',
        free: 'Support Standart',
        medium: 'Support Standart',
    },
    {
        content: 'Public documentation under your company domain',
        lineThrough: true,
    },
    { content: 'Change history', lineThrough: true },
    { content: 'Comments', lineThrough: true },
    { content: 'SSO / Audit logs', lineThrough: true, popular: true },
    {
        content: 'Guaranteed uptime SLA 99.9%',
        lineThrough: true,
        free: 'Guaranteed uptime SLA',
        medium: 'Guaranteed uptime SLA',
        popular: true,
    },
];

const PricingCard = (props) => {
    const {
        description,
        subscription,
        status,
        price,
        features,
        term,
        addSubscription,
        plan,
        showButton = false,
        style,
    } = props;
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const isCurrentPlan = currentOrg?.plan?.code === plan?.code;

    const handleButton = () => {
        addSubscription(plan);
    };

    return (
        <div
            className={
                status === 'medium'
                    ? 'card pricing-card pricing-card__medium'
                    : 'card pricing-card'
            }
            style={style}
        >
            <div className="card-body p-md-4">
                <div className="pricing-card__header">
                    <p className="mb-3 pricing-card__subscription">
                        {subscription}
                    </p>
                    <div className="d-flex align-items-center mb-3">
                        <h2 className="pricing-card__title">
                            {status !== 'enterprise'
                                ? `€${price}`
                                : 'Individually'}
                        </h2>
                        {status === 'medium' && (
                            <p className="mx-2 pricing-card__text">{term}</p>
                        )}
                    </div>
                    <p className="mb-4 pricing-card__text pricing-card__text-font">
                        {description}
                    </p>
                </div>
                <div
                    className="text-center mb-3"
                    style={{ width: 'fit-content' }}
                >
                    {showButton && (
                        <button
                            className="btn btn-block btn-primary mx-auto w-100 pricing-card__btn"
                            disabled={isCurrentPlan && status !== 'enterprise'}
                            onClick={handleButton}
                        >
                            {status === 'enterprise'
                                ? 'Contact sales'
                                : isCurrentPlan
                                  ? 'Current plan'
                                  : 'Select plan'}
                        </button>
                    )}
                </div>
                <div className="pricing-card__divider" />
                <p className="pricing-card__text pricing-card__text-font mt-3">
                    {features}
                </p>
                <ul className="list-unstyled mt-3 pricing-card__list w-100">
                    {pricingCardItemContent.map((item, index) => (
                        <li
                            className={classNames('pricing-card__item', {
                                'pricing-card__item--status':
                                    item.lineThrough && status === 'free',
                            })}
                            style={{
                                opacity:
                                    item.popular && status === 'medium'
                                        ? '0.4'
                                        : '',
                                textDecorationLine:
                                    item.popular && status === 'medium'
                                        ? 'line-through'
                                        : '',
                            }}
                            key={index}
                        >
                            <div className="d-flex align-items-center">
                                <img
                                    src={iconCheck}
                                    alt="checkmark"
                                    className="pricing-card__icon"
                                    style={{
                                        color:
                                            (item.lineThrough &&
                                                status === 'free') ||
                                            (item.popular &&
                                                status === 'medium')
                                                ? '#001A37'
                                                : '#00BF80',
                                    }}
                                />
                                <p>
                                    {status === 'free' &&
                                        `${item.free || item.content}`}
                                    {status === 'medium' &&
                                        `${item?.medium || item.content}`}
                                    {status === 'enterprise' &&
                                        `${item.content}`}
                                </p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

PricingCard.propTypes = {
    description: PropTypes.string,
    subscription: PropTypes.string,
    className: PropTypes.string,
    price: PropTypes.string,
    term: PropTypes.string,
    status: PropTypes.string,
    features: PropTypes.string,
    addSubscription: PropTypes.func,
};

export default PricingCard;
