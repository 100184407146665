import React, { useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import LocalLoader from 'ui/LocalLoader';
import GlobalLoader from 'ui/GlobalLoader';
import Compressor from 'compressorjs';

const ImageCrop = ({ sourceImage, saveAvatar, cancelAvatarSelection }) => {
    const [crop, setCrop] = useState({
        unit: 'px',
        width: 75,
        x: 0,
        y: 0,
        aspect: 1 / 1,
    });
    const [imageProperties, setImageProperties] = useState({
        croppedImage: {},
        isImageCropping: false,
        isCroppedImageSaving: false,
    });
    const [imageUrl, setImageUrl] = useState('');
    let ref = useRef();

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const makeClientCrop = async (crop) => {
        if (ref.current.imageRef.current && crop.width && crop.height) {
            setImageProperties({ ...imageProperties, isImageCropping: true });
            const croppedImage = await getCroppedImg(
                ref.current.imageRef.current,
                crop,
                `newFile${Math.round(performance.now())}.png`,
            );

            const url = window.URL.createObjectURL(croppedImage);
            setImageUrl(url);
            setImageProperties({
                ...imageProperties,
                isImageCropping: false,
                croppedImage: croppedImage,
            });
        }
    };

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    blob.name = fileName;
                    resolve(blob);
                },
                'image/png',
                1,
            );
        });
    };

    const onResetCrop = () => {
        setCrop({ ...crop, x: 0, y: 0 });
    };

    const onSaveAvatar = async () => {
        setImageProperties({ ...imageProperties, isCroppedImageSaving: true });

        new Compressor(imageProperties.croppedImage, {
            quality: 0.6,
            success(result) {
                setImageProperties({
                    ...imageProperties,
                    isCroppedImageSaving: false,
                });
                saveAvatar(result);
            },
        });
    };

    const onCancelSelection = () => {
        onResetCrop();
        cancelAvatarSelection();
    };

    return (
        <>
            <div className="d-flex mb-4">
                {!imageUrl ? (
                    <div className="preview-container me-3">
                        <LocalLoader loading={true} />
                    </div>
                ) : (
                    <img
                        src={imageUrl}
                        alt="Logo preview"
                        className="previev-image d-block me-3 user-select-none"
                        width="56px"
                        height="56px"
                    />
                )}
                <p className="fs-6">
                    Select the part of the uploaded image that you want to use
                    as your logo.
                </p>
            </div>
            <div className="d-flex justify-content-center mb-4">
                {sourceImage && (
                    <ReactCrop
                        src={sourceImage}
                        crop={crop}
                        style={{ width: '200px', height: '100%' }}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                        minWidth={50}
                        minHeight={50}
                        maxWidth={200}
                        maxHeight={200}
                        keepSelection={true}
                        ref={ref}
                        disabled={imageProperties.isImageCropping}
                    />
                )}
            </div>
            <div className="d-flex justify-content-end align-items-center">
                <button
                    className="btn btn-primary m-1"
                    type="button"
                    onClick={onSaveAvatar}
                    disabled={imageProperties.isImageCropping ? true : false}
                >
                    Save
                </button>
                <button
                    className="btn btn-link m-1"
                    type="button"
                    onClick={onCancelSelection}
                >
                    Cancel
                </button>
            </div>
            <GlobalLoader isLoading={imageProperties.isCroppedImageSaving} />
        </>
    );
};

export default ImageCrop;
