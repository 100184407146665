import React, { useEffect, useState } from 'react';
import { Copy } from 'react-feather';
import JSONPretty from 'react-json-pretty';
import { toast } from 'react-toastify';
import useCopyToClipboard from './hooks/useCopyToClipboard';
import { generateId } from './helpers';
import Tooltip from 'ui/Tooltip';

export default function ViewerExamples({ examples }) {
    const [currentExample, setCurrentExample] = useState(
        Object.keys(examples)[0] || null,
    );
    const [code, setCode] = useState('');
    const [setCopiedText] = useCopyToClipboard();

    useEffect(() => {
        setCode(examples[currentExample]);
    }, [examples, currentExample]);

    const selectExample = (el) => {
        setCurrentExample(el);
    };

    const onCopyToClipboard = () => {
        setCopiedText(JSON.stringify(code));
        toast.success('Copied to Clipboard');
    };

    return (
        <div
            className="JsonSchemaViewer__examples-wrapper"
            style={{
                maxHeight: !Object.keys(examples).length ? '80px' : '100%',
            }}
        >
            <div className="JsonSchemaViewer__examples-buttons-wrapper">
                <div className="JsonSchemaViewer__examples-buttons-list">
                    {Object.keys(examples)?.map((el) => (
                        <div
                            className="JsonSchemaViewer__examples-button-wrapper"
                            key={generateId()}
                        >
                            <button
                                onClick={() => selectExample(el)}
                                className={
                                    currentExample === el
                                        ? 'JsonSchemaViewer__examples-tab-button JsonSchemaViewer__examples-tab-button--active'
                                        : 'JsonSchemaViewer__examples-tab-button'
                                }
                                type="button"
                            >
                                <span>{el}</span>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <div className="JsonSchemaViewer__examples-editor-wrapper">
                {!!Object.keys(examples)?.length ? (
                    <>
                        <div className="JsonSchemaViewer__examples-info-pannel-wrapper">
                            <div className="JsonSchemaViewer__examples-info-pannel">
                                <span>{currentExample}</span>
                            </div>
                            <div className="ms-3">
                                <Tooltip
                                    id="copyexample"
                                    content="Copy example"
                                >
                                    <button
                                        onClick={onCopyToClipboard}
                                        className="JsonSchemaViewer__examples-copy-button"
                                        type="button"
                                    >
                                        <Copy size={16} color="#001a37b3" />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="JsonSchemaViewer__examples-code-editor-wrapper">
                            <div className="position-relative">
                                <JSONPretty data={code} />
                            </div>
                        </div>
                    </>
                ) : (
                    <div style={{ marginTop: '25px', textAlign: 'center' }}>
                        <p>No Examples</p>
                    </div>
                )}
            </div>
        </div>
    );
}
