import React from 'react';
import iconCheck from 'assets/img/landing/Icon-checkmark.svg';

const featuresFirstColumn = [
    'Unlimited Projects',
    'Unlimited APIs',
    'Team support',
    'Multiple environments',
    'Mock server',
    'Publish documentation under your company domain',
    'Internal documentation space',
    'Import/Export (Postman, Swagger)',
];
const featuresSecondColumn = [
    'Route history',
    'Comments',
    'Backups',
    'Support 24/7',
    'Branded documentation page',
    'Notifications if route is updated',
    'SSO',
];

const FeatureSection = () => {
    return (
        <section className="section-feature" id="pricing-feature">
            <div className="container section-feature__container">
                <h2 className="section-feature__title">Our features</h2>
                <div className="section-feature__content">
                    <ul className="section-feature__list">
                        {featuresFirstColumn.map((feature, index) => (
                            <li key={index} className="section-feature__item">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={iconCheck}
                                        alt="check"
                                        className="section-feature__icon"
                                    />
                                    <span className="section-feature__text">
                                        {feature}
                                    </span>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <ul className="section-feature__list">
                        {featuresSecondColumn?.map((feature, index) => (
                            <li
                                key={index}
                                className="section-feature__item d-flex"
                            >
                                <div className="d-flex align-items-center">
                                    <img
                                        src={iconCheck}
                                        alt="check"
                                        className="section-feature__icon"
                                    />
                                    <p className="section-feature__text">
                                        {feature}
                                    </p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="section-feature__started">
                    <div className="section-feature__started-content">
                        <h3 className="section-feature__title section-feature__title--size">
                            Ready to get started?
                        </h3>
                        <p className="section-feature__description">
                            Get a single connected workspace where all teams are
                            welcomed
                        </p>
                        <a
                            href="/signup"
                            className="btn btn-primary section-feature__button"
                        >
                            Get Started for Free
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeatureSection;
