import React, { useEffect, useState } from 'react';
import CustomSelect from 'ui/CustomSelect';
import { AutoSuggestInput, Input } from 'ui/Inputs';
import RequiredMark from 'ui/RequiredMark';
import { headers, headersValues } from 'shared/data';
import { useDispatch, useSelector } from 'react-redux';
import { initRoutes, routeSelectors } from 'store/slices/routesSlice';
import {
    createGlobalHeader,
    getGlobalHeaders,
    updateGlobalHeader,
} from 'store/slices/resourcesSlice';
import { toast } from 'react-toastify';
import ErrorText from 'ui/ErrorText';
import { createShallowCopy } from 'utils/helpers';

const selectStyles = {
    multiValue: (provided, state) => ({
        ...provided,
        borderRadius: '8px',
        backgroundColor: '#edeff3',
    }),
};

export default function GlobalHeadersModalForm({
    onCloseHeaderModal,
    project,
    api,
    headersModalFlag,
    selectedHeader,
}) {
    const dispatch = useDispatch();
    const routes = useSelector(routeSelectors.getRoutes);
    const [data, setData] = useState({
        name: '',
        value: '',
        description: '',
        exclusion_route_ids: [],
        apply_to_request: 1,
        apply_to_response: 0,
    });
    const [errors, setErrors] = useState({});

    const resetData = () => {
        setData({
            name: '',
            value: '',
            description: '',
            exclusion_route_ids: [],
            apply_to_request: 1,
            apply_to_response: 0,
        });
        setErrors({});
    };

    useEffect(() => {
        dispatch(initRoutes({ project: project?.uid, apiId: api?.id }));

        if (headersModalFlag === 'edit') setData(selectedHeader);
    }, [api?.id, dispatch, headersModalFlag, project?.uid, selectedHeader]);

    const onInputChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };

    const onCheckboxChange = (e) => {
        const { id, checked } = e.target;

        setData({ ...data, [id]: checked });

        const errorsCopy = createShallowCopy(errors);

        delete errorsCopy.apply_to_request;
        delete errorsCopy.apply_to_response;

        setErrors(errorsCopy);
    };

    const onRouteSelect = (e) => {
        const idList = e.map((el) => el.value);
        setData({ ...data, exclusion_route_ids: idList });
    };

    const onCreateGlobalHeader = (e) => {
        e.preventDefault();

        if (headersModalFlag === 'create') {
            dispatch(
                createGlobalHeader({
                    body: data,
                    apiId: api.id,
                    onSuccess: onSuccessCreate,
                    onError: onError,
                }),
            );
        } else {
            dispatch(
                updateGlobalHeader({
                    body: data,
                    headerId: selectedHeader.id,
                    onSuccess: onSuccessGlobalHeaderUpdate,
                    onError: onError,
                }),
            );
        }
    };

    const onSuccessCreate = (response) => {
        onCloseHeaderModal();
        toast.success('The header has been created');
        dispatch(getGlobalHeaders({ projectUid: project.uid, apiId: api.id }));
        resetData();
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    const onSuccessGlobalHeaderUpdate = (response) => {
        onCloseHeaderModal();
        toast.success('The header has been updated');
        dispatch(getGlobalHeaders({ projectUid: project.uid, apiId: api.id }));
        resetData();
    };

    const routesOptions = routes?.map((el) => ({
        value: el?.id,
        label: el?.name,
    }));

    const convertExclusionRoutesIds = (idsArray) => {
        const data = idsArray?.reduce((acc, el) => {
            const foundRoute = routes.find((route) => route.id === el);

            if (foundRoute) {
                acc.push({ value: foundRoute.id, label: foundRoute.name });
            }

            return acc;
        }, []);

        return data;
    };

    return (
        <>
            <div className="modal-body">
                <form>
                    <div>
                        <div className="form-group mb-4">
                            <div className="mb-3">
                                <label htmlFor="name" className="mb-2">
                                    Header name
                                    <RequiredMark />
                                </label>
                                <AutoSuggestInput
                                    name="name"
                                    id="name"
                                    placeholder="Header name"
                                    options={headers}
                                    value={data?.name}
                                    handleChange={onInputChange}
                                    className="form-control"
                                    errorText={errors?.name}
                                />
                            </div>
                            <div>
                                <label htmlFor="value" className="mb-2">
                                    Header value
                                    <RequiredMark />
                                </label>
                                <AutoSuggestInput
                                    name="value"
                                    id="value"
                                    placeholder="Header value"
                                    options={headersValues}
                                    value={data?.value}
                                    handleChange={onInputChange}
                                    className="form-control"
                                    errorText={errors?.value}
                                />
                            </div>
                        </div>
                        <div className="form-group mb-4">
                            <Input
                                type="text"
                                name="description"
                                className="form-control"
                                value={data?.description || ''}
                                onChange={onInputChange}
                                placeHolder="Add details..."
                                labelText="Description"
                                errorText={errors?.description}
                            />
                        </div>
                        <div className="d-flex mb-3">
                            <div className="d-flex me-3">
                                <input
                                    type="checkbox"
                                    id="apply_to_request"
                                    className="form-check-input align-baseline me-3"
                                    onChange={onCheckboxChange}
                                    checked={data?.apply_to_request}
                                />
                                <label
                                    className="d-block"
                                    htmlFor="apply_to_request"
                                >
                                    Request
                                </label>
                            </div>
                            <div className="d-flex">
                                <input
                                    type="checkbox"
                                    id="apply_to_response"
                                    className="form-check-input align-baseline me-3"
                                    onChange={onCheckboxChange}
                                    checked={data?.apply_to_response}
                                />
                                <label
                                    className="d-block"
                                    htmlFor="apply_to_response"
                                >
                                    Response
                                </label>
                            </div>
                        </div>
                        {errors?.apply_to_request && (
                            <div className="mb-3">
                                <ErrorText text={errors?.apply_to_request} />
                            </div>
                        )}

                        <div className="form-group mb-4">
                            <label className="mb-2">
                                Select routes, that won't include global header
                            </label>
                            <CustomSelect
                                className="select-main-container"
                                name="exclusion_route_ids"
                                options={routesOptions}
                                isMultiSelect={true}
                                onChange={onRouteSelect}
                                value={convertExclusionRoutesIds(
                                    data?.exclusion_route_ids,
                                )}
                                customStyles={selectStyles}
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={onCreateGlobalHeader}
                >
                    {headersModalFlag === 'create' ? 'Create' : 'Update'}
                </button>

                <button
                    type="button"
                    onClick={(e) => {
                        onCloseHeaderModal(e);
                        resetData();
                    }}
                    className="btn btn-link"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
            </div>
        </>
    );
}
