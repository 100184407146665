const RouteResponseTabsItem = ({ label, activeTab, onClick }) => {
    const isActive = activeTab === label;

    const handleClick = () => onClick(label);

    const colorCircle =
        label >= 200 && label < 300
            ? '#00C26E'
            : label >= 300 && label < 400
            ? '#FFEE32'
            : label >= 400 && label < 500
            ? '#FF9D3D'
            : '#DE4A4A';

    return (
        <li
            className={`cursor-pointer btn-action edit response-example-tabs-list-item ${
                isActive ? 'response-example-tabs-list--active' : ''
            }`}
            onClick={handleClick}
            style={{ marginRight: '16px', width: '80px' }}
        >
            <span
                className="response-example-circle"
                style={{ backgroundColor: colorCircle }}
            ></span>
            <h6>{label}</h6>
        </li>
    );
};

export default RouteResponseTabsItem;
