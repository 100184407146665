import { useState } from 'react';

import Modal from 'ui/Modal';

import editIcon from 'assets/img/icon-edit.svg';
import { useSelector } from 'react-redux';
import { billingSelectors } from 'store/slices/billingSlice';
import LocalLoader from 'ui/LocalLoader';
import { capitalize } from 'utils/helpers';
import { ModalCardsList } from './Modals/ModalCardsList';
import { getCardIcon } from './utils/utils';

const PaymentMethod = () => {
    const cards = useSelector(billingSelectors.getAllCards);
    const isLoadingCards = useSelector(billingSelectors.getIsCardsFetching);

    const [isShowModal, setIsShowModal] = useState(false);

    const defaultCard = cards?.find((card) => card.is_default);
    const isCardExpired =
        new Date() >
        new Date(defaultCard?.card?.exp_year, defaultCard?.card?.exp_month - 1);
    const brand = defaultCard?.card?.display_brand
        .split('_')
        .map((el) => capitalize(el))
        .join(' ');

    return (
        <div className="p-4 billing--card-block">
            <div className="d-flex justify-content-between">
                <div>
                    <h3 className="title-h5">Payment method </h3>
                    <p className="billing--paragraph pt-1 pb-4">
                        Change how you pay for your plan
                    </p>
                </div>
                <div
                    className="d-flex align-items-center cursor-pointer align-self-start"
                    onClick={() => setIsShowModal((prev) => !prev)}
                >
                    <img src={editIcon} alt="edit" className="d-block" />
                    <p className="billing--paragraph fs-6 px-2">Edit</p>
                </div>
            </div>
            <LocalLoader loading={isLoadingCards}>
                {cards?.length ? (
                    <div
                        className="billing--payment-card w-100"
                        style={{ marginBottom: '48px' }}
                    >
                        <div className="d-flex justify-content-between align-items-start">
                            <div className="d-flex align-items-start">
                                <div className="mt-1">
                                    <img
                                        src={getCardIcon(
                                            defaultCard?.card?.networks
                                                ?.preferred ||
                                                defaultCard?.card?.brand,
                                        )}
                                        alt="payment"
                                    />
                                </div>
                                <div className="d-flex flex-column px-3">
                                    <h5 className="billing--paragraph billing--paragraph-lineheight opacity-100 fw-semibold">
                                        {cards?.[0]?.card?.brand &&
                                            `${brand} ending in **** ${defaultCard?.card?.last4}`}
                                    </h5>
                                    <p
                                        className="billing--paragraph billing--paragraph-lineheight"
                                        style={{
                                            color: isCardExpired
                                                ? 'red'
                                                : 'initial',
                                        }}
                                    >
                                        {`Expiry ${defaultCard?.card?.exp_month}/${defaultCard?.card?.exp_year}`}
                                        {isCardExpired && ' - Expired'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p className="text-center">No cards added</p>
                )}
            </LocalLoader>
            <Modal
                show={isShowModal}
                customClasses={{
                    modalDialog:
                        'modal-dialog-scrollable modal-dialog--max-height-550',
                }}
                body={<ModalCardsList setIsShowModal={setIsShowModal} />}
            />
        </div>
    );
};

export default PaymentMethod;
