import { NavLink, useParams } from 'react-router-dom';
import { countRouteMethod } from 'utils/helpers';

const RoutesItem = ({ route }) => {
    const params = useParams();
    const basePath = `/docs/${params?.apiCode}`;
    return (
        <li key={route.id} className="nav-item sidebar-link-item mx-2">
            <NavLink
                className={
                    'nav-link sidebar-link sidebar-link-route word-break'
                }
                activeClassName="sidebar-link-active nav-link sidebar-link sidebar-link-route word-break"
                exact
                style={{ paddingTop: '7px', paddingBottom: '7px' }}
                to={`${basePath}/route/${route.id}`}
            >
                {countRouteMethod(route.method)}
                <span className="me-1">{route.name ?? route.url}</span>
            </NavLink>
        </li>
    );
};

export default RoutesItem;
