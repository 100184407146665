import { Input } from 'ui/Inputs';
import { getCardIcon } from 'pages/Billing/ManageBilling/utils/utils';
import { AddPaymentCard } from 'pages/Billing/ManageBilling/AddPaymentCard';
import plusIcon from 'assets/img/plus.svg';
import { capitalize } from 'utils/helpers';
import { Trash2 } from 'react-feather';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { billingSelectors } from 'store/slices/billingSlice';

export const CardList = ({
    selectedCardId,
    handleCardElementChange,
    handleChangeCard,
    handleChangeCardName,
    handleShowNewCard,
    showNewCard,
    newCardIdInput,
    cardName,
    error,
    handleDeleteCard,
    isCardDeleting,
}) => {
    const [selectedCard, setSelectedCard] = useState(null);
    const cardsLoading = useSelector(billingSelectors.getIsCardsFetching);
    const cards = useSelector(billingSelectors.getAllCards);

    return (
        <div>
            <p className="billing--paragraph">Saved cards</p>
            {cards?.map((cardObject) => {
                const isCardExpired =
                    new Date() >
                    new Date(
                        cardObject?.card?.exp_year,
                        cardObject?.card?.exp_month - 1,
                    );
                const brand = cardObject?.card?.display_brand
                    .split('_')
                    .map((el) => capitalize(el))
                    .join(' ');

                return (
                    <div
                        key={cardObject?.id}
                        className="billing--modal-card d-flex align-items-center"
                    >
                        <Input
                            className="form-check-input"
                            type="radio"
                            id={cardObject?.id}
                            checked={selectedCardId === cardObject?.id}
                            onChange={(e) => handleChangeCard(cardObject?.id)}
                        />
                        <label
                            className="form-check-label ms-3 d-flex align-items-center justify-content-between w-100"
                            htmlFor={cardObject?.id}
                        >
                            <div className="d-flex align-items-start">
                                <div className="mt-1">
                                    <img
                                        src={getCardIcon(
                                            cardObject?.card?.networks
                                                ?.preferred ||
                                                cardObject?.card?.brand,
                                        )}
                                        alt={getCardIcon(
                                            cardObject?.card?.display_brand,
                                        )}
                                        width={40}
                                        height={40}
                                    />
                                </div>
                                <div className="d-flex flex-column px-3">
                                    <h5 className="billing--paragraph opacity-100 fw-semibold">
                                        {brand} ending in ****{' '}
                                        {cardObject?.card.last4}
                                    </h5>
                                    <p
                                        className="billing--paragraph"
                                        style={{
                                            color: isCardExpired
                                                ? 'red'
                                                : 'initial',
                                        }}
                                    >
                                        {`Expiry ${cardObject?.card.exp_month}/${cardObject?.card.exp_year}`}
                                        {isCardExpired && ' - Expired'}
                                    </p>
                                </div>
                            </div>
                        </label>
                        {!cardObject?.is_default &&
                            ((isCardDeleting || cardsLoading) &&
                            selectedCard === cardObject?.id ? (
                                <div
                                    className="spinner-border spinner-border-sm text-primary"
                                    role="status"
                                >
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="text-danger fw-bold d-flex align-items-center bg-white"
                                    onClick={() => {
                                        setSelectedCard(cardObject?.id);
                                        handleDeleteCard(cardObject?.id);
                                    }}
                                >
                                    <Trash2 color="#dc3545" size={18} />
                                </button>
                            ))}
                    </div>
                );
            })}
            {!showNewCard ? (
                <div
                    className="billing--modal-card justify-content-center align-items-center d-flex cursor-pointer"
                    onClick={() => handleShowNewCard()}
                >
                    <div className="d-flex">
                        <img src={plusIcon} alt="Add new card" />
                        <p
                            className="billing--paragraph opacity-100 fw-semibold ms-2"
                            style={{ color: '#0d6efd' }}
                        >
                            Add new payment card
                        </p>
                    </div>
                </div>
            ) : (
                <div className="billing--modal-card d-flex align-items-center billing--new-card">
                    <Input
                        className="form-check-input"
                        type="radio"
                        name="paymentMethod"
                        id={newCardIdInput}
                        checked={selectedCardId === newCardIdInput}
                        onChange={() => handleChangeCard(newCardIdInput)}
                    />
                    <AddPaymentCard
                        cardName={cardName}
                        handleCardElementChange={handleCardElementChange}
                        handleChangeCardName={handleChangeCardName}
                        error={error}
                    />
                </div>
            )}
        </div>
    );
};
