import React, { useState } from 'react';
import { Input } from 'ui/Inputs';
import closeIcon from 'assets/img/x.svg';
import {
    authSelectors,
    sendMessageWithContactForm,
} from 'store/slices/authSlice';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

export default function ContactUsModal({ onClose }) {
    const [data, setData] = useState({ name: '', email: '', message: '' });
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const contactMessageStatus = useSelector(
        authSelectors.getSendMessageWithContactFormLoadingStatus,
    );

    const resetState = () => {
        setData({ name: '', email: '', message: '' });
    };

    const onInputChange = (e) => {
        const { name, value } = e.target;

        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        dispatch(sendMessageWithContactForm({ data, onSuccess, onError }));
    };

    const onSuccess = (response) => {
        onClose();
        toast.success(
            'Thank you for your request. We will answer as soon as possible.',
        );
        resetState();
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    return (
        <div className="modal-body">
            <div>
                <div className="modal-header border-bottom-0 align-items p-0 mb-3">
                    <h4 className="modal-title">Send us a message</h4>
                    <div
                        onClick={(e) => {
                            onClose(e);
                            resetState();
                        }}
                        className="cursor-pointer"
                    >
                        <img src={closeIcon} alt="close" />
                    </div>
                </div>
                <div className="form-group welcome-common-form__form-group">
                    <Input
                        type="text"
                        name="name"
                        className="form-control welcome-common-form__control"
                        value={data?.name}
                        onChange={onInputChange}
                        labelClassName="welcome-common-form__label"
                        labelText="Name"
                        placeHolder="Enter your name"
                        errorText={errors?.name}
                    />
                </div>
                <div className="form-group welcome-common-form__form-group">
                    <Input
                        type="email"
                        name="email"
                        className="form-control welcome-common-form__control "
                        value={data?.email}
                        onChange={onInputChange}
                        labelClassName="welcome-common-form__label"
                        labelText="Email address"
                        placeHolder="Enter your email"
                        errorText={errors?.email}
                    />
                </div>
                <div className="form-group welcome-common-form__form-group">
                    <Input
                        type="textarea"
                        name="message"
                        className="form-control welcome-common-form__control "
                        value={data?.message}
                        onChange={onInputChange}
                        labelClassName="welcome-common-form__label"
                        labelText="Message"
                        placeHolder="Enter your message"
                        errorText={errors?.message}
                        rows="5"
                    />
                </div>
            </div>
            <button
                type="button"
                className="btn btn-primary w-100 mt-4"
                onClick={handleSubmit}
                disabled={contactMessageStatus === 'loading'}
            >
                Send message
                {contactMessageStatus === 'loading' && (
                    <span
                        class="spinner-border spinner-border-sm ms-2"
                        aria-hidden="true"
                    ></span>
                )}
            </button>
        </div>
    );
}
