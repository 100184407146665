import React, { useState } from 'react';
import { Edit3, Trash2 } from 'react-feather';
import AddEnvVariablesForm from './AddEnvVariablesForm';
import EnvVariablesView from './EnvVariablesView';

import { useDispatch, useSelector } from 'react-redux';
import { apiSelectors } from 'store/slices/apiSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import { updateEnvironment } from 'store/slices/environmentsSlice';

const EnvironmentModal = ({ onClose, selectedEnvironment, onDelete }) => {
    const dispatch = useDispatch();
    const api = useSelector(apiSelectors.getCurrentApi);
    const project = useSelector(projectSelectors.getCurrentProject);

    const [view, setView] = useState('view');

    const updateEnvironmentHandle = (data, envId, onSuccess, onError) => {
        dispatch(updateEnvironment({ data, envId, onSuccess, onError }));
    };

    const onSetView = (view) => {
        setView(view);
    };

    return (
        <div className="modal-body">
            {view === 'view' && (
                <>
                    <div className="form-group d-flex justify-content-between align-items-center">
                        <p className="h5">View environment</p>
                        <div className="d-flex ">
                            <button
                                onClick={() => setView('edit')}
                                className="btn-action me-2 edit ms-auto hover-default"
                            >
                                <Edit3 size={13} color="#001A37" />
                            </button>
                            <button
                                onClick={onDelete}
                                className="btn-action remove hover-remove"
                            >
                                <Trash2 size={13} color="#FC4D70" />
                            </button>
                        </div>
                    </div>
                    <p className="h5 mt-4">{selectedEnvironment?.label}</p>
                </>
            )}
            {view === 'edit' && <p className="h5 mb-4">Edit environment</p>}
            <div className="pb-3 mt-3">
                {view === 'view' ? (
                    <EnvVariablesView
                        items={selectedEnvironment?.item?.variables}
                        onClose={onClose}
                    />
                ) : (
                    <AddEnvVariablesForm
                        api={api}
                        project={project}
                        onSubmit={updateEnvironmentHandle}
                        setView={onSetView}
                        envId={selectedEnvironment?.item?.id}
                        items={selectedEnvironment?.item?.variables}
                        title={selectedEnvironment?.label}
                    />
                )}
            </div>
        </div>
    );
};

export default EnvironmentModal;
