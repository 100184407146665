import { hasCombiner, isEmptyObject } from '../utils';

export class BaseNode {
    constructor(fragment) {
        this.fragment = fragment;
        this.refName = null;
        this.refPath = null;
        this.refSchema = null;
    }

    defineType() {
        if (hasCombiner(this.fragment)) {
            const combiner = this.defineCombiner(this.fragment);
            return [combiner];
        }

        if (!this.fragment.type && !this.fragment.$ref) return ['string'];

        if (this.fragment.type) {
            if (this.fragment.enum) {
                return ['string'];
            } else {
                return Array.isArray(this.fragment.type)
                    ? this.fragment.type
                    : [this.fragment.type];
            }
        }

        if (this.fragment.$ref) return '$ref';
    }

    defineSubType() {
        const type = this.defineType();

        if (type?.[0] !== 'array' || !this.fragment.items) return null;

        const items = Object.keys(this.fragment.items);

        if (!items.length) return null;

        if (this.fragment.items.type) {
            const isArrayOfTypes = Array.isArray(this.fragment.items.type);
            return isArrayOfTypes
                ? this.fragment.items.type
                : [this.fragment.items.type];
        }

        if (this.fragment.items.$ref) return '$ref';
    }

    defineBehaviour() {
        const behaviourData = {
            read: !!this.fragment?.readOnly,
            write: !!this.fragment?.writeOnly,
            'read/write': !this.fragment?.readOnly && !this.fragment?.writeOnly,
        };
        const resultBehaviour = Object.entries(behaviourData).find(
            (el) => !!el[1],
        );
        return resultBehaviour[0];
    }

    defineIsNullable() {
        return this.type.includes('null');
    }

    generateId() {
        const createRandomString = () => Math.random().toString(16).slice(2);
        return `${createRandomString()}-${createRandomString()}`;
    }

    setupChildren(fragment) {
        const hasProperties =
            fragment?.properties && !isEmptyObject(fragment?.properties);
        const hasItemsProperties =
            fragment?.items?.properties &&
            !isEmptyObject(fragment?.items?.properties);
        const combiner = this.defineCombiner(fragment);
        const isCombinerChildren = fragment[combiner];

        return hasProperties
            ? fragment?.properties
            : hasItemsProperties
              ? fragment?.items?.properties
              : isCombinerChildren?.length
                ? isCombinerChildren
                : null;
    }

    defineEnum() {
        return this.fragment.enum || null;
    }

    defineExtraProps() {
        const extraPropsList = [
            'format',
            'default',
            'example',
            'pattern',
            'minLength',
            'maxLength',
            'deprecated',
        ];
        const extraProps = {};

        for (const prop of Object.keys(this.fragment)) {
            if (extraPropsList.includes(prop)) {
                extraProps[prop] = this.fragment[prop];
            }
        }
        return extraProps;
    }

    defineCombiner(fragment) {
        const combinersList = ['oneOf', 'allOf', 'anyOf'];

        for (const key in fragment) {
            if (combinersList.includes(key)) return key;
        }
    }

    get data() {
        const {
            id,
            name,
            parent,
            type,
            subType,
            description,
            children = null,
            refPath,
            refName,
            fragment,
            isRequired,
            behaviour,
            isNullable,
            detached,
            refSchema,
            depth,
            enumValue,
            extraProps,
            isCombinerChild,
        } = this;
        return {
            id,
            name,
            parent,
            type,
            subType,
            description,
            refPath,
            refName,
            metadata: {
                fragment,
                children,
                refSchema,
            },
            isRequired,
            behaviour,
            isNullable,
            detached,
            depth,
            enumValue,
            extraProps,
            isCombinerChild,
        };
    }
}
