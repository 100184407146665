import axios from 'axios';
import { deleteTokens, isLoggedIn } from './auth';
import { toast } from 'react-toastify';
import { getCookie } from './cookie';

const baseURL = process.env.REACT_APP_API_URL;
// const baseURL = "http://api.cdprojects.local";

export const CancelToken = axios.CancelToken;

const http = axios.create({
    baseURL: baseURL,
});

http.defaults.headers = {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
};

if (isLoggedIn()) {
    const access_token = getCookie('access_token');
    http.defaults.headers['Authorization'] = `Bearer ${access_token}`;
}

const responseHandler = (response) => {
    // Handle responses
    return response;
};

const errorHandler = (error) => {
    switch (error.response.status) {
        case 401:
            deleteTokens();
            window.location.pathname = '/login';
            break;
        case 403:
            toast.error(error.response.data.message);
            break;
        case 404:
            toast.error(
                'The data you are trying to reach is temporary unavailable',
            );
            break;
        case 500:
            toast.error(error.response.data.message);
            break;
        default:
            break;
    }
    return Promise.reject({ ...error });
};

http.interceptors.request.use((request) => {
    if (process.env.NODE_ENV === 'development') {
        const hostElements = window.location.hostname.split('.');
        if (hostElements?.length > 1) {
            request.headers.domain = hostElements[0];
            return request;
        }
    } else {
        const domain = process.env.REACT_APP_DOMAIN;
        const hostname = window.location.hostname;
        const subdomain = hostname.replace(domain, '');

        if (subdomain) {
            request.headers.domain = subdomain.slice(0, -1);
            return request;
        }
    }
    return request;
});

http.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error),
);

export default http;
