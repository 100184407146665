import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';
import LocalLoader from 'ui/LocalLoader';
import Modal from 'ui/Modal';
import CommentsListItem from './CommentsListItem';
import { routeSelectors } from 'store/selectors';
import {
    commentSelectors,
    deleteComment,
    getComments,
    resetComments,
    resetCommentsStart,
    setCommentsStart,
} from 'store/slices/commentsSlice';

const CommentsList = ({ type, hasFocus, isAccessToCreateComment }) => {
    const dispatch = useDispatch();
    const route = useSelector(routeSelectors.getCurrentRoute);
    const start = useSelector(commentSelectors.getCommentsStart);
    // const page = useSelector((state) => state.pageReducer.page);
    const comments = useSelector(commentSelectors.getComments);
    const commentsFetching = useSelector(
        commentSelectors.getIsCommentsFetching,
    );
    const [isDeleteCommentModalShown, setIsDeleteCommentModalShown] =
        useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [selectedDropdown, setSelectedDropdown] = useState(null);

    useEffect(() => {
        if (start > 0 && isAccessToCreateComment) {
            type === 'route'
                ? dispatch(getComments({ type, typeId: `${route.id}`, start }))
                : dispatch(
                      getComments({
                          type,
                          //   `${page.id}`,
                          start,
                      }),
                  );
        }
    }, [dispatch, route.id, type, start, isAccessToCreateComment]);

    useEffect(() => {
        const handleScroll = (e) => {
            const scrollHeight = e.target.documentElement.scrollHeight;
            const currentHeight =
                e.target.documentElement.scrollTop + window.innerHeight;

            if (
                currentHeight + 1 >= scrollHeight &&
                comments.length === start + 20
            ) {
                dispatch(setCommentsStart(start + 20));
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [comments.length, dispatch, start]);

    useEffect(() => {
        return () => {
            dispatch(resetComments());
            dispatch(resetCommentsStart());
        };
    }, [dispatch]);

    const toggleDropdown = (e, id) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (id) {
            setSelectedDropdown(id);
        } else {
            setSelectedDropdown(null);
        }
    };

    const handleClickAction = (e, data, action) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        switch (action) {
            case 'update-comment':
                setSelectedDropdown(null);
                break;

            case 'delete-comment':
                setIsDeleteCommentModalShown(!isDeleteCommentModalShown);
                setSelectedData(data);
                break;

            default:
                break;
        }
    };

    const onDeleteComment = () => {
        if (selectedData) {
            dispatch(
                deleteComment({
                    commentId: selectedData.id,
                    onSuccess: onDeleteCommentSuccess,
                }),
            );
        }
    };

    const onDeleteCommentSuccess = () => {
        setIsDeleteCommentModalShown(false);
        toast.success('Comment successfully deleted');
        dispatch(resetComments());
        dispatch(resetCommentsStart());
        dispatch(getComments({ type, typeId: route.id, start: 0 }));
        handleClickAction(undefined, null, 'delete-comment');
    };

    return (
        <>
            <LocalLoader loading={commentsFetching}>
                <ul className="pt-4">
                    {comments.length > 0
                        ? comments?.map((comment) => (
                              <CommentsListItem
                                  type={type}
                                  key={comment.id}
                                  comment={comment}
                                  selectedDropdown={selectedDropdown}
                                  toggleDropdown={toggleDropdown}
                                  handleClickAction={handleClickAction}
                              />
                          ))
                        : !hasFocus &&
                          isAccessToCreateComment && (
                              <p className="m-auto w-100 text-center fs-6">
                                  No comments found
                              </p>
                          )}
                </ul>
                <Modal
                    show={isDeleteCommentModalShown}
                    title="Delete"
                    body={<p>This comment will be deleted, are you sure?</p>}
                    footer={
                        <Fragment>
                            <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={onDeleteComment}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                onClick={(e) =>
                                    handleClickAction(e, null, 'delete-comment')
                                }
                                className="btn btn-link"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </Fragment>
                    }
                />
            </LocalLoader>
        </>
    );
};

CommentsList.propTypes = {
    type: PropTypes.string.isRequired,
};

export default CommentsList;
