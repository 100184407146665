import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

import WelcomeHeader from 'layouts/welcome/WelcomeHeader';
import { Input } from 'ui/Inputs';
import googleLogo from 'assets/img/google_logo.svg';
import { saveTokens } from 'utils/auth';
import { getProviderUrl, signUp } from 'store/slices/authSlice';

const SignupPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(signUp({ data, onSuccess, onError }));
    };

    const handleClick = (provider, event) => {
        event.preventDefault();
        const { data } = {
            data: {
                provider: provider,
            },
            errors: {},
        };

        dispatch(
            getProviderUrl({ data, onSuccess: onSuccessRedirect, onError }),
        );
    };

    const onSuccessRedirect = (response) => {
        window.location.href = response.data.url;
    };

    const onSuccess = (response) => {
        saveTokens(response.data);
        history.push('/projects');
    };

    const onError = (errors) => {
        setErrors(errors.response.data.errors);
    };

    return (
        <>
            <Helmet>
                <title>
                    Signup - CDProjects - Create beautiful REST API
                    Documentations
                </title>
            </Helmet>
            <section className="welcome-common-form">
                <WelcomeHeader />
                <div className="container welcome-common-form__container">
                    <div className="welcome-common-form__content">
                        <div className="welcome-common-form__form">
                            <h1 className="welcome-common-form__title">
                                Sign up to{' '}
                                <span className="welcome-common-form__title welcome-common-form__title--color">
                                    CDProjects
                                </span>
                            </h1>
                            <div
                                className="btn-group welcome-common-form__google"
                                role="group"
                                aria-label="Second group"
                            >
                                <button
                                    onClick={(e) => handleClick('GOOGLE', e)}
                                    type="button"
                                    className="btn welcome-common-form__with-google d-block"
                                >
                                    <img
                                        src={googleLogo}
                                        alt="google"
                                        className="welcome-common-form__google-image"
                                    />
                                    Sign Up with Google
                                </button>
                            </div>
                            <div className="welcome-common-form__divider">
                                OR
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group welcome-common-form__form-group">
                                    <Input
                                        type="text"
                                        name="first_name"
                                        className="form-control welcome-common-form__control"
                                        value={data.first_name}
                                        onChange={handleChange}
                                        labelText="First name"
                                        labelClassName="welcome-common-form__label"
                                        placeHolder="Enter your first name"
                                        errorText={errors.first_name}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="form-group welcome-common-form__form-group">
                                    <Input
                                        type="text"
                                        name="last_name"
                                        className="form-control welcome-common-form__control"
                                        value={data.last_name}
                                        onChange={handleChange}
                                        labelText="Last name"
                                        labelClassName="welcome-common-form__label"
                                        placeHolder="Enter your last name"
                                        errorText={errors.last_name}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="form-group welcome-common-form__form-group">
                                    <Input
                                        type="email"
                                        name="email"
                                        className="form-control welcome-common-form__control"
                                        value={data.email}
                                        onChange={handleChange}
                                        labelClassName="welcome-common-form__label"
                                        labelText="Email address"
                                        placeHolder="Enter your email address"
                                        errorText={errors.email}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="form-group welcome-common-form__form-group">
                                    <Input
                                        type="password"
                                        name="password"
                                        className="form-control welcome-common-form__control"
                                        value={data.password}
                                        onChange={handleChange}
                                        labelClassName="welcome-common-form__label"
                                        labelText="Password"
                                        placeHolder="Enter your password"
                                        errorText={errors.password}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="orm-group welcome-common-form__form-group p-0">
                                    <Input
                                        type="password"
                                        name="password_confirmation"
                                        className="form-control welcome-common-form__control"
                                        value={data.password_confirmation}
                                        onChange={handleChange}
                                        labelClassName="welcome-common-form__label"
                                        labelText="Confirm password"
                                        placeHolder="Confirm your password"
                                        errorText={errors.password_confirmation}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="btn-toolbar" role="toolbar">
                                    <div
                                        className="btn-group welcome-common-form__toolbar"
                                        role="group"
                                        aria-label="First group"
                                    >
                                        <button
                                            className="btn btn-primary welcome-common-form__button"
                                            type="submit"
                                        >
                                            Sign Up
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className="welcome-common-form__footer">
                                <p className="opacity-75">
                                    Already have account?
                                </p>
                                <a
                                    href="/login"
                                    className="welcome-common-form__footer-link"
                                >
                                    Log In
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="welcome-common-form__bg" />
                </div>
            </section>
        </>
    );
};

export default SignupPage;
