import React from 'react';

export default function RouteGlobalHeadersItem({ item }) {
    return (
        <tr
            style={{
                fontSize: '14px',
                lineHeight: '16px',
                color: '#001a37',
                borderTop: '1px solid black !important',
                wordBreak: 'break-word',
            }}
        >
            <td>{item.name}</td>
            <td>{item.value}</td>
            <td>{item.description}</td>
            <td></td>
        </tr>
    );
}
