import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import WelcomeHeader from 'layouts/welcome/WelcomeHeader';
import { Input } from 'ui/Inputs';
import { sendResetPasswordEmail } from 'store/slices/authSlice';

const ForgotPasswordPage = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState({ email: '' });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(sendResetPasswordEmail({ data, onSuccess, onError }));
    };

    const onSuccess = () => {
        setData({ ...data, email: '' });
        toast.success(toastifyTextStyle);
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    const toastifyTextStyle = () => {
        return (
            <>
                <p className="toastify-forgot-password">Check your email</p>
                <p className="toastify-forgot-password-description">
                    We have sent you a reset password link on your registered
                    email address
                </p>
            </>
        );
    };
    return (
        <section className="welcome-common-form">
            <WelcomeHeader />
            <div className="container welcome-common-form__container">
                <div className="welcome-common-form__content">
                    <div className="welcome-common-form__form">
                        <h1 className="welcome-common-form__title">
                            Reset your password
                        </h1>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group welcome-common-form__form-group welcome-common-form__form-group--padding-top pb-0">
                                <Input
                                    type="text"
                                    name="email"
                                    className="form-control welcome-common-form__control"
                                    value={data.email}
                                    onChange={handleChange}
                                    labelClassName="welcome-common-form__label"
                                    labelText="Email"
                                    placeHolder="Enter your email"
                                    errorText={errors.email}
                                />
                            </div>
                            <div className="welcome-common-form__toolbar">
                                <button
                                    className="btn btn-primary welcome-common-form__button"
                                    type="submit"
                                >
                                    Reset
                                </button>
                            </div>
                        </form>
                        <div className="welcome-common-form__footer">
                            <p className="opacity-75">
                                Do you remember your password after all?
                            </p>
                            <a
                                href="/login"
                                className="welcome-common-form__footer-link"
                            >
                                Log in
                            </a>
                        </div>
                    </div>
                </div>
                <div className="welcome-common-form__bg" />
            </div>
        </section>
    );
};

export default ForgotPasswordPage;
