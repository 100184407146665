import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import { apiSelectors } from 'store/slices/apiSlice';
import { environmentSelectors } from 'store/slices/environmentsSlice';
import { routeSelectors } from 'store/slices/routesSlice';
import { Input } from 'ui/Inputs';
import {
    combineClasses as cc,
    createShallowCopy,
    generateId,
    replaceEnvVariable,
} from 'utils/helpers';
import { Play } from 'react-feather';
import { Collapse } from 'react-collapse';
import LocalLoader from 'ui/LocalLoader';
import ResponseView from './ResponseView';
import { Builder } from 'components/JsonSchemaEditor/builder/builder';
import { defineSchema, isCombiner } from 'components/JsonSchemaEditor/helpers';
import _ from 'lodash';
import Headers from './Headers/Headers';
import { resourceSelectors } from 'store/slices/resourcesSlice';
import Editor from '@monaco-editor/react';

const methodsWithBody = ['POST', 'PUT', 'PATCH'];

const literalsMap = {
    string: 'string',
    number: '1.0',
    integer: 1,
    boolean: true,
    object: {},
    array: [],
    null: null,
};

export default function EndpointRunner({ onClose }) {
    const route = useSelector(routeSelectors.getCurrentRoute);
    const selectedEnvironments = useSelector(
        environmentSelectors.getSelectedEnvironments,
    );
    const api = useSelector(apiSelectors.getCurrentApi);
    const resources = useSelector(resourceSelectors.getResources);

    const scrollPoint = useRef(null);
    const abortRef = useRef();
    const modalBody = useRef();
    const editorRef = useRef();

    const selectedEnvironment = selectedEnvironments.find(
        (el) => el.item.api_id === api.id,
    );
    const models = resources?.map((el) => JSON.parse(el.schema));
    const build = useMemo(
        () =>
            new Builder(defineSchema(route.schema, null), null, {
                models,
            }),
        [route.schema, models],
    );

    const [data, setData] = useState({
        headers: [],
        method: '',
        url: '',
        body: null,
    });
    const [selectedHeaders, setSelectedHeaders] = useState([]);
    const [response, setResponse] = useState(null);
    const [responseStatus, setResponseStatus] = useState(null);
    const [buildedSchema, setbuildedSchema] = useState(() => {
        build.build();
        return dataToJson(build.dataArray);
    });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const setEnvVariables = useCallback(
        (arr) => {
            return (
                arr?.map((el) => {
                    const newEl = createShallowCopy(el);
                    newEl.value = replaceEnvVariable(
                        el?.value,
                        selectedEnvironment?.item?.variables,
                    );
                    return newEl;
                }) || []
            );
        },
        [selectedEnvironment?.item?.variables],
    );

    const addUid = (arr) => {
        return createShallowCopy(
            arr.map((el) => {
                el.uid = generateId();
                return el;
            }),
        );
    };

    useLayoutEffect(() => {
        const headers = addUid([
            ...setEnvVariables(route?.global_headers),
            ...setEnvVariables(route?.headers),
        ]);
        setData({
            headers,
            method: route?.method || '',
            url:
                replaceEnvVariable(
                    route?.url,
                    selectedEnvironment?.item?.variables,
                ) || '',
        });

        setSelectedHeaders(headers.map((el) => el.uid));
    }, [
        route?.global_headers,
        route?.headers,
        route?.method,
        route?.schema,
        route?.url,
        selectedEnvironment?.item?.variables,
        setEnvVariables,
    ]);

    const toggleIsLoading = () => {
        setIsLoading((prev) => !prev);
    };

    const onInputChange = (e) => {
        const newData = createShallowCopy(data);
        newData.url = e.target.value;
        setData(newData);
    };

    const scrollToBottom = () => {
        scrollPoint.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [response, error]);

    const addParameter = () => {
        const newData = createShallowCopy(data);
        const uid = generateId();
        newData.headers.push({
            uid,
            name: '',
            value: '',
        });

        setData(newData);
        setSelectedHeaders((prev) => [...prev, uid]);
    };

    const changeParameter = (e, index) => {
        const { name, value } = e.target;
        const newData = createShallowCopy(data);

        newData.headers[index][name] = value;

        setData(newData);
    };

    const deleteParameterRow = (index) => {
        const newData = createShallowCopy(data);

        newData.headers = newData.headers.toSpliced(index, 1);

        setData(newData);
    };

    const routeHeaders = createShallowCopy(
        data?.headers?.filter(
            (el) => el.apply_to_request || el.apply_to_request === undefined,
        ) || [],
    );

    const clearResponse = () => {
        setResponse(null);
        setError(null);
    };

    const sendCall = async () => {
        abortRef.current = new AbortController();
        const signal = abortRef.current.signal;
        toggleIsLoading();

        const headers = data.headers.reduce((acc, el) => {
            if (el.name && el.value && selectedHeaders.includes(el.uid))
                acc[el.name] = el.value;
            return acc;
        }, {});

        try {
            const response = await fetch(data.url, {
                method: data.method,
                headers,
                ...(methodsWithBody?.includes(data?.method) && {
                    body: buildedSchema,
                }),
                signal: signal,
            });

            if (!signal.aborted) {
                let result;
                const contentType = response.headers
                    .get('content-type')
                    .split(';')[0];

                if (contentType === 'text/html') result = await response.text();
                if (contentType === 'application/json')
                    result = await response.json();

                setError(null);
                setResponse({
                    contentType,
                    result,
                });
                setResponseStatus(response?.status);
                scrollPoint?.current?.scrollIntoView({ behavior: 'smooth' });
            }
        } catch (error) {
            const err = new Error(error);
            if (err.message.includes('AbortError')) {
                return;
            }
            setResponse(null);
            setError({ result: err.message });
        } finally {
            toggleIsLoading();
        }
    };

    const selectHeaders = (id) => {
        if (selectedHeaders.includes(id)) {
            setSelectedHeaders((prev) => prev.filter((el) => el !== id));
        } else {
            setSelectedHeaders((prev) => [...prev, id]);
        }
    };

    const abortCall = () => {
        if (abortRef.current) abortRef.current.abort();
    };

    function dataToJson(data) {
        const grouped = _.groupBy(data, (item) =>
            item?.parent ? item?.parent?.id : item.parent,
        );

        const childrenOf = (parent) => {
            return (grouped[parent] || []).reduce((acc, val) => {
                const type = Array.isArray(val.type) ? val.type[0] : val.type;
                const subType = Array.isArray(val.subType)
                    ? val.subType[0]
                    : val.subType;
                const isDetachedRootNode = val.detached && val.name === 'root';
                const isCombinerType = isCombiner(type);

                if (isDetachedRootNode && type === 'object') {
                    return childrenOf(val.id);
                }
                if (
                    isDetachedRootNode &&
                    type === 'array' &&
                    ['object', '$ref'].includes(subType)
                ) {
                    return [childrenOf(val.id)];
                }

                const field =
                    type === 'array' && subType === 'object'
                        ? [childrenOf(val.id)]
                        : type === 'object' || type === '$ref'
                          ? childrenOf(val.id)
                          : isCombinerType
                            ? [childrenOf(val.id)].flatMap(Object.values)
                            : type === 'array' && subType === '$ref'
                              ? [childrenOf(val.id)]
                              : type === 'array'
                                ? subType
                                    ? [literalsMap[subType]]
                                    : []
                                : val?.extraProps?.example || literalsMap[type];

                return {
                    ...acc,
                    ...(val.isCombinerChild
                        ? { [val.id]: field }
                        : { [val.name]: field }),
                };
            }, {});
        };

        return JSON.stringify(Object.values(childrenOf(null))[0], null, 2);
    }

    const close = () => {
        setData({
            headers: [
                ...setEnvVariables(route?.global_headers),
                ...setEnvVariables(route?.headers),
            ],
            method: route?.method,
            schema: route?.schema,
            url: replaceEnvVariable(
                route?.url,
                selectedEnvironment?.item?.variables,
            ),
        });
        setResponse(null);
        setError(null);
        setbuildedSchema(() => {
            build.build();
            return dataToJson(build.dataArray);
        });
        onClose();
    };

    const onEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;
    };

    const onFormatCode = () => {
        editorRef.current.getAction('editor.action.formatDocument').run();
    };

    return (
        <>
            <div ref={modalBody} className="modal-body">
                <div>
                    <div className="col-8">
                        <div className="form-group ms-2 mb-3">
                            <Input
                                type="text"
                                name="url"
                                id="url"
                                className="form-control"
                                value={data?.url}
                                onChange={onInputChange}
                                labelText="URL"
                                labelClassName="form-label"
                            />
                        </div>
                    </div>
                    <div>
                        <div
                            className="d-flex justify-content-between align-items-center ps-3 pe-2 mb-2"
                            style={{
                                borderBottom: '2px solid #edeff3',
                            }}
                        >
                            <div className="d-flex align-items-center">
                                <h6>Headers</h6>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-light mb-1 mt-1 text-start"
                                    onClick={addParameter}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                        <Collapse isOpened={true}>
                            <Headers
                                headers={routeHeaders}
                                changeParam={(e, index) =>
                                    changeParameter(e, index)
                                }
                                deleteRow={deleteParameterRow}
                                selectRow={selectHeaders}
                                selectedHeaders={selectedHeaders}
                            />
                        </Collapse>
                        {methodsWithBody?.includes(data?.method) && (
                            <>
                                <div
                                    className="d-flex justify-content-between align-items-center ps-3 pe-2 mb-2"
                                    style={{
                                        borderBottom: '2px solid #edeff3',
                                        height: '47px',
                                    }}
                                >
                                    <h6>Body</h6>
                                    <button
                                        type="button"
                                        onClick={onFormatCode}
                                        className="btn btn-light"
                                    >
                                        Beautify
                                    </button>
                                </div>
                                <div className="mb-4 position-relative p-1">
                                    <Editor
                                        height="214px"
                                        defaultLanguage="json"
                                        defaultValue={buildedSchema}
                                        onChange={setbuildedSchema}
                                        onMount={onEditorDidMount}
                                        options={{
                                            scrollbar: {
                                                alwaysConsumeMouseWheel: false,
                                            },
                                            minimap: { enabled: false },
                                            readOnlyMessage: { value: null },
                                            wordWrap: 'on',
                                            scrollBeyondLastLine: false,
                                            contextmenu: false,
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        <LocalLoader loading={isLoading}>
                            {response && (
                                <ResponseView
                                    status="ok"
                                    data={response}
                                    clearResponse={clearResponse}
                                    responseStatus={responseStatus}
                                />
                            )}
                            {error && (
                                <ResponseView
                                    status="error"
                                    data={error}
                                    clearResponse={clearResponse}
                                />
                            )}
                        </LocalLoader>
                    </div>
                </div>
                <div ref={scrollPoint}></div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={isLoading ? abortCall : sendCall}
                    className={cc('btn d-flex align-items-center', {
                        'btn-primary': !isLoading,
                        'btn-danger': isLoading,
                    })}
                >
                    {isLoading ? (
                        'Cancel'
                    ) : (
                        <>
                            <Play size={16} className="me-2" />
                            Run
                        </>
                    )}
                </button>
                <button
                    type="button"
                    onClick={() => {
                        abortCall();
                        close();
                    }}
                    className="btn btn-link"
                >
                    Close
                </button>
            </div>
        </>
    );
}
