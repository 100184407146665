import React from 'react';
import { UserAvatar } from 'components/Avatar';
import { Users } from 'react-feather';

const MembersList = (props) => {
    return (
        <>
            {props?.members?.length ? (
                <>
                    <div className="px-2">
                        <span className="projects-favorite sub-menu p-2 mt-1">
                            Your colleagues
                        </span>
                    </div>
                    <div>
                        <hr className="dropdown-divider header-divider" />
                    </div>
                    <ul className="starred-projects-container overflow-auto">
                        {props?.members?.slice(0, 6).map((member) => {
                            return (
                                <li key={member?.id} className="d-flex">
                                    <a
                                        className="dropdown-item link-dropdown header-project-link py-2 word-break"
                                        href={`/people/${member.uuid}`}
                                    >
                                        <UserAvatar
                                            iconUrl={member.avatar_url}
                                            first_name={member.first_name}
                                            last_name={member.last_name}
                                        />
                                        {`${member.first_name} ${member.last_name}`}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                    <div>
                        <hr className="dropdown-divider header-divider" />
                    </div>
                    <div className="text-center">
                        <a
                            className="dropdown-item link-dropdown"
                            href="/members"
                        >
                            View all members
                        </a>
                    </div>
                </>
            ) : (
                <div className="header-projects-none">
                    <div className="text-center mb-4">
                        <Users
                            size={48}
                            className="header-projects-none-icon"
                            strokeWidth={1}
                        />
                    </div>
                    <p className="text-center header-link-text-none">
                        You haven't invited members yet
                    </p>
                </div>
            )}
        </>
    );
};

export default MembersList;
