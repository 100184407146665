export const appearance = {
    theme: 'stripe',
};

export const options = {
    appearance,
    locale: 'en',
};

export const FREE_PLAN_PRICE = 'Free';

export const cardOptions = {
    style: {
        base: {
            color: '#32325D',
            fontWeight: 400,
            fontFamily: 'Gilroy, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',

            '::placeholder': {
                color: '#CFD7DF',
            },
        },
        invalid: {
            color: '#dc3545',
        },
    },
    hidePostalCode: true,
};

export const freePlan = {
    name: 'Free Plan',
    code: FREE_PLAN_PRICE,
    cost: 0,
    max_users_count: 1,
    max_projects_count: 1,
    max_apis_count: 1,
    is_comments_enabled: false,
    is_sso_enabled: false,
    is_history_enabled: false,
};
