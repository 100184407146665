import React, { useEffect, useState } from 'react';
import { Link2, ChevronRight, Calendar } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-collapse';

import PropTypes from 'prop-types';
import moment from 'moment';
import UserAvatar from 'components/Avatar/UserAvatar';
import LocalLoader from 'ui/LocalLoader';
import { InputSearch } from 'ui/Inputs';
import DatePicker from 'components/DatePicker';
import TableDetails from './TableDetails';
import { countRouteMethod } from 'utils/helpers';
import { apiSelectors } from 'store/slices/apiSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import { fetchHistory, historySelectors } from 'store/slices/historySlice';

export const HistoryItem = ({ history, project, api, isOpened }) => {
    const [isShow, setIsShow] = useState(isOpened || false);

    const countActionStyles = (action) => {
        let style = {
            fontWeight: '600',
            width: '58px',
            fontSize: '10px',
            lineHeight: '12px',
            padding: '6px 12px',
            backgroundColor: '#F6F7F9',
            borderRadius: '4px',
        };

        switch (action) {
            case 'CREATED':
                style = { ...style, color: '#27AE60' };
                return <span style={style}>{action}</span>;
            case 'UPDATED':
                style = { ...style, color: '#F9A620' };
                return <span style={style}>{action}</span>;
            case 'DELETED':
                style = { ...style, color: '#FC4D70' };
                return <span style={style}>{action}</span>;
            case 'REMOVED':
                style = { ...style, color: '#FC4D70' };
                return <span style={style}>{action}</span>;
            case 'RESTORED':
                style = { ...style, color: '#27AE60' };
                return <span style={style}>{action}</span>;
            default:
                style = { ...style, color: '#fed42f' };
                return <span style={style}>UNKNOWN</span>;
        }
    };

    const name = history.after
        ? history.after.name || history.after.url
        : history.before.name || history.before.url;
    const method = history.after
        ? countRouteMethod(history.after.method)
        : countRouteMethod(history.before.method);

    return (
        <>
            <tr
                className="table-main-row cursor-pointer"
                onClick={() => setIsShow(!isShow)}
            >
                <td className="text-center">
                    <ChevronRight
                        size={16}
                        color="#001A37"
                        // className="me-3"
                        style={{ transform: isShow && 'rotate(90deg)' }}
                    />
                </td>
                <td className="word-break w-25">
                    <div className="d-flex align-items-center">
                        {method}
                        {name}
                    </div>
                </td>
                <td>{countActionStyles(history.action)}</td>
                <td>
                    <UserAvatar
                        iconUrl={history.user.avatar_url}
                        first_name={history.user.first_name}
                        last_name={history.user.last_name}
                    />{' '}
                    <a
                        className="table-main-link"
                        href={`/people/${history.user.uuid}`}
                    >
                        {history.user &&
                            `${history.user.first_name} ${history.user.last_name}`}
                    </a>
                </td>
                <td>{history.created_at}</td>
                <td className="text-center">
                    <Link
                        to={`/project/api/history/browse/${history.id}?project=${project.uid}&api=${api.uid}`}
                    >
                        <Link2
                            size={16}
                            color="#007BFF"
                            className="cursor-pointer"
                        />
                    </Link>
                </td>
            </tr>

            <tr>
                <td colSpan="6" className="p-0">
                    <Collapse isOpened={isShow}>
                        <div className="d-flex">
                            {history.before && (
                                <div
                                    style={{
                                        padding: '12px',
                                        flex: !history.after
                                            ? '1 1 100%'
                                            : '1 1 50%',
                                    }}
                                >
                                    <span></span>
                                    <TableDetails
                                        route={history.before}
                                        isAfter={false}
                                    />
                                </div>
                            )}
                            {history.after && (
                                <div
                                    style={{
                                        padding: '12px',
                                        flex: !history.before
                                            ? '1 1 100%'
                                            : '1 1 50%',
                                    }}
                                >
                                    <TableDetails
                                        route={history.after}
                                        isAfter={true}
                                    />
                                </div>
                            )}
                        </div>
                    </Collapse>
                </td>
            </tr>
        </>
    );
};

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

const Table = () => {
    const dispatch = useDispatch();

    const api = useSelector(apiSelectors.getCurrentApi);
    const project = useSelector(projectSelectors.getCurrentProject);
    const history = useSelector(historySelectors.getHistory);
    const isHistoryFetching = useSelector(
        historySelectors.getIsHistoryFetching,
    );

    const [params, setParams] = useState({
        search: '',
        from: moment().add(-30, 'days').format(DATE_FORMAT),
        to: moment().format(DATE_FORMAT),
    });

    useEffect(() => {
        dispatch(fetchHistory({ apiId: api.id, params }));
    }, [api.id, dispatch, params]);

    const handleSearch = (e) => {
        setParams({ ...params, search: e.target.value });
        dispatch(fetchHistory({ apiId: api.id, params }));
    };

    const handleDate = (date, name) => {
        setParams({ ...params, [name]: moment(date).format(DATE_FORMAT) });
        dispatch(fetchHistory({ apiId: api.id, params }));
    };

    return (
        <>
            <div className="projects-menu-container main-container mx-5 mb-3 pb-4">
                <div className="d-flex align-items-center">
                    <div className="input-group input-search-container">
                        <InputSearch
                            id="input-search"
                            type="text"
                            inputStyle={{
                                width: '300px',
                                padding: '0.5rem 0.8rem 0.5rem 2rem',
                            }}
                            className="form-control"
                            value={params.search}
                            onChange={handleSearch}
                        />
                    </div>
                    <div className="ms-3 position-relative">
                        <Calendar size={16} className="input-icon" />
                        <DatePicker
                            value={params.from}
                            onChange={(date) => handleDate(date, 'from')}
                            maxDate={new Date()}
                        />
                    </div>
                    <div className="ms-3 position-relative">
                        <Calendar size={16} className="input-icon" />
                        <DatePicker
                            value={params.to}
                            onChange={(date) => handleDate(date, 'to')}
                            minDate={params.from}
                            maxDate={new Date()}
                        />
                    </div>
                </div>
            </div>
            <LocalLoader loading={isHistoryFetching}>
                <div
                    className="project-list mx-5"
                    style={{ paddingBottom: history.length < 4 && '200px' }}
                >
                    <table className="table table-main projects-table">
                        <thead className="table-main-head">
                            <tr className="projects-table-row">
                                <th className="projects-table-title"></th>
                                <th className="projects-table-title">
                                    Route name
                                </th>
                                <th className="projects-table-title">Action</th>
                                <th className="projects-table-title">
                                    Edit by
                                </th>
                                <th className="projects-table-title">
                                    Date of editing
                                </th>
                                <th className="projects-table-title text-end">
                                    Link
                                </th>
                            </tr>
                        </thead>
                        <tbody className="table-main-body">
                            {history.length > 0 &&
                                history.map((h) => (
                                    <HistoryItem
                                        project={project}
                                        api={api}
                                        history={h}
                                        key={h.id}
                                    />
                                ))}
                            {history.length === 0 && (
                                <tr>
                                    <td colSpan="6" className="text-center">
                                        No history found.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </LocalLoader>
        </>
    );
};

export default Table;

HistoryItem.propTypes = {
    history: PropTypes.object,
};
