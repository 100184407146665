import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { socialLogin } from 'store/slices/authSlice';

import { saveTokens } from 'utils/auth';

const GoogleCallback = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const data = { code: code, provider: 'GOOGLE' };
        const onSuccess = (response) => {
            saveTokens(response.data);
            history.push('/projects');
        };
        const onError = () => history.push('/login');
        dispatch(socialLogin({ data, onSuccess, onError }));
    }, [dispatch, history]);

    return <></>;
};
export default GoogleCallback;
