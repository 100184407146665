import React, { useCallback, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import GlobalLoader from 'ui/GlobalLoader';
import DocsPage from './DocsPage';
import NoAccess from 'components/NoAccess';
import { useDispatch, useSelector } from 'react-redux';
import {
    documentationSelectors,
    getApiDocumentation,
} from 'store/slices/documentationSlice';
import PageNotFound from 'pages/PageNotFound';
import DocumentationLoginForm from './DocumentationLoginForm';
import { appSelectors } from 'store/slices/appSlice';
import {
    getPublicDocsRoutes,
    getPublicDocsRoutesGroups,
    routeSelectors,
} from 'store/slices/routesSlice';
import { getPublicDocsResources } from 'store/slices/resourcesSlice';
import { getPublicDocsEnvironments } from 'store/slices/environmentsSlice';
import { getPublicDocsPages, pageSelectors } from 'store/slices/pagesSlice';
import { useParams } from 'react-router-dom';

export default function PublicDocsLayout() {
    const dispatch = useDispatch();
    const params = useParams();
    const pages = useSelector(pageSelectors.getDocsPages);
    const isPagesFetching = useSelector(pageSelectors.getIsDocsPagesFetching);
    const routes = useSelector(routeSelectors.getDocsRoutes);
    const isRoutesFetching = useSelector(
        routeSelectors.getIsDocsRoutesFetching,
    );
    const routesGroups = useSelector(routeSelectors.getDocsRoutsGroups);
    const apiData = useSelector(documentationSelectors.getApi);
    const showGlobalLoader = useSelector(appSelectors.getShowGlobalLoader);
    const isApiInformationFetching = useSelector(
        documentationSelectors.getIsApiInformationFetching,
    );
    const [api, setApi] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errorStatus, setErrorStatus] = useState(null);
    const token = localStorage.getItem('docs_access_token');

    const toggleIsAuthenticated = useCallback(
        () => setIsAuthenticated(!isAuthenticated),
        [isAuthenticated],
    );

    const onGetRoutesError = useCallback(() => {
        toast.error('Something went wrong, please try again later.');
        localStorage.removeItem('docs_access_token');
        toggleIsAuthenticated();
    }, [toggleIsAuthenticated]);

    const getData = useCallback(async () => {
        if ((token || api.access === 'PUBLIC') && api.id) {
            await dispatch(
                getPublicDocsResources({
                    apiId: api.id,
                    token,
                    onGetRoutesError,
                }),
            ).unwrap();
            await dispatch(
                getPublicDocsRoutes({
                    apiId: api.id,
                    token,
                    onGetRoutesError,
                }),
            ).unwrap();
            await dispatch(
                getPublicDocsPages({ apiId: api.id, token, onGetRoutesError }),
            ).unwrap();
            await dispatch(
                getPublicDocsRoutesGroups({
                    apiId: api.id,
                    token,
                    onGetRoutesError,
                }),
            ).unwrap();
            await dispatch(
                getPublicDocsEnvironments({
                    apiId: api.id,
                    token,
                    onGetRoutesError,
                }),
            ).unwrap();
        }
    }, [api.access, api.id, dispatch, onGetRoutesError, token]);

    const onSuccess = useCallback(
        (response) => {
            setApi(response.data.data);
            getData();
        },
        [getData],
    );

    useEffect(() => {
        if (token) {
            setIsAuthenticated(true);
        }
        dispatch(
            getApiDocumentation({
                apiCode: params?.apiCode,
                onSuccess,
                onError,
            }),
        );
    }, [dispatch, onSuccess, params?.apiCode, token]);

    const onError = (error) => {
        if (error?.response?.status === 404) {
            setErrorStatus(error?.response?.status);
        } else {
            toast.error(error?.message);
        }
    };

    if (errorStatus === 404) {
        return <PageNotFound />;
    }
    if (apiData.access === 'PRIVATE' || apiData.access === null) {
        return <NoAccess />;
    }

    if (!isAuthenticated && apiData.access === 'PROTECTED') {
        return (
            <>
                <DocumentationLoginForm
                    toggleIsAuthenticated={toggleIsAuthenticated}
                    getData={getData}
                />
                <GlobalLoader isLoading={showGlobalLoader} />
            </>
        );
    }

    return (
        <>
            {(isAuthenticated || apiData.access === 'PUBLIC') && (
                <>
                    <DocsPage
                        pages={pages}
                        isPagesFetching={isPagesFetching}
                        routes={routes}
                        isRoutesFetching={isRoutesFetching}
                        groups={routesGroups}
                        api={apiData}
                    />
                    <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                        icon={false}
                    />
                </>
            )}
            <GlobalLoader isLoading={isApiInformationFetching} />
        </>
    );
}
