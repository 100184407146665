import React from 'react';
import { Input } from 'ui/Inputs';
import CustomSelect from 'ui/CustomSelect';
import RequiredMark from 'ui/RequiredMark/RequiredMark';
import ExpandableTextEditor from 'components/ExpandableTextEditor';

const options = [
    { value: 'GET', label: 'GET' },
    { value: 'POST', label: 'POST' },
    { value: 'PUT', label: 'PUT' },
    { value: 'PATCH', label: 'PATCH' },
    { value: 'DELETE', label: 'DELETE' },
];

const customStyles = (fieldError) => ({
    valueContainer: (provided, state) => ({
        ...provided,
        color: '#001a37',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: '400',
        minWidth: '65px',
        backgroundColor: 'white',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? '#f6f7f9'
            : state.isSelected
            ? '#f6f7f9;'
            : 'white',
        ':active': {
            // backgroundColor: state.isSelected ? 'yellow' : 'pink'
        },
        color: '#001a37',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: '400',
    }),
    control: (provided, state) => ({
        ...provided,
        width: '100%',
        display: 'flex',
        borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
        boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(13 110 253 / 25%)' : '',
        color: '#001a37',
        borderRadius: '4px',
        ...(!!fieldError && {
            border: '1px solid #dc3545',
            borderColor: '#dc3545 !important',
        }),
        '&:hover': {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? '#86b7fe' : '#ced4da',
        },
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
});

const RouteGeneralInfo = ({
    name,
    handleChange,
    handleChangeSelect,
    errors,
    description,
    method,
    url,
    group_id,
    handleEditorChange,
    selectGroupsOptions,
    handleChangeGroupSelect,
}) => {
    return (
        <div>
            <div className="form-group mb-3">
                <Input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    value={name}
                    onChange={handleChange}
                    labelText="Route name"
                    labelClassName="form-label"
                    placeHolder="Enter route name or leave it blank to use the url"
                    errorText={errors.name}
                    isRequired
                />
            </div>
            <div className="mb-4">
                <label className="form-label">Group</label>
                <CustomSelect
                    name="route-groups"
                    options={selectGroupsOptions}
                    onChange={handleChangeGroupSelect}
                    value={group_id || 'no-group'}
                    menuPortalTarget={'null'}
                />
            </div>
            <div className="form-group mb-4">
                <ExpandableTextEditor
                    label="Description"
                    description={description}
                    handleEditorChange={handleEditorChange}
                />
            </div>
            <span className="anchor" id="request" />
            <label className="label-main mb-2">
                HTTP request
                <RequiredMark />
            </label>
            <div className="input-group d-flex from-group mb-4">
                <div className="input-group-append">
                    <CustomSelect
                        className="select-main-container"
                        name="method"
                        options={options}
                        onChange={handleChangeSelect}
                        defaultOption={method}
                        value={method}
                        customStyles={customStyles}
                    />
                </div>
                <Input
                    type="text"
                    name="url"
                    className="form-control"
                    value={url}
                    onChange={handleChange}
                    placeHolder="https://api.cakes.com/v1/cakes/{id}"
                    errorText={errors.url}
                />
            </div>
        </div>
    );
};

export default RouteGeneralInfo;
