import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ChevronDown } from 'react-feather';

import LocalLoader from 'ui/LocalLoader';
import useOutsideClick from 'hooks/useOutsideClick';
import { projectSelectors } from 'store/slices/projectsSlice';
import AllProjects from './AllProjects/AllProjects';

const ProjectsSelector = () => {
    const { pathname, search } = useLocation();
    const [showList, setShowList] = useState(false);
    const selectProjectDropdown = useRef();

    const project = useSelector(projectSelectors.getCurrentProject);
    const projects = useSelector(projectSelectors.getProjects);
    const isProjectFetching = useSelector(
        projectSelectors.getIsProjectFetching,
    );

    const isProjectsFetching = useSelector(
        projectSelectors.getIsProjectsFetching,
    );

    const selectedProjectName = project?.name ? project.name : null;

    const handleClickOutside = (e) => {
        setShowList(false);
    };
    useOutsideClick(selectProjectDropdown, handleClickOutside);

    const toggleList = () => {
        setShowList(!showList);
    };

    return (
        <>
            <div className="dropdown btn-group" ref={selectProjectDropdown}>
                <button
                    className={`custom-dropdown-btn link-dropdown-btn 
                            ${
                                (selectedProjectName ||
                                    pathname === '/projects' ||
                                    search.includes('project')) &&
                                !search.includes('api')
                                    ? 'custom-dropdown-btn-active'
                                    : null
                            }`}
                    type="button"
                    id="dropdownMenuButton1"
                    aria-expanded="false"
                    onClick={toggleList}
                >
                    {isProjectFetching ? (
                        <>
                            <span className="spinner-border spinner-border-sm mx-2" />
                            Loading...
                        </>
                    ) : (
                        selectedProjectName ?? 'Select Project'
                    )}
                    <ChevronDown
                        className="custom-dropdown-btn-arrow"
                        size={12}
                        strokeWidth={2}
                    />
                </button>
                {showList && (
                    <div
                        className={`dropdown-menu ${showList ? 'show' : ''}`}
                        style={{ minWidth: '16.5rem' }}
                        aria-labelledby="dropdownMenuButton1"
                    >
                        <LocalLoader loading={isProjectsFetching}>
                            {!isProjectsFetching && (
                                <AllProjects projects={projects} />
                            )}
                        </LocalLoader>

                        <div>
                            <hr className="dropdown-divider header-divider" />
                        </div>
                        <div className="text-center">
                            <a
                                className="dropdown-item link-dropdown"
                                href="/projects"
                            >
                                View all Projects
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ProjectsSelector;
