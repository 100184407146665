import React from 'react';
import { Bell } from 'react-feather';

const NotificationsSelector = () => {
    return (
        <div className="btn-group">
            <button
                type="button"
                className="header-round-btn header-notifications-btn"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <Bell className="header-notifications-btn-icon" />
            </button>
            <ul className="dropdown-menu">
                <li className="px-2">
                    <h3 className="header-notifications-title p-2">
                        Notifications
                    </h3>
                </li>
                <li>
                    <hr className="dropdown-divider header-divider" />
                </li>
                <li className="header-projects-none">
                    <div className="text-center mb-4">
                        <svg
                            width="49"
                            height="49"
                            viewBox="0 0 49 49"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g opacity="0.7">
                                <path
                                    d="M28.3162 42.3979C27.9646 43.0041 27.4599 43.5073 26.8527 43.857C26.2455 44.2068 25.557 44.3909 24.8562 44.3909C24.1555 44.3909 23.467 44.2068 22.8598 43.857C22.2526 43.5073 21.7479 43.0041 21.3962 42.3979"
                                    stroke="#001A37"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M38.1161 26.3979C37.2264 23.1408 36.8022 19.774 36.8561 16.3979"
                                    stroke="#001A37"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M13.3762 12.918C13.0287 14.0452 12.8534 15.2184 12.8562 16.398C12.8562 30.398 6.8562 34.398 6.8562 34.398H34.8562"
                                    stroke="#001A37"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M36.856 16.3979C36.8593 14.2241 36.2719 12.0901 35.1567 10.2241C34.0415 8.35809 32.4404 6.83005 30.5243 5.80321C28.6082 4.77638 26.4492 4.28932 24.2778 4.39407C22.1065 4.49882 20.0044 5.19143 18.196 6.39793"
                                    stroke="#001A37"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M2.8562 2.39795L46.8562 46.3979"
                                    stroke="#001A37"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                        </svg>
                    </div>
                    <p className="text-center header-link-text-big pb-3">
                        No notifications
                    </p>
                    <p className="text-center heder-notifications-text">
                        When your team starts working, notifications will appear
                        here.
                    </p>
                </li>
            </ul>
        </div>
    );
};

export default NotificationsSelector;
