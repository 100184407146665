import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AlertCircle } from 'react-feather';

import { Input } from 'ui/Inputs';
import LocalLoader from 'ui/LocalLoader';
import RequiredMark from 'ui/RequiredMark/RequiredMark';
import TextEditor from 'components/TextEditor/TextEditor';
import ToggleButton from 'ui/ToggleButton';
import Tooltip from 'ui/Tooltip';
import { apiSelectors } from 'store/slices/apiSlice';
import {
    createPage,
    getPage,
    getPages,
    pageSelectors,
    updatePage,
} from 'store/slices/pagesSlice';
import { projectSelectors } from 'store/slices/projectsSlice';

const PageForm = () => {
    const dispatch = useDispatch();
    const { pageId } = useParams();
    const history = useHistory();

    const project = useSelector(projectSelectors.getCurrentProject);
    const page = useSelector(pageSelectors.getCurrentPage);
    const api = useSelector(apiSelectors.getCurrentApi);
    const pageFetching = useSelector(pageSelectors.getIsPageFetching);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isInternal, setIsInternal] = useState(page.is_internal ?? false);
    const [action, setAction] = useState('create');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (pageId) {
            dispatch(getPage({ pageId }));
            setAction('update');
            setTitle(page?.title);
            setIsInternal(page?.is_internal);

            if (typeof page.description === 'string') {
                setDescription(page?.description);
            }
        }
    }, [dispatch, page.description, page?.is_internal, page?.title, pageId]);

    useEffect(() => {
        if (errors.title) {
            window.scrollTo(0, 0);
        }
    }, [errors]);

    const handleEditorChange = (editorData) => {
        setDescription(editorData);
    };

    const onSuccessPageCreate = (response) => {
        toast.success('The page has been created');
        history.push(
            `/project/api/pages/browse/${response.data.data.id}?project=${project.uid}&api=${api.uid}`,
        );
        dispatch(getPages({ projectUid: project.uid, apiId: api.id }));
    };

    const onErrorPageCreate = (error) => {
        setErrors(error?.response.data.errors);
    };

    const onSuccessPageUpdate = (response) => {
        toast.success('The page has been updated');
        history.push(
            `/project/api/pages/browse/${response.data.data.id}?project=${project.uid}&api=${api.uid}`,
        );
        dispatch(getPages({ projectUid: project.uid, apiId: api.id }));
    };

    const onErrorPageUpdate = (error) => {
        setErrors(error?.response.data.errors);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newDescr = description?.replace(/<br ?\/?>/gi, '\n');
        const data = {
            title: title,
            description: newDescr,
            is_internal: isInternal,
        };

        if (action === 'create') {
            dispatch(
                createPage({
                    data,
                    apiId: api.id,
                    onSuccess: onSuccessPageCreate,
                    onError: onErrorPageCreate,
                }),
            );
        } else {
            dispatch(
                updatePage({
                    data,
                    pageId,
                    onSuccess: onSuccessPageUpdate,
                    onError: onErrorPageUpdate,
                }),
            );
        }
    };

    const handleSwitchInternal = (flag) => {
        setIsInternal(flag);
    };

    return (
        <LocalLoader loading={pageFetching}>
            <div className="row">
                <div className="col-10">
                    <div className="d-flex justify-content-between align-items-center col col-lg-11">
                        <h4 className="title-h4 mb-4 block" id="routeHead">
                            {action === 'create'
                                ? 'Create a new page'
                                : 'Edit page'}
                        </h4>
                        <div className="d-flex align-items-center mb-4">
                            <Tooltip
                                id="internalAlert"
                                content="When a page is set as internal, it is no longer visible in public documentation, 
                                but can still be accessed and seen by members within the workspace."
                            >
                                <AlertCircle color="gray" size={15} />
                            </Tooltip>
                            <p style={{ margin: '0 8px 0 4px' }}>
                                {isInternal ? 'Internal' : 'Public'}
                            </p>
                            <ToggleButton
                                defaultChecked={!!isInternal}
                                className={`d-flex toggle-small-size ${
                                    !isInternal
                                        ? 'toggle-disabled-container'
                                        : ''
                                }`}
                                onChange={(state) =>
                                    handleSwitchInternal(state)
                                }
                            />
                        </div>
                    </div>

                    <form onSubmit={handleSubmit} className="col col-lg-11">
                        <div className="form-group mb-4">
                            <Input
                                type="text"
                                name="title"
                                className="form-control"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                labelText="Title"
                                labelClassName="form-label"
                                placeHolder="Enter page title"
                                errorText={errors?.title}
                                isRequired
                            />
                        </div>
                        <div className="form-group mb-4 ">
                            <label className="form-label">Description</label>
                            <RequiredMark />
                            <TextEditor
                                description={description}
                                handleEditorChange={handleEditorChange}
                                errorText={errors?.description}
                                editorClassName={
                                    'editor-page-content ck-line-height'
                                }
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            {action === 'create' ? 'Save' : 'Update'}
                        </button>
                        <Link
                            to={
                                action === 'create'
                                    ? `/project/api/pages?project=${project.uid}&api=${api.uid}`
                                    : `/project/api/pages/browse/${pageId}?project=${project.uid}&api=${api.uid}`
                            }
                            type="button"
                            className="btn btn-link-main text-link-normal m-1"
                        >
                            Cancel
                        </Link>
                    </form>
                </div>
            </div>
        </LocalLoader>
    );
};

export default PageForm;
