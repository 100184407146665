import React from 'react';

const HeaderLogo = () => {
    return (
        <div className="header-logo">
            <a href="/projects">
                <img src="/img/logo.svg" alt="logo" />
            </a>
        </div>
    );
};

export default HeaderLogo;
