import { Input } from 'ui/Inputs';
import { CardElement } from '@stripe/react-stripe-js';
import { cardOptions } from 'pages/Billing/ManageBilling/utils/consts';

export const AddPaymentCard = ({
    cardName,
    handleCardElementChange,
    handleChangeCardName,
    error,
}) => {
    return (
        <div id="payment-form">
            <h5 className="title-h5">Card information</h5>
            <div className="form-group my-3">
                <Input
                    type="text"
                    name="cardName"
                    className="form-control"
                    value={cardName}
                    labelText="Card name"
                    labelClassName="form-label opacity-75"
                    placeHolder="Enter card name"
                    onChange={handleChangeCardName}
                />
            </div>
            <label className="d-block w-100 form-label opacity-75">
                <span className="form-label is-invalid d-block mb-2">
                    Card number
                </span>
                <CardElement
                    id="card-element"
                    options={cardOptions}
                    onChange={handleCardElementChange}
                />
            </label>
            {error && <p className="invalid-feedback d-block">{error}</p>}
        </div>
    );
};
