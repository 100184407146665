import { useDrop } from 'react-dnd';

const useDropMethod = (action, target) => {
    const [{ isOver, draggedItemType }, drop] = useDrop({
        accept: ['route'],
        drop: (item) => {
            const { id, group_id } = item;

            switch (draggedItemType) {
                case 'route':
                    if (
                        (group_id === null && target === undefined) ||
                        group_id === target
                    )
                        return;

                    if (target === undefined) {
                        action(id, null);
                    } else {
                        action(id, target);
                    }
                    break;

                default:
                    break;
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            draggedItemType: monitor.getItemType(),
        }),
    });

    return { drop, isOver, draggedItemType };
};

export default useDropMethod;
