import RouteResponseTabsItem from './RouteTabsItem';

const RouteResponseTabs = ({ items, activeTab, onClick }) => {
    const sortedItems =
        items.length > 0 &&
        [...items].sort(
            (prev, next) => prev.http_status_code - next.http_status_code,
        );

    return (
        <div className="d-flex justify-content-between align-items-center w-100 mb-3">
            <div className="response-example-tabs">
                <ul className="d-flex justify-content-start align-items-center">
                    {sortedItems.map((item, index) => (
                        <RouteResponseTabsItem
                            key={index}
                            label={item.http_status_code}
                            activeTab={activeTab}
                            onClick={onClick}
                            index={index}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default RouteResponseTabs;
