import React, { useState } from 'react';
import { Input } from 'ui/Inputs';

const CreateApiModal = ({ onClose, onSubmit, project, onCreated }) => {
    const [data, setData] = useState({ name: '', version: '' });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(project.uid, data, onSuccess, onError);
    };

    const onSuccess = (response) => {
        onCreated();
        setData({ name: '', version: '' });
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    const onCancelCreateApi = () => {
        onClose();
        setData({ name: '', version: '' });
        setErrors({});
    };

    return (
        <>
            <div className="modal-body">
                <div className="form-group mt-3">
                    <Input
                        type="text"
                        name="name"
                        className="form-control mt-2"
                        value={data.name}
                        onChange={handleChange}
                        labelText="Name"
                        labelClassName="label-main"
                        placeHolder="Enter Api name"
                        errorText={errors.name}
                    />
                </div>
                <div className="form-group mt-3">
                    <Input
                        type="text"
                        name="version"
                        className="form-control mt-2"
                        value={data.version}
                        onChange={handleChange}
                        labelText="Version"
                        labelClassName="label-main"
                        placeHolder="Enter Api version"
                        errorText={errors.version}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                >
                    Create
                </button>

                <button
                    type="button"
                    onClick={onCancelCreateApi}
                    className="btn btn-link"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
            </div>
        </>
    );
};

export default CreateApiModal;
