import { findRef } from '../utils/findRef';
import { BaseNode } from './BaseNode';

export class RootNode extends BaseNode {
    constructor(fragment, parent = null, options) {
        super(fragment);
        this.parent = parent;
        this.fragment = fragment;
        this.name = 'root';
        this.id = super.generateId();
        this.type = super.defineType();
        this.subType = super.defineSubType();
        this.description = this.fragment.description || '';
        this.children = super.setupChildren(this.fragment);
        this.refPath =
            this.fragment?.$ref || this.fragment?.items?.$ref || null;
        this.refName =
            (this.fragment?.$ref || this.fragment?.items?.$ref)
                ?.split('/')
                ?.slice(-1)[0] || null;
        this.isRequired = this.fragment?.required || null;
        this.behaviour = super.defineBehaviour();
        this.isNullable = false;
        this.detached = options?.detached;
        this.refSchema = findRef(options.models, this.refName);
        this.enumValue = super.defineEnum();
        this.depth = options.depth;
        this.extraProps = super.defineExtraProps();
        this.isCombinerChild = options?.isCombinerChild;
    }
}
