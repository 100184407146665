import React from 'react';

export function FolderClose({ style }) {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill={style?.fill || 'none'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.7">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.1042 4.62699H8.79017C8.5695 4.62699 8.36283 4.51766 8.23883 4.33499L7.30217 2.95833C7.17817 2.77633 6.97217 2.66699 6.7515 2.66699H3.77083C3.03417 2.66699 2.4375 3.26366 2.4375 4.00033V12.0003C2.4375 12.737 3.03417 13.3337 3.77083 13.3337H13.1042C13.8408 13.3337 14.4375 12.737 14.4375 12.0003V5.96033C14.4375 5.22433 13.8408 4.62699 13.1042 4.62699Z"
                    stroke={style?.stroke || '#001A37'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

export function FolderOpen({ style }) {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill={style?.fill || 'none'}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.7">
                <path
                    d="M13.1042 6.66699V6.00033C13.1042 5.26366 12.5075 4.66699 11.7708 4.66699H8.79417C8.5715 4.66699 8.36283 4.55566 8.2395 4.37033L7.30217 2.96433C7.17817 2.77833 6.97017 2.66699 6.7475 2.66699H3.77083C3.03417 2.66699 2.4375 3.26366 2.4375 4.00033V12.569C2.4375 12.9917 2.7795 13.3337 3.20217 13.3337V13.3337C3.5395 13.3337 3.8375 13.1123 3.93417 12.789L5.4855 7.61766C5.65483 7.05299 6.17417 6.66699 6.76283 6.66699H13.3235C14.2135 6.66699 14.8535 7.52166 14.6028 8.37566L13.4288 12.3757C13.2622 12.9437 12.7415 13.3337 12.1495 13.3337H3.20217"
                    stroke={style?.stroke || '#001A37'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

export function OfficeBuilding({ className }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            height="20"
            width="20"
            stroke="currentColor"
            className={className}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M23.25 23.25H0.75"
                strokeWidth="1.5"
            ></path>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M0.75 23.25V1.75c0 -0.6 0.4 -1 1 -1h14c0.6 0 1 0.4 1 1v21.5"
                strokeWidth="1.5"
            ></path>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M16.75 10.25h5.5c0.6 0 1 0.4 1 1v12"
                strokeWidth="1.5"
            ></path>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M7 3.75H4v4h3v-4Z"
                strokeWidth="1.5"
            ></path>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M13.5 3.75h-3v4h3v-4Z"
                strokeWidth="1.5"
            ></path>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M7 11.25H4v4h3v-4Z"
                strokeWidth="1.5"
            ></path>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M13.5 11.25h-3v4h3v-4Z"
                strokeWidth="1.5"
            ></path>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="M6.94995 23.2499v-3.3c0 -1 0.8 -1.8 1.8 -1.8s1.80005 0.8 1.80005 1.8v3.3"
                strokeWidth="1.5"
            ></path>
        </svg>
    );
}
