import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Input } from 'ui/Inputs';
import CustomSelect from 'ui/CustomSelect';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import {
    createRouteGroup,
    fetchRoutes,
    initRoutes,
    routeSelectors,
    updateRouteGroup,
} from 'store/slices/routesSlice';

const GroupFormModal = ({ selectedData, onClose, onSuccess }) => {
    const dispatch = useDispatch();

    const api = useSelector(apiSelectors.getCurrentApi);
    const project = useSelector(projectSelectors.getCurrentProject);
    const routes = useSelector(routeSelectors.getRoutes);

    const [data, setData] = useState({
        name: '',
        routes: [],
    });
    const [errors, setErrors] = useState({});

    const routeList = routes
        ?.filter((item) => item.group_id === null)
        ?.map((item) => {
            return {
                label: `${item.method} - ${item.name || item.url}`,
                value: item.id,
            };
        });

    useEffect(() => {
        dispatch(fetchRoutes({ project: project.uid, apiId: api.id }));

        if (selectedData) {
            setData((data) => {
                data.name = selectedData.name;
                data.routes = selectedData.routes || '';
                return { ...data };
            });
        }

        return () => {
            setErrors({});
        };
    }, [api.id, dispatch, project.uid, selectedData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleChangeSelect = (selected) => {
        const selectedRoutes = selected.map((el) => el.value);
        setData({ ...data, routes: selectedRoutes });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedData) {
            dispatch(
                updateRouteGroup({ id: selectedData.id, data, handleResponse }),
            );
        } else {
            dispatch(createRouteGroup({ aid: api.id, data, handleResponse }));
        }
    };

    const handleResponse = (response) => {
        const { status, data } = response;

        switch (status) {
            case 200:
                dispatch(initRoutes({ project: project.uid, apiId: api.id }));
                toast.success('Group successfully updated');
                onSuccess();
                break;
            case 201:
                dispatch(initRoutes({ project: project.uid, apiId: api.id }));
                toast.success('Group successfully created');
                onSuccess();
                break;
            case 422:
                setErrors(data.errors);
                break;
            case 500:
                toast.error('Something went wrong. Please try again later.');
                break;
            default:
                break;
        }
    };

    const convertRoutesIds = (idsArray = []) => {
        const data = idsArray?.reduce((acc, el) => {
            const foundRoute = routes.find((route) => route.id === el);

            if (foundRoute) {
                acc.push({ value: foundRoute.id, label: foundRoute.name });
            }

            return acc;
        }, []);

        return data;
    };

    return (
        <>
            <div className="modal-body">
                <div className="form-group mt-3">
                    <Input
                        type="text"
                        name="name"
                        className="form-control mt-2"
                        value={data.name}
                        onChange={handleChange}
                        labelText="Name"
                        labelClassName="label-main"
                        placeHolder="Enter group name"
                        errorText={errors.name}
                    />
                </div>
                <div className="form-group mt-3">
                    <CustomSelect
                        isMultiSelect={true}
                        name="group"
                        options={routeList}
                        onChange={handleChangeSelect}
                        value={convertRoutesIds(data?.routes)}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                >
                    {selectedData ? 'Update' : 'Create'}
                </button>

                <button
                    type="button"
                    onClick={onClose}
                    className="btn btn-link"
                    data-dismiss="modal"
                >
                    Cancel
                </button>
            </div>
        </>
    );
};

export default GroupFormModal;
