import React, { Fragment, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { combineClasses as cc } from 'utils/helpers';

const Modal = ({ customClasses, title, body, footer, show }) => {
    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style = null;
        }
    }, [show]);

    const styleNames = {
        display: show ? 'block' : 'none',
        paddingRight: '15px',
    };

    return createPortal(
        <div
            className={cc('modal fade modal-overlay', { show })}
            style={styleNames}
            tabIndex="-1"
        >
            <div className={cc('modal-dialog', customClasses?.modalDialog)}>
                <div
                    className={cc('modal-content', customClasses?.modalContent)}
                >
                    {title && (
                        <div className="modal-header">
                            <h5 className="modal-title">{title}</h5>
                        </div>
                    )}

                    {footer ? (
                        <Fragment>
                            <div className="modal-body">{body}</div>
                            <div className="modal-footer">{footer}</div>
                        </Fragment>
                    ) : (
                        <Fragment>{body}</Fragment>
                    )}
                </div>
            </div>
        </div>,
        document.getElementById('modal-root'),
    );
};

Modal.defaultProp = {
    show: false,
    title: '',
    body: '',
    footer: null,
};

Modal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    footer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    customClasses: PropTypes.objectOf(PropTypes.string),
};
export default Modal;
