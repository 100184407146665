import React from 'react';

const ProjectAvatar = ({ project, additionalClass }) => {
    return (
        <>
            {!!project && !!project.logo_thumb_url ? (
                <img
                    src={`${project.logo_thumb_url}?${performance.now()}`}
                    alt="project icon"
                    className={`inline-logo project-logo ${
                        additionalClass || ''
                    }`}
                    style={{ marginRight: '16px' }}
                />
            ) : (
                <img
                    src={`${project?.avatar_url}?${performance.now()}`}
                    alt="project icon"
                    className={`inline-logo project-logo ${
                        additionalClass || ''
                    }`}
                    style={{ marginRight: '16px' }}
                />
            )}
        </>
    );
};

export default ProjectAvatar;
