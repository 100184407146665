import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from 'store/selectors';
import {
    billingSelectors,
    createCard,
    deleteCard,
    getCards,
    updateDefaultCard,
} from 'store/slices/billingSlice';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useId } from 'react';

export const useModalCardsList = () => {
    const stripe = useStripe();
    const elements = useElements();

    const dispatch = useDispatch();
    const cards = useSelector(billingSelectors.getAllCards);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const newCardIdInput = useId();
    const fullName = myAccount.first_name + ' ' + myAccount.last_name;
    const currentDefaultCard = cards?.find((card) => card?.is_default)?.id;

    // TODO upd correct card
    const [selectedCardId, setSelectedCardId] = useState(currentDefaultCard);

    const handleChangeCard = (id) => {
        setSelectedCardId(id);
    };
    const [showNewCard, setShowNewCard] = useState(false);
    const [cardName, setCardName] = useState(fullName || '');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isCardDeleting, setIsCardDeleting] = useState(false);

    useEffect(() => {
        setSelectedCardId(currentDefaultCard);
    }, [currentDefaultCard]);

    const handleChangeCardName = (e) => {
        e.preventDefault();
        setCardName(e.target.value);
    };

    const handleCardElementChange = (event) => {
        if (!event.error) {
            setError(null);
            return;
        }

        setError(event?.error?.message);
    };

    const handleDeleteCard = (id) => {
        setIsCardDeleting(true);
        dispatch(
            deleteCard({
                id,
                onSuccess: onSuccessDeleteCard,
                onError: onErrorDeleteCard,
            }),
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);
        try {
            const email = myAccount.email;
            const selectedNewCardForm = selectedCardId === newCardIdInput;

            if (selectedNewCardForm) {
                const paymentMethod = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: cardName,
                        email,
                    },
                });

                if (paymentMethod.error) {
                    setIsLoading(false);
                    return;
                }
                const paymentMethodId = paymentMethod?.paymentMethod?.id;

                const data = {
                    paymentMethod: paymentMethodId,
                    isDefault: true,
                };

                dispatch(
                    createCard({
                        data,
                        onSuccess,
                        onError,
                    }),
                );
            } else {
                const data = {
                    isDefault: true,
                };

                dispatch(
                    updateDefaultCard({
                        id: selectedCardId,
                        data,
                        onSuccess,
                        onError,
                    }),
                );
            }
        } catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    };

    const onSuccess = (method) => {
        dispatch(getCards());
        setIsLoading(false);
        setShowNewCard(false);

        toast.success(`The payment method has been ${method} successfully`);
    };

    const onError = (error) => {
        if (error?.reponse?.status === 400) {
            toast.error(error?.response?.data?.message);
            return;
        }
        toast.error('Something went wrong, please try again later.');
        setIsLoading(false);
    };

    const onSuccessDeleteCard = () => {
        dispatch(getCards());
        setIsCardDeleting(false);
        toast.success('The payment card has been deleted successfully');
    };

    const onErrorDeleteCard = (error) => {
        if (error?.reponse?.status === 400) {
            toast.error(error?.response?.data?.message);
            return;
        }
        toast.error('Something went wrong, please try again later.');
        setIsCardDeleting(false);
    };

    const handleShowNewCard = () => {
        setShowNewCard((prev) => !prev);
        setSelectedCardId(newCardIdInput);
    };

    const resetCardsInputs = () => {
        setShowNewCard(false);
        setSelectedCardId(currentDefaultCard);
    };

    return {
        selectedCardId,
        cards,
        handleChangeCard,
        showNewCard,
        handleShowNewCard,
        cardName,
        handleChangeCardName,
        error,
        handleCardElementChange,
        currentDefaultCard,
        handleSubmit,
        newCardIdInput,
        isLoading,
        handleDeleteCard,
        resetCardsInputs,
        isCardDeleting,
    };
};
