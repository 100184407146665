import TextEditor from 'components/TextEditor';
import React, { useState } from 'react';

export default function ExpandableTextEditor({
    description,
    handleEditorChange,
    errors,
    label,
}) {
    const [hasFocus, setHasFocus] = useState(() => false);
    const [isExpanded, setIsExpanded] = useState(() =>
        description ? true : false,
    );

    const toggleFocus = () => {
        setHasFocus((prev) => !prev);
    };

    const toggleExpanded = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <div className="form-group w-100">
            <div className="d-flex justify-content-between">
                {label && <label className="form-label">{label}</label>}
                {!description && isExpanded && (
                    <span
                        className="link-primary cursor-pointer"
                        onClick={toggleExpanded}
                    >
                        Collapse
                    </span>
                )}
            </div>
            {!isExpanded ? (
                <input
                    placeholder="Add a description..."
                    className="form-control"
                    onFocus={() => {
                        toggleExpanded();
                        toggleFocus();
                    }}
                />
            ) : (
                <>
                    <TextEditor
                        description={description}
                        handleEditorChange={handleEditorChange}
                        errorText={errors?.body}
                        editorClassName={'ck-line-height'}
                        showEditorRef={hasFocus}
                    />
                </>
            )}
        </div>
    );
}
