import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { MoreHorizontal } from 'react-feather';
import { toast } from 'react-toastify';

import useOutsideClick from 'hooks/useOutsideClick';
import useDropdownPosition from 'hooks/useDropdownPosition';
import iconPage from 'assets/img/page-dark.svg';
import Modal from 'ui/Modal';
import LocalLoader from 'ui/LocalLoader';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import {
    deletePagePermanent,
    getPages,
    getTrashedPages,
    pageSelectors,
    restorePage,
} from 'store/slices/pagesSlice';
import { organizationSelectors } from 'store/slices/organizationSlice';
import { authSelectors } from 'store/slices/authSlice';

const PagesTrash = ({ pagesTrash, selectedDropdown, toggleDropdown }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const isPagesTrashFetching = useSelector(
        pageSelectors.getIsPagesTrashFetching,
    );

    const [isDeletePageModalShown, setIsDeletePageModalShown] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const dropdownRef = useRef(null);

    const hasOwnerRights =
        project.owner.id === myAccount.id || currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project.user_role === 'MAINTAINER' ||
        project.user_role === 'WRITE';

    useEffect(() => {
        dispatch(getTrashedPages({ projectUid: project.uid, apiId: api.id }));
    }, [project.uid, api.id, dispatch]);

    useOutsideClick(dropdownRef, () => toggleDropdown(undefined, null));
    useDropdownPosition(dropdownRef, selectedDropdown);

    const handleClickAction = (e, data, action) => {
        e?.stopPropagation();
        e?.preventDefault();

        switch (action) {
            case 'restore-page':
                dispatch(
                    restorePage({
                        pageId: data.id,
                        onSuccess: onRestorePageSuccess,
                    }),
                );
                break;

            case 'delete-page-permanently':
                if (!data) {
                    toggleDropdown(undefined, null);
                }
                setIsDeletePageModalShown(!isDeletePageModalShown);
                setSelectedData(data);

                break;

            default:
                break;
        }
    };

    const onDeletePagePermanentSuccess = () => {
        setIsDeletePageModalShown(false);
        toast.success('Page permanently deleted');
        dispatch(getTrashedPages({ projectUid: project.uid, apiId: api.id }));
        history.push(
            `/project/api/routes?project=${project.uid}&api=${api.uid}`,
        );
        handleClickAction(undefined, null, 'delete-page-permanently');
    };

    const onPermanentDeletePage = () => {
        dispatch(
            deletePagePermanent({
                pageId: selectedData.id,
                onSuccess: onDeletePagePermanentSuccess,
            }),
        );
    };

    const onRestorePageSuccess = () => {
        toast.success('Page succesfully restored');
        dispatch(getPages({ projectUid: project.uid, apiId: api.id }));
        dispatch(getTrashedPages({ projectUid: project.uid, apiId: api.id }));
        history.push(
            `/project/api/routes?project=${project.uid}&api=${api.uid}`,
        );
        handleClickAction(undefined, null, 'restore-page');
    };

    return (
        <LocalLoader loading={isPagesTrashFetching} className="mt-5">
            <ul className={`nav flex-column`}>
                {!!pagesTrash.length &&
                    pagesTrash.map((page) => {
                        const isShowDropdown = selectedDropdown === page.id;
                        return (
                            <li
                                key={page.id}
                                className="nav-item sidebar-link-item mx-2"
                            >
                                <NavLink
                                    className=" nav-link sidebar-link sidebar-link-route word-break"
                                    to={`/project/api/pages/browse/${page.id}?project=${project.uid}&api=${api.uid}`}
                                    activeClassName="sidebar-link-active nav-link sidebar-link sidebar-link-route word-break"
                                >
                                    <img
                                        src={iconPage}
                                        alt="page"
                                        style={{
                                            width: '31px',
                                            height: '14px',
                                        }}
                                    />
                                    <span className="me-1">{page.title}</span>
                                    <div className="action-container">
                                        {hasRights && (
                                            <>
                                                <button
                                                    type="button"
                                                    className="btn-action edit hover-default"
                                                    onClick={(e) => {
                                                        toggleDropdown(
                                                            e,
                                                            page.id,
                                                        );
                                                    }}
                                                >
                                                    <MoreHorizontal
                                                        size={16}
                                                        color="#000000"
                                                    />
                                                </button>
                                                {isShowDropdown && (
                                                    <ul
                                                        ref={dropdownRef}
                                                        className="dropdown-list"
                                                    >
                                                        <li>
                                                            <button
                                                                className="dropdown-link"
                                                                type="button"
                                                                name="edit"
                                                                onClick={(e) =>
                                                                    handleClickAction(
                                                                        e,
                                                                        page,
                                                                        'restore-page',
                                                                    )
                                                                }
                                                            >
                                                                Restore
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                className="dropdown-link text-danger"
                                                                type="button"
                                                                name="delete"
                                                                onClick={(e) =>
                                                                    handleClickAction(
                                                                        e,
                                                                        page,
                                                                        'delete-page-permanently',
                                                                    )
                                                                }
                                                            >
                                                                Delete
                                                            </button>
                                                        </li>
                                                    </ul>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </NavLink>
                            </li>
                        );
                    })}
            </ul>
            <Modal
                show={isDeletePageModalShown}
                title="Delete"
                body="This page will be permanently deleted, are you sure ?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onPermanentDeletePage}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={(e) =>
                                handleClickAction(
                                    e,
                                    null,
                                    'delete-page-permanently',
                                )
                            }
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
        </LocalLoader>
    );
};

export default PagesTrash;
