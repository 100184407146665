import React from 'react';
import { Check } from 'react-feather';

const EnvVariablesView = ({ items, onClose }) => {
    return (
        <>
            <div className="overflow-auto" style={{ maxHeight: '400px' }}>
                <table className="route-view-table w-100">
                    <thead className="opacity-1">
                        <tr className="thead-fixed">
                            <th className="route-view-table-header">Active</th>
                            <th className="route-view-table-header">
                                Variable
                            </th>
                            <th className="route-view-table-header">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items?.length > 0 ? (
                            items.map((item, i) => (
                                <tr key={i}>
                                    <td>
                                        <p>
                                            {item.is_active === 1 ? (
                                                <Check
                                                    color="green"
                                                    size={20}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </p>
                                    </td>
                                    <td>
                                        <p>{item.name}</p>
                                    </td>
                                    <td>
                                        <p className="text-break">
                                            {item.value}
                                        </p>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="text-center" colSpan={3}>
                                    <span>No environment variables</span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="modal-footer border-top-0">
                <button
                    type="button"
                    onClick={onClose}
                    className="btn btn-link"
                    data-dismiss="modal"
                >
                    Close
                </button>
            </div>
        </>
    );
};

export default EnvVariablesView;
