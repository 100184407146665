const NoAccess = () => {
    return (
        <div className="position-absolute top-50 start-50 translate-middle">
            <div className="mb-5 no-access-img"></div>
            <p className="mb-4 text-center title-h3">
                Oops! No access to this page.
            </p>
            <p className="text-center text-link-normal">
                Seems that you don&rsquo;t have access to this page. Please
                contact the <br />
                administrator to gain access
            </p>
        </div>
    );
};

export default NoAccess;
