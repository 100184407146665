import React from 'react';
import LocalLoader from 'ui/LocalLoader';
import MemberProjects from './MemberProjects';

export default function ProjectAccessModal({ projects, isLoading }) {
    return (
        <div className="project-list mx-0 mb-4">
            <LocalLoader loading={isLoading} height="10vh">
                <MemberProjects memberProjects={projects} />
            </LocalLoader>
        </div>
    );
}
