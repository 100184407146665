export const Tip = () => (
    <svg
        width="21"
        height="8"
        viewBox="0 0 21 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.6876 1.21169C17.4408 0.437044 18.4752 0 19.5556 0L20.0879 0L0.0878906 0L0.620161 0C1.70056 0 2.73502 0.437044 3.48814 1.21169L8.6539 6.52504C9.43925 7.33283 10.7365 7.33283 11.5219 6.52504L16.6876 1.21169Z"
            fill="white"
        />
    </svg>
);

export const Cross = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
);
