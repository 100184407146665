import { memo, useLayoutEffect, useRef, useState } from 'react';
import List from './List';
import TypesBox from './TypesBox';
import Tooltip from 'ui/Tooltip';
import {
    ArrowDown,
    ArrowUp,
    ChevronRight,
    Copy,
    Plus,
    Trash2,
} from 'react-feather';
import RequiredMark from 'ui/RequiredMark';
import { capitalize } from './helpers';

const typesColors = {
    string: '#00c26e',
    number: '#de4a4a',
    integer: '#f36f2d',
    boolean: '#9061f9',
    object: '#126aec',
    array: '#00b75f',
    $ref: '#6e2fff',
    null: '#b4842f',
    enum: '#ff2d68',
    allOf: '#87bb23',
    anyOf: '#87bb23',
    oneOf: '#87bb23',
};

const memoListItem = memo(function ListItem({
    listItem,
    addElement,
    deleteElement,
    duplicateElement,
    idx,
    listLength,
    moveTop,
    moveBottom,
    onKeyNameChange,
    onChangeType,
    changeProperties,
    isCollapsed,
    setCollapsed,
    schemaName,
    models,
    onChangeNullable,
    onChangeEnum,
    onChangeEnumValues,
    changeExtraProperties,
    onClearTree,
}) {
    const {
        name,
        detached,
        type,
        subType,
        children,
        id,
        parent,
        refName,
        refPath,
        metadata,
        isRequired,
        isNullable,
        enumValue,
        description,
        behaviour,
        extraProps,
        isCombinerChild,
    } = listItem;
    const [typeBoxIsOpen, setTypeBoxIsOpen] = useState(false);
    const [inputWidth, setInputWidth] = useState(32);
    const rowTypeRef = useRef(null);
    const [tempData, setTempData] = useState({ name: name || '', id: '' });
    const [rowHeight, setRowHeight] = useState(36);
    const box = useRef();

    useLayoutEffect(() => {
        if (box?.current?.offsetHeight)
            setRowHeight(box?.current?.offsetHeight);
    }, [description]);

    const onChangeName = (name, id) => {
        setTempData({ name, id });
    };

    const onAddElement = (e, id) => {
        e.stopPropagation();
        addElement(id);
    };

    const onDeleteElement = (e, id) => {
        e.stopPropagation();
        deleteElement(id);
    };

    const onDuplicateElement = (e, id) => {
        e.stopPropagation();
        duplicateElement(id);
    };

    const closeTypesBox = () => {
        setTypeBoxIsOpen(false);
    };

    const handleDeleteRowButton = (e, id, isSchemaRoot) => {
        if (isSchemaRoot) {
            onClearTree();
        } else {
            onDeleteElement(e, id);
        }
    };

    const getType = (type, subType) => {
        const thisType = Array.isArray(type) ? type[0] : type;
        const thisSubType =
            subType && Array.isArray(subType) ? subType[0] : subType;

        if (!thisSubType) return thisType;

        return `${thisType}[${thisSubType}]`;
    };

    const setupType = () => {
        let resultType = resolvedType;
        if (enumValue) resultType = 'enum';

        if (resolvedType === 'array[$ref]') {
            resultType = `array[${connectedRef || '$ref'}]`;
        }

        if (resolvedType === '$ref') {
            resultType = connectedRef;
        }

        return isSchemaRoot || isDetachedTreeRoot
            ? capitalize(resultType)
            : resultType;
    };

    const resolvedType = getType(type, subType);
    const isCombinerType = ['allOf', 'anyOf', 'oneOf'].includes(resolvedType);
    const childrenCount =
        ((resolvedType === 'object' || isCombinerType) &&
            `{ ${children?.length || 0} }`) ||
        (resolvedType === 'array[object]' && `[ ${children?.length || 0} ]`);
    const isNotPrimitiveType = [
        'object',
        'array[object]',
        '$ref',
        'array[$ref]',
        'oneOf',
        'allOf',
        'anyOf',
    ].includes(resolvedType);
    const isObjectType =
        (resolvedType === 'object' ||
            resolvedType === 'array[object]' ||
            isCombinerType) &&
        !detached;
    const isFirstChild = idx === 0;
    const isLastChild = idx === listLength - 1;
    const isExpanded = !isCollapsed.includes(id);
    const rowTypePosition = rowTypeRef?.current?.getBoundingClientRect();
    const isSchemaRoot = !parent;
    const isDetachedTreeRoot = detached && name === 'root';
    const connectedRef = metadata.refSchema ? refName : refPath;
    const typeColor = resolvedType.includes('array')
        ? typesColors.array
        : typesColors[enumValue ? 'enum' : resolvedType];
    const displayedType = setupType();
    const behaviourMap = { write: '[write-only]', read: '[read-only]' };

    return (
        <>
            <div ref={box} className="JsonSchemaEditor__schema-row">
                <div className="JsonSchemaEditor__schema-row-inner-wrapper">
                    {(isObjectType || isCombinerType) && (
                        <div>
                            <button
                                className="JsonSchemaEditor__schema-static-button"
                                onClick={(e) => onAddElement(e, id)}
                                type="button"
                            >
                                <Plus size={16} />
                            </button>
                        </div>
                    )}
                    <div
                        className="JsonSchemaEditor__schema-row-aligned"
                        style={{
                            paddingLeft: `${
                                listItem.depth * 20 + (!isObjectType && 28)
                            }px`,
                        }}
                    >
                        <div className="JsonSchemaEditor__schema-row-entries-wrapper">
                            <div
                                className={`JsonSchemaEditor__schema-row-entries ${
                                    detached ? 'disabled' : ''
                                }`}
                                style={{
                                    alignSelf:
                                        rowHeight > 36
                                            ? 'flex-start'
                                            : 'center',
                                }}
                            >
                                {isNotPrimitiveType && !!children ? (
                                    <button
                                        className="JsonSchemaEditor__schema-static-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setCollapsed(id);
                                        }}
                                        type="button"
                                        style={{
                                            transform:
                                                isExpanded && 'rotate(90deg)',
                                        }}
                                    >
                                        <ChevronRight size={16} />
                                    </button>
                                ) : (
                                    <div
                                        style={{
                                            minWidth: '28px',
                                            height: '28px',
                                            width: '28px',
                                        }}
                                    ></div>
                                )}
                                {isSchemaRoot ||
                                isDetachedTreeRoot ||
                                isCombinerChild ? (
                                    <>
                                        <span
                                            ref={rowTypeRef}
                                            onClick={(e) =>
                                                setTypeBoxIsOpen(true)
                                            }
                                            className="JsonSchemaEditor__schema-row-type ms-2"
                                        >
                                            <span
                                                style={{
                                                    color: typeColor,
                                                    ...(extraProps?.deprecated && {
                                                        textDecoration:
                                                            'line-through',
                                                    }),
                                                }}
                                            >
                                                {displayedType}
                                                {extraProps?.format &&
                                                    `<${extraProps?.format}>`}
                                            </span>
                                        </span>
                                        {(resolvedType === 'object' ||
                                            resolvedType === 'array[object]' ||
                                            isCombinerType) && (
                                            <span className="JsonSchemaEditor__schema-row-children-counter">
                                                {childrenCount}
                                            </span>
                                        )}
                                        {!!enumValue?.length && (
                                            <div className="ms-2">
                                                <span
                                                    style={{
                                                        color: '#001a37b3',
                                                    }}
                                                >
                                                    {'[ '}
                                                </span>
                                                {enumValue?.map((el, i) => (
                                                    <span
                                                        key={i}
                                                        className="JsonSchemaEditor__schema-row-children-counter m-0"
                                                    >
                                                        {`${
                                                            i > 0 ? ' , ' : ''
                                                        }${el}`}
                                                    </span>
                                                ))}
                                                <span
                                                    style={{
                                                        color: '#001a37b3',
                                                    }}
                                                >
                                                    {' ]'}
                                                </span>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="ms-2 d-flex align-items-center">
                                        <span
                                            style={{
                                                maxWidth: '400px',
                                            }}
                                        >
                                            <div>
                                                <input
                                                    className="JsonSchemaEditor__schema-row-input"
                                                    value={tempData.name}
                                                    placeholder="name"
                                                    type="text"
                                                    autoComplete="off"
                                                    style={{
                                                        width:
                                                            inputWidth ||
                                                            '38px',
                                                        ...(extraProps?.deprecated && {
                                                            textDecoration:
                                                                'line-through',
                                                        }),
                                                    }}
                                                    onChange={(e) =>
                                                        onChangeName(
                                                            e.target.value,
                                                            id,
                                                        )
                                                    }
                                                    onBlur={() => {
                                                        if (tempData.id)
                                                            onKeyNameChange(
                                                                tempData.name,
                                                                tempData.id,
                                                            );
                                                    }}
                                                />
                                                {isRequired && <RequiredMark />}
                                                <span
                                                    ref={(el) =>
                                                        setInputWidth(
                                                            el?.offsetWidth,
                                                        )
                                                    }
                                                    className="JsonSchemaEditor__schema-row-fake-input"
                                                >
                                                    {tempData.name}
                                                </span>
                                            </div>
                                        </span>
                                        <span className="mx-1 pe-none">:</span>
                                        <span
                                            ref={rowTypeRef}
                                            onClick={(e) =>
                                                setTypeBoxIsOpen(true)
                                            }
                                            className="JsonSchemaEditor__schema-row-type"
                                            style={{
                                                ...(extraProps?.deprecated && {
                                                    textDecoration:
                                                        'line-through',
                                                }),
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: typeColor,
                                                }}
                                            >
                                                {displayedType}
                                                {extraProps?.format &&
                                                    `<${extraProps?.format}>`}
                                            </span>
                                            {isNullable &&
                                                displayedType !== 'null' && (
                                                    <>
                                                        <span className="mx-1">
                                                            or
                                                        </span>
                                                        <span
                                                            style={{
                                                                color: typesColors.null,
                                                            }}
                                                        >
                                                            null
                                                        </span>
                                                    </>
                                                )}
                                        </span>
                                        {(resolvedType === 'object' ||
                                            resolvedType === 'array[object]' ||
                                            isCombinerType) && (
                                            <span className="JsonSchemaEditor__schema-row-children-counter">
                                                {childrenCount}
                                            </span>
                                        )}
                                        {!!enumValue?.length && (
                                            <div className="ms-2">
                                                <span
                                                    style={{
                                                        color: '#001a37b3',
                                                    }}
                                                >
                                                    {'[ '}
                                                </span>
                                                {enumValue?.map((el, i) => (
                                                    <span
                                                        key={i}
                                                        className="JsonSchemaEditor__schema-row-children-counter m-0"
                                                    >
                                                        {`${
                                                            i > 0 ? ' , ' : ''
                                                        }${el}`}
                                                    </span>
                                                ))}
                                                <span
                                                    style={{
                                                        color: '#001a37b3',
                                                    }}
                                                >
                                                    {' ]'}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            {(listItem.behaviour === 'read' ||
                                listItem.behaviour === 'write') && (
                                <div
                                    style={{
                                        alignSelf:
                                            rowHeight > 36
                                                ? 'flex-start'
                                                : 'center',
                                        marginLeft: '10px',
                                        padding: '5px 0',
                                    }}
                                >
                                    <span className="JsonSchemaEditor__schema-row-behaviour">
                                        {behaviourMap[behaviour]}
                                    </span>
                                </div>
                            )}
                            {description && (
                                <div className="JsonSchemaEditor__schema-row-description">
                                    {description}
                                </div>
                            )}
                        </div>
                        <div className="d-flex mx-2">
                            <Tooltip
                                id={`${id}-moveup`}
                                content="Move up"
                                hidden={isFirstChild || detached}
                            >
                                <button
                                    className="JsonSchemaEditor__schema-dynamic-button"
                                    disabled={isFirstChild || detached}
                                    onClick={() => moveTop(id, parent.id)}
                                    type="button"
                                >
                                    <ArrowUp size={16} color="#001a37b3" />
                                </button>
                            </Tooltip>
                            <Tooltip
                                id={`${id}-movedown`}
                                content="Move down"
                                hidden={isLastChild || detached}
                            >
                                <button
                                    className="JsonSchemaEditor__schema-dynamic-button"
                                    disabled={isLastChild || detached}
                                    onClick={() => moveBottom(id, parent.id)}
                                    type="button"
                                >
                                    <ArrowDown size={16} color="#001a37b3" />
                                </button>
                            </Tooltip>
                            <Tooltip
                                id={`${id}-duplicate`}
                                content="Duplicate"
                                hidden={isSchemaRoot || detached}
                            >
                                <button
                                    className="JsonSchemaEditor__schema-dynamic-button"
                                    disabled={isSchemaRoot || detached}
                                    onClick={(e) => onDuplicateElement(e, id)}
                                    type="button"
                                >
                                    <Copy size={16} color="#001a37b3" />
                                </button>
                            </Tooltip>
                            <Tooltip
                                id={`${id}-delete`}
                                content={isSchemaRoot ? 'Reset tree' : 'Delete'}
                                hidden={detached}
                            >
                                <button
                                    className="JsonSchemaEditor__schema-dynamic-button"
                                    onClick={(e) =>
                                        handleDeleteRowButton(
                                            e,
                                            id,
                                            isSchemaRoot,
                                        )
                                    }
                                    disabled={detached}
                                    type="button"
                                >
                                    <Trash2 size={16} color="#001a37b3" />
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            {typeBoxIsOpen && (
                <TypesBox
                    typeBoxIsOpen={typeBoxIsOpen}
                    type={resolvedType}
                    listItem={listItem}
                    isNullable={isNullable}
                    onChangeType={(currentType, refName) =>
                        onChangeType(currentType, id, refName)
                    }
                    changeNullable={(e) => onChangeNullable(e, id)}
                    changeEnum={() => onChangeEnum(id)}
                    changeEnumValues={(values) =>
                        onChangeEnumValues(id, values)
                    }
                    schemaName={schemaName}
                    currentModel={connectedRef}
                    close={closeTypesBox}
                    changeProperties={(properties) =>
                        changeProperties(id, properties)
                    }
                    rowTypePosition={rowTypePosition}
                    models={models}
                    changeExtraProperties={(extraProperties) =>
                        changeExtraProperties(id, extraProperties)
                    }
                />
            )}
            {children && isExpanded && (
                <List
                    list={children}
                    schemaName={schemaName}
                    isCollapsed={isCollapsed}
                    models={models}
                    setCollapsed={setCollapsed}
                    addElement={addElement}
                    deleteElement={deleteElement}
                    duplicateElement={duplicateElement}
                    moveBottom={moveBottom}
                    moveTop={moveTop}
                    onKeyNameChange={onKeyNameChange}
                    onChangeType={onChangeType}
                    changeProperties={changeProperties}
                    onChangeNullable={onChangeNullable}
                    onChangeEnum={onChangeEnum}
                    onChangeEnumValues={onChangeEnumValues}
                    changeExtraProperties={changeExtraProperties}
                />
            )}
        </>
    );
});

export default memoListItem;
