import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginDocumentation } from 'store/slices/documentationSlice';
import { Input } from 'ui/Inputs';
import DocsAppLogo from './components/DocsAppLogo';

const DocumentationLoginForm = ({ getData, toggleIsAuthenticated }) => {
    const dispatch = useDispatch();
    const api = useSelector((state) => state.documentation.api);

    const [data, setData] = useState({
        username: '',
        password: '',
        api_id: api?.id || null,
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(loginDocumentation({ data, onSuccess, onError }));
    };

    const onSuccess = (response) => {
        localStorage.setItem('docs_access_token', response.data.access_token);
        const token = localStorage.getItem('docs_access_token');
        toggleIsAuthenticated();

        if (token) {
            getData();
        }
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row vh-100 form-background position-relative">
                    <div className="d-flex justify-content-center align-items-center p-4 h-100">
                        <div className="login-form">
                            {api.project_logo_url ? (
                                <div className="d-flex flex-column align-items-center">
                                    <img
                                        className="mb-3"
                                        src={api.project_logo_url}
                                        alt="logo"
                                        width="150"
                                    />
                                    <h2 className="text-center fw-bolder mb-5">
                                        {api.name}
                                    </h2>
                                </div>
                            ) : (
                                <div className="d-flex align-items-center justify-content-center mb-5">
                                    <img
                                        className="me-3"
                                        src={api.project_avatar_url}
                                        alt="logo"
                                        width="48"
                                    />
                                    <h2 className="fw-bolder">
                                        {api.project_name}
                                    </h2>
                                </div>
                            )}{' '}
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <Input
                                        type="text"
                                        name="username"
                                        className="form-control"
                                        value={data.username}
                                        onChange={handleChange}
                                        labelClassName="fw-bolder mb-2"
                                        labelText="Name"
                                        placeHolder="Enter your name"
                                        errorText={errors.username}
                                    />
                                </div>
                                <div className="mb-5">
                                    <Input
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        value={data.password}
                                        onChange={handleChange}
                                        labelClassName="fw-bolder mb-2"
                                        labelText="Password"
                                        placeHolder="Enter your password"
                                        errorText={errors.password}
                                    />
                                </div>
                                <button
                                    className="btn btn-primary d-block my-4 fw-bolder w-100"
                                    type="submit"
                                >
                                    Sign in
                                </button>
                            </form>
                        </div>
                    </div>
                    <DocsAppLogo />
                </div>
            </div>
        </>
    );
};

export default DocumentationLoginForm;
