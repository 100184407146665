import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'utils/http';
import { hideGlobalLoader, showGlobalLoader } from './appSlice';

export const initRoutes = createAsyncThunk(
    'routes/initRoutes',
    async ({ project, apiId }, { dispatch }) => {
        dispatch(isRoutesFetching(true));

        let chain = [
            dispatch(fetchRouteGroups({ projectUid: project, apiId })),
            dispatch(fetchRoutes({ project, apiId })),
        ];

        await Promise.all(chain);

        return dispatch(isRoutesFetching(false));
    },
);

export const fetchRouteGroups = createAsyncThunk(
    'routes/fetchRouteGroups',
    async ({ projectUid, apiId }) => {
        try {
            const response = await http.get(
                `/api/v1/api/${apiId}/project/${projectUid}/route-group`,
            );

            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    },
);

export const fetchRoutes = createAsyncThunk(
    'routes/fetchRoutes',
    async ({ project, apiId }) => {
        try {
            // dispatch(isRoutesFetching(true));
            const response = await http.get(
                `/api/v1/project/${project}/api/${apiId}/route`,
            );
            // dispatch(isRoutesFetching(false));
            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    },
);

export const fetchRoute = createAsyncThunk(
    'routes/fetchRoute',
    async ({ rid }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(isRouteFetching(true));
            const response = await http.get(`/api/v1/api/route/${rid}`);
            dispatch(isRouteFetching(false));

            return response.data.data;
        } catch (error) {
            rejectWithValue(error);
            dispatch(isRouteFetching(false));
        } finally {
            dispatch(isRouteFetching(false));
        }
    },
);

export const createRoute = createAsyncThunk(
    'routes/createRoute',
    async ({ data, apiId }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.post(
                `/api/v1/api/${apiId}/route`,
                data,
            );
            dispatch(hideGlobalLoader());
            return response.data.data;
        } catch (error) {
            dispatch(hideGlobalLoader());
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const updateRoute = createAsyncThunk(
    'routes/updateRoute',
    async ({ data, routeId }, { dispatch, rejectWithValue }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.put(
                `/api/v1/api/route/${routeId}`,
                data,
            );
            dispatch(hideGlobalLoader());
            return response.data.data;
        } catch (error) {
            dispatch(hideGlobalLoader());
            return rejectWithValue(error.response.data);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const createRouteGroup = createAsyncThunk(
    'routes/createRouteGroup',
    async ({ aid, data, handleResponse }) => {
        try {
            const response = await http.post(
                `/api/v1/api/${aid}/route-group`,
                data,
            );
            handleResponse(response);
        } catch (error) {
            handleResponse(error.response);
        }
    },
);

export const setRouteGroup = createAsyncThunk(
    'routes/setRouteGroup',
    async ({ id, data }) => {
        try {
            await http.patch(`/api/v1/api/route/${id}`, data);
        } catch (error) {
            console.log(error);
        }
    },
);

export const updateRouteGroup = createAsyncThunk(
    'routes/updateRouteGroup',
    async ({ id, data, handleResponse }) => {
        try {
            const response = await http.put(
                `/api/v1/api/route-group/${id}`,
                data,
            );
            handleResponse(response);
        } catch (error) {
            handleResponse(error.response);
        }
    },
);

export const deleteRoute = createAsyncThunk(
    'routes/deleteRoute',
    async ({ id }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.delete(`/api/v1/api/route/${id}`);
            dispatch(resetRoute());
            dispatch(hideGlobalLoader());
        } catch (error) {
            dispatch(hideGlobalLoader());
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const deleteRouteGroup = createAsyncThunk(
    'routes/deleteRouteGroup',
    async ({ id }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.delete(`/api/v1/api/route-group/${id}`);
            dispatch(hideGlobalLoader());
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const fetchRoutesTrash = createAsyncThunk(
    'routes/fetchRoutesTrash',
    async ({ project, apiId }, { dispatch }) => {
        try {
            dispatch(isRoutesTrashFetching(true));
            const response = await http.get(
                `/api/v1/project/${project}/api/${apiId}/route/trash`,
            );
            dispatch(isRoutesTrashFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isRoutesTrashFetching(false));
            console.log(error);
        } finally {
            dispatch(isRoutesTrashFetching(false));
        }
    },
);

export const restoreRoute = createAsyncThunk(
    'routes/restoreRoute',
    async ({ routeId }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.post(`/api/v1/route/${routeId}/restore`);
            dispatch(hideGlobalLoader());
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const permanentDeleteRoute = createAsyncThunk(
    'routes/permanentDeleteRoute',
    async ({ routeId }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.post(`/api/v1/route/${routeId}/destroy`);
            dispatch(hideGlobalLoader());
        } catch (error) {
            dispatch(hideGlobalLoader());
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const emptyRoutesTrash = createAsyncThunk(
    'routes/emptyRoutesTrash',
    async ({ pUid, apiId, handleResponse }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.delete(
                `/api/v1/project/${pUid}/api/${apiId}/route/trash`,
            );
            dispatch(hideGlobalLoader());
            handleResponse(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            handleResponse(error.response);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const getRecentHeaders = createAsyncThunk(
    'routes/getRecentHeaders',
    async ({ projectUid, apiUid }) => {
        try {
            const response = await http.get(
                `/api/v1/project/${projectUid}/api/${apiUid}/headers`,
            );

            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    },
);

export const copyRouteToApi = createAsyncThunk(
    'routes/copyRouteToApi',
    async (
        { routeId, data, onSuccess, onError, isCopyModalShown },
        { dispatch },
    ) => {
        try {
            dispatch(showGlobalLoader());
            await http.post(`/api/v1/project/api/route/${routeId}`, data);
            dispatch(hideGlobalLoader());
            onSuccess();
            isCopyModalShown(false);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const getPublicDocsRoutes = createAsyncThunk(
    'pages/getPublicDocsRoutes',
    async ({ apiId, token, onError }, { rejectWithValue }) => {
        try {
            const response = await http.get(`/api/v1/api/${apiId}/routes`, {
                headers: { 'Doc-Auth': token },
            });
            return response.data.data;
        } catch (error) {
            onError(error);
            return rejectWithValue(error);
        }
    },
);

export const getPublicDocsRoute = createAsyncThunk(
    'pages/getPublicDocsRoute',
    async ({ apiId, routeId, token }) => {
        try {
            const response = await http.get(
                `/api/v1/api/${apiId}/route/${routeId}`,
                {
                    headers: { 'Doc-Auth': token },
                },
            );
            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    },
);

export const getPublicDocsRoutesGroups = createAsyncThunk(
    'pages/getPublicDocsRoutesGroups',
    async ({ apiId, token, onError }, { rejectWithValue }) => {
        try {
            const response = await http.get(
                `/api/v1/api/${apiId}/route-groups`,
                {
                    headers: { 'Doc-Auth': token },
                },
            );
            return response.data.data;
        } catch (error) {
            onError(error);
            return rejectWithValue(error);
        }
    },
);

export const getRouteGroup = createAsyncThunk(
    'pages/getRouteGroup',
    async ({ apiId, groupId, token, onError }, thunkAPI) => {
        try {
            const response = await http.get(
                `/api/v1/api/${apiId}/route-group/${groupId}`,
                {
                    headers: { 'Doc-Auth': token },
                },
            );
            return response.data.data;
        } catch (error) {
            onError(error);
            return thunkAPI.rejectWithValue(error);
        }
    },
);

const initialState = {
    routes: [],
    routesTrash: [],
    isRoutesFetching: false,
    isRoutesTrashFetching: false,
    routesListSettings: {},

    route: {},
    headers: [],
    isRouteFetching: false,
    routeLoadingStatus: 'idle',

    groups: [],
    lastOpenGroupId: null,

    docsRoutes: [],
    isDocsRoutesFetching: false,
    docsRoutesGroups: [],
    docsRoutesGroup: {},
    docsRoute: {},
    isDocsRouteFetching: false,
};

export const routesSlice = createSlice({
    name: 'routes',
    initialState,
    reducers: {
        isRoutesTrashFetching(state, action) {
            state.isRoutesTrashFetching = action.payload;
        },
        isRoutesFetching(state, action) {
            state.isRoutesFetching = action.payload;
        },
        isRouteFetching(state, action) {
            state.isRouteFetching = action.payload;
        },
        resetRoute(state) {
            state.route = {};
        },
        setLastOpenGroupId(state, action) {
            state.lastOpenGroupId = action.payload;
        },
        setRoutesListSettings(state, action) {
            state.routesListSettings = action.payload;
        },
    },
    extraReducers: {
        [fetchRouteGroups.fulfilled]: (state, action) => {
            state.groups = action.payload;
        },
        [fetchRoutes.fulfilled]: (state, action) => {
            state.routes = action.payload;
        },
        [fetchRoute.pending]: (state) => {
            state.routeLoadingStatus = 'loading';
        },
        [fetchRoute.fulfilled]: (state, action) => {
            state.route = action.payload;
            state.routeLoadingStatus = 'succeeded';
        },
        [fetchRoutesTrash.fulfilled]: (state, action) => {
            state.routesTrash = action.payload;
        },
        [getRecentHeaders.fulfilled]: (state, action) => {
            state.headers = action.payload;
        },
        [getPublicDocsRoutes.pending]: (state) => {
            state.isDocsRoutesFetching = true;
        },
        [getPublicDocsRoutes.fulfilled]: (state, action) => {
            state.docsRoutes = action.payload;
            state.isDocsRoutesFetching = false;
        },
        [getPublicDocsRoutes.rejected]: (state) => {
            state.isDocsRoutesFetching = false;
        },
        [getPublicDocsRoutesGroups.fulfilled]: (state, action) => {
            state.docsRoutesGroups = action.payload;
        },
        [getPublicDocsRoute.pending]: (state) => {
            state.isDocsRouteFetching = true;
        },
        [getPublicDocsRoute.fulfilled]: (state, action) => {
            state.docsRoute = action.payload;
            state.isDocsRouteFetching = false;
        },
        [getPublicDocsRoute.rejected]: (state) => {
            state.isDocsRouteFetching = false;
        },
    },
});

// Actions

export const {
    isRoutesTrashFetching,
    isRoutesFetching,
    isRouteFetching,
    resetRoute,
    setLastOpenGroupId,
    setRoutesListSettings,
} = routesSlice.actions;

// Selectors

export const routeSelectors = {
    getCurrentRoute: (state) => state.routes.route,
    getRoutes: (state) => state.routes.routes,
    getRoutesTrash: (state) => state.routes.routesTrash,
    getIsRoutesFetching: (state) => state.routes.isRoutesFetching,
    getIsRouteFetching: (state) => state.routes.isRouteFetching,
    getRouteLoadingStatus: (state) => state.routes.routeLoadingStatus,
    getGroups: (state) => state.routes.groups,
    getHeaders: (state) => state.routes.headers,
    getLastOpenGroupId: (state) => state.routes.lastOpenGroupId,
    getRoutesListSettings: (state) => state.routes.routesListSettings,
    getDocsRoutes: (state) => state.routes.docsRoutes,
    getIsDocsRoutesFetching: (state) => state.routes.isDocsRoutesFetching,
    getDocsRoutsGroups: (state) => state.routes.docsRoutesGroups,
    getDocsRoutsGroup: (state) => state.routes.docsRoutesGroup,
    getDocsRoute: (state) => state.routes.docsRoute,
    getIsDocsRouteFetching: (state) => state.routes.isDocsRouteFetching,
};

export default routesSlice.reducer;
