import { RootNode, RegularNode, ReferenceNode } from './nodes';
import {
    findRef,
    resolveRefChain,
    isPrimitiveType,
    resolveType,
    createShallowCopy,
    isEmptyObject,
    cache,
    hasCombiner,
    isCombinerType,
} from './utils';

export class Builder {
    constructor(schema, parent = null, options) {
        this.schemaName = Object.keys(schema)[0] || 'schema';
        this.schema = schema[this.schemaName];
        this.parent = parent;
        this.depth = options?.depth || 0;
        this.root = new RootNode(this.schema, this.parent, {
            detached: !!options?.detached,
            models: options?.models,
            depth: this.depth,
            isCombinerChild: options?.isCombinerChild,
        }).data;
        this.dataArray = [];
        this.detached = !!options?.detached;
        this.models = options?.models || [];
        this.collapsed = new Set();

        this.dataArray.push(this.root);
    }

    build(node = this.root) {
        if (node.type === '$ref' || node.subType === '$ref') {
            const ref = findRef(this.models, node.refName);
            if (cache.has(node.refName)) return;
            if (!ref) return;

            cache.add(node.refName);
            this.collapsed.add(node.id);
            const resolved = ref && resolveRefChain(ref, this.models);

            if (Object.keys(resolved)[0] === this.schemaName) return;

            this.depth++;
            const builded = new Builder(resolved, node, {
                detached: true,
                models: this.models,
                depth: this.depth,
            });
            builded.build();
            cache.delete(node.refName);
            this.depth--;
            this.dataArray.push(...builded.dataArray);
        }

        if (node.type[0] === 'object' || node?.subType?.[0] === 'object') {
            if (node.metadata.children) {
                this.depth++;
                this.propertiesTraversal(node);
                this.depth--;
            }
        }

        if (isCombinerType(node.type[0])) {
            if (node.metadata.children?.length) {
                for (const item of node.metadata.children) {
                    this.depth++;
                    const builded = new Builder({ schema: { ...item } }, node, {
                        detached: false,
                        models: this.models,
                        depth: this.depth,
                        isCombinerChild: true,
                    });
                    builded.build();
                    this.depth--;
                    this.dataArray.push(...builded.dataArray);
                }
            }
        }
    }

    propertiesTraversal(node) {
        if (!node.metadata.children) return;

        const keys = Object.keys(node.metadata.children);

        for (const key of keys) {
            const isRequired =
                !!node.metadata.fragment?.required?.includes(key);
            if (
                isPrimitiveType(node.metadata.children[key].type) ||
                (!node.metadata.children[key].type &&
                    node.metadata.children[key].enum)
            ) {
                this.dataArray.push(
                    new RegularNode(
                        { [key]: node.metadata.children[key] },
                        node,
                        {
                            isRequired,
                            detached: this.detached,
                            depth: this.depth,
                        },
                    ).data,
                );
            }

            if (resolveType(node.metadata.children[key].type) === 'array') {
                if (
                    !node.metadata.children[key]?.items ||
                    isPrimitiveType(node.metadata.children[key]?.items.type) ||
                    isEmptyObject(node.metadata.children[key]?.items)
                ) {
                    const newNode = new RegularNode(
                        { [key]: node.metadata.children[key] },
                        node,
                        {
                            isRequired,
                            detached: this.detached,
                            depth: this.depth,
                        },
                    ).data;
                    this.dataArray.push(newNode);
                }

                if (node.metadata.children[key]?.items?.type === 'object') {
                    const newNode = new RegularNode(
                        { [key]: node.metadata.children[key] },
                        node,
                        {
                            isRequired,
                            detached: this.detached,
                            depth: this.depth,
                        },
                    ).data;
                    this.dataArray.push(newNode);
                    this.depth++;
                    this.propertiesTraversal(newNode);
                    this.depth--;
                }

                if (node.metadata.children[key]?.items?.$ref) {
                    const newNode = new ReferenceNode(
                        { [key]: node.metadata.children[key] },
                        node,
                        {
                            isRequired,
                            detached: this.detached,
                            models: this.models,
                            depth: this.depth,
                        },
                    ).data;

                    this.collapsed.add(newNode.id);
                    this.dataArray.push(newNode);
                    this.build(newNode);
                }
            }

            if (resolveType(node.metadata.children[key].type) === 'object') {
                const newNode = new RegularNode(
                    { [key]: node.metadata.children[key] },
                    node,
                    {
                        isRequired,
                        detached: this.detached,
                        depth: this.depth,
                    },
                ).data;
                this.dataArray.push(newNode);
                this.depth++;
                this.propertiesTraversal(newNode);
                this.depth--;
            }

            if (node.metadata.children[key].$ref) {
                const newNode = new ReferenceNode(
                    { [key]: node.metadata.children[key] },
                    node,
                    {
                        isRequired,
                        detached: this.detached,
                        models: this.models,
                        depth: this.depth,
                    },
                ).data;
                this.dataArray.push(newNode);
                this.collapsed.add(newNode.id);
                const ref = findRef(this.models, newNode.refName);

                if (ref) {
                    this.build(newNode);
                }
            }

            if (hasCombiner(node.metadata.children[key])) {
                const newNode = new RegularNode(
                    { [key]: node.metadata.children[key] },
                    node,
                    {
                        isRequired,
                        detached: this.detached,
                        depth: this.depth,
                    },
                ).data;
                this.dataArray.push(newNode);

                if (newNode.metadata.children?.length) {
                    for (const item of newNode.metadata.children) {
                        this.depth++;
                        const builded = new Builder(
                            { schema: { ...item } },
                            newNode,
                            {
                                detached: false,
                                models: this.models,
                                depth: this.depth,
                                isCombinerChild: true,
                            },
                        );
                        builded.build();
                        this.depth--;
                        this.dataArray.push(...builded.dataArray);
                    }
                }
            }
        }
    }

    buildTree(dataArray) {
        const dataCopy = createShallowCopy(dataArray);
        const dataMap = new Map(dataCopy.map((item) => [item.id, item]));

        for (let item of dataMap.values()) {
            if (!dataMap.has(item?.parent?.id)) {
                continue;
            }
            const parent = dataMap.get(item.parent.id);
            parent.children = [...(parent.children || []), item];
        }
        return [...dataMap.values()].filter((item) => !item.parent);
    }
}
