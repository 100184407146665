import React from 'react';
import PageHeader from 'ui/PageHeader';
import AccessForm from './AccessForm';
import { useSelector } from 'react-redux';
import { organizationSelectors } from 'store/slices/organizationSlice';

export const DocumentationContainer = () => {
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    return (
        <div className="row">
            <PageHeader>Documentation access</PageHeader>
            <div className="col-lg-12 col-xl-6">
                {currentOrg ? (
                    <AccessForm />
                ) : (
                    <>
                        <p>Documentation is not enabled for your plan.</p>
                        <p>Please upgrade your plan to use documentation</p>
                    </>
                )}
            </div>
            <div className="w-100 d-lg-none"></div>
        </div>
    );
};

export default DocumentationContainer;
