import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'utils/http';
import { hideGlobalLoader, showGlobalLoader } from './appSlice';

export const updateDocumentationAccess = createAsyncThunk(
    'documentation/updateDocumentationAccess',
    async ({ projectUid, apiUid, data, onSuccess, onError }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            const response = await http.put(
                `/api/v1/project/${projectUid}/api/${apiUid}/documentation`,
                data,
            );
            dispatch(hideGlobalLoader());
            onSuccess(response);
        } catch (error) {
            dispatch(hideGlobalLoader());
            onError(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

export const getDocumentationUsers = createAsyncThunk(
    'documentation/getDocumentationUsers',
    async ({ id, onSuccess }, { dispatch }) => {
        try {
            dispatch(isDocumentationUsersFetching(true));
            const response = await http.get(
                `/api/v1/api/${id}/documentation/users`,
            );
            onSuccess(response);
            dispatch(isDocumentationUsersFetching(false));
        } catch (error) {
            console.log(error);
            dispatch(isDocumentationUsersFetching(false));
        } finally {
            dispatch(isDocumentationUsersFetching(false));
        }
    },
);

export const getApiDocumentation = createAsyncThunk(
    'documentation/getApiDocumentation',
    async ({ apiCode, onSuccess, onError }, { rejectWithValue }) => {
        try {
            const response = await http.get(`/api/v1/api/public/${apiCode}`);
            onSuccess(response);

            return response.data.data;
        } catch (error) {
            onError(error);
        }
    },
);

export const loginDocumentation = createAsyncThunk(
    'documentation/loginDocumentation',
    async ({ data, onSuccess, onError }, { rejectWithValue }) => {
        try {
            const response = await http.post(
                `/api/v1/documentation/login`,
                data,
            );
            onSuccess(response);
        } catch (error) {
            onError(error);
        }
    },
);

const initialState = {
    api: {},
    environments: [],
    selectedEnvironment: {},
    isApiInformationFetching: false,
    isDocumentationUsersFetching: false,
};

export const documentationSlice = createSlice({
    name: 'documentation',
    initialState,
    reducers: {
        isDocumentationUsersFetching(state, action) {
            state.isDocumentationUsersFetching = action.payload;
        },
    },
    extraReducers: {
        [getApiDocumentation.pending]: (state) => {
            state.isApiInformationFetching = true;
        },
        [getApiDocumentation.fulfilled]: (state, action) => {
            state.api = action.payload;
            state.isApiInformationFetching = false;
        },
        [getApiDocumentation.rejected]: (state) => {
            state.isApiInformationFetching = false;
        },
    },
});

// Actions

export const { isDocumentationUsersFetching } = documentationSlice.actions;

// Selectors

export const documentationSelectors = {
    getIsDocumentationUsersFetching: (state) =>
        state.documentation.isDocumentationUsersFetching,
    getApi: (state) => state.documentation.api,
    getEnvironments: (state) => state.documentation.environments,
    getSelectedEnvironment: (state) => state.documentation.selectedEnvironment,
    getIsApiInformationFetching: (state) =>
        state.documentation.isApiInformationFetching,
    getShowGlobalLoader: (state) => state.documentation.showGlobalLoader,
};

export default documentationSlice.reducer;
