import React from 'react';
import { AutoSuggestInput } from 'ui/Inputs';
import { headers, headersValues } from 'shared/data';

export default function HeadersItem({
    header,
    handleChange,
    deleteRow,
    selectRow,
    selectedHeaders,
}) {
    const isSelected = selectedHeaders.includes(header.uid);

    return (
        <tr>
            <td className="align-middle">
                <input
                    type="checkbox"
                    name="is_active"
                    className="form-check-input align-baseline"
                    onChange={() => selectRow(header.uid)}
                    defaultChecked={isSelected}
                />
            </td>
            <td>
                <AutoSuggestInput
                    name="name"
                    placeholder="Header name"
                    options={headers}
                    value={header?.name}
                    handleChange={handleChange}
                    className="form-control"
                />
            </td>
            <td>
                <AutoSuggestInput
                    name="value"
                    placeholder="Header value"
                    options={headersValues}
                    value={header?.value}
                    handleChange={handleChange}
                    className="form-control"
                />
            </td>

            <td className="text-end align-middle">
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={deleteRow}
                >
                    -
                </button>
            </td>
        </tr>
    );
}
