import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'utils/http';

export const getComments = createAsyncThunk(
    'comments/getComments',
    async ({ type, typeId, start }, { dispatch }) => {
        try {
            if (start < 20) {
                dispatch(isCommentsFetching(true));
            }
            const response = await http.get(
                `/api/v1/comment/${type}/${typeId}?start=${start}&limit=20`,
            );
            dispatch(isCommentsFetching(false));

            return response.data.data;
        } catch (error) {
            dispatch(isCommentsFetching(false));
            console.log(error);
        } finally {
            dispatch(isCommentsFetching(false));
        }
    },
);

export const getComment = createAsyncThunk(
    'comments/getComment',
    async ({ commentId, onSuccess }, { dispatch }) => {
        try {
            dispatch(isCommentFetching(true));
            const response = await http.get(`/api/v1/comment/${commentId}`);
            dispatch(isCommentFetching(false));
            onSuccess(response);

            return response.data.data;
        } catch (error) {
            dispatch(isCommentFetching(false));
        } finally {
            dispatch(isCommentFetching(false));
        }
    },
);

export const createComment = createAsyncThunk(
    'comments/createComment',
    async ({ data, type, typeId, onSuccess, onError }) => {
        try {
            const response = await http.post(
                `/api/v1/comment/${type}/${typeId}`,
                data,
            );
            onSuccess(response);
        } catch (error) {
            onError(error);
        }
    },
);

export const updateComment = createAsyncThunk(
    'comments/updateComment',
    async ({ data, commentId, onSuccess, onError }, { dispatch }) => {
        try {
            await http.put(`/api/v1/comment/${commentId}`, data);
            onSuccess();
        } catch (error) {
            onError(error);
        }
    },
);

export const deleteComment = createAsyncThunk(
    'comments/deleteComment',
    async ({ commentId, onSuccess }, { dispatch }) => {
        try {
            await http.delete(`/api/v1/comment/${commentId}`);
            onSuccess();
        } catch (error) {
            console.log(error);
        }
    },
);

const initialState = {
    comments: [],
    comment: {},
    start: 0,
    isCommentFetching: false,
    isCommentsFetching: false,
};

export const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        isCommentFetching(state, action) {
            state.isCommentFetching = action.payload;
        },
        isCommentsFetching(state, action) {
            state.isCommentsFetching = action.payload;
        },
        resetComments(state) {
            state.comments = [];
        },
        resetCommentsStart(state) {
            state.start = 0;
        },
        setCommentsStart(state, action) {
            state.start = action.payload;
        },
    },
    extraReducers: {
        [getComments.fulfilled]: (state, action) => {
            state.comments = [...state.comments, ...action.payload];
        },
        [getComment.fulfilled]: (state, action) => {
            state.comment = action.payload;
        },
    },
});

// Actions

export const {
    isCommentsFetching,
    isCommentFetching,
    resetComments,
    resetCommentsStart,
    setCommentsStart,
} = commentsSlice.actions;

// Selectors

export const commentSelectors = {
    getComments: (state) => state.comments.comments,
    getComment: (state) => state.comments.comment,
    getCommentsStart: (state) => state.comments.start,
    getIsCommentsFetching: (state) => state.comments.isCommentsFetching,
};

export default commentsSlice.reducer;
