import { setCookie, getCookie, deleteCookie } from './cookie';
import http from './http';

export function isLoggedIn() {
    if (!getCookie('access_token')) {
        return false;
    }
    return true;
}

export function requireLogin(to, from, next) {
    if (to.meta.auth) {
        if (isLoggedIn()) {
            next();
        }
        next.redirect('/login');
    } else {
        next();
    }
}

export function saveTokens(data) {
    //const { access_token, refresh_token } = data;
    const keep_logged = JSON.parse(window.localStorage.getItem('remember_me'));
    const { access_token } = data;
    const cookieOptions =
        process.env.NODE_ENV === 'development'
            ? {}
            : { domain: process.env.REACT_APP_DOMAIN };

    setCookie('access_token', access_token, cookieOptions, keep_logged);
    //setCookie('refresh_token', refresh_token, {}, keep_logged);

    // Change Authorization header for the next requests
    http.defaults.headers['Authorization'] = `Bearer ${access_token}`;
}

export function deleteTokens() {
    deleteCookie('access_token');
    //deleteCookie('refresh_token');
}
