import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// import arrowIconDown from "assets/img/arrows/arrow-down.svg";
// import arrowIconUp from "assets/img/arrows/arrow-up.svg";
import TextEditor from 'components/TextEditor/TextEditor';
import CommentsList from './CommentsList';
import { routeSelectors } from 'store/slices/routesSlice';
import { authSelectors } from 'store/slices/authSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import {
    createComment,
    getComments,
    resetComments,
    resetCommentsStart,
} from 'store/slices/commentsSlice';
import History from './History';
import { organizationSelectors } from 'store/slices/organizationSlice';

const Comments = ({ type }) => {
    const dispatch = useDispatch();
    const route = useSelector(routeSelectors.getCurrentRoute);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const project = useSelector(projectSelectors.getCurrentProject);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const [description, setDescription] = useState('');
    const [errors, setErrors] = useState({});
    // const [showOldestSort, setShowFirstSort] = useState(false);
    const [hasFocus, setFocus] = useState(false);
    const [isShowComments, setIsShowComments] = useState(true);

    const hasOwnerRights =
        (project.owner.id === myAccount.id && currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project.user_role === 'MAINTAINER' ||
        project.user_role === 'WRITE' ||
        currentOrg?.role === 'MANAGER';
    const isAccessToCreateComment = !!currentOrg?.plan?.is_comments_enabled;
    const isAccessToActivateHistory = !!currentOrg?.plan?.is_history_enabled;

    const handleEditorChange = (editorData) => {
        setDescription(editorData);
    };

    const onChangeFocus = () => {
        setFocus(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newDescr = description?.replace(/<br ?\/?>/gi, '\n');
        const data = {
            body: newDescr,
        };

        dispatch(
            createComment({
                data,
                type,
                typeId: route.id,
                onSuccess: onSuccessCommentCreate,
                onError: onErrorCommentCreate,
            }),
        );
    };

    const onSuccessCommentCreate = () => {
        toast.success('The comment has been created');
        dispatch(resetComments());
        dispatch(resetCommentsStart());
        dispatch(getComments({ type, typeId: route.id, start: 0 }));
        setDescription('');
        setFocus(false);
        setErrors({});
    };

    const onErrorCommentCreate = (error) => {
        setErrors(error?.response.data.errors);
    };

    const toggleShowCommentsBlock = () => {
        setIsShowComments(!isShowComments);

        if (!isShowComments && isAccessToCreateComment) {
            dispatch(getComments({ type, typeId: route.id, start: 0 }));
        }
    };

    return (
        <section className="pt-5">
            <h5 className="title-h4 pb-3 fw-bold">Activity</h5>
            <div className="d-flex justify-content-between align-items-center pb-4">
                <div className="d-flex align-items-center">
                    <p>Show:</p>
                    <button
                        className={`comment-button mx-3 ${
                            isShowComments && 'comment-button--active'
                        }`}
                        onClick={toggleShowCommentsBlock}
                    >
                        Comments
                    </button>
                    <button
                        className={`comment-button mx-2 ${
                            !isShowComments && 'comment-button--active'
                        }`}
                        onClick={toggleShowCommentsBlock}
                    >
                        History
                    </button>
                </div>
                {/* {!showOldestSort ? (
                    <button
                        className="d-flex align-items-center comment-button comment-button--color"
                        onClick={() => setShowFirstSort(true)}
                    >
                        <span className="px-2">Newest first</span>
                        <img src={arrowIconDown} alt="" />
                    </button>
                ) : (
                    <button
                        className="d-flex align-items-center comment-button comment-button--color"
                        onClick={() => setShowFirstSort(false)}
                    >
                        <span className="px-2">Oldest first</span>
                        <img src={arrowIconUp} alt="" />
                    </button>
                )} */}
            </div>
            {isShowComments && hasRights && (
                <form onSubmit={handleSubmit} className="w-100">
                    <div className="d-flex align-items-start">
                        {isAccessToCreateComment && (
                            <div style={{ marginRight: '16px' }}>
                                <img
                                    src={myAccount?.avatar_url}
                                    alt="avatar"
                                    className="comment-image"
                                />
                            </div>
                        )}
                        {isAccessToCreateComment ? (
                            <div className="form-group w-100">
                                {!hasFocus ? (
                                    <input
                                        placeholder="Add a comment..."
                                        className="form-control"
                                        onFocus={onChangeFocus}
                                    />
                                ) : (
                                    <>
                                        <TextEditor
                                            description={description}
                                            handleEditorChange={
                                                handleEditorChange
                                            }
                                            errorText={errors?.body}
                                            editorClassName={'ck-line-height'}
                                            showEditorRef={true}
                                            textEditorForComment={true}
                                        />
                                        <button
                                            type="submit"
                                            className="btn btn-primary mt-4 pl-3"
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-link-main mt-4"
                                            onClick={() => setFocus(!hasFocus)}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="pt-2 w-100 d-flex justify-content-center mb-4">
                                To add comments please upgrade your plan
                            </div>
                        )}
                    </div>
                </form>
            )}
            {isShowComments && (
                <CommentsList
                    type={type}
                    hasFocus={hasFocus}
                    isAccessToCreateComment={isAccessToCreateComment}
                />
            )}

            {!isShowComments && (
                <History
                    isAccessToActivateHistory={isAccessToActivateHistory}
                />
            )}
        </section>
    );
};

Comments.propTypes = {
    type: PropTypes.string.isRequired,
};
export default Comments;
