import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx';
import JSONPretty from 'react-json-pretty';
import { Copy } from 'react-feather';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import RequestHeadersView from 'pages/Routes/RouteView/RequestHeadersView';
import RequestParamsView from 'pages/Routes/RouteView/RequestParamsView';
import ResponseExampleView from 'pages/Routes/RouteView/ResponseExampleView';
import { countRouteMethod } from 'utils/helpers';
import PreBlock from 'ui/PreBlock';

export default function TableDetails({ route, isAfter }) {
    const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

    const onCopyToClipboard = () => {
        if (isCopiedToClipboard) return;

        setIsCopiedToClipboard(true);
        setTimeout(() => setIsCopiedToClipboard(false), 1500);
    };

    return (
        <>
            <div
                className="p-2 mb-3"
                style={{
                    backgroundColor: 'rgb(246, 247, 249)',
                    borderRadius: '10px',
                }}
            >
                <h3
                    className="title-h4 word-break fw-bold text-center"
                    style={{
                        color: isAfter
                            ? 'rgb(34, 142, 55)'
                            : 'rgba(255, 0, 0, .8)',
                    }}
                >
                    {isAfter ? 'AFTER' : 'BEFORE'}
                </h3>
            </div>
            <div className="d-flex">
                <h3 className="title-h4 mb-3 word-break fw-bold">
                    {route.name || route.url}
                </h3>
            </div>
            {route.description && (
                <div className="mb-3">
                    <div>
                        <Markdown
                            options={{
                                overrides: {
                                    pre: PreBlock,
                                },
                            }}
                            className="mb-4 ck"
                        >
                            {route.description}
                        </Markdown>
                    </div>
                </div>
            )}
            <div className="mb-3">
                <h5 className="mb-2 title-h5">HTTP Request</h5>
                <div className="badge-route word-break">
                    {countRouteMethod(route.method, {
                        fontSize: 14,
                        lineHeight: '16px',
                        padding: '4px 8px',
                        backgroundColor: '#F6F7F9',
                        borderRadius: 4,
                        marginRight: 10,
                        height: 'auto',
                        minWidth: 'auto',
                    })}
                    {route.url}
                </div>
            </div>
            {route.request_example && (
                <div className="mb-3">
                    <h5 className="mb-2 title-h5">Request Body</h5>
                    <p className="mb-2">
                        The request body contains data with the following
                        structure:
                    </p>
                    <div className="position-relative">
                        <JSONPretty data={JSON.parse(route.request_example)} />
                        <CopyToClipboard
                            text={route.request_example}
                            onCopy={onCopyToClipboard}
                        >
                            {isCopiedToClipboard ? (
                                <span className="json-copy-message">
                                    Copied
                                </span>
                            ) : (
                                <Copy className="json-copy" />
                            )}
                        </CopyToClipboard>
                    </div>
                </div>
            )}
            <div>
                <RequestHeadersView items={route.headers} />
                <RequestParamsView
                    items={route.path_parameters}
                    title="Path parameters"
                />
                <RequestParamsView
                    items={route.query_parameters}
                    title="Query parameters"
                />
                <RequestParamsView
                    items={route.body_parameters}
                    resources={route.resources}
                    title="Body parameters"
                />
                <RequestParamsView
                    items={route.form_data_parameters}
                    title="Form Data parameters"
                />
            </div>
            <ResponseExampleView items={route.responses} />
        </>
    );
}
