import React from 'react';
import { Helmet } from 'react-helmet';
import WelcomeContact from './ContactSection';
import WelcomeHeader from 'layouts/welcome/WelcomeHeader';
import FeatureSection from './FeatureSection';
import IntroSection from './IntroSection';
import PricingPage from './PricingPage';

const IndexPage = () => {
    return (
        <div className="landing-page">
            <Helmet>
                <title>
                    CDProjects - Create beautiful REST API Documentations
                </title>
            </Helmet>
            <WelcomeHeader />
            <IntroSection />
            <FeatureSection />
            <PricingPage />
            <WelcomeContact />
        </div>
    );
};

export default IndexPage;
