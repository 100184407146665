import React, { useState, useEffect } from 'react';
import PricingCard from './PricingCard';

const PricingPage = () => {
    const [subscription, setSubscription] = useState(null);

    const addSubscription = (item) => {
        if (item) {
            setSubscription(item);
        }
    };

    useEffect(() => {
        if (subscription) {
            const data = {
                subscription: subscription,
                pay: 'monthly',
            };

            localStorage.setItem('subscription', JSON.stringify(data));
        }
    }, [subscription]);

    return (
        <>
            <section className="section-pricing" id="pricing-section">
                <div className="container section-pricing__content">
                    <div className="">
                        <h3 className="section-pricing__title">
                            We’ve got a plan that’s perfect for you{' '}
                        </h3>
                    </div>
                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-center my-3 my-md-5">
                        <PricingCard
                            subscription={'Free'}
                            price={'0'}
                            description={'For Your Startup'}
                            term={'Monthly'}
                            features={'Everything you need for startup...'}
                            amountUsers={'10'}
                            amountMemory={'2'}
                            support={'Email support'}
                            textButton={'Sign Up for Free'}
                            status={'free'}
                            addSubscription={addSubscription}
                        />
                        <PricingCard
                            subscription={'Medium'}
                            price={'49'}
                            description={'For Medium Teams'}
                            term={'Monthly'}
                            features={'Everything in free plan plus...'}
                            amountUsers={'20'}
                            amountMemory={'10'}
                            support={'Priority email support'}
                            textButton={'Get Started'}
                            style={{ margin: '0 32px' }}
                            status={'medium'}
                            addSubscription={addSubscription}
                        />
                        <PricingCard
                            subscription={'Enterprise'}
                            price={'Individually'}
                            term={'Monthly'}
                            description={'For Big Companies Unlimited APIs'}
                            features={'Everything in medium plan plus...'}
                            amountUsers={'30'}
                            amountMemory={'15'}
                            support={'Phone and email support'}
                            textButton={'Contact us'}
                            status={'enterprise'}
                            addSubscription={addSubscription}
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default PricingPage;
