import React from 'react';
import iconCheck from 'assets/img/landing/Icon-checkmark.svg';
import { useSelector } from 'react-redux';
import { organizationSelectors } from 'store/slices/organizationSlice';
import { combineClasses as cc } from 'utils/helpers';

const pricingCardItemContent = [
    // { content: 'Unlimited Projects', free: '1 Project', medium: '10 Projects' },
    // { content: 'Unlimited APIs', free: '1 API', medium: '10 APIs' },
    // { content: 'Unlimited Users', free: '1 User', medium: '10 Users' },
    { content: 'Multiple environments' },
    { content: 'Import/Export your collections (Postman, Swagger)' },
    {
        content: 'Support Premium',
        free: 'Support Standart',
        medium: 'Support Standart',
    },
    {
        content: 'Public documentation under your company domain',
        lineThrough: true,
    },
    { content: 'Change history', lineThrough: true },
    { content: 'Comments', lineThrough: true },
    { content: 'SSO / Audit logs', lineThrough: true, popular: true },
    {
        content: 'Guaranteed uptime SLA 99.9%',
        lineThrough: true,
        free: 'Guaranteed uptime SLA',
        medium: 'Guaranteed uptime SLA',
        popular: true,
    },
];

const Card = (props) => {
    const {
        subscription,
        status,
        price,
        term,
        addSubscription,
        plan,
        showButton = false,
        maxProjects,
        maxApis,
        maxUsers,
        description,
    } = props;
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const isCurrentPlan = currentOrg?.plan?.code === plan?.code;

    const handleButton = () => {
        addSubscription(plan);
    };

    return (
        <div className={'card pricing-card mx-4'}>
            <div className="card-body p-md-4">
                <div className="pricing-card__header">
                    <p className="mb-3 pricing-card__subscription">
                        {subscription}
                    </p>
                    <div className="d-flex align-items-center mb-3">
                        <h2 className="pricing-card__title">{price}</h2>
                        <p className="mx-2 pricing-card__text">{term}</p>
                    </div>
                    <p className="mb-4 pricing-card__text pricing-card__text-font">
                        {description}
                    </p>
                </div>
                <div
                    className="text-center mb-3"
                    style={{ width: 'fit-content' }}
                >
                    {showButton && (
                        <button
                            className="btn btn-block btn-primary mx-auto w-100 pricing-card__btn"
                            disabled={isCurrentPlan}
                            onClick={handleButton}
                        >
                            {isCurrentPlan ? 'Current plan' : 'Select plan'}
                        </button>
                    )}
                </div>
                <div className="pricing-card__divider" />
                <ul className="list-unstyled mt-3 pricing-card__list w-100">
                    <li className="pricing-card__item" key="project">
                        <div className="d-flex align-items-center">
                            <img
                                src={iconCheck}
                                alt="checkmark"
                                className="pricing-card__icon"
                            />
                            <p>{maxProjects} Projects</p>
                        </div>
                    </li>
                    <li className="pricing-card__item" key="apis">
                        <div className="d-flex align-items-center">
                            <img
                                src={iconCheck}
                                alt="checkmark"
                                className="pricing-card__icon"
                                style={{
                                    color: '#00BF80',
                                }}
                            />
                            <p>{maxApis} APIs</p>
                        </div>
                    </li>
                    <li className="pricing-card__item" key="users">
                        <div className="d-flex align-items-center">
                            <img
                                src={iconCheck}
                                alt="checkmark"
                                className="pricing-card__icon"
                                style={{
                                    color: '#00BF80',
                                }}
                            />
                            <p>{maxUsers} Users</p>
                        </div>
                    </li>
                    {pricingCardItemContent.map((item, index) => (
                        <li
                            className={cc('pricing-card__item', {
                                'pricing-card__item--status':
                                    item.lineThrough && status === 'free',
                            })}
                            style={{
                                opacity:
                                    item.popular && status === 'medium'
                                        ? '0.4'
                                        : '',
                                textDecorationLine:
                                    item.popular && status === 'medium'
                                        ? 'line-through'
                                        : '',
                            }}
                            key={index}
                        >
                            <div className="d-flex align-items-center">
                                <img
                                    src={iconCheck}
                                    alt="checkmark"
                                    className="pricing-card__icon"
                                    style={{
                                        color:
                                            (item.lineThrough &&
                                                status === 'free') ||
                                            (item.popular &&
                                                status === 'medium')
                                                ? '#001A37'
                                                : '#00BF80',
                                    }}
                                />
                                <p>
                                    {status === 'free' &&
                                        `${item.free || item.content}`}
                                    {status === 'medium' &&
                                        `${item?.medium || item.content}`}
                                    {status === 'enterprise' &&
                                        `${item.content}`}
                                </p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Card;
