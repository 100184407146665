import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Input } from 'ui/Inputs';
import WelcomeHeader from 'layouts/welcome/WelcomeHeader';
import { saveTokens } from 'utils/auth';
import googleLogo from 'assets/img/google_logo.svg';
import { getProviderUrl, login } from 'store/slices/authSlice';

const LoginPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [data, setData] = useState({
        email: '',
        password: '',
        remember_me: false,
    });
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;

        setData({ ...data, [name]: value });
    };

    const handleChangeCheckbox = (event) => {
        const { checked } = event.target;
        data.remember_me = checked;
        window.localStorage.setItem('remember_me', data.remember_me);

        setData({ ...data });
    };

    const handleClick = (provider, event) => {
        event.preventDefault();
        const { data } = {
            data: {
                provider: provider,
            },
            errors: {},
        };
        dispatch(
            getProviderUrl({ data, onSuccess: onSuccessRedirect, onError }),
        );
    };

    const onSuccessRedirect = (response) => {
        window.location.href = response.data.url;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(login({ data, onSuccess, onError }));
    };

    const onSuccess = (response) => {
        saveTokens(response.data);
        history.push('/projects');
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    return (
        <>
            <Helmet>
                <title>
                    Login - CDProjects - Create beautiful REST API
                    Documentations
                </title>
            </Helmet>
            <section className="welcome-common-form">
                <WelcomeHeader />
                <div className="container welcome-common-form__container">
                    <div className="welcome-common-form__content">
                        <div className="welcome-common-form__form">
                            <h1 className="welcome-common-form__title">
                                Log In to{' '}
                                <span className="welcome-common-form__title welcome-common-form__title--color">
                                    CDProjects
                                </span>
                            </h1>
                            <div
                                className="btn-group welcome-common-form__google"
                                role="group"
                                aria-label="Second group"
                            >
                                <button
                                    onClick={(e) => handleClick('GOOGLE', e)}
                                    type="button"
                                    className="btn welcome-common-form__with-google d-block"
                                >
                                    <img
                                        src={googleLogo}
                                        alt="google"
                                        className="welcome-common-form__google-image"
                                    />
                                    Log In with Google
                                </button>
                            </div>
                            <div className="welcome-common-form__divider">
                                OR
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group welcome-common-form__form-group">
                                    <Input
                                        type="text"
                                        name="email"
                                        className="form-control welcome-common-form__control"
                                        value={data.email}
                                        onChange={handleChange}
                                        labelClassName="welcome-common-form__label"
                                        labelText="Email"
                                        placeHolder="Enter your email"
                                        errorText={errors.email}
                                    />
                                </div>
                                <div className="form-group welcome-common-form__form-group">
                                    <Input
                                        type="password"
                                        name="password"
                                        className="form-control welcome-common-form__control"
                                        value={data.password}
                                        onChange={handleChange}
                                        labelClassName="welcome-common-form__label"
                                        labelText="Password"
                                        placeHolder="Enter your password"
                                        errorText={errors.password}
                                    />
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <input
                                            className="form-check-input mt-0"
                                            type="checkbox"
                                            value={'remember-me'}
                                            checked={data.remember_me}
                                            onChange={handleChangeCheckbox}
                                            id="remember-me"
                                        />
                                        <label
                                            className="welcome-common-form__check"
                                            htmlFor="remember-me"
                                        >
                                            Remember me
                                        </label>
                                    </div>
                                    <a
                                        href="/forgot-password"
                                        className="welcome-common-form__link"
                                    >
                                        Forgot password ?
                                    </a>
                                </div>

                                <div className="btn-toolbar" role="toolbar">
                                    <div
                                        className="btn-group welcome-common-form__toolbar"
                                        role="group"
                                        aria-label="First group"
                                    >
                                        <button
                                            className="btn btn-primary welcome-common-form__button"
                                            type="submit"
                                        >
                                            Log In
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className="welcome-common-form__footer">
                                <p className="opacity-75">
                                    Don’t have an account?
                                </p>
                                <a
                                    href="signup"
                                    className="welcome-common-form__footer-link"
                                >
                                    Sign up
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="welcome-common-form__bg" />
                </div>
            </section>
        </>
    );
};

export default LoginPage;
