import React, { useCallback, useEffect, useState } from 'react';
import ResponseExample from './Response/ResponseExample';
import { createShallowCopy } from 'utils/helpers';
import { ChevronDown } from 'react-feather';
import RouteHeaders from './RouteHeaders/RouteHeaders';
import { Collapse } from 'react-collapse';

const codes = [
    '200',
    '201',
    '202',
    '204',
    '206',
    '301',
    '400',
    '401',
    '403',
    '404',
    '405',
    '406',
    '407',
    '409',
    '410',
    '411',
    '413',
    '422',
    '429',
];
export default function RouteFormResponse({
    onStateUpdate,
    data,
    selectResponseStatus,
    errors,
    headers,
    onSchemaChange,
    selectSharedResponse,
    globalHeaders,
}) {
    const newData = JSON.parse(JSON.stringify(data));
    const [activeTab, setActiveTab] = useState(
        data.responses[0]?.http_status_code || 200,
    );
    const [isOpened, setIsOpened] = useState(false);

    const handleClick = (value) => setActiveTab(value);

    const changeResponse = (schema, index) => {
        const newData = createShallowCopy(data);

        newData.responses[index].schema = schema;

        onSchemaChange(newData.responses);
    };

    const addResponseExample = useCallback(
        (e) => {
            e?.preventDefault();
            e?.stopPropagation();

            let params = data.responses;
            let codesClone = codes.slice();

            params.forEach((e) => {
                if (codesClone.includes(e.http_status_code.toString())) {
                    codesClone.splice(
                        codesClone.indexOf(e.http_status_code.toString()),
                        1,
                    );
                }
            });

            if (codesClone.length > 0) {
                params.push({
                    http_status_code: codesClone[0],
                    http_status_name: '',
                    description: '',
                    example: null,
                });
            }
            const updatedData = { ...newData, responses: params };

            setActiveTab(params[params.length - 1].http_status_code);
            onStateUpdate({ data: updatedData });
        },
        [data.responses, newData, onStateUpdate],
    );

    useEffect(() => {
        if (!data?.responses?.length) addResponseExample();
    }, [addResponseExample, data?.responses?.length]);

    const deleteResponseExample = (e, index) => {
        e.preventDefault();

        const responses = data.responses;
        const newResponses = responses.filter((el, i) => i !== index);
        const updatedData = { ...newData, responses: newResponses };

        if (newResponses.length === 0) {
            setActiveTab(codes[0]);
        } else {
            setActiveTab(
                newResponses[newResponses.length - 1].http_status_code,
            );
        }
        onStateUpdate({ data: updatedData });
    };

    const addResponseHeader = (e, recent = undefined) => {
        e.stopPropagation();
        const newData = JSON.parse(JSON.stringify(data));

        if (!newData?.response_headers) {
            newData.response_headers = [];
        }

        if (recent) {
            newData.response_headers.push(recent);
        } else {
            newData.response_headers.push({
                name: '',
                value: '',
                description: '',
            });
            if (!isOpened) setIsOpened(true);
        }

        onStateUpdate({ data: newData });
    };

    const deleteResponseHeader = (headerIndex) => {
        const newData = JSON.parse(JSON.stringify(data));

        newData.response_headers.splice(headerIndex, 1);

        onStateUpdate({ data: newData });
    };

    const changeHeaderParameter = (e, headerIndex) => {
        const { name, value } = e.target;
        const newData = JSON.parse(JSON.stringify(data));
        newData.response_headers[headerIndex][name] = value;
        onStateUpdate({ data: newData });
    };

    const handleRecentHeaders = (e, item) => {
        const newData = JSON.parse(JSON.stringify(data));
        const findIndex = newData.response_headers.findIndex(
            (h) => h.id === item.id,
        );

        findIndex === -1
            ? addResponseHeader(e, item)
            : deleteResponseHeader(findIndex);
    };

    const handleEditorChange = (editorData, index) => {
        newData.responses[index]['description'] = editorData;

        onStateUpdate({ data: newData });
    };

    const apiGlobalHeaders = [
        ...createShallowCopy(
            globalHeaders?.filter((el) => el.apply_to_request) || [],
        ),
    ];

    return (
        <div className="mb-4">
            <span className="anchor" id="response" />
            <div className="mb-3">
                <button
                    className="btn btn-light"
                    type="button"
                    onClick={(e) => addResponseHeader(e)}
                >
                    <h6>
                        <span style={{ marginRight: '8px' }}>+</span>
                        Header
                    </h6>
                </button>
                {data?.response_headers?.length > 0 && (
                    <div className="mt-4 w-100">
                        <span className="anchor" id="headers" />
                        <div
                            className="d-flex justify-content-between align-items-center ps-3 pe-2 cursor-pointer"
                            style={{
                                borderBottom: '2px solid #edeff3',
                            }}
                            onClick={(e) => setIsOpened((prev) => !prev)}
                        >
                            <h6>{`Headers (${
                                data?.response_headers?.length || 0
                            })`}</h6>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-light mb-1 mt-1 text-start"
                                    onClick={(e) => addResponseHeader(e)}
                                >
                                    +
                                </button>
                                <ChevronDown
                                    size={16}
                                    color="#001A37"
                                    className="ms-3"
                                    style={{
                                        transform: isOpened && 'rotate(180deg)',
                                    }}
                                />
                            </div>
                        </div>
                        <Collapse isOpened={isOpened}>
                            <RouteHeaders
                                items={data.response_headers || []}
                                headers={headers}
                                globalHeaders={apiGlobalHeaders}
                                errors={errors}
                                changeParam={(e, headerIndex) =>
                                    changeHeaderParameter(e, headerIndex)
                                }
                                deleteRow={(index) =>
                                    deleteResponseHeader(index)
                                }
                                handleRecentHeaders={handleRecentHeaders}
                                isResponsesHeaders={true}
                            />
                        </Collapse>
                    </div>
                )}
            </div>
            <ResponseExample
                items={data.responses}
                changeParam={changeResponse}
                editorChange={handleEditorChange}
                deleteResponseExample={(e, index) =>
                    deleteResponseExample(e, index)
                }
                selectResponseStatus={(e, index) =>
                    selectResponseStatus(e, index)
                }
                selectSharedResponse={selectSharedResponse}
                handleClick={handleClick}
                activeTab={activeTab}
                errors={errors}
                addResponseExample={addResponseExample}
            />
        </div>
    );
}
