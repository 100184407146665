import React from 'react';

export default function LocalLoader({ loading, size, children, className }) {
    return loading ? (
        <div
            className={`d-flex justify-content-center align-items-center ${
                className ? className : ''
            }`}
            style={size ? { minHeight: size + 'px' } : {}}
        >
            <div
                className="spinner-border spinner-border-md text-primary"
                role="status"
            >
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    ) : (
        <>{children}</>
    );
}
