import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Link,
    Redirect,
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { AlertCircle } from 'react-feather';

import RouteGeneralInfo from './RouteGeneralInfo';
import RouteFormRequest from './RouteFormRequest';
import RouteFormResponse from './RouteFormResponse';
// import RouteFormHashLinks from './RouteFormHashLinks';
import LocalLoader from 'ui/LocalLoader';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import {
    createRoute,
    fetchRoute,
    fetchRouteGroups,
    fetchRoutes,
    getRecentHeaders,
    routeSelectors,
    updateRoute,
} from 'store/slices/routesSlice';
import { getModels, getResponses } from 'store/slices/resourcesSlice';
import ToggleButton from 'ui/ToggleButton';
import Tooltip from 'ui/Tooltip';
import { createShallowCopy } from 'utils/helpers';
import { authSelectors } from 'store/slices/authSlice';
import { organizationSelectors } from 'store/slices/organizationSlice';

const RouteForm = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { id } = useParams();

    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const headers = useSelector(routeSelectors.getHeaders);
    const isRouteFetching = useSelector(routeSelectors.getIsRouteFetching);
    const groups = useSelector(routeSelectors.getGroups);
    const lastOpenGroupId = useSelector(routeSelectors.getLastOpenGroupId);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const [data, setData] = useState({
        id: null,
        name: '',
        description: '',
        method: 'GET',
        url: '',
        schema: '',
        api_version_id: 1,
        path_parameters: [],
        headers: [],
        query_parameters: [],
        responses: [],
        response_headers: [],
        created_at: '',
        deleted_at: '',
        updated_at: '',
        group_id: location?.state?.groupId || lastOpenGroupId || null,
        is_internal: false,
    });
    const [errors, setErrors] = useState({});
    const [action, setAction] = useState('create');

    const hasOwnerRights =
        project.owner.id === myAccount.id || currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project.user_role === 'MAINTAINER' ||
        project.user_role === 'WRITE';

    const selectGroupsOptions = groups?.reduce(
        (acc, item) => {
            acc.push({ value: item.id, label: item.name });
            return acc;
        },
        [{ value: 'no-group', label: 'No group' }],
    );

    const setRouteData = useCallback(
        (data) => {
            const responses = data.responses?.map((item) => {
                const newDescr = item.description?.replace(/<br ?\/?>/gi, '\n');
                return { ...item, description: newDescr };
            });
            const name =
                location?.state?.action === 'duplicate'
                    ? `${data.name} Copy`
                    : data.name;
            const url =
                location?.state?.action === 'duplicate'
                    ? `${data.url} (COPY)`
                    : data.url;
            const stateData = {
                id: data.id,
                name,
                description: data.description ?? '',
                method: data.method,
                url,
                schema: data.schema || '',
                api_version_id: data.api_version_id,
                path_parameters: data.path_parameters,
                headers: data.headers,
                response_headers: data.response_headers,
                query_parameters: data.query_parameters,
                responses,
                created_at: data.created_at,
                deleted_at: data.deleted_at,
                updated_at: data.updated_at,
                group_id: data.group_id,
                is_internal: data?.is_internal,
            };
            setData(stateData);
        },
        [location?.state?.action],
    );

    const getRoute = useCallback(
        async (id) => {
            const response = await dispatch(fetchRoute({ rid: id })).unwrap();
            setRouteData(response);
        },
        [dispatch, setRouteData],
    );

    useEffect(() => {
        dispatch(getModels({ projectUid: project.uid, apiId: api.id }));
        dispatch(getResponses({ projectUid: project.uid, apiId: api.id }));
        dispatch(
            getRecentHeaders({ projectUid: project.uid, apiUid: api.uid }),
        );

        if (location?.state?.id) {
            getRoute(location.state.id);
            setAction('create');
        }
        if (id) {
            getRoute(id);
            setAction('update');
        }

        if (!!location?.state?.id) {
            const name = location.state.name
                ? `${location.state.name} Copy`
                : '';
            setRouteData({ ...location.state, name });
        }
    }, [
        api.id,
        api.uid,
        dispatch,
        getRoute,
        id,
        location.id,
        location.name,
        location.state,
        project.uid,
        setRouteData,
    ]);

    useEffect(() => {
        setData((prev) => ({
            ...prev,
            group_id: location?.state?.groupId || null,
        }));
    }, [location?.state?.groupId]);

    const onStateUpdate = ({ data }) => {
        if (data) {
            setData({ ...data });
        }
    };

    const updateSchema = (schema) => {
        setData({ ...data, schema });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleChangeSelect = (option) => {
        const newData = { ...data };
        newData.method = option.value;

        setData({ ...newData });
    };

    const handleChangeGroupSelect = (option) => {
        setData({
            ...data,
            group_id: option.value === 'no-group' ? null : option.value,
        });
    };

    const onSchemaChange = (responses) => {
        setData((prev) => {
            prev.responses = responses;
            return prev;
        });
    };

    const selectResponseStatus = (option, index) => {
        const newData = { ...data };
        let responses = [...newData.responses];
        responses[index].http_status_code = option.value;
        setData({ ...newData, responses: responses });
    };

    const selectSharedResponse = (option, index) => {
        const newData = { ...data };
        const responses = [...newData.responses];
        responses[index].shared_response = option.id;
        setData({ ...newData, responses });
    };

    const handleEditorChange = (editorData) => {
        const newData = { ...data };
        newData.description = editorData;

        setData({ ...newData });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newData = createShallowCopy(data);
        const responses = newData.responses?.map((item) => {
            const newDescr = item?.description
                ? item.description.replace(/\n/g, '<br>')
                : null;
            item.description = newDescr;
            const newHeaders = item?.headers ? item.headers : [];
            item.headers = newHeaders;
            return item;
        });

        const dataForSubmit = {
            ...newData,
            responses,
        };

        if (action === 'create') {
            handleCreateRoute(dataForSubmit);
        } else {
            handleUpdateRoute(dataForSubmit);
        }
    };

    const handleCreateRoute = async (data) => {
        try {
            const response = await dispatch(
                createRoute({
                    data,
                    apiId: api.id,
                }),
            ).unwrap();
            toast.success('The route has been created');
            if (data.group_id) {
                dispatch(
                    fetchRouteGroups({
                        projectUid: project.uid,
                        apiId: api.id,
                    }),
                );
            }
            dispatch(fetchRoutes({ project: project.uid, apiId: api.id }));
            history.push(
                `/project/api/routes/browse/${response.id}?project=${project.uid}&api=${api.uid}`,
            );
        } catch (error) {
            setErrors(error.errors);
            if (error.errors?.name) {
                window.scrollTo(0, 0);
            }
        }
    };

    const handleUpdateRoute = async (data) => {
        try {
            const response = await dispatch(
                updateRoute({
                    data,
                    routeId: data.id,
                }),
            ).unwrap();
            toast.success('The route has been updated');
            if (data.group_id) {
                dispatch(
                    fetchRouteGroups({
                        projectUid: project.uid,
                        apiId: api.id,
                    }),
                );
            }
            dispatch(fetchRoutes({ project: project.uid, apiId: api.id }));
            history.push(
                `/project/api/routes/browse/${response.id}?project=${project.uid}&api=${api.uid}`,
            );
        } catch (error) {
            setErrors(error.errors);
            if (error.errors?.name) {
                window.scrollTo(0, 0);
            }
        }
    };

    const handleSwitchInternal = (flag) => {
        setData({ ...data, is_internal: flag });
    };

    if (project && !hasRights) {
        return <Redirect to="/" />;
    }

    return (
        <LocalLoader loading={isRouteFetching} height="50vh">
            <div className="row">
                <div className="col-10">
                    <div className="d-flex justify-content-between align-items-center col col-lg-11">
                        <h4 className="title-h4 mb-4 block" id="routeHead">
                            {action === 'create'
                                ? 'Create a new route'
                                : 'Edit route'}
                        </h4>
                        <div className="d-flex align-items-center mb-4">
                            <Tooltip
                                id="internalAlert"
                                content="When an endpoint is set as internal, it is no longer visible in public documentation, 
                                but can still be accessed and seen by members within the workspace."
                            >
                                <AlertCircle color="gray" size={15} />
                            </Tooltip>
                            <p style={{ margin: '0 8px 0 4px' }}>
                                {data.is_internal ? 'Internal' : 'Public'}
                            </p>
                            <ToggleButton
                                defaultChecked={!!data.is_internal}
                                className={`d-flex toggle-small-size ${
                                    !data.is_internal
                                        ? 'toggle-disabled-container'
                                        : ''
                                }`}
                                onChange={(state) =>
                                    handleSwitchInternal(state)
                                }
                            />
                        </div>
                    </div>

                    <div className="row">
                        <form onSubmit={handleSubmit} className="col col-lg-11">
                            <RouteGeneralInfo
                                name={data.name}
                                description={data?.description}
                                method={data.method}
                                url={data.url}
                                handleChange={handleChange}
                                handleChangeSelect={handleChangeSelect}
                                handleEditorChange={handleEditorChange}
                                errors={errors}
                                group_id={data.group_id}
                                selectGroupsOptions={selectGroupsOptions}
                                handleChangeGroupSelect={
                                    handleChangeGroupSelect
                                }
                            />
                            <h4
                                className="title-h4 mb-4 p-2"
                                style={{ borderBottom: '2px solid black' }}
                            >
                                Request
                            </h4>
                            <RouteFormRequest
                                onStateUpdate={onStateUpdate}
                                onUpdateSchema={updateSchema}
                                data={data}
                                headers={headers}
                                globalHeaders={api?.global_headers}
                                errors={errors}
                            />
                            <h4
                                style={{ borderBottom: '2px solid black' }}
                                className="title-h4 my-4 p-2"
                            >
                                Response
                            </h4>
                            <RouteFormResponse
                                onStateUpdate={onStateUpdate}
                                onSchemaChange={onSchemaChange}
                                data={data}
                                selectResponseStatus={selectResponseStatus}
                                selectSharedResponse={selectSharedResponse}
                                headers={headers}
                                globalHeaders={api?.global_headers}
                                errors={errors}
                            />
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    {action === 'create' ? 'Create' : 'Update'}
                                </button>

                                <Link
                                    to={
                                        action === 'create'
                                            ? `/project/api/routes?project=${project.uid}&api=${api.uid}`
                                            : `/project/api/routes/browse/${id}?project=${project.uid}&api=${api.uid}`
                                    }
                                    type="button"
                                    className="btn btn-link-main text-link-normal m-1"
                                >
                                    Cancel
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </LocalLoader>
    );
};

export default RouteForm;
