import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { isLoggedIn } from 'utils/auth';
import WelcomeLayout from 'layouts/welcome/WelcomeLayout';
import { useSelector } from 'react-redux';
import { appSelectors } from 'store/slices/appSlice';

const PublicRoute = ({ component: Component, ...rest }) => {
    const domain = useSelector(appSelectors.getOrgDomain);
    const location = useLocation();

    if (
        domain &&
        (location.pathname === '/login' || location.pathname === '/signup')
    ) {
        window.location.href =
            window.location.protocol +
            '//' +
            window.location.host.split('.').slice(1).join('.') +
            window.location.pathname;
        return;
    }
    return (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn() ? (
                    <Redirect to={{ pathname: '/projects' }} />
                ) : (
                    <WelcomeLayout>
                        <Component {...props} />
                    </WelcomeLayout>
                )
            }
        />
    );
};

export default PublicRoute;
