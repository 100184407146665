import React from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from 'ui/PageHeader';
import InviteForm from './InviteForm';
import PendingInvites from './PendingInvites';
import MembersList from './MembersList';

export default function Team() {
    return (
        <div className="main-container m-5">
            <Helmet>
                <title>
                    Team - CDProjects - Create beautiful REST API Documentations
                </title>
            </Helmet>

            <PageHeader>Team</PageHeader>

            <div>
                {true && (
                    <>
                        <InviteForm />
                        <PendingInvites />
                    </>
                )}
                <MembersList />
            </div>
        </div>
    );
}
