import React, { useState } from 'react';
import { ChevronDown } from 'react-feather';
import { Collapse } from 'react-collapse';

const RequestParamsView = (props) => {
    const [isOpened, setIsOpened] = useState(true);
    const paramsName = props.title.split(' ')[0].toLowerCase();

    const findResource = (resources, parameter) => {
        if (paramsName === 'body') {
            return resources.find((el) => parameter.resource_id === el.id);
        }
    };

    if (props.items && !!props.items.length) {
        return (
            <div className="mt-3">
                <span className="anchor" id={paramsName} />
                <div
                    onClick={() => setIsOpened(!isOpened)}
                    className="d-flex justify-content-between align-items-center ps-3 pe-2 cursor-pointer"
                    style={{
                        height: '47px',
                        borderBottom: '2px solid #edeff3',
                    }}
                >
                    <h6 className="text">{`${props.title} (${props.items.length})`}</h6>
                    <ChevronDown
                        size={16}
                        color="#001A37"
                        style={{ transform: isOpened && 'rotate(180deg)' }}
                    />
                </div>
                <Collapse isOpened={isOpened}>
                    <table className="route-view-table w-100">
                        <thead>
                            <tr>
                                <th className="route-view-table-header">
                                    Name
                                </th>
                                <th className="route-view-table-header">
                                    Type
                                </th>
                                <th className="route-view-table-header">
                                    Required
                                </th>
                                <th className="route-view-table-header">
                                    {paramsName === 'body'
                                        ? 'Description/Resource'
                                        : 'Description'}
                                </th>
                            </tr>
                        </thead>
                        {props.items.map((parameter, index) => {
                            const resource = findResource(
                                props.resources,
                                parameter,
                            );

                            return (
                                <tbody key={index} className="w-100">
                                    <tr>
                                        <td className="text-break">
                                            {parameter.name}
                                        </td>
                                        <td>{parameter.type}</td>
                                        <td>
                                            {parameter.is_required
                                                ? 'required'
                                                : 'optional'}
                                        </td>
                                        <td className="text-break">
                                            {parameter.type === 'resource'
                                                ? resource?.name
                                                : parameter.description}
                                        </td>
                                    </tr>
                                </tbody>
                            );
                        })}
                    </table>
                </Collapse>
            </div>
        );
    }

    return <></>;
};

export default RequestParamsView;
