import React, { useEffect, useState } from 'react';
import { Settings } from 'react-feather';
import { getCookie, setCookie } from 'utils/cookie';
import { useDispatch } from 'react-redux';
import { setRoutesListSettings } from 'store/slices/routesSlice';
import CustomSelect from 'ui/CustomSelect';
import { useOutsideClickAlternative } from 'hooks/useOutsideClickAlternative';

const sortTypes = [
    {
        value: 'name',
        label: 'Name',
    },
    {
        value: 'date',
        label: 'Date',
    },
];
const sortDirection = [
    {
        value: 'ASC',
        label: 'ASC',
    },
    {
        value: 'DESC',
        label: 'DESC',
    },
];

const selectOptions = [
    { label: 'Name', value: 'name' },
    { label: 'URL', value: 'url' },
    { label: 'Name and URL', value: 'name/url' },
];

const cookieOptions =
    process.env.NODE_ENV === 'development'
        ? {}
        : { domain: process.env.REACT_APP_DOMAIN };

export default function ListSettingsSelector() {
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const [selectedSort, setSelectedSort] = useState({
        type: 'name',
        direction: 'ASC',
    });
    const [routeName, setRouteName] = useState('name');
    const dispatch = useDispatch();

    useEffect(() => {
        const settings = getCookie('routes_settings');

        if (!settings) {
            const settingsObject = {
                sort: { type: 'name', direction: 'ASC' },
                routeName: 'name',
            };
            setCookie(
                'routes_settings',
                JSON.stringify(settingsObject),
                cookieOptions,
            );
            dispatch(setRoutesListSettings(settingsObject));
            setSelectedSort(settingsObject.sort);
            setRouteName(settingsObject.routeName);
        } else {
            setSelectedSort(JSON.parse(settings)?.sort);
            setRouteName(JSON.parse(settings)?.routeName);
            dispatch(setRoutesListSettings(JSON.parse(settings)));
        }
    }, [dispatch]);

    const handleClickOutside = (e) => {
        if (e) {
            e.stopPropagation();
        }
        const settings = getCookie('routes_settings');
        setSelectedSort(JSON.parse(settings).sort);
        setRouteName(JSON.parse(settings).routeName);
        setIsShowDropdown(false);
    };

    const changeSortType = (options, flag) => {
        setSelectedSort((prev) => ({
            ...(typeof prev === 'object' && prev),
            [flag]: options.value,
        }));
    };

    const applySettings = () => {
        const settings = { sort: selectedSort, routeName };

        setCookie('routes_settings', JSON.stringify(settings), cookieOptions);
        dispatch(setRoutesListSettings(settings));
        setIsShowDropdown(false);
    };

    const toggleSettingsButton = (e) => {
        e.stopPropagation();

        if (isShowDropdown) setIsShowDropdown(false);
        else setIsShowDropdown(true);
    };

    const selectRouteName = (options) => {
        setRouteName(options.value);
    };
    const dropdownRef = useOutsideClickAlternative(handleClickOutside);

    return (
        <div className="action-container">
            <>
                <button
                    type="button"
                    className="btn-action edit hover-default"
                    onClick={toggleSettingsButton}
                >
                    <Settings size={13} color="#007BFF" />
                </button>
                {isShowDropdown && (
                    <div
                        ref={dropdownRef}
                        className="dropdown-list p-3 shadow"
                        style={{ width: '280px' }}
                    >
                        <div>
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="title-h5 d-block">
                                    Routes settings
                                </span>
                                <button
                                    className="btn btn-link"
                                    onClick={applySettings}
                                >
                                    Apply
                                </button>
                            </div>
                            <div className="my-3"></div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <label className="mb-1 cursor-pointer">
                                        Sort by
                                    </label>
                                    <CustomSelect
                                        className="select-main-container"
                                        name="method"
                                        options={sortTypes}
                                        onChange={(options) =>
                                            changeSortType(options, 'type')
                                        }
                                        value={selectedSort.type}
                                    />
                                </div>
                                <div>
                                    <label className="mb-1 cursor-pointer">
                                        Sort direction
                                    </label>
                                    <CustomSelect
                                        className="select-main-container"
                                        name="method"
                                        options={sortDirection}
                                        onChange={(options) =>
                                            changeSortType(options, 'direction')
                                        }
                                        value={selectedSort.direction}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="my-3"></div>
                        <span
                            className="title-h5 d-block mb-1"
                            style={{ fontSize: '14px' }}
                        >
                            Others:
                        </span>
                        <div>
                            <label className="mb-1 cursor-pointer">
                                Display options
                            </label>
                            <CustomSelect
                                className="select-main-container"
                                name="method"
                                options={selectOptions}
                                onChange={selectRouteName}
                                value={routeName}
                            />
                        </div>
                    </div>
                )}
            </>
        </div>
    );
}
