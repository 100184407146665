import React from 'react';
import ResponsesForm from './ResponsesForm';
import ResponsesView from './ResponsesView';
import { Route } from 'react-router';

export default function ResponsesRoutes() {
    return (
        <>
            <Route path="/project/api/components/responses/create">
                <ResponsesForm />
            </Route>
            <Route path="/project/api/components/responses/browse/:id">
                <ResponsesView />
            </Route>
            <Route path="/project/api/components/responses/:id/edit">
                <ResponsesForm />
            </Route>
        </>
    );
}
