import React from 'react';
import CustomSelect from 'ui/CustomSelect';
import { isValidValue } from '../helpers';

const selectOptions = [
    { label: 'true', value: 'true' },
    { label: 'false', value: 'false' },
];

export default function BooleanExtraProps({
    extraProps,
    onChangeExtraProperties,
}) {
    const onChangeProps = (option, name) => {
        const propOption = {
            target: {
                value: isValidValue(option) ? option.value : null,
                id: name,
                type: 'select',
            },
        };

        onChangeExtraProperties(propOption);
    };
    return (
        <div>
            <div className="mb-3 ps-1">
                <label className="JsonSchemaEditor__schema-types-modal-title">
                    DEFAULT
                </label>
                <CustomSelect
                    name="default"
                    options={selectOptions}
                    onChange={(option) => onChangeProps(option, 'default')}
                    value={extraProps?.default}
                    isClearable={true}
                />
            </div>
        </div>
    );
}
