import React from 'react';
import HeadersItem from './HeadersItem';

export default function Headers({
    headers,
    changeParam,
    deleteRow,
    selectRow,
    selectedHeaders,
}) {
    return (
        <div className="pb-3">
            <table className="table mb-0">
                <thead>
                    <tr>
                        <th className="title-table">&nbsp;</th>
                        <th className="title-table">Name</th>
                        <th className="title-table">Value</th>
                        <th className="title-table">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {!!headers?.length ? (
                        headers?.map((header, index) => (
                            <HeadersItem
                                key={index}
                                header={header}
                                handleChange={(e) =>
                                    changeParam(e, index, header)
                                }
                                deleteRow={() => deleteRow(index, header)}
                                selectRow={selectRow}
                                selectedHeaders={selectedHeaders}
                            />
                        ))
                    ) : (
                        <tr className="text-center">
                            <td colSpan={4}>No added headers</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
