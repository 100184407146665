import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import WelcomeFooter from './WelcomeFooter';
import 'react-toastify/dist/ReactToastify.css';
import GlobalLoader from 'ui/GlobalLoader';
import { appSelectors, setOrgDomain } from 'store/slices/appSlice.js';

const WelcomeLayout = ({ children }) => {
    const dispatch = useDispatch();
    const showGlobalLoader = useSelector(appSelectors.getShowGlobalLoader);
    useEffect(() => {
        dispatch(setOrgDomain());
    }, [dispatch]);

    return (
        <>
            {children}
            <WelcomeFooter />

            <GlobalLoader isLoading={showGlobalLoader} />

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                icon={false}
            />
        </>
    );
};

export default WelcomeLayout;
