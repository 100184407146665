import { useRef, useEffect } from 'react';

export const useOutsideClickAlternative = (callback) => {
    const ref = useRef();

    useEffect(() => {
        const handleClick = (event) => {
            if (event.target.classList.contains('select-main__option')) return;

            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref, callback]);

    return ref;
};
