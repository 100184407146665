import React from 'react';
import CustomSelect from 'ui/CustomSelect';
import { Input } from 'ui/Inputs';

const selectOptions = [
    { label: 'date-time', value: 'date-time' },
    { label: 'date', value: 'date' },
    { label: 'time', value: 'time' },
    { label: 'duration', value: 'duration' },
    { label: 'email', value: 'email' },
    { label: 'idn-email', value: 'idn-email' },
    { label: 'hostname', value: 'hostname' },
    { label: 'idn-hostname', value: 'idn-hostname' },
    { label: 'ipv4', value: 'ipv4' },
    { label: 'ipv6', value: 'ipv6' },
    { label: 'uri', value: 'uri' },
    { label: 'uri-reference', value: 'uri-reference' },
    { label: 'iri', value: 'iri' },
    { label: 'iri-reference', value: 'iri-reference' },
    { label: 'uuid', value: 'uuid' },
    { label: 'uri-template', value: 'uri-template' },
    { label: 'json-pointer', value: 'json-pointer' },
    { label: 'relative-json-pointer', value: 'relative-json-pointer' },
    { label: 'regex', value: 'regex' },
    { label: 'password', value: 'password' },
    { label: 'binary', value: 'binary' },
    { label: 'byte', value: 'byte' },
];

export default function StringExtraProps({
    extraProps,
    onChangeExtraProperties,
    onFormatSelectChange,
}) {
    return (
        <div>
            <div className="mb-3 ps-1">
                <label className="JsonSchemaEditor__schema-types-modal-title">
                    FORMAT
                </label>
                <CustomSelect
                    name="format"
                    options={selectOptions}
                    onChange={onFormatSelectChange}
                    value={extraProps?.format}
                    isClearable={true}
                />
            </div>
            <div className="mb-3 ps-1">
                <Input
                    type="text"
                    id="default"
                    className="form-control"
                    value={extraProps?.default || ''}
                    onChange={onChangeExtraProperties}
                    labelClassName="JsonSchemaEditor__schema-types-modal-title"
                    labelText="DEFAULT"
                    placeHolder="Default"
                />
            </div>
            <div className="mb-3 ps-1">
                <Input
                    type="text"
                    id="example"
                    className="form-control"
                    value={extraProps?.example || ''}
                    onChange={onChangeExtraProperties}
                    labelClassName="JsonSchemaEditor__schema-types-modal-title"
                    labelText="EXAMPLE"
                    placeHolder="Example"
                />
            </div>
            <div className="mb-3 ps-1">
                <Input
                    type="text"
                    id="pattern"
                    className="form-control"
                    value={extraProps?.pattern || ''}
                    onChange={onChangeExtraProperties}
                    labelClassName="JsonSchemaEditor__schema-types-modal-title"
                    labelText="PATTERN"
                    placeHolder="^[A-Za-z0-9 -_]+"
                />
            </div>
            <div className="mb-3 ps-1 d-flex justify-content-between">
                <div>
                    <Input
                        type="number"
                        id="minLength"
                        className="form-control"
                        value={extraProps?.minLength || ''}
                        onChange={onChangeExtraProperties}
                        labelClassName="JsonSchemaEditor__schema-types-modal-title"
                        labelText="MIN_LENGTH"
                        placeHolder=">=0"
                    />
                </div>
                <div>
                    <Input
                        type="number"
                        id="maxLength"
                        className="form-control"
                        value={extraProps?.maxLength || ''}
                        onChange={onChangeExtraProperties}
                        labelClassName="JsonSchemaEditor__schema-types-modal-title"
                        labelText="MAX_LENGTH"
                        placeHolder=">=0"
                    />
                </div>
            </div>
        </div>
    );
}
