import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { billingSelectors, contactSales } from 'store/slices/billingSlice';
import { Input } from 'ui/Inputs';
import { CheckCircle } from 'react-feather';
import { organizationSelectors } from 'store/slices/organizationSlice';

export default function ContactSalesPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const contactSalesStatus = useSelector(
        billingSelectors.getContactSalesLoadingStatus,
    );
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company_name: '',
        employee_number: '',
        message: '',
    });
    const [errors, setErrors] = useState({});
    const [currentComponent, setCurrentComponent] = useState('form');

    useLayoutEffect(() => {
        if (currentOrg && currentOrg?.role !== 'OWNER') history.push('/403');
    }, [currentOrg, currentOrg?.role, history]);

    const onInputChange = (e) => {
        const { value, name } = e.target;

        setData((prev) => ({ ...prev, [name]: value }));
    };

    const onSubmitForm = () => {
        dispatch(contactSales({ data, onSuccess, onError }));
    };

    const onSuccess = () => {
        setCurrentComponent('message');
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    const redirectToMainPage = () => {
        history.replace('/projects');
    };

    return currentComponent === 'form' ? (
        <section
            className="section-contact m-0"
            style={{ backgroundColor: 'white' }}
        >
            <div className="contact container">
                <div className="welcome-common-form__form">
                    <h3 className="welcome-common-form__title welcome-common-form__title--padding-b">
                        Contact sales
                    </h3>
                    <form className="welcome-form__container">
                        <div className="form-group welcome-common-form__form-group">
                            <Input
                                type="text"
                                name="first_name"
                                className="form-control welcome-common-form__control"
                                value={data?.first_name}
                                onChange={onInputChange}
                                labelClassName="welcome-common-form__label"
                                labelText="First Name"
                                placeHolder="Enter your first name"
                                errorText={errors?.first_name}
                                isRequired
                            />
                        </div>
                        <div className="form-group welcome-common-form__form-group">
                            <Input
                                type="text"
                                name="last_name"
                                className="form-control welcome-common-form__control"
                                value={data?.last_name}
                                onChange={onInputChange}
                                labelClassName="welcome-common-form__label"
                                labelText="Last Name"
                                placeHolder="Enter your last name"
                                errorText={errors?.last_name}
                                isRequired
                            />
                        </div>
                        <div className="form-group welcome-common-form__form-group">
                            <Input
                                type="email"
                                name="email"
                                className="form-control welcome-common-form__control "
                                value={data?.email}
                                onChange={onInputChange}
                                labelClassName="welcome-common-form__label"
                                labelText="Email"
                                placeHolder="Enter your email"
                                errorText={errors?.email}
                                isRequired
                            />
                        </div>
                        <div className="form-group welcome-common-form__form-group">
                            <Input
                                type="text"
                                name="phone"
                                className="form-control welcome-common-form__control "
                                value={data?.phone}
                                onChange={onInputChange}
                                labelClassName="welcome-common-form__label"
                                labelText="Phone"
                                placeHolder="Enter your phone number"
                                errorText={errors?.phone}
                            />
                        </div>
                        <div className="form-group welcome-common-form__form-group">
                            <Input
                                type="text"
                                name="company_name"
                                className="form-control welcome-common-form__control "
                                value={data?.company_name}
                                onChange={onInputChange}
                                labelClassName="welcome-common-form__label"
                                labelText="Company Name"
                                placeHolder="Enter your company name"
                                errorText={errors?.company_name}
                            />
                        </div>
                        <div className="form-group welcome-common-form__form-group">
                            <Input
                                type="number"
                                name="employee_number"
                                className="form-control welcome-common-form__control"
                                value={data?.employee_number}
                                onChange={onInputChange}
                                labelClassName="welcome-common-form__label"
                                labelText="Employees Number"
                                placeHolder="Enter employees number"
                                errorText={errors?.employee_number}
                                isRequired
                            />
                        </div>
                        <div className="form-group welcome-common-form__form-group">
                            <Input
                                type="textarea"
                                name="message"
                                className="form-control welcome-common-form__control "
                                value={data?.message}
                                onChange={onInputChange}
                                labelClassName="welcome-common-form__label"
                                labelText="Message"
                                placeHolder="Enter your message"
                                errorText={errors?.message}
                                rows="5"
                            />
                        </div>
                        <button
                            className="btn btn-primary welcome-common-form__button w-100"
                            type="button"
                            disabled={contactSalesStatus === 'loading'}
                            onClick={onSubmitForm}
                        >
                            Send message
                            {contactSalesStatus === 'loading' && (
                                <span
                                    className="spinner-border spinner-border-sm ms-2"
                                    aria-hidden="true"
                                ></span>
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </section>
    ) : (
        <section
            className="section-contact m-0"
            style={{ backgroundColor: 'white' }}
        >
            <div className="AppErrorFallback__container">
                <CheckCircle color="green" size={50} />
                <h1 className="AppErrorFallback__title">
                    Thank you for contacting us!
                </h1>
                <p className="AppErrorFallback__text">
                    Your message has been sent to our sales manager, who will be
                    in touch with you shortly to discuss your specific needs and
                    provide more information.
                </p>
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={redirectToMainPage}
                >
                    Go to main page
                </button>
            </div>
        </section>
    );
}
