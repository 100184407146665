import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { Input } from 'ui/Inputs';
import LocalLoader from 'ui/LocalLoader';
import { projectSelectors } from 'store/slices/projectsSlice';
import { apiSelectors } from 'store/slices/apiSlice';
import {
    createResponse,
    getResponse,
    getResponses,
    resourceSelectors,
    updateResponse,
} from 'store/slices/resourcesSlice';
import JsonSchemaEditor from 'components/JsonSchemaEditor';
import { getRecentHeaders } from 'store/slices/routesSlice';
import ExpandableTextEditor from 'components/ExpandableTextEditor';

const ResponsesForm = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const history = useHistory();
    const location = useLocation();
    const params = useParams();

    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const responses = useSelector(resourceSelectors.getResponses);
    const responsesLoadingStatus = useSelector(
        resourceSelectors.getResponsesLoadingStatus,
    );
    const isResponsesFetching = useSelector(
        resourceSelectors.getIsResponsesFetching,
    );
    const isResponseFetching = useSelector(
        resourceSelectors.getIsResponseFetching,
    );
    const [data, setData] = useState({
        name: '',
        description: '',
        schema: '',
        headers: [],
    });
    const [action, setAction] = useState('create');
    const [errors, setErrors] = useState({});

    useLayoutEffect(() => {
        const duplicateResponsesAction = location.state?.action === 'duplicate';

        if (duplicateResponsesAction) {
            setData({
                name: `${location.state.name} Copy` || '',
                description: location.state.description || '',
                schema: location.state.schema || '',
                headers: location.state.headers || [],
            });
        }

        if (id && responsesLoadingStatus === 'succeeded') {
            setAction('update');
            dispatch(
                getResponse({
                    responseId: id,
                    onSuccess: onResponseFetchSuccess,
                }),
            ).unwrap();
        } else {
            dispatch(
                getRecentHeaders({
                    projectUid: project.uid,
                    apiUid: api.uid,
                }),
            );
        }
    }, [
        api.uid,
        dispatch,
        id,
        project.uid,
        responsesLoadingStatus,
        location.state,
    ]);

    const onResponseFetchSuccess = (response) => {
        setData({
            name: response.data.data.name || '',
            description: response.data.data.description || '',
            schema: response.data.data.schema || '',
            headers: [],
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const request = { ...data };

        if (action === 'create') {
            dispatch(
                createResponse({
                    body: request,
                    apiId: api.id,
                    onSuccess: onSuccessCreate,
                    onError: onErrorCreate,
                }),
            );
        } else {
            dispatch(
                updateResponse({
                    body: request,
                    responseId: id,
                    onSuccess: onSuccessResponseUpdate,
                    onError: onErrorResponseUpdate,
                }),
            );
        }
    };

    const handleEditorChange = (editorData) => {
        const newData = JSON.parse(JSON.stringify(data));
        newData.description = editorData;

        setData({ ...newData });
    };

    const onSuccessCreate = (response) => {
        toast.success('The response has been created');
        dispatch(getResponses({ projectUid: project.uid, apiId: api.id }));
        history.push(
            `/project/api/components/responses/browse/${response.data.data.id}?project=${project.uid}&api=${api.uid}`,
        );
    };

    const onErrorCreate = (error) => {
        setErrors(error.response.data.errors);
    };

    const onSuccessResponseUpdate = (response) => {
        toast.success('The response has been updated');
        dispatch(getResponses({ projectUid: project.uid, apiId: api.id }));
        history.push(
            `/project/api/components/responses/browse/${response.data.data.id}?project=${project.uid}&api=${api.uid}`,
        );
    };

    const onErrorResponseUpdate = (error) => {
        setErrors(error.response.data.errors);
    };

    const getSchema = (schema) => {
        setData({ ...data, schema });
    };

    const models = responses?.map((el) => JSON.parse(el.schema));
    const isDataFetching = isResponseFetching || isResponsesFetching;

    return (
        <div className="row">
            <div className="col col-md-10 col-lg-10">
                <LocalLoader loading={isDataFetching} height="50vh">
                    <h4 className="title-h4 mb-4" id="routeHead">
                        {action === 'create'
                            ? 'Create a new response'
                            : 'Edit response'}
                    </h4>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="form-group mb-4">
                                <Input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    value={data?.name}
                                    onChange={handleChange}
                                    labelText="Response name"
                                    labelClassName="form-label"
                                    placeHolder="Enter response name"
                                    errorText={errors?.name}
                                    isRequired={true}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <ExpandableTextEditor
                                    label="Description"
                                    description={data?.description}
                                    handleEditorChange={handleEditorChange}
                                />
                            </div>
                            <div className="form-group mb-4 mt-3">
                                <JsonSchemaEditor
                                    schema={data?.schema}
                                    models={models}
                                    modelName={data?.name}
                                    getSchema={getSchema}
                                    schemaType="response"
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                {action === 'create' ? 'Create' : 'Update'}
                            </button>

                            <Link
                                to={
                                    action === 'create'
                                        ? `/project/api/components?project=${project.uid}&api=${api.uid}`
                                        : `/project/api/components/responses/browse/${params?.id}?project=${project.uid}&api=${api.uid}`
                                }
                                type="button"
                                className="btn btn-link-main text-link-normal m-1"
                            >
                                Cancel
                            </Link>
                        </div>
                    </form>
                </LocalLoader>
            </div>
        </div>
    );
};

export default ResponsesForm;
