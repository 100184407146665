import React from 'react';
import AutoSuggestInput from 'ui/Inputs/AutoSuggestInput';
import { Input } from 'ui/Inputs';
import { headers, headersValues } from 'shared/data';

const RouteHeadersItem = ({
    name,
    value,
    index,
    description,
    handleChange,
    errors,
    deleteRow,
    isResponsesHeaders,
}) => {
    return (
        <tr>
            <td>
                <AutoSuggestInput
                    name="name"
                    placeholder="Header name"
                    options={headers}
                    value={name}
                    handleChange={handleChange}
                    className={'form-control'}
                    errorText={
                        errors &&
                        errors[
                            `${
                                isResponsesHeaders ? 'responses.' : ''
                            }headers.${index}.name`
                        ]
                    }
                />
            </td>
            <td>
                <AutoSuggestInput
                    name="value"
                    placeholder="Header value"
                    options={headersValues}
                    value={value}
                    handleChange={handleChange}
                    className={'form-control'}
                />
            </td>
            <td>
                <Input
                    type="text"
                    name="description"
                    className="form-control"
                    value={description || ''}
                    onChange={handleChange}
                    placeHolder="Describe the field"
                    errorText={
                        errors &&
                        errors[
                            `${
                                isResponsesHeaders ? 'responses.' : ''
                            }headers?.${index}.description`
                        ]
                    }
                />
            </td>
            <td className="text-end align-middle">
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={deleteRow}
                >
                    -
                </button>
            </td>
        </tr>
    );
};

export default RouteHeadersItem;
