import React from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import { FileText, Upload, Download, ArrowLeftCircle } from 'react-feather';
import ApiContent from 'components/ApiContent/ApiContent';
import { LeftSideBar } from 'components/LeftSideBar';
import SummaryPage from './Summary/SummaryPage';
import ImportPage from './ImportPage/ImportPage';
import ExportPage from './ExportPage/ExportPage';
import MainContent from 'ui/MainContent';
import { useSelector } from 'react-redux';
import { apiSelectors } from 'store/slices/apiSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import { authSelectors } from 'store/selectors';
import { organizationSelectors } from 'store/slices/organizationSlice';
import PermissionRoute from 'routes/PermissionRoute';

const ApiSettingsContainer = () => {
    const myAccount = useSelector(authSelectors.getMyAccount);
    const api = useSelector(apiSelectors.getCurrentApi);
    const project = useSelector(projectSelectors.getCurrentProject);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const hasOwnerRights =
        (project.owner.id === myAccount.id && currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project?.user_role === 'MAINTAINER' ||
        project?.user_role === 'WRITE';

    return (
        <ApiContent>
            <LeftSideBar initialWidth={380} minWidth={240}>
                <nav className="h-100 d-flex flex-column pt-3">
                    <a
                        className="mx-2 mb-2 link-dropdown"
                        href={`/project?project=${project?.uid}`}
                    >
                        <ArrowLeftCircle
                            size={24}
                            className="mx-2"
                            strokeWidth={2}
                        />
                        Back to API's
                    </a>
                    <ul className="nav flex-column">
                        <li className="nav-item sidebar-link-item mx-2">
                            <NavLink
                                to={`/project/api/settings/summary?project=${project?.uid}&api=${api?.uid}`}
                                className="nav-link sidebar-link"
                                activeClassName="nav-link sidebar-link sidebar-link-active"
                            >
                                <FileText
                                    size={20}
                                    className="sidebar-link-icon mr-2"
                                    strokeWidth={1.5}
                                />
                                Summary
                            </NavLink>
                        </li>
                        {hasRights && (
                            <>
                                <li className="nav-item sidebar-link-item mx-2">
                                    <NavLink
                                        to={`/project/api/settings/import?project=${project?.uid}&api=${api?.uid}`}
                                        className="nav-link sidebar-link"
                                        activeClassName="nav-link sidebar-link sidebar-link-active"
                                    >
                                        <Upload
                                            size={20}
                                            className="sidebar-link-icon mr-2"
                                            strokeWidth={1.5}
                                        />
                                        Import
                                    </NavLink>
                                </li>
                                <li className="nav-item sidebar-link-item mx-2">
                                    <NavLink
                                        to={`/project/api/settings/export?project=${project?.uid}&api=${api?.uid}`}
                                        className="nav-link sidebar-link"
                                        activeClassName="nav-link sidebar-link sidebar-link-active"
                                    >
                                        <Download
                                            size={20}
                                            className="sidebar-link-icon mr-2"
                                            strokeWidth={1.5}
                                        />
                                        Export
                                    </NavLink>
                                </li>
                            </>
                        )}
                    </ul>
                </nav>
            </LeftSideBar>
            <MainContent>
                <Switch>
                    <Route
                        exact
                        path="/project/api/settings/summary"
                        component={SummaryPage}
                    />
                    <PermissionRoute
                        exact
                        path="/project/api/settings/import"
                        component={ImportPage}
                        redirectTo="/403"
                        hasRights={hasRights}
                    />
                    <PermissionRoute
                        exact
                        path="/project/api/settings/export"
                        component={ExportPage}
                        redirectTo="/403"
                        hasRights={hasRights}
                    />
                </Switch>
            </MainContent>
        </ApiContent>
    );
};

export default ApiSettingsContainer;
