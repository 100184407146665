import PagesItem from './PagesItem';

const PagesList = ({ pages, api }) => {
    return (
        <>
            <ul className="nav flex-column flex-grow-1 pt-1">
                {pages?.map((page) => {
                    return <PagesItem api={api} key={page.id} page={page} />;
                })}
            </ul>
        </>
    );
};

export default PagesList;
