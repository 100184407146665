import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiSelectors, getOpenApiSchema } from 'store/slices/apiSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import LocalLoader from 'ui/LocalLoader';
import ReactJson from '@microlink/react-json-view';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { toast } from 'react-toastify';

export default function OpenApiPage() {
    const dispatch = useDispatch();
    const schema = useSelector(apiSelectors.getApiSchema);
    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const isApiSchemaLoading = useSelector(apiSelectors.getIsApiSchemaLoading);

    useLayoutEffect(() => {
        dispatch(
            getOpenApiSchema({ projectUid: project?.uid, apiUid: api?.uid }),
        );
    }, [api?.uid, dispatch, project?.uid]);

    const [copy] = useCopyToClipboard();

    const onCopyToClipboard = () => {
        copy(JSON.stringify(schema));
        toast.success('Copied to clipboard');
    };

    return (
        <div>
            <div
                style={{
                    position: 'fixed',
                    top: 'calc(var(--contentTop, 0px) + var(--topMessageHeight, 0px) + 48px)',
                    right: '20px',
                    display: 'inline-block',
                    zIndex: 100,
                }}
            >
                <button
                    onClick={onCopyToClipboard}
                    className="d-flex align-items-center btn btn-light"
                >
                    Copy to clipboard
                </button>
            </div>
            <LocalLoader loading={isApiSchemaLoading}>
                <div>
                    <ReactJson
                        src={schema || {}}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        indentWidth={5}
                        collapseStringsAfterLength={false}
                        enableClipboard={false}
                        style={{
                            fontFamily: 'inherit',
                            fontSize: '14px',
                            fontWeight: 500,
                        }}
                    />
                </div>
            </LocalLoader>
        </div>
    );
}
