import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import MainContent from 'ui/MainContent';
import Purchase from './Purchase';
import arrowBack from 'assets/img/arrows/arrow-back.svg';
// import { authSelectors } from 'store/slices/authSlice';
// import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useSelector } from 'react-redux';
import { organizationSelectors } from 'store/slices/organizationSlice';

const options = {
    appearance: {
        theme: 'stripe',
    },
    locale: 'en',
};

loadStripe.setLoadParameters({ advancedFraudSignals: false });
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function ChangePlanPage() {
    const history = useHistory();
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    useLayoutEffect(() => {
        if (currentOrg) history.push('/billing');
    }, [currentOrg, history]);

    return (
        <MainContent>
            <div className="main-container plansContainer">
                <Helmet>
                    <title>Change plan - CDP</title>
                </Helmet>

                <div className="page-header d-flex flex-column mx-sm-5 mx-3 mb-5">
                    <div className="mb-3">
                        <Link
                            to="/billing/plans"
                            className="d-flex align-items-center"
                        >
                            <img src={arrowBack} alt="back" className="block" />
                            <span className="page-header__back">All plans</span>
                        </Link>
                    </div>
                    <Elements stripe={stripePromise} options={options}>
                        <Purchase />
                    </Elements>
                </div>
            </div>
        </MainContent>
    );
}
