import React, { useState, useLayoutEffect } from 'react';
// import { Collapse } from 'react-collapse';
// import { ChevronDown } from 'react-feather';
import { useSelector } from 'react-redux';
import JsonSchemaEditor from 'components/JsonSchemaEditor';
import TextEditor from 'components/TextEditor/TextEditor';
import { resourceSelectors } from 'store/slices/resourcesSlice';
// import RouteHeaders from '../RouteHeaders/RouteHeaders';
import Markdown from 'markdown-to-jsx';
import PreBlock from 'ui/PreBlock';
import JsonSchemaViewer from 'components/JsonSchemaViewer/JsonSchemaViewer';
// import RequestHeadersView from 'pages/Routes/RouteView/RequestHeadersView';

const ResponseExampleItem = ({
    item,
    index,
    editorChange,
    changeParam,
    // addResponseHeader,
    // deleteResponseHeader,
    // handleRecentHeaders,
    // changeHeaderParameter,
    // errors,
    // headers,
}) => {
    const [showDescription, setShowDescription] = useState(false);
    // const [isOpened, setIsOpened] = useState(false);

    const resources = useSelector(resourceSelectors.getResources);
    const responses = useSelector(resourceSelectors.getResponses);

    useLayoutEffect(() => {
        const checkItemDescription = () => {
            if (item.description) {
                setShowDescription(true);
            }
        };

        checkItemDescription();
    }, [item]);

    const activeDescription = (e) => {
        e.preventDefault();

        setShowDescription(true);
    };

    // const addHeaderParameter = (e) => {
    //     e.preventDefault();

    //     addResponseHeader(e, index);
    //     setIsOpened(true);
    // };

    // const deleteHeaderParameter = (e, headerIndex) => {
    //     if (item.headers.length === 1) {
    //         setIsOpened(false);
    //     }
    //     deleteResponseHeader(index, headerIndex);
    // };

    // const toggleOpenedParams = (e) => {
    //     e.preventDefault();
    //     if (!item.headers || item?.headers?.length === 0) {
    //         setIsOpened(true);
    //         addHeaderParameter(e, index);
    //         return;
    //     }
    //     setIsOpened((prev) => !prev);
    // };

    const getSchema = (schema) => {
        changeParam(schema, index);
    };

    const models = resources?.map((el) => JSON.parse(el.schema));
    const foundSharedResponse =
        responses.find((el) => el.id === item.shared_response) || null;

    return (
        <>
            <li className="d-flex pb-3 w-100">
                <div className="col-11 mr-2 route-response-container w-100">
                    {foundSharedResponse ? (
                        <div className="response-example">
                            {/* <RequestHeadersView
                                items={foundSharedResponse?.headers}
                            /> */}
                            {foundSharedResponse?.description && (
                                <div className="response-example-desc word-break">
                                    <Markdown
                                        options={{
                                            overrides: {
                                                pre: PreBlock,
                                            },
                                        }}
                                    >
                                        {foundSharedResponse.description}
                                    </Markdown>
                                </div>
                            )}

                            <div className="mt-4">
                                <JsonSchemaViewer
                                    models={models}
                                    schema={foundSharedResponse?.schema}
                                    schemaType="response"
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            {/* <button
                                className="btn btn-light"
                                onClick={(e) => addHeaderParameter(e)}
                            >
                                <h6>
                                    <span style={{ marginRight: '8px' }}>
                                        +
                                    </span>
                                    Header
                                </h6>
                            </button>
                            {item?.headers?.length > 0 && (
                                <div className="mt-4 w-100">
                                    <span className="anchor" id="headers" />
                                    <div
                                        className="d-flex justify-content-between align-items-center ps-3 pe-2 cursor-pointer"
                                        style={{
                                            borderBottom: '2px solid #edeff3',
                                        }}
                                        onClick={(e) => toggleOpenedParams(e)}
                                    >
                                        <h6>{`Headers (${
                                            item?.headers?.length || 0
                                        })`}</h6>
                                        <div>
                                            <button
                                                type="button"
                                                className="btn btn-light mb-1 mt-1 text-start"
                                                onClick={(e) =>
                                                    addHeaderParameter(e)
                                                }
                                            >
                                                +
                                            </button>
                                            <ChevronDown
                                                size={16}
                                                color="#001A37"
                                                className="ms-3"
                                                style={{
                                                    transform:
                                                        isOpened &&
                                                        'rotate(180deg)',
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <Collapse isOpened={isOpened}>
                                        <RouteHeaders
                                            items={item?.headers}
                                            headers={headers}
                                            errors={errors}
                                            changeParam={(e, headerIndex) =>
                                                changeHeaderParameter(
                                                    e,
                                                    index,
                                                    headerIndex,
                                                )
                                            }
                                            deleteRow={(index) =>
                                                deleteHeaderParameter(index)
                                            }
                                            handleRecentHeaders={
                                                handleRecentHeaders
                                            }
                                            index={index}
                                            isResponsesHeaders={true}
                                        />
                                    </Collapse>
                                </div>
                            )} */}
                            {showDescription ? (
                                <div>
                                    <label
                                        className="my-3"
                                        htmlFor="description"
                                    >
                                        Description
                                        <span
                                            className="m-1 link-primary cursor-pointer"
                                            onClick={() =>
                                                setShowDescription(false)
                                            }
                                        >
                                            (Hide)
                                        </span>
                                    </label>
                                    <div className="mb-3">
                                        <TextEditor
                                            description={
                                                item?.description || ''
                                            }
                                            handleEditorChange={(editorData) =>
                                                editorChange(editorData, index)
                                            }
                                            editorClassName={'ck-line-height'}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="my-3">
                                    <a
                                        className="link-primary cursor-pointer"
                                        href={item.http_status_code.toString()}
                                        htmlFor="description"
                                        onClick={activeDescription}
                                    >
                                        Add description
                                    </a>
                                </div>
                            )}
                            <div className="mb-4">
                                <JsonSchemaEditor
                                    schema={item?.schema}
                                    models={models}
                                    getSchema={getSchema}
                                    schemaType="response"
                                />
                            </div>
                        </>
                    )}
                </div>
            </li>
        </>
    );
};

export default ResponseExampleItem;
