import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Input } from 'ui/Inputs';
import {
    authSelectors,
    sendMessageWithContactForm,
} from 'store/slices/authSlice';

const ContactSection = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const contactMessageStatus = useSelector(
        authSelectors.getSendMessageWithContactFormLoadingStatus,
    );

    const onSuccess = (response) => {
        toast.success(
            'Thank you for your request. We will answer as soon as possible.',
        );
        setName('');
        setEmail('');
        setMessage('');
    };

    const onError = (error) => {
        setErrors(error.response.data.errors);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name,
            email,
            message,
        };
        dispatch(sendMessageWithContactForm({ data, onSuccess, onError }));
    };
    return (
        <section id="contact-section" className="section-contact">
            <div className="contact container">
                <h2 className="contact__title">Contact us</h2>
                <p className="contact__description">
                    We’d love to hear from you. Our friendly team is always here
                    to chat.
                </p>
                <div className="welcome-common-form__form">
                    <h3 className="welcome-common-form__title welcome-common-form__title--padding-b">
                        Send us a message
                    </h3>
                    <form
                        className="welcome-form__container"
                        onSubmit={handleSubmit}
                    >
                        <div className="form-group welcome-common-form__form-group">
                            <Input
                                type="text"
                                name="name"
                                className="form-control welcome-common-form__control"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                labelClassName="welcome-common-form__label"
                                labelText="Name"
                                placeHolder="Enter your name"
                                errorText={errors?.name}
                            />
                        </div>
                        <div className="form-group welcome-common-form__form-group">
                            <Input
                                type="email"
                                name="email"
                                className="form-control welcome-common-form__control "
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                labelClassName="welcome-common-form__label"
                                labelText="Email address"
                                placeHolder="Enter your email"
                                errorText={errors?.email}
                            />
                        </div>
                        <div className="form-group welcome-common-form__form-group">
                            <Input
                                type="textarea"
                                name="message"
                                className="form-control welcome-common-form__control "
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                labelClassName="welcome-common-form__label"
                                labelText="Message"
                                placeHolder="Enter your message"
                                errorText={errors?.message}
                                rows="5"
                            />
                        </div>
                        <button
                            className="btn btn-primary welcome-common-form__button w-100"
                            type="submit"
                            disabled={contactMessageStatus === 'loading'}
                        >
                            Send message
                            {contactMessageStatus === 'loading' && (
                                <span
                                    class="spinner-border spinner-border-sm ms-2"
                                    aria-hidden="true"
                                ></span>
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;
