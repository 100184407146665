import React from 'react';
import Editor from '@monaco-editor/react';

export default function PrettyTextTab({ data }) {
    return (
        <Editor
            height="310px"
            defaultLanguage={
                data?.contentType === 'text/html' ? 'html' : 'json'
            }
            defaultValue={
                data?.contentType === 'text/html'
                    ? data.result
                    : JSON.stringify(data.result, null, 2)
            }
            options={{
                readOnly: true,
                minimap: { enabled: false },
                scrollbar: {
                    alwaysConsumeMouseWheel: false,
                },
                readOnlyMessage: { value: null },
                wordWrap: 'on',
                scrollBeyondLastLine: false,
                contextmenu: false,
            }}
        />
    );
}
