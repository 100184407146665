import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Mail, Globe } from 'react-feather';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import MemberProjects from './MemberProjects';
import UserAvatar from 'components/Avatar/UserAvatar';
import MainContent from 'ui/MainContent';
import {
    fetchMemberProfile,
    fetchMemberProjects,
    memberSelectors,
} from 'store/slices/membersSlice';

const MemberProfilePage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const member = useSelector(memberSelectors.getMember);
    const memberProjects = useSelector(memberSelectors.getMemberProjects);

    useEffect(() => {
        dispatch(fetchMemberProfile({ uuid: id }));
        dispatch(fetchMemberProjects({ uuid: id }));
    }, [dispatch, id]);

    return (
        <MainContent>
            <Helmet>
                <title>
                    CDProjects - Create beautiful REST API Documentations
                </title>
            </Helmet>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-3 text-center">
                        <div className="text-center">
                            <UserAvatar
                                iconUrl={member.avatar_url}
                                first_name={member.first_name}
                                last_name={member.last_name}
                                className="avatar"
                            />
                        </div>

                        <h4 className="mb-4">
                            {member.first_name} {member.last_name}
                        </h4>
                        <div className="card" style={{ width: '100%' }}>
                            <div className="card-header">About</div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <Mail size={17} className="" />{' '}
                                    {member.email}
                                </li>
                                <li className="list-group-item">
                                    <Globe size={17} className="" />{' '}
                                    {member.country?.name ?? 'N/A'}
                                </li>
                                {/* <li className="list-group-item"><Phone size={17} className="" /> {member.phone ?? "N/A"}</li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col">
                        <h5 className="mb-4">Work on</h5>
                        <MemberProjects memberProjects={memberProjects} />
                    </div>
                </div>
            </div>
        </MainContent>
    );
};

export default MemberProfilePage;
