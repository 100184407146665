import React from 'react';

const AppLogo = () => {
    return (
        <div className="header-logo">
            <a className="" href="/">
                <img src="/img/logo.svg" alt="logo" />
            </a>
        </div>
    );
};

export default AppLogo;
