const PageNotFound = () => {
    return (
        <div className="position-absolute top-50 start-50 translate-middle">
            <p className="mb-5 text-center" style={{ fontSize: '100px' }}>
                404
            </p>
            <p className="fs-1 mb-5 text-center">Page not found</p>
            <p className="fs-5 text-center">
                We're sorry, the page you requested could not be found.
            </p>
        </div>
    );
};

export default PageNotFound;
