import React from 'react';
import RequestParamsItem from './RequestParamsItem';

const RequestParams = ({
    items,
    paramName,
    changeParam,
    onSelectChange,
    deleteRow,
    resourcesOptions,
    errors,
}) => {
    const findResourceOption = (paramName, options, item) => {
        if (paramName === 'Body parameters') {
            return options?.find((el) => el.id === item.resource_id);
        }
    };

    if (items?.length) {
        return (
            <div className="pb-3">
                <table className="table mb-0">
                    <thead>
                        <tr>
                            <th className="title-table">Name</th>
                            <th className="title-table">Type</th>
                            <th className="title-table">Required</th>
                            <th className="title-table">Description</th>
                            <th className="title-table">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => {
                            const resourceItem = findResourceOption(
                                paramName,
                                resourcesOptions,
                                item,
                            );

                            return (
                                <RequestParamsItem
                                    key={index}
                                    index={index}
                                    name={item.name}
                                    param={item.state_name}
                                    paramName={paramName}
                                    type={item.type}
                                    resource={resourceItem?.value}
                                    resourcesOptions={resourcesOptions}
                                    isRequired={item.is_required}
                                    description={item.description}
                                    handleChange={(e) =>
                                        changeParam(e, index, item)
                                    }
                                    onSelectChange={(option, param) =>
                                        onSelectChange(
                                            option,
                                            index,
                                            item,
                                            param,
                                        )
                                    }
                                    deleteRow={() => deleteRow(index, item)}
                                    errors={errors}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    } else {
        return <div></div>;
    }
};

export default RequestParams;
