import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { billingSelectors, getPlans } from 'store/slices/billingSlice';
import MainContent from 'ui/MainContent';
import PricingCard from 'pages/LandingPage/PricingCard';
import { useEffect, useState } from 'react';
import { organizationSelectors } from 'store/slices/organizationSlice';
import ToggleButton from 'ui/ToggleButton';
import { capitalize } from 'utils/helpers';
import Card from './Card';

const PlansPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const plans = useSelector(billingSelectors.getAllPlans);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const [currency, setCurrency] = useState('eur');

    const addSubscription = (plan) => {
        history.push(`/billing/plans/${plan?.id}`, {
            plan,
        });
    };

    const redirectToContactForm = () => {
        history.push('/billing/contact-sales');
    };

    useEffect(() => {
        if (currentOrg && currentOrg?.role !== 'OWNER') {
            history.push('/403');
        } else {
            dispatch(getPlans({ currency }));
        }
    }, [currency, currentOrg, dispatch, history]);

    return (
        <MainContent>
            <div className="main-container plansContainer">
                <Helmet>
                    <title>Plans - CDP</title>
                </Helmet>
                <div className="page-header d-flex flex-column mx-sm-5 mx-3 mb-5">
                    <h1 className="title-h3">Plans</h1>
                    <p className="pt-2 plansContainer__subheader">
                        Choose the subscription plan for your needs
                    </p>
                </div>
                <div className="d-flex justify-content-center mb-2">
                    <ToggleButton
                        className="d-flex align-items-center"
                        check="EUR"
                        uncheck="USD"
                        checkClassNames="me-2"
                        uncheckClassNames="ms-2"
                        onChange={(e) => setCurrency(e ? 'usd' : 'eur')}
                    />
                </div>
                <div className="d-flex flex-wrap flex-md-nowrap justify-content-center">
                    {plans?.map((plan) => (
                        <Card
                            key={plan.id}
                            subscription={plan.name}
                            price={Intl.NumberFormat('en', {
                                currency: plan.currency,
                                style: 'currency',
                            }).format(plan.cost)}
                            description={plan.description}
                            maxProjects={plan.max_projects_count}
                            maxApis={plan.max_apis_count}
                            maxUsers={plan.max_users_count}
                            term={capitalize(plan.period.toLowerCase())}
                            status={'medium'}
                            addSubscription={addSubscription}
                            plan={plan}
                            showButton
                        />
                    ))}
                    <PricingCard
                        subscription={'Enterprise'}
                        price={'Individually'}
                        term={'Monthly'}
                        description={'For Big Companies Unlimited APIs'}
                        textButton={'Contact us'}
                        status={'enterprise'}
                        addSubscription={redirectToContactForm}
                        showButton
                        style={{ marginLeft: '24px' }}
                    />
                </div>
            </div>
        </MainContent>
    );
};

export default PlansPage;
