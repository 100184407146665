import React, { useState } from 'react';
import AppLogo from 'components/AppLogo';
import WelcomeMenu from './WelcomeMenu';
import menuIcon from 'assets/img/landing/menu.svg';

const WelcomeHeader = () => {
    const [menuActive, setMenuActive] = useState(false);

    return (
        <div className="w-full bg-white">
            <header className="d-flex flex-md-row justify-content-between align-items-center welcome-header">
                <AppLogo />
                <nav className="welcome-header__nav">
                    <a href="/" className="welcome-header__link">
                        About
                    </a>
                    <a
                        href="/#pricing-section"
                        className="welcome-header__link px-5"
                    >
                        Pricing
                    </a>
                    <a
                        href="/#contact-section"
                        className="welcome-header__link"
                    >
                        Contact
                    </a>
                    {/* <Link to="/partners" className="welcome-header__link px-5">Partners</Link> */}
                </nav>
                <div className="welcome-header__buttons">
                    <a href="/login" className="welcome-header__login">
                        Log in
                    </a>
                    <a
                        href="/signup"
                        className="btn btn-primary welcome-header__signup"
                    >
                        Sign up
                    </a>
                </div>
                <div
                    className="welcome-menu__btn"
                    onClick={() => setMenuActive(!menuActive)}
                >
                    <img src={menuIcon} alt="menu" />
                </div>
            </header>
            <WelcomeMenu active={menuActive} setMenuActive={setMenuActive} />
        </div>
    );
};

export default WelcomeHeader;
