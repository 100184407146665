import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Input } from 'ui/Inputs';
import WelcomeHeader from 'layouts/welcome/WelcomeHeader';
import { acceptInvitation, checkCode } from 'store/slices/authSlice';
import { saveTokens } from 'utils/auth';
import { toast } from 'react-toastify';

const InvitationSignUpPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
        code: '',
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        setData((prevData) => ({ ...prevData, code: code }));

        dispatch(checkCode({ data: { code }, onSuccess: onSuccessCheckCode }));
    }, [dispatch, location.search]);

    const onSuccessCheckCode = (response) => {
        if (response.data.status === 'STATUS_USED') {
            window.location.pathname = '/projects';
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(acceptInvitation({ data, onSuccess, onError }));
    };

    const onSuccess = (response) => {
        saveTokens({ access_token: response.data.access_token });
        window.location.href = response.data.redirect_to + '/projects';
    };

    const onError = (errors) => {
        if (errors.response.status === 400) {
            toast.error(errors.response.data.message);
        } else {
            setErrors(errors.response.data.errors);
        }
    };

    return (
        <section className="signup-section bg-gray">
            <WelcomeHeader />
            <div className="container form-bg-image">
                <div className="row"></div>
                <div className="row"></div>
                <div className="row mt-md-5 pt-md-5">
                    <div className="col-10 col-md-6 pb-3 singup-registration">
                        <h1 className="h3 mb-3">
                            Invitation sign up to{' '}
                            <span className="text-primary h3">CDProjects</span>
                        </h1>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group mb-4">
                                <Input
                                    type="text"
                                    name="first_name"
                                    className="form-control"
                                    value={data?.first_name}
                                    onChange={handleChange}
                                    labelText="First name"
                                    labelClassName="form-label"
                                    placeHolder="Enter your first name"
                                    errorText={errors?.first_name}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <Input
                                    type="text"
                                    name="last_name"
                                    className="form-control"
                                    value={data?.last_name}
                                    onChange={handleChange}
                                    labelClassName="form-label"
                                    labelText="Last name"
                                    placeHolder="Enter your last name"
                                    errorText={errors?.last_name}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <Input
                                    type="password"
                                    name="password"
                                    className="form-control"
                                    value={data?.password}
                                    onChange={handleChange}
                                    labelClassName="form-label"
                                    labelText="Password"
                                    placeHolder="Enter your password"
                                    errorText={errors?.password}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <Input
                                    type="password"
                                    name="password_confirmation"
                                    className="form-control"
                                    value={data?.password_confirmation}
                                    onChange={handleChange}
                                    labelClassName="form-label"
                                    labelText="Password confirmation"
                                    placeHolder="Confirm your password"
                                    errorText={errors?.password_confirmation}
                                />
                            </div>
                            <button
                                className="btn btn-primary d-block mt-4 mt-md-5 mb-md-5"
                                type="submit"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                    <div className="col-1 col-mt-2"></div>
                    <div className="col-3 pt-2 px-4 px-md-0 mb-5">
                        <p className="mb-2 mt-md-5 pt-3 form-label">
                            Already have an account?
                        </p>
                        <a href="/login" className="title-h4">
                            Log in
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default InvitationSignUpPage;
