import {
    useState,
    useCallback,
    useEffect,
    useMemo,
    createContext,
} from 'react';
import _ from 'lodash';
import List from './List';
import './assets/styles/styles.scss';
import Examples from './Examples';
import GenerateFromJson from './GenerateFromJson';
import { Edit3 } from 'react-feather';
import RequiredMark from 'ui/RequiredMark';
import {
    schemaConverter,
    validateSchema,
    generateId,
    createShallowCopy,
    findRef,
    defineSchema,
    literalsMap,
    getObjectFieldsIntersections,
    isCombiner,
    getSchemaExamples,
} from './helpers';
import { Builder } from './builder/builder';

export const SchemaTypeContext = createContext(null);

export default function JsonSchemaEditor({
    schema,
    models,
    modelName,
    getSchema,
    schemaType = 'default',
}) {
    const build = useMemo(
        () =>
            new Builder(defineSchema(schema, modelName), null, {
                models,
            }),
        [modelName, models, schema],
    );
    const [isCollapsed, setIsCollapsed] = useState([]);
    const [tree, setTree] = useState([]);
    const [data, setData] = useState(() => {
        build.build();
        setIsCollapsed(Array.from(build.collapsed));
        return build.dataArray;
    });
    const [activeTab, setActiveTab] = useState('schema');
    const [examples, setExamples] = useState(() =>
        getSchemaExamples(validateSchema(schema)),
    );
    const [validJson, setValidJson] = useState('');
    const [isValidJson, setIsValidJson] = useState(false);
    const schemaName = useMemo(
        () => modelName || Object.keys(validateSchema(schema))[0] || 'schema',
        [modelName, schema],
    );

    const dataToJson = useCallback((data) => {
        const grouped = _.groupBy(data, (item) =>
            item?.parent ? item?.parent?.id : item.parent,
        );

        const childrenOf = (parent) => {
            return (grouped[parent] || []).reduce((acc, val) => {
                const type = Array.isArray(val.type) ? val.type[0] : val.type;
                const subType = Array.isArray(val.subType)
                    ? val.subType[0]
                    : val.subType;
                const isDetachedRootNode = val.detached && val.name === 'root';
                const isCombinerType = isCombiner(type);

                if (isDetachedRootNode && type === 'object') {
                    return childrenOf(val.id);
                }
                if (
                    isDetachedRootNode &&
                    type === 'array' &&
                    ['object', '$ref'].includes(subType)
                ) {
                    return [childrenOf(val.id)];
                }

                const field =
                    type === 'array' && subType === 'object'
                        ? [childrenOf(val.id)]
                        : type === 'object' || type === '$ref'
                          ? childrenOf(val.id)
                          : isCombinerType
                            ? [childrenOf(val.id)].flatMap(Object.values)
                            : type === 'array' && subType === '$ref'
                              ? [childrenOf(val.id)]
                              : type === 'array'
                                ? subType
                                    ? [literalsMap[subType]]
                                    : []
                                : val?.extraProps?.example || literalsMap[type];

                return {
                    ...acc,
                    ...(val.isCombinerChild
                        ? { [val.id]: field }
                        : { [val.name]: field }),
                };
            }, {});
        };

        return JSON.stringify(Object.values(childrenOf(null))[0], null, 6);
    }, []);

    const dataToSchema = useCallback((data) => {
        const grouped = _.groupBy(data, (item) =>
            item?.parent ? item?.parent?.id : item.parent,
        );
        const childrenOf = (parent) => {
            return (grouped[parent] || []).reduce((acc, val) => {
                const type = Array.isArray(val.type) ? val.type[0] : val.type;
                const subType = Array.isArray(val.subType)
                    ? val.subType[0]
                    : val.subType;
                const requiredFields = data
                    .filter((el) => el.parent?.id === val.id && !!el.isRequired)
                    .map((el) => el.name);
                const behaviourMap = {
                    read: 'readOnly',
                    write: 'writeOnly',
                };
                const combinerType = isCombiner(type) && type;
                const typesMap = {
                    array: {
                        type: val.type,
                        ...(val.description && {
                            description: val.description,
                        }),
                        ...((val.behaviour === 'read' ||
                            val.behaviour === 'write') && {
                            [behaviourMap[val.behaviour]]: true,
                        }),
                        items: subType
                            ? subType === 'object'
                                ? {
                                      type: subType,
                                      ...(Object.keys(childrenOf(val.id))
                                          .length && {
                                          properties: childrenOf(val.id),
                                      }),

                                      ...(requiredFields.length && {
                                          required: requiredFields,
                                      }),
                                  }
                                : subType === '$ref'
                                  ? val.refPath
                                      ? { $ref: val.refPath }
                                      : {}
                                  : {
                                        type: subType,
                                    }
                            : {},
                    },
                    object: {
                        type: val.type,
                        ...(Object.keys(childrenOf(val.id)).length && {
                            properties: childrenOf(val.id),
                        }),
                        ...(val.description && {
                            description: val.description,
                        }),
                        ...((val.behaviour === 'read' ||
                            val.behaviour === 'write') && {
                            [behaviourMap[val.behaviour]]: true,
                        }),
                        ...(requiredFields.length && {
                            required: requiredFields,
                        }),
                    },
                    $ref: {
                        $ref: val.refPath,
                        ...(val.description && {
                            description: val.description,
                        }),
                        ...((val.behaviour === 'read' ||
                            val.behaviour === 'write') && {
                            [behaviourMap[val.behaviour]]: true,
                        }),
                    },
                    [combinerType]: {
                        [combinerType]: [childrenOf(val.id)].flatMap(
                            Object.values,
                        ),
                    },
                    default: {
                        type: val.type,
                        ...(val.description && {
                            description: val.description,
                        }),
                        ...((val.behaviour === 'read' ||
                            val.behaviour === 'write') && {
                            [behaviourMap[val.behaviour]]: true,
                        }),
                        ...(val.enumValue && {
                            enum: val.enumValue,
                        }),
                        ...val.extraProps,
                    },
                };
                const field = typesMap[type] ?? typesMap.default;

                return {
                    ...acc,
                    ...(val.isCombinerChild
                        ? { [val.id]: field }
                        : { [val.name]: field }),
                };
            }, {});
        };

        return childrenOf(null);
    }, []);

    const filterByBehaviour = useCallback(
        (data) => {
            const typesMap = {
                request: () => data.filter((el) => el.behaviour !== 'read'),
                response: () => data.filter((el) => el.behaviour !== 'write'),
            };
            return typesMap?.[schemaType]?.() || data;
        },
        [schemaType],
    );

    const changeSchemaName = useCallback(
        (schema) => {
            const name = Object.keys(schema)[0] || 'schema';
            return { [modelName || 'schema']: schema[name] };
        },
        [modelName],
    );

    const setCollapsed = useCallback(
        (id) => {
            if (isCollapsed.includes(id)) {
                const result = isCollapsed.filter((item) => item !== id);
                setIsCollapsed(result);
                return;
            }
            const result = [...isCollapsed, id];
            setIsCollapsed(result);
        },
        [isCollapsed],
    );

    useEffect(() => {
        const tree = build.buildTree(data);
        const readySchema = dataToSchema(data);
        const modifiedSchema = changeSchemaName(readySchema);

        modifiedSchema[Object.keys(modifiedSchema)[0]]['x-examples'] =
            examples || {};

        setTree(tree);
        if (JSON.stringify(modifiedSchema) !== schema) {
            getSchema(JSON.stringify(modifiedSchema));
        }
    }, [
        build,
        changeSchemaName,
        data,
        dataToSchema,
        examples,
        getSchema,
        schema,
    ]);

    const addElement = useCallback(
        (parentId) => {
            const dataCopy = createShallowCopy(data);
            const parent = dataCopy.find((el) => el.id === parentId);

            if (isCollapsed.includes(parentId)) {
                setCollapsed(parentId);
            }

            dataCopy.push({
                id: generateId(),
                name: '',
                parent: parent,
                type: isCombiner(parent.type[0]) ? ['object'] : ['string'],
                subType: null,
                description: '',
                refPath: null,
                refName: null,
                metadata: {
                    fragment: {
                        type: isCombiner(parent.type[0]) ? 'object' : 'string',
                    },
                    children: null,
                    refSchema: null,
                },
                isRequired: null,
                behaviour: 'read/write',
                isNullable: false,
                enumValue: null,
                detached: false,
                depth: parent.depth + 1,
                extraProps: {},
                ...(isCombiner(parent.type[0]) && { isCombinerChild: true }),
            });

            setData(dataCopy);
        },
        [data, isCollapsed, setCollapsed],
    );

    const deleteElement = useCallback(
        (id) => {
            const dataCopy = createShallowCopy(data);

            function deleteTwig(id) {
                const twig = dataCopy.find((el) => el.id === id);
                const children = dataCopy.filter((el) => el?.parent?.id === id);
                const index = dataCopy.indexOf(twig);

                dataCopy.splice(index, 1);

                for (let i = 0; i < children.length; i++) {
                    deleteTwig(children[i].id);
                }
            }

            deleteTwig(id);
            setData(dataCopy);
            return dataCopy;
        },
        [data],
    );

    const setupElementsToMove = useCallback(
        (elementId, parentId) => {
            const dataCopy = _.cloneDeep(data);
            const others = dataCopy.filter((el) => el?.parent?.id !== parentId);
            const childrenList = dataCopy.filter(
                (el) => el?.parent?.id === parentId,
            );
            const index = childrenList.findIndex((el) => el.id === elementId);
            const element = childrenList.splice(index, 1)[0];

            return { others, childrenList, element, index };
        },
        [data],
    );

    const moveTop = useCallback(
        (elementId, parentId) => {
            const { others, childrenList, element, index } =
                setupElementsToMove(elementId, parentId);

            childrenList.splice(index - 1, 0, element);
            setData([...others, ...childrenList]);
        },
        [setupElementsToMove],
    );

    const moveBottom = useCallback(
        (elementId, parentId) => {
            const { others, childrenList, element, index } =
                setupElementsToMove(elementId, parentId);

            childrenList.splice(index + 1, 0, element);
            setData([...others, ...childrenList]);
        },
        [setupElementsToMove],
    );

    const onKeyNameChange = useCallback(
        (value, id) => {
            const dataCopy = createShallowCopy(data);
            const index = dataCopy.findIndex((el) => el.id === id);
            const element = dataCopy[index];

            element.name = value;

            setData(dataCopy);
        },
        [data],
    );

    const duplicateElement = useCallback(
        (id) => {
            const dataCopy = createShallowCopy(data);

            let newList = [];
            let counter = 0;

            function duplicateTwig(id, counter) {
                const twig = dataCopy.find((el) => el.id === id);
                const twigParentChildren = dataCopy.filter(
                    (el) => el?.parent?.id === twig?.parent?.id,
                );
                const parentChildrenWithCopyLength = twigParentChildren.filter(
                    (el) => el?.name?.includes('- copy'),
                ).length;

                twig.id = generateId();
                twig.name = !counter
                    ? twig.name.split('-')[0].trim() +
                      ` - copy ${
                          !parentChildrenWithCopyLength
                              ? ''
                              : parentChildrenWithCopyLength
                      }`
                    : twig.name;

                const children = dataCopy
                    .filter((el) => el?.parent?.id === id)
                    .map((el) => {
                        el.parent = twig;
                        return el;
                    });
                newList.push(twig);
                newList.concat(children);
                counter++;
                for (let i = 0; i < children.length; i++) {
                    duplicateTwig(children[i].id, counter);
                }
                return newList;
            }

            const result = duplicateTwig(id, counter);
            setData([...data, ...result]);
        },
        [data],
    );

    const onChangeType = useCallback(
        (types, elementId, refName = '') => {
            const dataCopy = createShallowCopy(data);
            const index = dataCopy.findIndex((el) => el.id === elementId);
            const element = dataCopy[index];
            const type = types.type === '$ref' ? types.type : [types.type];
            const subType =
                types.subType === '$ref'
                    ? types.subType
                    : types.subType && [types.subType];

            if (isCombiner(type[0]) && isCombiner(element.type[0])) {
                element.type = type;
                setData(dataCopy);
                return;
            }

            element.type = type;
            element.subType = subType;
            element.refPath = null;
            element.refName = null;
            element.enumValue = null;
            element.metadata.refSchema = null;
            element.metadata.fragment = null;
            element.extraProps = ['string', 'number', 'integer'].includes(
                type[0],
            )
                ? getObjectFieldsIntersections(element.extraProps, type[0])
                : {};

            if (type === '$ref' || (subType === '$ref' && refName)) {
                const ref = findRef(models, refName);

                element.refPath = `#/components/schemas/${refName}`;
                element.refName = refName;
                element.metadata.refSchema = ref;
                element.isNullable = false;
                element.extraProps = {};

                const build = new Builder(
                    defineSchema(JSON.stringify(ref), modelName),
                    element,
                    {
                        models,
                        depth: element.depth + 1,
                        detached: true,
                    },
                );

                build.build();

                setIsCollapsed((prev) => [
                    ...prev,
                    elementId,
                    ...Array.from(build.collapsed),
                ]);

                const data = deleteElement(elementId);

                data.splice(index, 0, element);
                setData([...data, ...build.dataArray]);
                return;
            }

            const newData = deleteElement(elementId);
            newData.splice(index, 0, element);
            setData(newData);
            return;
        },
        [data, deleteElement, modelName, models],
    );

    const onChangeIsNullable = useCallback(
        (e, elementId) => {
            const dataCopy = createShallowCopy(data);
            const index = dataCopy.findIndex((el) => el.id === elementId);
            const element = dataCopy[index];

            element.isNullable = e.target.checked;

            if (element.type.includes('null')) element.type.pop();
            else element.type.push('null');

            setData(dataCopy);
        },
        [data],
    );

    const changeProperties = useCallback(
        (id, properties) => {
            const dataCopy = createShallowCopy(data);
            const { behaviour, description, isRequired } = properties;
            const index = dataCopy.findIndex((el) => el.id === id);
            const element = dataCopy[index];

            element.behaviour = behaviour;
            element.description = description;
            element.isRequired = isRequired;

            setData(dataCopy);
        },
        [data],
    );

    const changeExtraProperties = useCallback(
        (id, extraProperties) => {
            const dataCopy = createShallowCopy(data);
            const index = dataCopy.findIndex((el) => el.id === id);
            const element = dataCopy[index];

            for (const key in extraProperties) {
                if (
                    !extraProperties[key] &&
                    typeof extraProperties[key] !== 'number'
                ) {
                    delete extraProperties[key];
                }
            }
            element.extraProps = { ...extraProperties };

            setData(dataCopy);
        },
        [data],
    );

    const selectActiveTab = useCallback((e) => {
        const { dataset } = e.currentTarget;

        setActiveTab(dataset.key);
    }, []);

    const addExample = useCallback(() => {
        const examplesCopy = createShallowCopy(examples);
        const filteredData = filterByBehaviour(data);
        const jsonExample = dataToJson(filteredData);
        const examplesWithStandartNameAmount = Object.keys(examplesCopy).filter(
            (el) => el.includes('Example-'),
        ).length;

        setExamples({
            ...examplesCopy,
            [`Example-${examplesWithStandartNameAmount + 1}`]:
                JSON.parse(jsonExample),
        });
    }, [data, dataToJson, examples, filterByBehaviour]);

    const editExample = useCallback(
        (code, exampleName) => {
            const examplesCopy = createShallowCopy(examples);

            examplesCopy[exampleName] = JSON.parse(code);

            setExamples(examplesCopy);
        },
        [examples],
    );

    const editExampleName = useCallback(
        (currentName, newName) => {
            const examplesCopy = createShallowCopy(examples);
            const examplesEntries = Object.entries(examplesCopy);

            const exampleIndex = examplesEntries.findIndex(
                (el) => el[0] === currentName,
            );

            examplesEntries[exampleIndex][0] = newName;

            const newExamples = Object.fromEntries(examplesEntries);

            setExamples(newExamples);
        },
        [examples],
    );

    const deleteExample = useCallback(
        (exampleIndex) => {
            const examplesCopy = createShallowCopy(examples);

            delete examplesCopy[exampleIndex];

            setExamples(examplesCopy);
        },
        [examples],
    );

    const getJson = useCallback((json, isJsonValid) => {
        setIsValidJson(isJsonValid);
        setValidJson(json);
    }, []);

    const generateJson = useCallback(() => {
        const schema = schemaConverter(`{"${schemaName}":${validJson}}`);
        const build = new Builder(defineSchema(schema, modelName), null, {
            models,
        });
        setData(() => {
            build.build();
            setIsCollapsed(Array.from(build.collapsed));
            return build.dataArray;
        });
        setExamples({});
        setIsValidJson(false);
        setActiveTab('schema');
    }, [modelName, models, schemaName, validJson]);

    const onChangeEnumType = useCallback(
        (elementId) => {
            const dataCopy = createShallowCopy(data);
            const index = dataCopy.findIndex((el) => el.id === elementId);
            const element = dataCopy[index];

            element.type = ['string'];
            element.subType = null;
            element.isNullable = false;
            element.enumValue = [];
            element.refPath = null;
            element.refName = null;
            element.metadata.refSchema = null;
            element.metadata.fragment = null;
            element.extraProps = {};

            const newData = deleteElement(elementId);
            newData.splice(index, 0, element);
            setData(newData);
        },
        [data, deleteElement],
    );

    const onChangeEnumValues = useCallback(
        (elementId, values) => {
            const dataCopy = createShallowCopy(data);
            const index = dataCopy.findIndex((el) => el.id === elementId);
            const element = dataCopy[index];

            element.enumValue = values.filter((el) => !!el);

            setData(dataCopy);
        },
        [data],
    );

    const onClearTree = () => {
        const dataCopy = createShallowCopy(data);

        dataCopy[0] = {
            id: generateId(),
            name: 'root',
            parent: null,
            type: ['object'],
            subType: null,
            description: '',
            refPath: null,
            refName: null,
            metadata: {
                fragment: null,
                children: null,
                refSchema: null,
            },
            isRequired: null,
            behaviour: 'read/write',
            isNullable: false,
            detached: false,
            depth: 0,
            enumValue: null,
            extraProps: {},
        };

        setData([dataCopy[0]]);
    };

    return (
        <SchemaTypeContext.Provider value={schemaType}>
            <div className="JsonSchemaEditor__outer-wrapper">
                <div className="JsonSchemaEditor__navigation-pannel">
                    {activeTab === 'generator' ? (
                        <></>
                    ) : (
                        <>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <button
                                        className={
                                            activeTab === 'schema'
                                                ? 'JsonSchemaEditor__tab-button JsonSchemaEditor__tab-button--active me-2'
                                                : 'JsonSchemaEditor__tab-button me-2'
                                        }
                                        onClick={selectActiveTab}
                                        data-key="schema"
                                        type="button"
                                    >
                                        Schema
                                    </button>
                                    <button
                                        className={
                                            activeTab === 'examples'
                                                ? 'JsonSchemaEditor__tab-button JsonSchemaEditor__tab-button--active me-2'
                                                : 'JsonSchemaEditor__tab-button me-2'
                                        }
                                        onClick={selectActiveTab}
                                        data-key="examples"
                                        type="button"
                                    >
                                        Examples
                                    </button>
                                </div>
                                <div>
                                    <button
                                        className="JsonSchemaEditor__generate-button"
                                        onClick={selectActiveTab}
                                        data-key="generator"
                                        type="button"
                                    >
                                        <Edit3 size={16} />
                                        <span className="ms-2">
                                            Generate from JSON
                                        </span>
                                    </button>
                                </div>
                            </div>
                            {schemaType !== 'response' && (
                                <div className="pe-3 mt-2 text-end">
                                    <span
                                        style={{
                                            fontSize: '14px',
                                            fontWeight: 500,
                                        }}
                                    >
                                        <RequiredMark /> - required field
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                </div>
                {activeTab === 'schema' && (
                    <div className="JsonSchemaEditor__schema-wrapper">
                        <List
                            list={tree}
                            schemaName={schemaName}
                            isCollapsed={isCollapsed}
                            models={models}
                            setCollapsed={setCollapsed}
                            addElement={addElement}
                            deleteElement={deleteElement}
                            duplicateElement={duplicateElement}
                            moveTop={moveTop}
                            moveBottom={moveBottom}
                            onKeyNameChange={onKeyNameChange}
                            onChangeType={onChangeType}
                            changeProperties={changeProperties}
                            onChangeNullable={onChangeIsNullable}
                            onChangeEnum={onChangeEnumType}
                            onChangeEnumValues={onChangeEnumValues}
                            changeExtraProperties={changeExtraProperties}
                            onClearTree={onClearTree}
                        />
                    </div>
                )}
                {activeTab === 'examples' && (
                    <Examples
                        examples={examples}
                        addExample={addExample}
                        editExample={editExample}
                        editExampleName={editExampleName}
                        deleteExample={deleteExample}
                    />
                )}
                {activeTab === 'generator' && (
                    <GenerateFromJson
                        getJson={getJson}
                        setActiveTab={() => setActiveTab('schema')}
                        generateJson={generateJson}
                        isEditedJsonValid={isValidJson}
                    />
                )}
            </div>
        </SchemaTypeContext.Provider>
    );
}
