import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

import MainContent from 'ui/MainContent';
import LocalLoader from 'ui/LocalLoader';
import TransferTable from './TransferTable';
import Modal from 'ui/Modal';
import { authSelectors } from 'store/slices/authSlice';
import {
    acceptTransferProject,
    cancelTransferProject,
    declineTransferProject,
    getTransfers,
    projectSelectors,
} from 'store/slices/projectsSlice';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { organizationSelectors } from 'store/slices/organizationSlice';

const permissions = ['OWNER', 'ADMIN'];

const TransferPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const myAccount = useSelector(authSelectors.getMyAccount);
    const transfers = useSelector(projectSelectors.getTransfers);
    const isTransfersFetching = useSelector(
        projectSelectors.getIsTransfersFetching,
    );
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const [transferModalShown, setTransferModalShown] = useState({
        isCancelTransferModalShown: false,
        isAcceptTransferModalShown: false,
        isDeclineTransferModalShown: false,
    });
    const [transferUid, setTransferUid] = useState(null);

    useLayoutEffect(() => {
        if (currentOrg && !permissions.includes(currentOrg?.role))
            history.push('/403');
    }, [currentOrg, currentOrg?.role, history]);

    const filteredTransfers = transfers.filter(
        (transfer) => transfer.status === 'STATUS_PENDING',
    );

    useEffect(() => {
        dispatch(getTransfers());
    }, [dispatch]);

    const isCancelTransferModalShown = (flag, transferUid = null) => {
        setTransferModalShown({
            ...transferModalShown,
            isCancelTransferModalShown: flag,
        });
        setTransferUid(transferUid);
    };

    const handleCancelTransferModal = (e, transfer) => {
        e.preventDefault();
        isCancelTransferModalShown(true, transfer.project.uid);
    };

    const isAcceptTransferModalShown = (flag, transferUid = null) => {
        setTransferModalShown({
            ...transferModalShown,
            isAcceptTransferModalShown: flag,
        });
        setTransferUid(transferUid);
    };

    const handleAcceptTransferModal = (e, transfer) => {
        e.preventDefault();
        isAcceptTransferModalShown(true, transfer.project.uid);
    };

    const isDeclineTransferModalShown = (flag, transferUid = null) => {
        setTransferModalShown({
            ...transferModalShown,
            isDeclineTransferModalShown: flag,
        });
        setTransferUid(transferUid);
    };

    const handleDeclineTransferModal = (e, transfer) => {
        e.preventDefault();
        isDeclineTransferModalShown(true, transfer.project.uid);
    };

    const onCancelTransfer = () => {
        dispatch(
            cancelTransferProject({
                uid: transferUid,
                onSuccess: onSuccessCancelTransfer,
                onError,
            }),
        );

        isCancelTransferModalShown(false);
    };

    const onSuccessCancelTransfer = () => {
        toast.success('The project’s transfer has been canceled');
        setTimeout(() => {
            window.location.href = '/transfers';
        }, 700);
    };

    const onError = (error) => {
        toast.error('Something went wrong, please try again later');
    };

    const onAcceptTransfer = () => {
        dispatch(
            acceptTransferProject({
                uid: transferUid,
                onSuccess: onSuccessAcceptTransfer,
                onError,
            }),
        );

        isAcceptTransferModalShown(false);
    };

    const onSuccessAcceptTransfer = () => {
        toast.success('The project’s transfer has been accepted');
        setTimeout(() => {
            window.location.href = '/transfers';
        }, 700);
    };

    const onDeclineTransfer = () => {
        dispatch(
            declineTransferProject({
                uid: transferUid,
                onSuccess: onSuccessDeclineTransfer,
                onError,
            }),
        );

        isDeclineTransferModalShown(false);
    };

    const onSuccessDeclineTransfer = () => {
        toast.success('The project’s transfer has been declined');
        setTimeout(() => {
            window.location.href = '/transfers';
        }, 700);
    };

    return (
        <MainContent>
            <div className="main-container">
                <Helmet>
                    <title>
                        Projects - CDProjects - Create beautiful REST API
                        Documentations
                    </title>
                </Helmet>

                <div className="page-header d-flex flex-row justify-content-between mx-sm-5 mx-3">
                    <h1 className="title-h3">Transfers</h1>
                </div>
                <LocalLoader loading={isTransfersFetching}>
                    <TransferTable
                        myAccount={myAccount}
                        transfers={filteredTransfers}
                        handleCancelTransferModal={handleCancelTransferModal}
                        handleAcceptTransferModal={handleAcceptTransferModal}
                        handleDeclineTransferModal={handleDeclineTransferModal}
                    />
                </LocalLoader>
                <Modal
                    show={transferModalShown.isCancelTransferModalShown}
                    title="Cancel transfer"
                    body="This Project transfer will be canceled, are you sure ?"
                    footer={
                        <Fragment>
                            <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={onCancelTransfer}
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    isCancelTransferModalShown(false)
                                }
                                className="btn btn-link"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </Fragment>
                    }
                />
                <Modal
                    show={transferModalShown.isAcceptTransferModalShown}
                    title="Accept transfer"
                    body="This Project will be transferred to you, are you sure?"
                    footer={
                        <Fragment>
                            <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={onAcceptTransfer}
                            >
                                Accept
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    isAcceptTransferModalShown(false)
                                }
                                className="btn btn-link"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </Fragment>
                    }
                />
                <Modal
                    show={transferModalShown.isDeclineTransferModalShown}
                    title="Decline transfer"
                    body="This Project transfer will be declined, are you sure?"
                    footer={
                        <Fragment>
                            <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={onDeclineTransfer}
                            >
                                Decline
                            </button>
                            <button
                                type="button"
                                onClick={() =>
                                    isDeclineTransferModalShown(false)
                                }
                                className="btn btn-link"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </Fragment>
                    }
                />
            </div>
        </MainContent>
    );
};

export default TransferPage;
