import { Component } from 'react';

export default class ErrorBoundary extends Component {
    state = { hasError: false, error: null };

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error,
        };
    }

    render() {
        const { children, fallback } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return fallback;
        }
        return children;
    }
}
