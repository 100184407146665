import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import PageHeader from 'ui/PageHeader';
import { Input } from 'ui/Inputs';
import Modal from 'ui/Modal';
import { projectSelectors } from 'store/slices/projectsSlice';
import {
    apiSelectors,
    fetchApi,
    moveApi,
    trashApi,
    updateApi,
} from 'store/slices/apiSlice';
import { authSelectors } from 'store/slices/authSlice';
import LocalLoader from 'ui/LocalLoader';
import CustomSelect from 'ui/CustomSelect';
import Servers from './Servers';
import GlobalHeaders from './GlobalHeaders';
import { organizationSelectors } from 'store/slices/organizationSlice';

const SummaryPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const project = useSelector(projectSelectors.getCurrentProject);
    const projects = useSelector(projectSelectors.getProjects);
    const isProjectsFetching = useSelector(
        projectSelectors.getIsProjectFetching,
    );
    const api = useSelector(apiSelectors.getCurrentApi);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const [data, setData] = useState({
        name: '',
        code: '',
        version: '',
        api_description: '',
        project_uid_transfer_to: '',
        api_servers: [],
    });
    const [isTrashModalShown, setIsTrashModelShown] = useState(false);
    const [isMoveModalShown, setIsMoveModelShown] = useState(false);
    const [errors, setErrors] = useState({});
    const selectProjectsOptions = projects
        ?.filter(
            (item) =>
                item.owner.id === myAccount?.id && item.uid !== project?.uid,
        )
        .reduce((acc, item) => {
            acc.push({ value: item.uid, label: item.name });
            return acc;
        }, []);

    useEffect(() => {
        setData({
            name: api?.name,
            code: api?.code,
            version: api?.version,
            api_description: api?.api_description,
            api_servers: api?.servers,
        });
    }, [
        api?.code,
        api?.name,
        api?.version,
        api?.api_description,
        dispatch,
        location?.search,
        api?.id,
        project?.uid,
        api?.servers,
    ]);

    const handleChangeSelect = (option) => {
        setData({ ...data, project_uid_transfer_to: option.value });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setData({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(
            updateApi({
                projectUid: project?.uid,
                apiUid: api?.uid,
                data,
                onSuccess,
                onError,
            }),
        );
    };

    const onSuccess = (response) => {
        const queryParams = new URLSearchParams(window.location.search);
        const projectUid = queryParams.get('project');
        const apiUid = queryParams.get('api');
        const api = response.data.data;
        toast.success('Your data has been saved.');
        setErrors({});
        if (apiUid !== api?.uid) {
            setTimeout(
                () =>
                    window.location.replace(
                        `/project/api/settings/summary?project=${projectUid}&api=${api?.uid}`,
                    ),
                700,
            );
        } else {
            dispatch(fetchApi({ projectUid: project?.uid, apiUid: api?.uid }));
        }
    };

    const onError = (error) => {
        setErrors(error.response.data?.errors);
    };

    const toggleTrashModal = () => {
        setIsTrashModelShown(!isTrashModalShown);
    };

    const toggleMoveModal = () => {
        setIsMoveModelShown(!isMoveModalShown);
    };

    const onTrash = () => {
        toggleTrashModal();
        dispatch(
            trashApi({
                projectUid: project?.uid,
                apiUid: api?.uid,
                onSuccess: onSuccessTrash,
                onError,
            }),
        );
    };

    const onSuccessTrash = (response) => {
        toast.success('The project has been moved to trash');
        setTimeout(() => {
            window.location.href = `/project?project=${project?.uid}`;
        }, 700);
    };

    const onMoveApiToProject = () => {
        dispatch(
            moveApi({
                projectUid: project?.uid,
                api,
                data: {
                    project_uid_transfer_to: data?.project_uid_transfer_to,
                },
                onSuccess: onSuccessMoveApi,
                onError,
            }),
        );
    };

    const onSuccessMoveApi = (response, api, data) => {
        setIsMoveModelShown(false);
        toast.success(
            `The API ${api} has been moved to the ${data.project_uid_transfer_to} successfully`,
        );
        setTimeout(
            () =>
                window.location.replace(
                    `/project?project=${data.project_uid_transfer_to}`,
                ),
            700,
        );
    };

    const addParameter = () => {
        const newData = JSON.parse(JSON.stringify(data));
        newData.api_servers.push({
            url: '',
            name: '',
        });

        setData({ ...newData, api_servers: newData.api_servers });
    };

    const deleteParameterRow = (index) => {
        const newData = JSON.parse(JSON.stringify(data));
        const filteredServers = newData.api_servers.filter(
            (el, i) => i !== index,
        );

        setData({ ...newData, api_servers: filteredServers });
    };

    const changeParameterServer = (e, index) => {
        const { name, value } = e.target;
        const newData = JSON.parse(JSON.stringify(data));
        let dataItem = newData.api_servers[index];

        dataItem[name] = value;
        newData.api_servers[index] = { ...dataItem };

        setData({ ...newData, api_servers: newData.api_servers });
    };

    const hasOwnerRights =
        (project?.owner?.id === myAccount.id &&
            currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project?.user_role === 'MAINTAINER';
    const writeRights = hasRights || project?.user_role === 'WRITE';

    return (
        <div className="container-fluid p-5">
            <Helmet>
                <title>
                    Summary - CDProjects - Create beautiful REST API
                    Documentations
                </title>
            </Helmet>
            <div className="row">
                <div className="col">
                    <div className="d-flex justify-content-between mb-4">
                        <PageHeader margin="mb-0">Summary</PageHeader>
                        {(hasOwnerRights || currentOrg?.role === 'ADMIN') && (
                            <div className="btn-group">
                                <button
                                    type="button"
                                    className="btn btn-primary dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Manage API
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <button
                                            className="dropdown-item text-wrap"
                                            onClick={toggleTrashModal}
                                        >
                                            Delete API
                                        </button>
                                    </li>
                                    {!!selectProjectsOptions?.length && (
                                        <li>
                                            <button
                                                className="dropdown-item text-wrap"
                                                onClick={toggleMoveModal}
                                            >
                                                Move to project
                                            </button>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="row w-100">
                        <div className="">
                            <p className="mb-4">
                                Here you can change the API's related data.
                            </p>
                            <form className="w-75">
                                <div className="form-group mb-4">
                                    <Input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        value={data.name}
                                        id="name"
                                        onChange={handleChange}
                                        labelText="Name"
                                        labelClassName="form-label"
                                        placeHolder="Enter project name"
                                        errorText={errors.name}
                                        isDisabled={!hasRights}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="form-group mb-4">
                                    <Input
                                        type="text"
                                        name="code"
                                        className="form-control"
                                        value={data.code}
                                        id="code"
                                        onChange={handleChange}
                                        labelText="Code"
                                        labelClassName="form-label"
                                        placeHolder="Enter project code"
                                        errorText={errors?.code}
                                        isDisabled={!hasRights}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="form-group mb-4">
                                    <Input
                                        type="text"
                                        name="version"
                                        className="form-control"
                                        value={data?.version || ''}
                                        id="version"
                                        onChange={handleChange}
                                        labelText="Version"
                                        labelClassName="form-label"
                                        placeHolder="Enter Api version"
                                        errorText={errors?.version}
                                        isDisabled={!hasRights}
                                        isRequired={true}
                                    />
                                </div>
                                <div className="form-group mb-4 word-break">
                                    <Input
                                        type="textarea"
                                        value={data?.api_description || ''}
                                        onChange={handleChange}
                                        name="api_description"
                                        className="form-control"
                                        labelText="Description"
                                        labelClassName="form-label"
                                        id="description"
                                        placeHolder="Enter api description"
                                        rows="7"
                                        isDisabled={!hasRights}
                                        errorText={errors?.api_description}
                                    />
                                </div>
                                <Servers
                                    apiId={api?.id}
                                    project={project}
                                    servers={data?.api_servers}
                                    myAccount={myAccount}
                                    addParameter={addParameter}
                                    deleteParameterRow={deleteParameterRow}
                                    changeParameterServer={
                                        changeParameterServer
                                    }
                                    errors={errors}
                                />
                                <GlobalHeaders />
                                <button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="btn btn-primary"
                                    disabled={!writeRights}
                                >
                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={isTrashModalShown}
                title="Move to trash"
                body="This API will be moved to trash, are you sure ?"
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onTrash}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={toggleTrashModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
            <Modal
                show={isMoveModalShown}
                title="Move API to another project"
                body={
                    <div>
                        <p className="fw-bold text-black-50 mb-2">
                            Select project
                        </p>
                        <LocalLoader loading={isProjectsFetching}>
                            <CustomSelect
                                name="api"
                                options={selectProjectsOptions}
                                onChange={handleChangeSelect}
                                value={data?.project_uid_transfer_to}
                                fieldError={errors?.project_uid_transfer_to}
                            />
                        </LocalLoader>
                    </div>
                }
                footer={
                    <Fragment>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onMoveApiToProject}
                        >
                            Confirm
                        </button>
                        <button
                            type="button"
                            onClick={() => setIsMoveModelShown(false)}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Fragment>
                }
            />
        </div>
    );
};

export default SummaryPage;
