import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiSelectors } from 'store/slices/apiSlice';
import { authSelectors } from 'store/slices/authSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import LocalLoader from 'ui/LocalLoader';
import GlobalHeadersItem from './GlobalHeadersItem';
import Modal from 'ui/Modal';
import GlobalHeadersModalForm from './GlobalHeadersModalForm';
import {
    getGlobalHeaders,
    resourceSelectors,
    deleteGlobalHeader,
} from 'store/slices/resourcesSlice';
import { toast } from 'react-toastify';
import { organizationSelectors } from 'store/slices/organizationSlice';

export default function GlobalHeaders() {
    const [isHeadersModalOpened, setIsHeadersModalOpened] = useState(false);
    const [isDeleteHeaderModalShown, setIsDeleteHeaderModalShown] =
        useState(false);
    const [headersModalFlag, setHeadersModalFlag] = useState('create');
    const [selectedHeader, setSelectedHeader] = useState(null);

    const dispatch = useDispatch();
    const project = useSelector(projectSelectors.getCurrentProject);
    const api = useSelector(apiSelectors.getCurrentApi);
    const myAccount = useSelector(authSelectors.getMyAccount);
    const headers = useSelector(resourceSelectors.getGlobalHeaders);
    const isGlobalHeaderLoading = useSelector(
        resourceSelectors.getIsGlobalHeadersFetching,
    );
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const hasOwnerRights =
        (project.owner.id === myAccount.id && currentOrg?.role !== 'MEMBER') ||
        currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project?.user_role === 'MAINTAINER' ||
        project?.user_role === 'WRITE';
    useEffect(() => {
        dispatch(getGlobalHeaders({ apiId: api?.id }));
    }, [api?.id, dispatch]);

    const toggleHeaderModal = (flag, item = null) => {
        setIsHeadersModalOpened((prev) => !prev);
        setHeadersModalFlag(flag);
        setSelectedHeader(item);
    };

    const toggleDeleteHeaderModal = (item = null) => {
        setIsDeleteHeaderModalShown((prev) => !prev);
        setSelectedHeader(item);
    };

    const resetSelectedHeaderData = () => {
        setSelectedHeader(null);
    };

    const onDeleteHeader = () => {
        dispatch(
            deleteGlobalHeader({
                headerId: selectedHeader.id,
                onSuccess: onDeleteGlobalHeaderSuccess,
            }),
        );
    };

    const onDeleteGlobalHeaderSuccess = () => {
        toggleDeleteHeaderModal();
        toast.success('Header successfully deleted');
        dispatch(
            getGlobalHeaders({ projectUid: project?.uid, apiId: api?.id }),
        );
    };

    return (
        <>
            <section>
                <div
                    className="d-flex justify-content-between align-items-center pe-2 ps-3 mb-3"
                    style={
                        !hasRights
                            ? { height: '44px' }
                            : { borderBottom: '2px solid #edeff3' }
                    }
                >
                    <div className="d-flex align-items-center">
                        <h6 style={{ fontWeight: '400' }}>Global headers</h6>
                    </div>
                    <div>
                        {hasRights && (
                            <button
                                type="button"
                                className="btn btn-light mb-1 mt-1 text-start"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleHeaderModal('create');
                                }}
                            >
                                +
                            </button>
                        )}
                    </div>
                </div>
                <ul className="mb-4">
                    <LocalLoader loading={isGlobalHeaderLoading}>
                        {headers?.length > 0 &&
                            headers?.map((header, index) => (
                                <GlobalHeadersItem
                                    header={header}
                                    key={index}
                                    index={index}
                                    openEditForm={toggleHeaderModal}
                                    openDeleteHeaderModal={
                                        toggleDeleteHeaderModal
                                    }
                                />
                            ))}
                        {!headers?.length && !hasRights && (
                            <div
                                className="create-route-panel not-found"
                                style={{ margin: 0, height: '44px' }}
                            >
                                <p className="create-route-panel__desc">
                                    There are no global headers at the moment.
                                </p>
                            </div>
                        )}
                        {!headers?.length && hasRights && (
                            <div
                                className="create-route-panel not-found"
                                style={{ margin: 0, height: '44px' }}
                            >
                                <p className="create-route-panel__desc">
                                    No global headers found. Please create your
                                    first global header.
                                </p>
                            </div>
                        )}
                    </LocalLoader>
                </ul>
            </section>
            <Modal
                show={isHeadersModalOpened}
                customClasses={{ modalDialog: 'modal-dialog-centered' }}
                title={
                    headersModalFlag === 'create'
                        ? 'Create a new global header'
                        : 'Edit global header'
                }
                body={
                    <GlobalHeadersModalForm
                        onCloseHeaderModal={toggleHeaderModal}
                        header={selectedHeader}
                        project={project}
                        api={api}
                        headersModalFlag={headersModalFlag}
                        selectedHeader={selectedHeader}
                        resetSelectedHeaderData={resetSelectedHeaderData}
                    />
                }
            />
            <Modal
                show={isDeleteHeaderModalShown}
                title="Delete"
                body="This header will be permanently deleted, are you sure?"
                footer={
                    <>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={onDeleteHeader}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            onClick={toggleDeleteHeaderModal}
                            className="btn btn-link"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </>
                }
            />
        </>
    );
}
