export function isValidJson(value) {
    try {
        JSON.parse(value);
        return true;
    } catch (e) {
        return false;
    }
}

export function getQueryParam(name) {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(name) || '';
}

export function formatDate(dateStr) {
    // return dateStr.toString().slice(0, 10).split('-').reverse().join('.');
    return new Date(dateStr).toLocaleDateString('uk-UA');
}

export function redirect(url) {
    window.location.pathname = url;
}

export function countRouteMethod(method, customStyle = {}) {
    let style = {
        marginRight: '4px',
        fontSize: '10px',
        minWidth: '31px',
        height: '14px',
        textAlign: 'center',
        fontWeight: '600',
        whiteSpace: 'nowrap',
    };
    style = Object.assign({}, style, customStyle);

    switch (method) {
        case 'GET':
            style = { ...style, color: 'rgb(34, 142, 55)' };
            return <span style={style}>GET</span>;
        case 'POST':
            style = { ...style, color: 'rgb(254, 166, 11)' };
            return <span style={style}>POST</span>;
        case 'PUT':
            style = { ...style, color: 'rgb(17, 97, 229)' };
            return <span style={style}>PUT</span>;
        case 'PATCH':
            style = { ...style, color: '#909090' };
            return <span style={style}>PATCH</span>;
        case 'DELETE':
            style = { ...style, color: 'rgb(228, 0, 17)' };
            return <span style={style}>DEL</span>;
        default:
            style = { ...style, color: '#fed42f' };
            return <span style={style}>OTHER</span>;
    }
}

let lastId = 0;
export function uniqueId(prefix = 'id-') {
    lastId++;
    return `${prefix}${lastId}`;
}

export function isActiveApiTab(location, name) {
    if (location.pathname.indexOf(name) !== -1) return true;

    return false;
}

export function replaceEnvVariable(initialString, envVariablesArray) {
    if (!initialString || !envVariablesArray) return initialString;

    if (typeof initialString !== 'string') {
        throw new TypeError(
            `Wrong data type at first parameter. 'String' expected, instead got '${typeof initialString}'`,
        );
    }

    if (!Array.isArray(envVariablesArray)) {
        throw new TypeError(
            `Wrong data type at second parameter. 'Array' expected, instead got '${typeof envVariablesArray}'`,
        );
    }

    const regExp = /\{\{.*?\}\}/g;
    const matchesArray = initialString.match(regExp);

    if (!matchesArray) return initialString;

    const newString = matchesArray.reduce((acc, matchElem) => {
        envVariablesArray.forEach((envVariable) => {
            if (
                envVariable.name === matchElem.slice(2, -2) &&
                envVariable.is_active === 1
            ) {
                acc = acc.replace(matchElem, envVariable.value);
            }
        });
        return acc;
    }, initialString);

    return newString;
}

export const createShallowCopy = (data) => {
    return JSON.parse(JSON.stringify(data));
};

export const generateId = () => {
    return Math.random().toString(16).slice(2);
};

export const capitalize = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

export const combineClasses = (...args) => {
    const result = [];

    const parseObject = (arg) => {
        let keys = '';

        for (const key in arg) {
            if (arg[key]) keys += ' ' + key;
        }
        return keys.trim();
    };

    for (const className of args.flat(Infinity)) {
        if (className) {
            if (typeof className === 'object') {
                const classes = parseObject(className);
                if (classes) result.push(classes);
                continue;
            }
            result.push(className);
        }
    }

    return result.join(' ');
};

export const omit = (target, ...props) => {
    if (!props.length || !Object.keys(target).length) {
        return target;
    }

    const result = {};

    for (const prop in target) {
        if (!props.includes(prop)) {
            result[prop] = target[prop];
        }
    }

    return createShallowCopy(result);
};

export const defineCurrentDomain = () => {
    if (process.env.NODE_ENV === 'development') {
        const subDomain = window.location.hostname.split('.')[0];
        if (subDomain !== 'localhost') {
            return subDomain;
        } else {
            return null;
        }
    } else {
        const currentDomain =
            process.env.REACT_APP_DOMAIN === window.location.hostname
                ? null
                : window.location.hostname.split('.')[0];
        return currentDomain;
    }
};

export const isEmptyObject = (object) => {
    return object ? !Object.keys(object)?.length : !!object;
};
