import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Markdown from 'markdown-to-jsx';
import {
    countRouteMethod,
    createShallowCopy,
    isEmptyObject,
    replaceEnvVariable,
} from 'utils/helpers';

import { useState } from 'react';
import RequestTab from './RequestTab';
import ResponsesTab from './ResponsesTab';
import { documentationSelectors } from 'store/slices/documentationSlice';
import { getPublicDocsRoute, routeSelectors } from 'store/slices/routesSlice';
import { resourceSelectors } from 'store/slices/resourcesSlice';
import { environmentSelectors } from 'store/slices/environmentsSlice';
import RouteNotFound from '../RouteNotFound';
import LocalLoader from 'ui/LocalLoader';

const RouteView = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const api = useSelector(documentationSelectors.getApi);
    const route = useSelector(routeSelectors.getDocsRoute);
    const resources = useSelector(resourceSelectors.getDocsResources);
    const responses = useSelector(resourceSelectors.getDocsResponses);
    const selectedEnvironment = useSelector(
        environmentSelectors.getSelectedDocsEnvironment,
    );
    const isRouteFetching = useSelector(routeSelectors.getIsDocsRouteFetching);
    const resourcesLoadingStatus = useSelector(
        resourceSelectors.getDocsResourcesLoadingStatus,
    );
    const isApiInformationFetching = useSelector(
        documentationSelectors.getIsApiInformationFetching,
    );

    const [currentTab, setCurrentTab] = useState('request');

    const token = localStorage.getItem('docs_access_token');

    useEffect(() => {
        if (
            !isApiInformationFetching &&
            resourcesLoadingStatus === 'succeeded'
        ) {
            dispatch(
                getPublicDocsRoute({
                    apiId: api.id,
                    routeId: id,
                    token,
                }),
            );
        }
    }, [
        id,
        api,
        isApiInformationFetching,
        token,
        resourcesLoadingStatus,
        dispatch,
    ]);

    const selectTab = (e) => {
        setCurrentTab(e.target.dataset.tab);
    };

    const models = resources?.map((el) => JSON.parse(el.schema));
    const routeHeaders = [
        ...createShallowCopy(route?.headers || []),
        ...createShallowCopy(
            route?.global_headers?.filter((el) => el.apply_to_request) || [],
        ),
    ];

    return (
        <>
            <Helmet>
                <title>
                    {`${route?.name} - ${api.name} - ${api.project_name}`} -
                    CDDocs
                </title>
            </Helmet>
            <LocalLoader
                loading={isEmptyObject(route) || isRouteFetching}
                className="mt-5"
            >
                {!!route ? (
                    <div className="row">
                        <div className="col-12 col-xl-10">
                            <div className="row">
                                <div className="col col-lg-12">
                                    <h3 className="title-h4 mb-3 word-break fw-bold">
                                        {route.name ||
                                            replaceEnvVariable(
                                                route.url,
                                                selectedEnvironment?.variables,
                                            )}
                                    </h3>
                                    {route.description && (
                                        <div className="mb-3">
                                            <div>
                                                <Markdown className="mb-4 ck route-view page-description-paragraph">
                                                    {route.description}
                                                </Markdown>
                                            </div>
                                        </div>
                                    )}
                                    <div className="mb-3">
                                        <span className="anchor" id="request" />
                                        <h5 className="mb-2 title-h5">
                                            HTTP Request
                                        </h5>
                                        <div className="badge-route word-break">
                                            {countRouteMethod(route.method, {
                                                fontSize: 14,
                                                lineHeight: '16px',
                                                padding: '4px 8px',
                                                backgroundColor: '#F6F7F9',
                                                borderRadius: 4,
                                                marginRight: 10,
                                                height: 'auto',
                                                minWidth: 'auto',
                                            })}
                                            {replaceEnvVariable(
                                                route.url,
                                                selectedEnvironment?.variables,
                                            )}
                                        </div>
                                    </div>
                                    <ul className="route-tabs-list">
                                        <li
                                            data-tab="request"
                                            className={
                                                currentTab === 'request'
                                                    ? 'route-tabs-list-item--selected'
                                                    : 'route-tabs-list-item'
                                            }
                                            onClick={selectTab}
                                        >
                                            Request
                                        </li>
                                        <li
                                            data-tab="response"
                                            className={
                                                currentTab === 'response'
                                                    ? 'route-tabs-list-item--selected'
                                                    : 'route-tabs-list-item'
                                            }
                                            onClick={selectTab}
                                        >
                                            Responses
                                        </li>
                                    </ul>
                                    {currentTab === 'request' && (
                                        <RequestTab
                                            route={route}
                                            models={models}
                                            routeHeaders={routeHeaders}
                                            variables={
                                                selectedEnvironment?.variables
                                            }
                                        />
                                    )}
                                    {currentTab === 'response' && (
                                        <ResponsesTab
                                            route={route}
                                            resources={models}
                                            responses={responses}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <RouteNotFound />
                )}
            </LocalLoader>
        </>
    );
};

export default RouteView;
