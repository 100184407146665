import React from 'react';
import { ProjectAvatar, UserAvatar } from 'components/Avatar';
import moment from 'moment';

const TransferItem = ({
    myAccount,
    transfer,
    handleCancelTransferModal,
    handleAcceptTransferModal,
    handleDeclineTransferModal,
}) => {
    const isOwner = myAccount.id === transfer.from_user.id;

    return (
        <tr className="table-main-row" key={transfer.id}>
            <td>{transfer.id}</td>
            <td style={{ position: 'relative' }}>
                <ProjectAvatar project={transfer.project} />
                <a
                    className="table-main-link"
                    href={`/project?project=${transfer.project.uid}`}
                    name={transfer.project.uid}
                >
                    {transfer.project.name}
                </a>
            </td>
            <td>
                <UserAvatar
                    iconUrl={transfer.from_user.avatar_url}
                    first_name={transfer.from_user.first_name}
                    last_name={transfer.from_user.last_name}
                />
                <a
                    className="table-main-link"
                    href={`/people/${transfer.from_user.uuid}`}
                >
                    {`${transfer.from_user.first_name} ${transfer.from_user.last_name}`}
                </a>
            </td>
            <td>
                {' '}
                <UserAvatar
                    iconUrl={transfer.to_user.avatar_url}
                    first_name={transfer.to_user.first_name}
                    last_name={transfer.to_user.last_name}
                />
                <a
                    className="table-main-link"
                    href={`/people/${transfer.to_user.uuid}`}
                >
                    {`${transfer.to_user.first_name} ${transfer.to_user.last_name}`}
                </a>
            </td>
            <td>{moment(transfer.created_at).format('Do of MMM YYYY')}</td>
            <td>
                {isOwner ? (
                    <div className="text-end">
                        <button
                            className="btn btn-danger btn-sm text-end"
                            onClick={(e) =>
                                handleCancelTransferModal(e, transfer)
                            }
                        >
                            Cancel
                        </button>
                    </div>
                ) : (
                    <div className="text-end">
                        <button
                            className="btn btn-success btn-sm me-3"
                            onClick={(e) =>
                                handleAcceptTransferModal(e, transfer)
                            }
                        >
                            Accept
                        </button>
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={(e) =>
                                handleDeclineTransferModal(e, transfer)
                            }
                        >
                            Decline
                        </button>
                    </div>
                )}
            </td>
        </tr>
    );
};

const TransferTable = ({
    transfers,
    myAccount,
    handleCancelTransferModal,
    handleAcceptTransferModal,
    handleDeclineTransferModal,
}) => {
    return (
        <div className="project-list mx-5">
            <table className="table table-hover table-main projects-table">
                <thead className="table-main-head">
                    <tr className="projects-table-row">
                        <th className="projects-table-title">Transfer ID</th>
                        <th className="projects-table-title">Project</th>
                        <th className="projects-table-title">From user</th>
                        <th className="projects-table-title">To user</th>
                        <th className="projects-table-title">Started at</th>
                        <th className="projects-table-title text-end">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody className="table-main-body">
                    {transfers.length > 0 &&
                        transfers.map((transfer) => (
                            <TransferItem
                                transfer={transfer}
                                key={transfer.id}
                                myAccount={myAccount}
                                handleCancelTransferModal={
                                    handleCancelTransferModal
                                }
                                handleAcceptTransferModal={
                                    handleAcceptTransferModal
                                }
                                handleDeclineTransferModal={
                                    handleDeclineTransferModal
                                }
                            />
                        ))}
                    {transfers.length === 0 && (
                        <tr>
                            <td colSpan="6" className="text-center">
                                No transfers found.
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default TransferTable;
