import React, { Fragment } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Shield, User, Users } from 'react-feather';

import MainContent from 'ui/MainContent';
import { LeftSideBar, LeftSideBarHeader } from 'components/LeftSideBar';
import UserAvatar from 'components/Avatar/UserAvatar';
import Profile from './Profile';
import SecurityPage from './Security/SecurityPage';
import { authSelectors } from 'store/slices/authSlice';
import { OfficeBuilding } from 'assets/img/Svg';
import Organization from './Organization';
import { organizationSelectors } from 'store/slices/organizationSlice';
import Team from './Team';
import PermissionRoute from 'routes/PermissionRoute';

const UserSettingsLayout = () => {
    const myAccount = useSelector(authSelectors.getMyAccount);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );

    const hasOwnerRights = currentOrg?.role === 'OWNER';
    const hasRights = hasOwnerRights || currentOrg?.role === 'ADMIN';

    return (
        <Fragment>
            <LeftSideBar>
                <nav>
                    <LeftSideBarHeader>
                        <UserAvatar
                            iconUrl={myAccount.avatar_url}
                            first_name={myAccount.first_name}
                            last_name={myAccount.last_name}
                        />
                        <strong>{`${myAccount.first_name} ${myAccount.last_name}`}</strong>
                    </LeftSideBarHeader>

                    <ul className="nav flex-column">
                        <li className="nav-item sidebar-link-item mx-2">
                            <NavLink
                                className="nav-link sidebar-link"
                                activeClassName="sidebar-link-active"
                                to={`/settings/profile`}
                            >
                                <User
                                    size={20}
                                    className="sidebar-link-icon"
                                    strokeWidth={1.5}
                                />
                                Profile
                            </NavLink>
                        </li>
                        <li className="nav-item sidebar-link-item mx-2">
                            <NavLink
                                activeClassName="sidebar-link-active"
                                className="nav-link sidebar-link"
                                to={`/settings/security`}
                            >
                                <Shield
                                    size={20}
                                    className="sidebar-link-icon"
                                    strokeWidth={1.5}
                                />
                                Security
                            </NavLink>
                        </li>
                        {currentOrg?.role === 'OWNER' && (
                            <li className="nav-item sidebar-link-item mx-2">
                                <NavLink
                                    activeClassName="sidebar-link-active"
                                    className="nav-link sidebar-link"
                                    to={`/settings/organization`}
                                >
                                    <OfficeBuilding className="sidebar-link-icon" />
                                    Organization
                                </NavLink>
                            </li>
                        )}
                        {(currentOrg?.role === 'OWNER' ||
                            currentOrg?.role === 'ADMIN') && (
                            <li className="nav-item sidebar-link-item mx-2">
                                <NavLink
                                    className="nav-link sidebar-link"
                                    activeClassName="sidebar-link-active"
                                    to={`/settings/team`}
                                >
                                    <Users
                                        size={20}
                                        className="sidebar-link-icon"
                                        strokeWidth={1.5}
                                    />
                                    Team
                                </NavLink>
                            </li>
                        )}
                    </ul>
                </nav>
            </LeftSideBar>
            <MainContent>
                <Switch>
                    <Route exact path="/settings/profile">
                        <Profile myAccount={myAccount} />
                    </Route>
                    <Route exact path="/settings/security">
                        <SecurityPage />
                    </Route>
                    <PermissionRoute
                        exact
                        path="/settings/organization"
                        component={Organization}
                        redirectTo="/403"
                        hasRights={hasOwnerRights}
                    />
                    <PermissionRoute
                        exact
                        path="/settings/team"
                        component={Team}
                        redirectTo="/403"
                        hasRights={hasRights}
                    />
                </Switch>
            </MainContent>
        </Fragment>
    );
};

export default UserSettingsLayout;
