import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { MoreHorizontal } from 'react-feather';

import useDragMethod from 'hooks/useDragMethod';
import useOutsideClick from 'hooks/useOutsideClick';
import { uniqueId } from 'utils/helpers';
import useDropdownPosition from 'hooks/useDropdownPosition';
import { getCookie } from 'utils/cookie';
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store/slices/authSlice';
import { organizationSelectors } from 'store/slices/organizationSlice';
import { apiSelectors } from 'store/slices/apiSlice';

export default function RoutesGroupChildren({
    project,
    api,
    path,
    countMethod,
    handleClickAction,
    route,
    selectedDropdown,
    toggleDropdown,
    routesSettings: routesNamesSettings,
}) {
    const myAccount = useSelector(authSelectors.getMyAccount);
    const currentOrg = useSelector(
        organizationSelectors.getCurrentOrganization,
    );
    const apis = useSelector(apiSelectors.getApis);
    const [dropdownId, setDropdownId] = useState(undefined);
    const [rowHeight, setRowHeight] = useState(40);
    const itemRef = useRef(null);
    const dropdownRef = useRef(null);
    const routeLink = useRef();
    const { drag } = useDragMethod(route, 'route');
    const isActiveLink =
        path.pathname === `/project/api/routes/browse/${route.id}` ||
        path.pathname === `/project/api/routes/${route.id}/edit`;
    const hasOwnerRights =
        project.owner.id === myAccount.id || currentOrg?.role === 'OWNER';
    const hasRights =
        hasOwnerRights ||
        currentOrg?.role === 'ADMIN' ||
        project.user_role === 'MAINTAINER' ||
        project.user_role === 'WRITE';
    const isShowDropdown = selectedDropdown === dropdownId;

    useOutsideClick(dropdownRef, () => toggleDropdown(undefined, null));
    drag(itemRef);
    useDropdownPosition(dropdownRef, isShowDropdown);
    const routesSettings = JSON.parse(getCookie('routes_settings'));
    const routeNameMarkup = {
        name: <span className="me-1 d-block">{route.name}</span>,
        url: <span className="me-1 d-block">{route.url || ''}</span>,
        'name/url': (
            <>
                <span className="me-1 d-block">{route.name}</span>
                <span className="me-1 d-block sidebar-link-route-subtitle">
                    {route.url || ''}
                </span>
            </>
        ),
    };

    useLayoutEffect(() => {
        if (routeLink?.current?.offsetHeight)
            setRowHeight(routeLink?.current?.offsetHeight);
    }, [routesNamesSettings]);

    const routeMethodStyles = {
        alignSelf: rowHeight > 40 ? 'baseline' : 'center',
    };

    return (
        <li className="nav-item sidebar-link-item mx-2" ref={itemRef}>
            <Link
                className={
                    (isActiveLink && 'sidebar-link-active') +
                    ' nav-link sidebar-link sidebar-link-route word-break--word'
                }
                to={`/project/api/routes/browse/${route.id}?project=${project.uid}&api=${api.uid}`}
                ref={routeLink}
            >
                {countMethod(route.method, routeMethodStyles)}
                <div>
                    {routeNameMarkup[routesSettings?.routeName] || route.name}
                </div>
                <div className="action-container">
                    {hasRights && (
                        <>
                            <button
                                type="button"
                                onClick={(e) => {
                                    const id = uniqueId();
                                    setDropdownId(id);
                                    toggleDropdown(e, id);
                                }}
                                className="btn-action edit hover-default"
                            >
                                <MoreHorizontal size={16} color="#000000" />
                            </button>
                            {isShowDropdown && (
                                <ul
                                    ref={dropdownRef}
                                    className="dropdown-list"
                                    style={{ minWidth: '110px' }}
                                >
                                    <li>
                                        <button
                                            className="dropdown-link"
                                            name="edit"
                                            onClick={(e) => {
                                                handleClickAction(
                                                    e,
                                                    route,
                                                    'edit-route',
                                                );
                                                toggleDropdown(undefined, null);
                                            }}
                                        >
                                            Edit
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className="dropdown-link"
                                            name="duplicate"
                                            onClick={(e) => {
                                                handleClickAction(
                                                    e,
                                                    route,
                                                    'duplicate-route',
                                                );
                                                toggleDropdown(undefined, null);
                                            }}
                                        >
                                            Duplicate
                                        </button>
                                    </li>
                                    {apis?.length > 1 && (
                                        <li>
                                            <button
                                                className="dropdown-link"
                                                name="copy"
                                                onClick={(e) =>
                                                    handleClickAction(
                                                        e,
                                                        route,
                                                        'copy-route',
                                                    )
                                                }
                                            >
                                                Copy to API
                                            </button>
                                        </li>
                                    )}

                                    <li>
                                        <button
                                            className="dropdown-link text-danger"
                                            name="delete"
                                            onClick={(e) =>
                                                handleClickAction(
                                                    e,
                                                    route,
                                                    'delete-route',
                                                )
                                            }
                                        >
                                            Delete
                                        </button>
                                    </li>
                                </ul>
                            )}
                        </>
                    )}
                </div>
            </Link>
        </li>
    );
}
