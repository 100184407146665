import React from 'react';
import AppLogo from 'components/AppLogo/AppLogo';
import PropTypes from 'prop-types';

const WelcomeMenu = ({ active, setMenuActive }) => {
    return (
        <nav
            className={
                active ? 'welcome-menu-active welcome-menu ' : 'welcome-menu'
            }
            onClick={() => setMenuActive(false)}
        >
            <div className="welcome-blur" />
            <ul
                className="welcome-menu__box"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="position-relative d-flex justify-content-between align-items-center w-100">
                    <AppLogo />
                    <div
                        className="position-relative"
                        onClick={() => setMenuActive(false)}
                    >
                        <span className="welcome-menu-close"></span>
                    </div>
                </div>

                <li className="pt-5 pb-4">
                    <a className="welcome-header__link" href="/">
                        About
                    </a>
                </li>
                <li className="pb-4">
                    <a
                        className="welcome-header__link"
                        href="/#pricing-section"
                    >
                        Pricing
                    </a>
                </li>
                <li className="pb-4">
                    <a
                        className="welcome-header__link"
                        href="/#contact-section"
                    >
                        Contact
                    </a>
                </li>
                {/* <li className="pb-5">
                                <a className="welcome-header__link" href="/partners">
                                    Partners
                                </a>
                            </li> */}
                <li>
                    <a href="/login" className="welcome-header__login">
                        Log in
                    </a>
                    <a
                        href="/signup"
                        className="welcome-header__signup btn btn-primary "
                    >
                        Sign up
                    </a>
                </li>
            </ul>
        </nav>
    );
};

WelcomeMenu.propTypes = {
    active: PropTypes.bool,
    setMenuActive: PropTypes.func,
};

export default WelcomeMenu;
