import { CardList } from './components/CardsList';
import closeIcon from 'assets/img/x.svg';
import { useModalCardsList } from './hooks/useModalCardsList';

export const ModalCardsList = ({ setIsShowModal }) => {
    const {
        selectedCardId,
        handleChangeCard,
        handleShowNewCard,
        showNewCard,
        cardName,
        handleCardElementChange,
        handleChangeCardName,
        error,
        newCardIdInput,
        isLoading,
        currentDefaultCard,
        handleSubmit,
        handleDeleteCard,
        resetCardsInputs,
        isCardDeleting,
    } = useModalCardsList();

    const isDisabled = selectedCardId === currentDefaultCard || isLoading;

    return (
        <>
            <div className="modal-header align-items border-bottom-0">
                <h5 className="modal-title fw-semibold">
                    Change payment method
                </h5>
                <div
                    onClick={() => {
                        setIsShowModal((prev) => !prev);
                        resetCardsInputs();
                    }}
                    className="cursor-pointer"
                >
                    <img src={closeIcon} alt="close" />
                </div>
                {/* </div> */}
            </div>
            <div className="modal-body">
                <div>
                    <CardList
                        selectedCardId={selectedCardId}
                        handleCardElementChange={handleCardElementChange}
                        handleChangeCard={handleChangeCard}
                        handleChangeCardName={handleChangeCardName}
                        handleShowNewCard={handleShowNewCard}
                        showNewCard={showNewCard}
                        newCardIdInput={newCardIdInput}
                        cardName={cardName}
                        error={error}
                        handleDeleteCard={handleDeleteCard}
                        isCardDeleting={isCardDeleting}
                    />
                </div>
            </div>
            <div className="modal-footer border-top-0">
                <button
                    type="button"
                    className="btn btn-primary w-100"
                    disabled={isDisabled}
                    onClick={handleSubmit}
                >
                    {isLoading ? 'Processing...' : 'Save changes'}
                </button>
            </div>
        </>
    );
};
