import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http, { CancelToken } from 'utils/http';
import { hideGlobalLoader, showGlobalLoader } from './appSlice';
import qs from 'qs';

let cancelFetchHistory;

export const fetchHistory = createAsyncThunk(
    'history/fetchHistory',
    async ({ apiId, params }, { dispatch }) => {
        try {
            const query = `?${qs.stringify(params)}`;
            if (cancelFetchHistory !== undefined) {
                cancelFetchHistory();
            }
            dispatch(isHistoryFetching(true));
            const response = await http.get(
                `/api/v1/api/${apiId}/route-history${query}`,
                {
                    cancelToken: new CancelToken(function executor(c) {
                        cancelFetchHistory = c;
                    }),
                },
            );
            dispatch(isHistoryFetching(false));

            return response.data.data;
        } catch (error) {
            return Promise.reject(error.response);
        } finally {
            dispatch(isHistoryFetching(false));
        }
    },
);

export const fetchHistoryById = createAsyncThunk(
    'history/fetchHistoryById',
    async ({ hId, apiId }, { dispatch }) => {
        try {
            dispatch(showGlobalLoader());
            await http.get(`/api/v1/api/${apiId}/route-history/${hId}`);
            dispatch(hideGlobalLoader());
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(hideGlobalLoader());
        }
    },
);

const initialState = {
    history: [],
    historyById: {},
    isHistoryFetching: false,
};

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        isHistoryFetching(state, action) {
            state.isHistoryFetching = action.payload;
        },
    },
    extraReducers: {
        [fetchHistory.fulfilled]: (state, action) => {
            state.history = action.payload;
        },
        [fetchHistoryById.fulfilled]: (state, action) => {
            state.historyById = action.payload;
        },
    },
});

// Actions

export const { isHistoryFetching } = historySlice.actions;

// Selectors

export const historySelectors = {
    getHistory: (state) => state.history.history,
    getCurrentHistory: (state) => state.history.historyById,
    getIsHistoryFetching: (state) => state.history.isHistoryFetching,
    getIsHistoryByIdFetching: (state) => state.history.isHistoryByIdFetching,
};

export default historySlice.reducer;
