import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { combineClasses as cc } from 'utils/helpers';

const ToggleButton = (props) => {
    const [toggle, setToggle] = useState(false);
    const {
        disabled,
        className,
        defaultChecked,
        onChange,
        check,
        uncheck,
        checkClassNames,
        uncheckClassNames,
    } = props;

    useEffect(() => {
        if (defaultChecked) {
            setToggle(defaultChecked);
        }
    }, [defaultChecked]);

    const toggleClasses = cc(
        'toggle-button',
        {
            'toggle-button--checked': toggle,
            'toggle-button--disabled': disabled,
        },
        className,
    );

    const handleToggle = () => {
        if (disabled) {
            return;
        }

        setToggle(!toggle);

        if (typeof onChange === 'function') {
            onChange(!toggle);
        }
    };

    return (
        <div className={toggleClasses}>
            <p
                htmlFor="flexSwitchCheckDefault"
                className={cc(`toggle-button-uncheck`, checkClassNames)}
            >
                {check}
            </p>
            <div className={toggleClasses} onClick={handleToggle}>
                <div className="toggle-button-container"></div>
                <div className="toggle-button-circle"></div>
                <input
                    className="toggle-button-input"
                    type="checkbox"
                    aria-label="Toggle Button"
                />
            </div>
            <p
                htmlFor="flexSwitchCheckDefault"
                className={cc(`toggle-button-check`, uncheckClassNames)}
            >
                {uncheck}
            </p>
        </div>
    );
};

ToggleButton.propTypes = {
    disabled: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    check: PropTypes.string,
    uncheck: PropTypes.string,
};

export default ToggleButton;
