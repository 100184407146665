import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ChevronLeft } from 'react-feather';

import { HistoryItem } from './Table';
import { apiSelectors } from 'store/slices/apiSlice';
import { projectSelectors } from 'store/slices/projectsSlice';
import { fetchHistoryById, historySelectors } from 'store/slices/historySlice';

export const View = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const api = useSelector(apiSelectors.getCurrentApi);
    const project = useSelector(projectSelectors.getCurrentProject);
    const historyById = useSelector(historySelectors.getCurrentHistory);
    // const isHistoryByIdFetching = useSelector(
    //     historySelectors.getIsHistoryByIdFetching
    // )

    useEffect(() => {
        dispatch(fetchHistoryById({ hId: id, apiUid: api.id }));
    }, [api.id, dispatch, id]);

    return (
        <div className="project-list mx-5 mt-4">
            <Link
                to={`/project/api/history?project=${project.uid}&api=${api.uid}`}
                className="d-inline-flex align-items-center link-back"
            >
                <ChevronLeft size={16} color="#001A37" className="me-2" />
                <span>Return back to history</span>
            </Link>
            <table className="table table-main projects-table">
                <thead className="table-main-head">
                    <tr className="projects-table-row">
                        <th className="projects-table-title"></th>
                        <th className="projects-table-title">
                            Date of editing
                        </th>
                        <th className="projects-table-title">Edit by</th>
                        <th className="projects-table-title">Route name</th>
                        <th className="projects-table-title">Action</th>
                        <th className="projects-table-title text-end">Link</th>
                    </tr>
                </thead>
                <tbody className="table-main-body">
                    {Object.keys(historyById).length !== 0 && (
                        <HistoryItem
                            project={project}
                            api={api}
                            history={historyById}
                            isOpened={true}
                        />
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default View;
